import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FeatureGroup
} from 'react-leaflet';
import L from "leaflet";
import Select from 'react-select';
import { toast } from 'react-toastify';

import Block from '../../components/Block';
import Panel from '../../components/Panel';
import LoadingOverlay from '../../components/LoadingOverlay';
import Map from '../../components/Maps/Map';
import VirtualizedList from '../../components/VirtualizedList';
import LandmarksLayer from '../../components/Maps/LandmarksLayer';
import LandmarkForm from '../../forms/LandmarkForm';
import { useDeviceContext } from '../../features/DeviceContext';
import { useGeoContext } from '../../features/GeoContext';
import { getCurrentUser } from '../../services/AuthService';
import * as GeoService from '../../services/GeoService';
import SearchInput from '../../components/SearchInput';

import '../../styles/Form.scss';
import 'react-toastify/dist/ReactToastify.css';

const LandmarkPage = () => {
  const {
    devicesState
  } = useDeviceContext();

  const {
    colors,
    emblems,
    fences,
    addFence,
    landmarks,
    addLandmark,
    updateLandmark,
    removeLandmark,
    updateLandmarks
  } = useGeoContext();

  const user = getCurrentUser();

  // As definições do mapa
  const mapRef = useRef();
  const mapCenter = {
    position: [-23.3292135, -46.7273893],
    zoom: 11
  };
  const featureGroupRef = useRef();
  
  const [searchValue, setSearchValue] = useState('');
  
  // Os clientes
  const customers = useMemo(() => {
    if (user.groupID < 6) {
      // Filtra os dispositivos para obter apenas aqueles com cliente
      // definido
      const filteredDevices = devicesState.devices.filter(
        device => device.customer !== undefined && device.customer !== null
      );

      // Cria um conjunto de clientes únicos 
      const uniqueCustomers = new Set();
      filteredDevices.forEach(device => {
        uniqueCustomers.add(device.customer.id);
      });

      // Converte o conjunto de clientes de volta para um array
      const uniqueCustomersArray = Array.from(uniqueCustomers);

      // Ordena os clientes alfabeticamente com base no nome
      const sortedCustomers = uniqueCustomersArray
        .map(customerId => {
          const device = filteredDevices.find(d => d.customer.id === customerId);
          return {
            value: device.customer.id,
            label: device.customer.id === device.customer.payerID
              ? device.customer.name
              : device.customer.affiliated
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
      ;

      // Adicionamos no começo uma opção para as cercas do usuário
      sortedCustomers.unshift({
        value: null,
        label: 'Meus pontos de referência'
      });

      return sortedCustomers;
    }
  }, [devicesState.devices, user.groupID]);
  const [selectedCustomer, setSelectedCustomer] = useState((customers && customers[0]) || null);
  
  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(true);
  
  const [editingLandmark, setEditingLandmark] = useState(null);
  const [editingLandmarks, setEditingLandmarks] = useState([]);
  const [addingLandmarkMode, setAddingLandmarkMode] = useState(false);
  const [visibleLandmarks, setVisibleLandmarks] = useState(landmarks);
  const [forceSelect, setForceSelect] = useState(null);

  const displayLandmarks = useMemo(() => {
    // Exibimos todos os pontos de referência, exceto os que estão em
    // modo de edição
    return landmarks.filter((landmark) => landmark.id !== editingLandmark?.id);
  }, [landmarks, editingLandmark]);

  const names = useMemo(() => {
    // Obtemos os nomes dos pontos de referência, tornando-os case
    // insensitive, sem espaços e sem acentos, mas retirando dos nomes
    // àquele que está em edição
    return landmarks.map(landmark => {
      if (landmark.id === editingLandmark?.id) {
        return null;
      }

      return landmark.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      ;
    });
  }, [landmarks, editingLandmark]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      backgroundColor: "var(--theme-input-bg)",
      borderRadius: ".375rem",
      borderColor: "var(--theme-input-border-color)",
      boxShadow: state.isFocused ? "0 0 0 2px #007bff" : "none",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      fontWeight: 400,
      lineHeight: 1.5,
      borderRadius: ".375rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-placeholder-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: state.isFocused ? "var(--theme-dropdown-hover-bg)" : "transparent",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: ".5rem .3rem",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: "var(--theme-dropdown-bg)",
      borderRadius: ".25rem",
      border: "1px solid var(--theme-input-border-color)"
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: "var(--theme-heading-color)",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: ".5rem .3rem",
      textTansform: "none",
    })
  };

  useEffect(() => {
    return () => {
      console.log('LandmarkPage is unmounting');

      if (user && user.groupID < 6) {
        // Carregamos os dados de pontos de referência do usuário
        console.log('Obtendo pontos de referência do usuário');

        GeoService.getLandmarks(user.customerID)
          .then((response) => {
            console.debug(`Recuperados ${response.length} pontos de referência`);
            // Atualiza os pontos de referência
            updateLandmarks(response);
          })
          .catch((error) => {
            const { message } = error;
      
            console.error(message);
          })
        ;
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      // Obtemos os pontos de referência do cliente selecionado
      console.log('Obtendo pontos de referência do cliente', selectedCustomer.label);
      setIsLoading(true);
      GeoService.getLandmarks(selectedCustomer.value)
        .then((response) => {
          console.debug(`Recuperados ${response.length} pontos de referência`);
          // Atualiza os pontos de referência
          updateLandmarks(response);

          // Depois de um tempo, fazemos o fit nos pontos de referência
          setTimeout(() => {
            setIsLoading(false);
            const bounds = new L.LatLngBounds();
            mapRef.current.eachLayer(function (layer) {
              if (layer instanceof L.TileLayer) {
                console.log('Ignoring base layer', layer.options.id);
                return;
              }
              if (layer instanceof L.FeatureGroup) {
                if (layer._layers === undefined || Object.keys(layer._layers).length === 0) {
                  console.log('Ignoring layer with no content');
                  return;
                }
                // Se as options do layer contiverem uma atribuição
                // (attribution) e o seu valor for 'ignoreOfFit', então não
                // incluímos a camada no ajuste do mapa
                if (layer.options.attribution === 'ignoreOnFit') {
                  console.log('Ignoring layer with hidden content');
                  return;
                }
        
                bounds.extend(layer.getBounds());
              }
            });
        
            if (bounds.isValid()) {
              mapRef.current.fitBounds(bounds,
                {
                  animated: true,
                  duration: 0.5,
                  easeLinearity: 0.50,
                  paddingTopLeft: [40, 40],
                  paddingBottomRight: [40, 40],
                  maxZoom: 17
                }
              );
            }
          }, 1000);
        })
        .catch((error) => {
          const { message } = error;
    
          console.error(message);
        })
      ;
    }
  }, [selectedCustomer]);

  useEffect(() => {
    const allLandmarks = landmarks.slice();
    if (searchValue) {
      const search = searchValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const filteredLandmarks = allLandmarks.filter((landmark) => {
        // Verifica se o ID do ponto de referência é igual ao do que
        // está em edição
        if (landmark.id === editingLandmark?.id) {
          return true;
        }

        // Verifica se o nome do ponto de referência contém o valor de
        // pesquisa (ignorando maiúsculas e minúsculas)
        const matchesSearchValue = landmark.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
          )
        ;

        return matchesSearchValue;
      });

      if (editingLandmark) {
        if (editingLandmark.id === 0) {
          // Adiciona também o novo ponto de referência em edição
          console.log('Adicionando ponto de referência em edição', editingLandmark);
          filteredLandmarks.push(editingLandmark);
        }
      }

      setVisibleLandmarks(filteredLandmarks);
    } else {
      if (editingLandmark) {
        if (editingLandmark.id === 0) {
          // Adiciona também o novo ponto de referência em edição
          allLandmarks.push(editingLandmark);
        }
      }

      setVisibleLandmarks(allLandmarks);
    }
  }, [searchValue, landmarks, editingLandmark]);

  const handleAddLandmark = () => {
    console.log('handleAddLandmark');
    setAddingLandmarkMode(
      prevAddingLandmarkMode => !prevAddingLandmarkMode
    );
  }

  const handleSelectLandmark = useCallback((landmark) => {
    setEditingLandmark(landmark);
    setEditingLandmarks([landmark]);

    // Determina os limites do ponto de referência
    const center = L.latLng(landmark.latitude, landmark.longitude);
    const bounds = center.toBounds(50);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 19
        }
      );
    }
  }, [landmarks, editingLandmark]);

  const handleDeleteLandmark = useCallback(async (landmark) => {
    console.log('Deleting landmark', landmark);

    if (landmark.id === 0) {
      if (editingLandmark) {
        if (editingLandmark.id === landmark.id) {
          setEditingLandmark(null);
          setEditingLandmarks([]);
        }
      }

      return;
    }

    // Fazemos a requisição para remover o ponto de referência
    const result = await GeoService.removeLandmark(landmark.id);
    if (result) {
      // Removemos o ponto de referência da lista
      removeLandmark(landmark);

      if (editingLandmark) {
        if (editingLandmark.id === landmark.id) {
          setEditingLandmark(null);
          setEditingLandmarks([]);
        }
      }
    }
  }, [landmarks, editingLandmark, removeLandmark]);

  const refreshLandmark = (field, newValue) => {
    if (!editingLandmark) {
      // Ignora, pois não há ponto de referência em edição
      return;
    }

    if (editingLandmark[field] === newValue) {
      // Ignora, pois o valor não foi alterado
      return;
    }

    const landmark = { ...editingLandmark, [field]: newValue };
    console.log(
      'Refresh landmark because field', field, 'changed to', newValue
    );
    setEditingLandmark(landmark);
    setEditingLandmarks([landmark]);

    if (field === 'latitude' || field === 'longitude') {
      // Determina os limites do ponto de referência
      const center = L.latLng(landmark.latitude, landmark.longitude);
      const bounds = center.toBounds(50);

      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds,
          {
            animated: true,
            duration: 0.5,
            easeLinearity: 0.50,
            paddingTopLeft: [50, 50],
            paddingBottomRight: [50, 50],
            maxZoom: 19
          }
        );
      }
    }
  };

  const handleLandmarkUpdated = async (landmarkData) => {
    console.log('handleLandmarkUpdated', landmarkData);

    // Fazemos a requisição para adicionar/modificar o ponto de referência
    const result = (landmarkData.id === 0)
      ? await GeoService.addLandmark(landmarkData)
      : await GeoService.updateLandmark(landmarkData)
    ;
    if (result) {
      if (landmarkData.id === 0) {
        // Adicionamos o ponto de referência na lista
        landmarkData.id = parseInt(result);
        addLandmark(landmarkData);
        console.log('Landmark added', landmarkData);
      } else {
        // Atualizamos o ponto de referência na lista
        updateLandmark(landmarkData);
        console.log('Modified landmark', landmarkData);
      }

      console.log('Landmarks deediting');
      setEditingLandmark(null);
      setEditingLandmarks([]);
    }
  };

  // Os métodos do lado do mapa
  const handleNewLandmark = (center) => {
    console.log('handleNewLandmark', center);
    const newLandmark = {
      id: 0,
      name: '',
      note: '',
      latitude: center[0],
      longitude: center[1],
      emblemid: 30,
      color: colors[0],
      showname: true,
      disabled: false,
      customerid: (user.groupID < 6)
        ? (
            selectedCustomer && selectedCustomer.value
              ? selectedCustomer.value
              : user.customerID
          )
        : user.customerID
    };
    console.log('New landmark', newLandmark);
    setEditingLandmark(newLandmark);
    setEditingLandmarks([newLandmark]);

    // Determina os limites do ponto de referência
    const landmarkCenter = L.latLng(
      newLandmark.latitude,
      newLandmark.longitude
    );
    const bounds = landmarkCenter.toBounds(50);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 19
        }
      );
    }
  };

  const handleEditLandmark = (landmarkID) => {
    const landmark = landmarks.find(f => f.id === landmarkID);
    setEditingLandmark(landmark);
    setEditingLandmarks([landmark]);

    // Determina os limites do ponto de referência
    const landmarkCenter = L.latLng(
      landmark.latitude, landmark.longitude
    );
    const bounds = landmarkCenter.toBounds(50);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 19
        }
      );
    }

  };

  const handleUpdatedLandmark = (landmark) => {
    setEditingLandmark(landmark);
    setEditingLandmarks([landmark]);
  }
  
  useEffect(() => {
    if (mapRef.current) {
      const classList = mapRef.current._container.classList;
      if (addingLandmarkMode) {
        classList.add('leaflet-interactive');
        classList.add('leaflet-crosshair');
      } else {
        classList.remove('leaflet-interactive');
        classList.remove('leaflet-crosshair');
      }
    }
  }, [addingLandmarkMode]);

  useEffect(() => {
    console.log('Editing landmark changed', editingLandmark);
    if (editingLandmark) {
      console.log('Set force select', editingLandmark.id);
      setForceSelect(editingLandmark.id);
    } else {
      console.log('Set force select to null');
      setForceSelect(null);
    }
  }, [editingLandmark]); 

  return (
    <Block horizontal={true}>
      <Panel style={styles.panel}>
        <h3 style={styles.title}>Pontos de referência</h3>
        {user.groupID < 6 && (
        <div style={styles.customerBar}>
          <span style={styles.customerLabel}>Cliente:</span>
          <Select
            id="customer"
            value={selectedCustomer}
            onChange={(customer) => {
              setSelectedCustomer(customer);
            }}
            options={customers}
            placeholder="Selecione um cliente..."
            styles={customStyles}
          />
        </div>
        )}
        <div style={styles.toolbar}>
          <SearchInput
            value={searchValue}
            placeholder="Localize um ponto de referência..."
            onChange={setSearchValue}
            disabled={editingLandmark !== null}
          />
          <button
            style={{
              ...styles.button,
              backgroundColor: addingLandmarkMode
                ? '#665d1e'
                : 'var(--theme-primary)',
              opacity: editingLandmark !== null ? 0.5 : 1
            }}
            onClick={handleAddLandmark}
            disabled={editingLandmark !== null}
          >
            <svg
              width=".9rem"
              height=".9rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" fill="currentColor" />
            </svg>
          </button>
        </div>
        <div style={styles.wrapper}>
        <VirtualizedList
          article='o'
          label="ponto de referência"
          items={visibleLandmarks}
          onSelect={handleSelectLandmark}
          onDelete={handleDeleteLandmark}
          forceSelect={forceSelect}
          onUnselect={() => {
            console.log('Landmark is unselected');
            setEditingLandmark(null);
            setEditingLandmarks([]);
          }}
          renderItem={(landmark, hideItem) => {
            return (
              <div style={{ margin: '0 .5em' }}>
                <LandmarkForm
                  landmark={editingLandmark}
                  colors={colors}
                  emblems={emblems}
                  names={names}
                  onFieldChange={refreshLandmark}
                  onSubmit={(landmark) => {
                    console.log('Landmark is submitting', landmark);
                    handleLandmarkUpdated(landmark);
                    hideItem();
                  }}
                  onCancel={() => {
                    console.log('Landmark is canceling');
                    hideItem();
                    setEditingLandmark(null);
                    setEditingLandmarks([]);
                  }}
                  onSaveAsFence={async () => {
                    console.log('Landmark is saving as fence');
                    // Obtém os dados do ponto de referência
                    const {
                      name,
                      latitude,
                      longitude,
                      note,
                      customerid
                    } = editingLandmark;

                    // Adiciona o ponto de referência como cerca
                    const newFence = {
                      id: 0,
                      name: name,
                      note: note,
                      format: 'CIRCLE',
                      latitude: latitude,
                      longitude: longitude,
                      radius: 200,
                      notification: "ENTER",
                      showinmap: true,
                      disabled: false,
                      customerid: customerid
                    }


                    // Analisa se não existe uma cerca com o mesmo nome
                    const fenceExists = fences.find(
                      fence => fence.name === newFence.name
                    );
                    if (fenceExists) {
                      // Emitimos uma mensagem de erro
                      toast.error(
                        'Já existe uma cerca com o nome '
                        + newFence.name, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });

                      return false;
                    }

                    // Analisa se não existe uma cerca com a mesma
                    // localização
                    const fenceAtLocation = fences.find(
                      fence => (
                        fence.latitude === newFence.latitude
                        && fence.longitude === newFence.longitude
                      )
                    );
                    if (fenceAtLocation) {
                      // Emitimos uma mensagem de erro
                      toast.error(
                        'Já existe uma cerca na localização '
                        + newFence.latitude + ', ' + newFence.longitude, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });

                      return false;
                    }

                    console.log('Adding fence', name, latitude, longitude, note);
                    const result = await GeoService.addFence(newFence);
                    if (result) {
                      // Adicionamos a cerca na lista
                      newFence.id = parseInt(result);
                      addFence(newFence);
                      console.log('Fence added', newFence);

                      // Emitimos uma mensagem de alerta
                      toast.info(
                        'Armazenada o ponto de referência ' + name
                        + ' com cerca', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    } else {
                      // Emitimos uma mensagem de erro
                      toast.error(
                        result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                      });
                    }

                    return true;
                  }}
                />
              </div>
            );
          }}
          noContentMessage="Nenhum ponto de referência encontrado"
        />
        </div>
      </Panel>
      <LoadingOverlay
        isLoading={isLoading}
        message="Aguarde enquanto carregamos os dados..."
      />
      <div
        className="section map"
        style={{
          flex: 1,
          height: '100%',
          minHeight: '89vh',
          boxShadow: 'none',
          borderLeft: '1px solid var(--theme-border-color)',
        }}
      >
        <Map
          onRef={mapRef}
          center={mapCenter}
          onMapReady={(map) => {
            // Fazemos o fit dos pontos de referência depois de um tempo
            setTimeout(() => {
              setIsLoading(false);
              const bounds = new L.LatLngBounds();
              mapRef.current.eachLayer(function (layer) {
                if (layer instanceof L.TileLayer) {
                  console.log('Ignoring base layer', layer.options.id);
                  return;
                }
                if (layer instanceof L.FeatureGroup) {
                  if (layer._layers === undefined || Object.keys(layer._layers).length === 0) {
                    console.log('Ignoring layer with no content');
                    return;
                  }
                  // Se as options do layer contiverem uma atribuição
                  // (attribution) e o seu valor for 'ignoreOfFit', então não
                  // incluímos a camada no ajuste do mapa
                  if (layer.options.attribution === 'ignoreOnFit') {
                    console.log('Ignoring layer with hidden content');
                    return;
                  }
          
                  bounds.extend(layer.getBounds());
                }
              });
          
              if (bounds.isValid()) {
                mapRef.current.fitBounds(bounds,
                  {
                    animated: true,
                    duration: 0.5,
                    easeLinearity: 0.50,
                    paddingTopLeft: [40, 40],
                    paddingBottomRight: [40, 40],
                    maxZoom: 17
                  }
                );
              }
            }, 1000);
          }}
        >
          <FeatureGroup
            ref={featureGroupRef}
          >
            <LandmarksLayer
              landmarks={editingLandmarks}
              emblems={emblems}
              onNewLandmark={handleNewLandmark}
              onUpdatedLandmark={handleUpdatedLandmark}
              addingMode={addingLandmarkMode}
              setAddingMode={setAddingLandmarkMode}
              underEditing={editingLandmark !== null}
            />
            <LandmarksLayer
              landmarks={displayLandmarks}
              emblems={emblems}
              onEditLandmark={handleEditLandmark}
              editable={true}
            />
          </FeatureGroup>
          { addingLandmarkMode && (
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px solid #b59124',
                backgroundColor: 'rgba(255, 204, 51, 0.7)',
                padding: '10px',
                width: '300px',
                fontWeight: '500',
                fontSize: '1rem',
                borderRadius: '.475rem',
                marginTop: '40px',
                marginLeft: '40px',
                textAlign: 'center',
                zIndex: 500
              }}>
                Para adicionar um novo ponto de referência, clique no
                local onde o mesmo está localizado
            </div>
          )}
        </Map>
      </div>
    </Block>
  );
};

const styles = {
  panel: {
    borderRadius: "0",
    flexDirection: "column",
    width: '500px',
    padding: '.5rem',
  },
  customerBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: '.5rem'
  },
  customerLabel: {
    fontSize: '.9rem',
    paddingBottom: '.1rem',
    color: 'var(--theme-text-color)',
  },
  title: {
    marginBottom: '.5rem',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '.5rem',
    gap: '.5rem',
  },
  button: {
    display: 'inline-flex',
    padding: '.58571429em .58571429em .58571429em',
    border: 'none',
    borderRadius: '.475rem',
    cursor: 'pointer',
    fontSize: '.9rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    gap: '.5rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rounded: '.475rem',
    flex: 1,
    border: '2px solid var(--theme-border-color)',
    backgroundColor: 'var(--theme-modal-bg)',
    borderRadius: '.5rem',
  }
};

export default LandmarkPage;
