import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import TenantRoute from './TenantRoute';
import PublicLayout from '../pages/Public/PublicLayout';
import HomePage from '../pages/Public/HomePage';
import AboutPage from '../pages/Public/AboutPage';
import MonitorPage from '../pages/Public/MonitorPage';
import UninformedTenant from '../pages/Public/UninformedTenant';
import NotFoundTenant from '../pages/Public/NotFoundTenant';
import NotFoundPage from '../pages/Public/NotFoundPage';
import PrivacyPolicyPage from '../pages/Public/PrivacyPolicyPage';
import AppHome from '../pages/Private/AppHome';
import LoginPage from '../pages/Private/LoginPage';
import ForgotPasswordPage from '../pages/Private/ForgotPasswordPage';
import CommandPage from '../pages/Private/CommandPage';
import FencePage from '../pages/Private/FencePage';
import LandmarkPage from '../pages/Private/LandmarkPage';
import HistoryPage from "../pages/Private/Reports/HistoryPage";
import DriverPage from "../pages/Private/DriverPage";
import TripHistoryPage from "../pages/Private/Reports/TripHistoryPage";
import SpeedHistoryPage from "../pages/Private/Reports/SpeedHistoryPage";
import AmountOfSpeedingHistoryPage from "../pages/Private/Reports/AmountOfSpeedingHistoryPage";
import AppNotFoundPage from '../pages/Private/AppNotFoundPage';

function Router() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/app/:tenantName/*" element={
          <TenantRoute>
            <Routes>
              <Route path="login" element={<LoginPage />} />
              <Route path="forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="*" element={
                <PrivateRoute>
                  <Routes>
                    <Route path="" element={<AppHome />} />
                    <Route path="fences" element={<FencePage />} />
                    <Route path="landmarks" element={<LandmarkPage />} />
                    <Route path="commands" element={<CommandPage />} />
                    <Route path="drivers" element={<DriverPage />} />
                    <Route path="reports/history" element={<HistoryPage />} />
                    <Route path="reports/triphistory" element={<TripHistoryPage />} />
                    <Route path="reports/speedhistory" element={<SpeedHistoryPage />} />
                    <Route path="reports/speedinghistory" element={<AmountOfSpeedingHistoryPage />} />
                    <Route path="privacypolicy" element={<PrivacyPolicyPage />} />
                    <Route path="*" element={<AppNotFoundPage />} />
                  </Routes>
                </PrivateRoute>
              } />
            </Routes>
          </TenantRoute>
        } />
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="app" element={<UninformedTenant />} />
          <Route path="supervisor" element={<MonitorPage />} />
          <Route path="notfoundtenant/:tenantName" element={<NotFoundTenant />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default Router;
