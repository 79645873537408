function byteToHexString(byteValue) {
  // Converte o valor inteiro para uma string hexadecimal
  let hexString = parseInt(byteValue, 10).toString(16);

  // Verifica se a string tem dois caracteres
  if (hexString.length === 1) {
    // Adiciona um zero à esquerda
    hexString = "0" + hexString;
  }

  return hexString;
}

function intToHexString(intValue, bytes) {
  // Converte o valor inteiro para uma string hexadecimal
  let hexString = intValue.toString(16);

  // Verifica se a string tem a quantidade de caracteres
  const size = bytes * 2;
  if (hexString.length !== size) {
    // Adiciona zeros à esquerda até que a string tenha 8 caracteres
    hexString = "0".repeat(size - hexString.length) + hexString;
  }

  return hexString;
}

function parameterConverter(value, commandID, position) {
  console.log("Conversão do valor de", value, commandID, position);
  switch (commandID) {
    case 127:
      // Limite de velocidade
      return byteToHexString(value);
    case 128:
      // Definição de servidor (IP e Porta)
      switch (position) {
        case 1:
          // Como é um endereço IP, vamos separar os octetos e
          // convertê-los para hexadecimal individualmente
          const parts = value.split(".");

          return (
            byteToHexString(parts[0]) +
            byteToHexString(parts[1]) +
            byteToHexString(parts[2]) +
            byteToHexString(parts[3])
          );
        case 2:
          // Porta
          return intToHexString(parseInt(value), 2);
        default:
          return value;
      }
    case 129:
      // Odômetro

      // A kilometragem possui uma casa decimal, então vamos multiplicar
      // o valor por 10 para que possamos converter para hexadecimal
      value = parseInt(value) * 10;

      return intToHexString(value, 3);
    case 130:
    case 131:
    case 133:
      // Intervalo de tempo do ciclo de acordar e dormir
      // Intervalo de tempo entre posições
      return intToHexString(value, 2);
    default:
      return value;
  }
}

export default parameterConverter;
