import React, {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

const IntegerControl = ({
  name,
  options = {},
  size,
  ...props
}) => {
  const {
    control,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  // Função para parsear o valor inteiro
  const parseValue = (value, fault = null) => {
    if (value === null || value === undefined) {
      return fault;
    }

    if (typeof value === 'string' || value instanceof String) {
      // Limpa quaisquer caracteres que não sejam números
      value = value.replace(/[^\d]/g, '');

      if (value === '') {
        return fault;
      }

      return parseInt(value);
    }

    if (isNaN(value)) {
      return fault;
    }

    if (Number.isInteger(value)) {
      return value;
    }

    return fault;
  }

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return parseValue(defaultValues[name]);
    }

    return null;
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(parseValue(defaultValues[name]));
    } else {
      setDefaultValue(null);
    }
  }, [defaultValues, name]);

  const {
    field,
    fieldState: { invalid, isTouched, isDirty }
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options },
  });

  const [intValue, setIntValue] = useState(parseValue(field.value));

  const [textValue, setTextValue] = useState(() => {
    const value = parseValue(field.value);

    if (value) {
      return value.toFixed(0);
    }

    return '';
  });

  const [changed, setChanged] = useState(false);

  // Função para renderizar o valor do campo
  const renderValue = (value) => {
    if (value === null) {
      setTextValue('');

      return;
    }

    setTextValue(value.toFixed(0));
  };

  // Atualiza o valor do campo quando o valor do campo muda
  useEffect(() => {
    if (changed) {
      console.log('internal change, ignore');
      setChanged(false);
      
      return;
    }

    const value = parseValue(field.value);

    setIntValue(value);
    renderValue(value);
  }, [field.value]);

  // Manipulador para mudanças no input
  const handleOnInput = (event) => {
    const { value: inputValue, selectionStart, selectionEnd } = event.target;
    let cursorStart = selectionStart;

    let newValue = inputValue;

    newValue = parseValue(newValue);

    if (newValue === null) {
      // Se o valor for nulo, então deixamos desta forma
      setIntValue(null);
      setTextValue('');
      setChanged(true);
      field.onChange(null);

      return;
    }
      
    newValue = newValue.toFixed(0);

    if (newValue !== inputValue) {
      // Se o valor foi alterado, então ajustamos a posição do cursor
      cursorStart = cursorStart - (inputValue.length - newValue.length);
    }

    if (newValue === '') {
      // Se o valor for vazio, então deixamos o valor como nulo
      setIntValue(null);
      setTextValue('');
      setChanged(true);
      field.onChange(null);

      return;
    }

    if (newValue.startsWith('0')) {
      if (cursorStart === 0) {
        cursorStart = 1;
      }
    }

    setTextValue(newValue);
    const newIntValue = newValue === ''
      ? null
      : parseInt(newValue)
    ;
    setIntValue(newIntValue);

    // Atualiza o valor do campo no formulário
    setChanged(true);
    field.onChange(newIntValue);

    // Restaura a posição do cursor depois da manipulação
    requestAnimationFrame(() => {
      event.target.setSelectionRange(cursorStart, cursorStart);
    });
  };

  // Manipulador para colar dados no input
  const handleOnPaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const cursorStart = event.target.selectionStart;
    const cursorEnd = event.target.selectionEnd;

    // Se o valor a ser colado for vazio, então não fazemos nada
    if (clipboardData === '') {
      return;
    }

    // Se o valor a ser colado for um número, então o tratamos como
    // um valor válido
    if (!isNaN(clipboardData)) {
      const newValue = textValue.slice(0, cursorStart)
        + clipboardData.replace('.', ',')
        + textValue.slice(cursorEnd)
      ;
      event.target.value = newValue;
      handleOnInput(event);

      return;
    }
  }

  // Manipulador para perda de foco do input
  const handleOnBlur = (event) => {
    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: intValue } };
    field.onBlur(newEvent);
  };

  return (
    <input
      ref={field.ref}
      id={name}
      type="text"
      value={textValue}
      onInput={handleOnInput}
      onPaste={handleOnPaste}
      onBlur={handleOnBlur}
      disabled={isSubmitting}
      {...props}
    />
  );
};

export default IntegerControl;