import {
  useFormContext,
  Controller
} from "react-hook-form";

const HiddenInteger = ({
  name
}) => {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      type="hidden"
      control={control}
      defaultValue=""
      render={({ field }) => (
        <input
          type="hidden"
          {...field}
          value={field.value || ""}
          onChange={(e) => {
            const value = e.target.value === "" ? null : parseInt(e.target.value, 10);
            field.onChange(value);
          }}
          disabled={isSubmitting}
        />
      )}
    />
  );
};

export default HiddenInteger;