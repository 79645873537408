import React from 'react';

const AboutPage = () => {
  return (
    <main className="site">
      <section id="about">
        <div className="content">
          <h2>
            Sobre
          </h2>
          <div className="sub header">
            Conheça um pouco sobre nossa empresa
          </div>
        </div>
        <p>
          A MR Tecnologia é uma empresa brasileira voltada para o
          desenvolvimento de softwares de gestão de frotas e monitoramento
          de veículos. Somos focados em soluções de rastreamento e
          telemetria para frotas de todos os portes e segmentos.
        </p>
        <p>
          Como existe uma demanda crescente por soluções inteligentes e
          eficientes para monitorar e controlar os veículos de empresas
          e pessoas físicas, a MR Tecnologia surge como uma empresa
          focada em criar soluções simples de utilizar e com os recursos
          na medida das necessidades do cliente, e que ofereçam diversas
          funcionalidades para facilitar a vida dos gestores e
          proprietários de frotas.
        </p>          
      </section>
    </main>
  );
};

export default AboutPage;