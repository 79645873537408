import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./Leaflet/leaflet-ruler";

import "./Leaflet/leaflet-ruler.css";

export default function MapRuler() {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    L.control.ruler().addTo(map);
  }, [map]);

  return null;
};
