import React from 'react';
import { Tooltip } from 'react-leaflet';
import shortid from 'shortid';

import {ReactComponent as Compass} from '../../assets/icons/markers/compass.svg';

function PlateTag({ course, label }) {
  return (
    <Tooltip
      key={shortid.generate()}
      direction="right"
      permanent="true"
      offset={[20, -28]}
      className="leaflet-tooltip-own"
    >
      <span
        key={shortid.generate()}
        style={{
          fontSize: '1em',
          padding: '0 .3em',
          verticalAlign: 'base-line',
          display: 'inline-block'
        }}
      >
        <Compass
          key={shortid.generate()}
          height={15}
          width={15}
          transform={'rotate(' + course + ', 0, 0)'}
          style={{
            transform: [{ rotateY: '90deg' }],
            marginRight: '.5em',
            marginBottom: '-4px',
            borderRadius: '50%',
            padding: '.1em .1em',
            backgroundColor: '#c7e3e8',
            display: 'inline-block',
            position: 'relative'
          }}
        />
        {label}
      </span>
    </Tooltip>
  );
}

export default PlateTag;