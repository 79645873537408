import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import Head from './Head';
import Body from './Body';
import Pagination from './Pagination';
import paginate from './paginate';

import './styles.scss';

function Table({
  data,
  columns,
  onClick,
  rowColor,
  isLoading,
  rowSize = 0,
  pageSize = 5,
  autoPageSize = false,
  noContentMessage,
  groupBy = null,
  groupRender= (group) => { return group; },
  ...rest
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(pageSize);
  const tableBodyRef = useRef(null);

  // Agrupamos os dados se necessário
  const groupedData = groupBy
    ? data.reduce((acc, item) => {
        const group = item[groupBy];
        acc[group] = acc[group] || [];
        acc[group].push(item);

        return acc;
      }, {})
    : { '': data }
  ;

  // Obtemos a quantidade de items total
  const totalItems = Object.values(groupedData).reduce((acc, group) => {
    return acc + group.length;
  }, 0);

  // Função para exibir os dados da página atual
  const displayData = () => {
    if (itemsPerPage < 1) return groupedData;

    if (totalItems <= itemsPerPage) {
      if (currentPage !== 1) {
        setCurrentPage(1);
      }

      return groupedData;
    }

    // Paginamos os dados
    let actualPage = currentPage;

    do {
      const paginatedData = paginate(
        groupedData,
        actualPage,
        itemsPerPage
      );

      if (Object.keys(paginatedData).length > 0) {
        return paginatedData;
      }

      actualPage--;
      setCurrentPage(previous => actualPage);

    } while (actualPage > 1);

    return [];
  };

  // Função para atualizar a página atual
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    function calculatePageSize(rowSizeInPixels, minPageSize) {
      const bodyHeight = tableBodyRef.current
        ? tableBodyRef.current.clientHeight
        : window.innerHeight
      ;
      const rowsPerPage = Math.floor(bodyHeight / rowSizeInPixels);
      
      return Math.max(rowsPerPage, minPageSize);
    }

    const handleResize = () => {
      const rowHeight = rowSize || 40; // Altura da linha em pixels
      const minPageSize = pageSize || 10; // Mínimo de linhas
      const newItemsPerPage = calculatePageSize(rowHeight, minPageSize);
      setItemsPerPage(newItemsPerPage);
    };

    if (autoPageSize) {
      handleResize(); // Calcula inicialmente
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [rowSize, pageSize]);

  return (
    <div
      style={{
        height: itemsPerPage < 1 ? 'auto' : 'calc(100% - 59px)',
      }}
    >
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <table className="themed" {...rest}>
        <Head keys={Object.keys(data[0])} columns={columns} />
        <Body
          ref={tableBodyRef}
          data={displayData()}
          keys={Object.keys(data[0])}
          columns={columns}
          onClick={onClick}
          rowColor={rowColor}
          noContentMessage={noContentMessage}
          autoHeight={itemsPerPage < 1}
          groupBy={groupBy}
          groupRender={groupRender}
        />
      </table>

      {itemsPerPage > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(data.length / itemsPerPage)}
          onPageChange={goToPage}
        />
      )}
    </div>
  );
}

export default Table;