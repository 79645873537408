import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, Navigate } from "react-router-dom";
import classNames from 'classnames';
import { BeatLoader } from 'react-spinners';
import ReactCodeInput from 'react-code-input';

import { isAuthenticated } from "../../features/authorization/AuthContext";
import Container from '../../components/Container';
import { useThemeContext } from "../../components/ThemeProvider";
import { useTenantContext } from "../../features/TenantContext";
import {
  accountFinder,
  accountRequestValidationCode,
  accountValidateUsingCode,
  accountRedefinePasswordUsingCode
} from "../../services/AuthService";

import {ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import {ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg';

import '../../styles/Login.scss';
import '../../styles/Forms.scss';
import '../../styles/PrimaryButton.scss';

function ForgotPasswordPage(props) {
  const { theme } = useThemeContext();
  const { tenant } = useTenantContext();
  const getTenantLogo = () => {
    if (theme === 'dark') {
      return tenant.logo.inverted;
    }

    return tenant.logo.normal;
  }


  // O estágio do processo de redefinição de senha
  const [stage, setStage] = useState(0);

  // As variáveis de controle de cada estágio

  // Estágio inicial - Informar o identificador
  const [identifier, setIdentifier] = useState("");
  const [hasErrorStage0, setHasErrorStage0] = useState(null);
  const [loadingStage1, setLoadingStage1] = useState(false);
  const inputRef = useRef(null);

  // Estágio 1 - Identificar a conta
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [noSelectedAccount, setNoSelectedAccount] = useState(false);
  const [hasErrorStage1, setHasErrorStage1] = useState(null);

  // Estágio 2 - Inserir o código de verificação
  const [validatingCode, setValidatingCode] = useState("");
  const [counter, setCounter] = useState(0);
  const [hasErrorStage2, setHasErrorStage2] = useState(null);

  // Estágio 3 - Alterar a senha
  const minPasswordLength = 8;
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [chkPassword, setChkPassword] = useState("");
  const [showChkPassword, setShowChkPassword] = useState(false);
  const [hasErrorStage3, setHasErrorStage3] = useState(null);
  const passwordRef = useRef(null);
  
  // Coloca o foco no campo de identificação ao carregar o componente
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  // Contagem regressiva para solicitar um novo código de verificação
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter > 0) {
        setCounter(prevCounter => prevCounter - 1);
      }
    }, 1000);

    // Limpar o intervalo quando o componente desmontar
    return () => clearInterval(intervalId);
  }, [counter]);

  // Verifica se o usuário já está autenticado, antes de prosseguir
  if (isAuthenticated(tenant.tenantname)) {
    return <Navigate to={`/app/${tenant.tenantname}/`} replace={true} />;
  }

  // -----[ Estágio inicial ]-------------------------------------------

  const handleIdentifierChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Precisamos deixar o botão de solicitação de uma forma que o
    // usuário saiba que a solicitação foi feita e que ele precisa
    // aguardar a resposta do servidor
    setLoadingStage1(true);
    setSelectedAccount(null);
    setNoSelectedAccount(false);
    setHasErrorStage1(null);

    // Realiza a solicitação
    accountFinder(identifier, tenant)
      .then((accounts) => {
        // Armazena as contas encontradas
        setAccounts(accounts);

        // Se houver apenas uma conta, já a seleciona
        if (accounts.length === 1) {
          setSelectedAccount(accounts[0].id);
        }

        // Avança para o próximo estágio
        setStage(1);
      })
      .catch((error) => {
        console.log('Error', error);
        setLoadingStage1(false);
        if (error.message === 'O identificador informado não é válido.') {
          setHasErrorStage0("Informe um CPF, CNPJ ou e-mail válidos.");
          return;
        }

        // Verifica se é um e-mail, CPF ou CNPJ
        const $typeOfIdentifier = identifier.includes('@')
          ? 'e-mail'
          : (
              identifier.replace(/\D/g, "").length === 11
                ? 'CPF'
                : 'CNPJ'
            )
        ;
        setHasErrorStage0(
          "Não foi possível localizar a conta. Verifique o "
          + $typeOfIdentifier + " informado."
        );
      })
    ;
  };

  // -----[ Estágio 1 ]-------------------------------------------------

  const handleSelectAccount = (account) => {
    setSelectedAccount(account);
    setNoSelectedAccount(false);
  };

  const handleStartRecovery = async (event) => {
    event.preventDefault();
    setHasErrorStage1("");

    // Garante que uma conta foi selecionada
    if (!selectedAccount) {
      console.log('Nenhuma conta selecionada');
      setNoSelectedAccount(true);
      return;
    }

    // Solicita o envio do código de verificação para o e-mail desta
    // conta para confirmar que o usuário é o dono da conta
    accountRequestValidationCode(selectedAccount.id, tenant)
      .then(() => {
        // Avança para o próximo estágio
        setCounter(120);
        setStage(2);
      })
      .catch((error) => {
        console.log('Error', error);
        setHasErrorStage1(
          "Não foi possível enviar o código de confirmação de conta. "
          + "Entre em contato com o atendimento ao cliente."
        );
      })
    ;
  };

  // -----[ Estágio 2 ]-------------------------------------------------

  const handleChangeValidationCode = (value) => {
    setValidatingCode(value);
    setHasErrorStage2("");
  };

  const requestAgain = async () => {
    // Solicita um novo envio do código de verificação para o e-mail
    // desta conta para confirmar que o usuário é o dono da conta
    accountRequestValidationCode(selectedAccount.id, tenant)
      .then(() => {
        setCounter(120);
      })
      .catch((error) => {
        console.log('Error', error);
        setHasErrorStage2(
          "Não foi possível enviar o código de confirmação de conta. "
          + "Entre em contato com o atendimento ao cliente."
        );
      })
    ;
  };

  const handleValidateAccount = async (event) => {
    event.preventDefault();

    if (validatingCode.length < 4) {
      console.log('Código de verificação inválido');

      setHasErrorStage2("Informe o código de verificação enviado.");

      return;
    }

    // Realiza a validação do código de verificação
    accountValidateUsingCode(selectedAccount.id, validatingCode, tenant)
      .then(() => {
        // Avança para o próximo estágio
        setStage(3);
        setTimeout(() => {
          passwordRef.current?.focus();
        }, 200);
      })
      .catch((error) => {
        console.log('Error', error);
        setHasErrorStage2(
          error.message
        );
      })
    ;
  };

  const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  
  const format = time => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);
  
    // Get the seconds left after converting minutes
    const seconds = time % 60;
  
    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };

  // -----[ Estágio 3 ]-------------------------------------------------

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setHasErrorStage3(null);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleChkPasswordChange = (e) => {
    setChkPassword(e.target.value);
    setHasErrorStage3(null);
  };

  const toggleChkPasswordVisibility = () => {
    setShowChkPassword(!showChkPassword);
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (newPassword.length < minPasswordLength) {
      console.log('Senha possui menos caracteres do que o exigido');

      setHasErrorStage3("A senha deve ter pelo menos "
        + minPasswordLength + " caracteres."
      );

      return;
    }

    if (newPassword !== chkPassword) {
      console.log('Senhas não conferem');

      setHasErrorStage3("As senhas informadas não conferem.");

      return;
    }

    // Realiza a validação do código de verificação
    accountRedefinePasswordUsingCode(selectedAccount.id, validatingCode, tenant, newPassword)
      .then(() => {
        setStage(4);
      })
      .catch((error) => {
        console.log('Error', error);
        setHasErrorStage3(
          error.message
        );
      })
    ;
  };

  switch (stage) {
    case 4:
      // Exibe a tela de confirmação de redefinição de senha
      return (
        <Container>
          <div className="backdraw">
            <FormContainer>
              <Form>
                <FormContent>
                  <Title>
                    Senha redefinida com sucesso
                  </Title>
                  <SmallText>
                    Você já pode acessar sua conta
                  </SmallText>
                  <div className="flex-center">
                    <img src={getTenantLogo()} alt={"logo"} style={{ width: '200px', height: 'auto', marginBottom: '1em' }} />
                  </div>
                  <p className="auth-form-text">
                  </p>
                  <p className="auth-form-text">
                    Pronto, sua senha foi redefinida com sucesso e você
                    já pode acessar normalmente sua conta.
                  </p>
                  <p className="auth-form-text">
                    Guarde bem a sua nova senha e não a compartilhe com
                    ninguém.
                  </p>
                  <p className="auth-form-text">
                    &nbsp;
                  </p>
                  <div className="d-grid gap-2 mt-3">
                    <Link
                      className="fakeButton"
                      to={`/app/${tenant.tenantname}/login`}
                    >
                      Retornar para a tela de login
                    </Link>
                  </div>
                  <p className="auth-form-text">
                    &nbsp;
                  </p>
                </FormContent>
              </Form>
            </FormContainer>
          </div>
        </Container>
      );
    case 3:
      // Exibe a tela de alteração da senha do usuário
      return (
        <Container>
          <div className="backdraw">
            <FormContainer>
              <Form onSubmit={handleChangePassword}>
                <FormContent>
                  <Title>
                    Defina uma nova senha
                  </Title>
                  <SmallText>
                    Sua conta foi verificada e você já pode
                    definir uma nova senha.
                  </SmallText>
                  <p className="auth-form-text">
                    Informe a sua nova senha e confirme ela para
                    prosseguir.
                  </p>
                  <p className="auth-form-text">
                    Use uma senha forte, com pelo menos 8 caracteres,
                    contendo letras, números e símbolos.
                  </p>
                  <Field>
                    <Label>Nova senha</Label>
                    <InputWithIcon>
                      <input
                        ref={passwordRef}
                        type={showNewPassword ? 'text' : 'password'}
                        className="field-input"
                        name="newpassword"
                        id="newpassword"
                        placeholder="Informe sua nova senha"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        required
                        autoComplete="off"
                      />
                      <TogglePasswordIcon
                        showPassword={showNewPassword}
                        togglePasswordVisibility={toggleNewPasswordVisibility}
                      />
                    </InputWithIcon>
                  </Field>
                  <Field>
                    <Label>Confirme a nova senha</Label>
                    <InputWithIcon>
                      <input
                        type={showChkPassword ? 'text' : 'password'}
                        className="field-input"
                        name="chkpassword"
                        id="chkpassword"
                        placeholder="Confirme a sua nova senha"
                        value={chkPassword}
                        onChange={handleChkPasswordChange}
                        required
                        autoComplete="off"
                      />
                      <TogglePasswordIcon
                        showPassword={showChkPassword}
                        togglePasswordVisibility={toggleChkPasswordVisibility}
                      />
                    </InputWithIcon>
                  </Field>
                  <div className="d-grid gap-2 mt-3">
                    <SubmitButton>
                      Confirmar
                    </SubmitButton>
                  </div>
                  {hasErrorStage3 && (
                    <p style={{ color: "maroon", marginTop: "10px" }}>
                      {hasErrorStage3}
                    </p>
                  )}
                  <div className="auth-row">
                    <div className="auth-col-auto">
                      <p className="medium-link paddingTop">
                        <Link to={`/app/${tenant.tenantname}/login`}>
                          Lembrou? Retorne para a tela de login
                        </Link>
                      </p>
                    </div>
                  </div>
                </FormContent>
              </Form>
            </FormContainer>
          </div>
        </Container>
      );
    case 2:
      // Exibe a tela de inserção do código de verificação
      return (
        <Container>
          <div className="backdraw">
            <FormContainer>
              <Form onSubmit={handleValidateAccount}>
                <FormContent>
                  <Title>
                    Enviamos um e-mail para você
                  </Title>
                  <SmallText>
                    Precisamos confirmar que você é o dono desta conta
                  </SmallText>
                  <p className="auth-form-text">
                    Digite o código de verificação que enviamos para o
                    e-mail <i>{selectedAccount?.email}</i>:
                  </p>
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center', paddingBottom: '1em' }}>
                    <ReactCodeInput
                      type='text'
                      fields={4}
                      value={validatingCode}
                      onChange={handleChangeValidationCode}
                    />
                  </div>
                  <p className="auth-form-text">
                    {counter > 0
                      ? (<>Não recebeu o e-mail ainda, aguarde {format(counter)} para poder solicitar o envio de um novo código de verificação.</>)
                      : (<>Não recebeu o e-mail, solicite o envio de um novo código de verificação clicando <span onClick={requestAgain} style={{ fontWeight: "bold", fontStyle: "italic", textDecorationLine: "underline", cursor: "pointer" }}>aqui</span>.</>)
                    }
                  </p>
                  <div className="d-grid gap-2 mt-3">
                    <SubmitButton>
                      Confirmar
                    </SubmitButton>
                  </div>
                  {hasErrorStage2 && (
                    <p style={{ color: "maroon", marginTop: "10px" }}>
                      {hasErrorStage2}
                    </p>
                  )}
                  <div className="auth-row">
                    <div className="auth-col-auto">
                      <p className="medium-link paddingTop">
                        <Link to={`/app/${tenant.tenantname}/login`}>
                          Lembrou? Retorne para a tela de login
                        </Link>
                      </p>
                    </div>
                  </div>
                </FormContent>
              </Form>
            </FormContainer>
          </div>
        </Container>
      );

    case 1:
      // Exibe a tela de seleção de conta
      return (
        <Container>
          <div className="backdraw">
            <FormContainer>
              <Form onSubmit={handleStartRecovery}>
                <FormContent>
                  <Title>
                    {
                      accounts.length > 1
                        ? "Localizamos mais de uma conta"
                        : "Localizamos sua conta"
                    }
                  </Title>
                  <SmallText>
                    {
                      accounts.length > 1
                        ? "Precisamos que você nos ajude a identificar qual é a conta"
                        : "Apenas confirme para prosseguir"
                    }
                  </SmallText>
                  <p className="auth-form-text">
                    {accounts.length > 1
                      ? "Indique qual destas contas é a que você deseja redefinir a senha:"
                      : "Confirme se esta é a conta que você deseja redefinir a senha:"
                    }
                  </p>
                  <List>
                    {accounts.map((account) => (
                      <Account
                        key={account.id}
                        account={account}
                        selected={selectedAccount?.id === account.id}
                        onSelect={handleSelectAccount}
                      />
                    ))}
                  </List>
                  <div className="d-grid gap-2 mt-3">
                    <SubmitButton>
                      Iniciar recuperação
                    </SubmitButton>
                  </div>
                  {noSelectedAccount && (
                    <p style={{ color: "maroon", marginTop: "10px" }}>
                      Selecione uma conta para prosseguir
                    </p>
                  )}
                  {hasErrorStage1 && (
                    <p style={{ color: "maroon", marginTop: "10px" }}>
                      {hasErrorStage1}
                    </p>
                  )}
                  <div className="auth-row">
                    <div className="auth-col-auto">
                      <p className="medium-link paddingTop">
                        <Link to={`/app/${tenant.tenantname}/login`}>
                          Lembrou? Retorne para a tela de login
                        </Link>
                      </p>
                    </div>
                  </div>
                </FormContent>
              </Form>
            </FormContainer>
          </div>
        </Container>
      );

    default:
      // Exibe o formulário inicial de redefinição de senha para que o
      // usuário informe o identificador da conta (CPF, CNPJ ou e-mail)
      return (
        <Container>
          <div className="backdraw">
            <FormContainer>
              <Form onSubmit={handleSubmit}>
                <FormContent>
                  <Title>
                    Esqueceu sua senha?
                  </Title>
                  <SmallText> Não têm problema, vamos definir uma nova!</SmallText>
                  <div className="flex-center">
                    <img src={getTenantLogo()} alt={"logo"} style={{ width: '200px', height: 'auto', marginBottom: '1em' }} />
                  </div>
                  <p className="auth-form-text">
                  Informe o seu CPF, CNPJ ou o e-mail cadastrado em sua
                  conta para que possamos localizá-la:
                  </p>
                  <Field>
                    <Label>CPF/CNPJ ou E-mail</Label>
                    <input
                      ref={inputRef}
                      type="text"
                      className="field-input"
                      name="identifier"
                      id="identifier"
                      placeholder="Informe algo para localizarmos..."
                      value={identifier}
                      onChange={handleIdentifierChange}
                      required
                      autoComplete="off"
                    />
                  </Field>
                  <div className="d-grid gap-2 mt-3">
                    <SubmitButton disabled={loadingStage1}>
                      {loadingStage1 ? (
                        <BeatLoader size=".7em" color="#ffffff" speedMultiplier={1} />
                      ) : "Enviar"}
                    </SubmitButton>
                  </div>
                  {hasErrorStage0 && (
                    <p style={{ color: "maroon", marginTop: "10px" }}>
                      {hasErrorStage0}
                    </p>
                  )}
                  <div className="auth-row">
                    <div className="auth-col-auto">
                      <p className="medium-link paddingTop">
                        <Link to={`/app/${tenant.tenantname}/login`}>
                          Lembrou? Retorne para a tela de login
                        </Link>
                      </p>
                    </div>
                  </div>
                </FormContent>
              </Form>
            </FormContainer>
          </div>
        </Container>
      );
  }
}

function FormContainer({ children }) {
  return ( 
    <div className="auth-form-container">
      {children}
    </div>
  );
}

function Form({ children, onSubmit }) {
  return ( 
    <form className="auth-form" onSubmit={onSubmit}>
      {children}
    </form>
  );
}

function FormContent({ children }) {
  return ( 
    <div className="auth-form-content">
      {children}
    </div>
  );
}

function Title({ children }) {
  return ( 
    <h3 className="auth-form-title">
      {children}
    </h3>
  );
}
function SmallText({ children }) {
  return ( 
    <p className="auth-form-smalltext">
      {children}
    </p>
  );
}

function Field({ children }) {
  return ( 
    <div className="wrap-input">
      {children}
    </div>
  );
}

function InputWithIcon({ children }) {
  return ( 
    <div className="labeled-input">
      {children}
    </div>
  );
}

function Label({ children }) {
  return ( 
    <label className="label-input">
      {children}
    </label>
  );
}

function TogglePasswordIcon({
  showPassword,
  togglePasswordVisibility
}) {
  return (
    <span
      className="input-icon"
      onClick={togglePasswordVisibility}
    >
      {showPassword ? (
        <EyeSlashIcon width={'1em'} height={'1em'} fill='currentColor' />
      ) : (
        <EyeIcon width={'1em'} height={'1em'} fill='currentColor' />
      )}
    </span>
  );
}
function SubmitButton({
  children,
  color="primary",
  disabled=false
}) {
  const buttonClass = classNames(disabled?'secondary':'primary',
    color
  );

  return (
    <button
      type="submit"
      className={buttonClass}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

function List({ children }) {
  return ( 
    <ul className="auth-form-list">
      {children}
    </ul>
  );
}

function Account({ account, selected, onSelect }) {
  const handleSelect = () => onSelect(account);

  return (
    <li
      className="auth-form-list-item"
      onClick={handleSelect}
    >
      <div className="auth-form-list-item-check">
        <svg
          width="1.5em"
          height="1.5em"
          viewBox="0 0 1.44 1.44"
        >
          <g strokeWidth=".06">
            {selected && (
              <path
                d="m1.119961.34019531c-.025676 0-.051305.009704-.070664.0290625l-.48937512.48925783-.17027344-.17027346-.008906-.007734-.001172-.00082032c-.0391704-.0290632-.0951149-.0255883-.1303125.009609-.0387173.0387173-.0387173.10272798 0 .14144532l.24105469.24105472.009023.00773.001172.0008202c.0391705.029064.0949978.025588.13019534-.00961l.56109373-.56109378.00773-.008906.0008202-.001172c.029064-.0391704.025588-.0951148-.00961-.1303124-.019359-.0193587-.045105-.0290625-.070781-.0290625z"
                fill="#008000"
              />
            )}
            <path
              d="m1.22 0c.1215026 0 .22.09849735.22.22v1c0 .1215026-.098497.22-.22.22h-1c-.12150265 0-.22-.0984974-.22-.22v-1c0-.12150265.09849735-.22.22-.22zm0 .12h-1c-.0552285 0-.1.0447715-.1.1v1c0 .055229.0447715.1.1.1h1c.055229 0 .1-.044771.1-.1v-1c0-.0552285-.044771-.1-.1-.1z"
              fill="var(--theme-input-border-color)"
            />
          </g>
        </svg>
      </div>
      <div className="auth-form-list-item-text">
        <p className="name">{account.name}</p>
        <p>Conta: <span>{account.username}</span></p>
        <p>E-mail: {account.email}</p>
        <p>Telefone: {account.phonenumber}</p>
      </div>
    </li>
  );
}


export default ForgotPasswordPage;
