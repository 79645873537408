import React from 'react';
import Table from './Table';
import { Button } from './form';
import { ActivateIcon, BlockIcon, EditIcon } from './Icons';

const DriverTable = ({ drivers, handleEditDriver, handleActivateDriver, handleDeactivateDriver }) => {
  const grammaticalNumberInflection = (amount, singular, plural) => {
    return amount > 1 ? plural : singular;
  };

  const emptyDriverRegister = [{
    id: null,
    driverID: 0,
    driverName: '',
    driver: {
      id: null,
      name: '',
      cpf: '',
      phonenumber: ''
    },
    identifier: {
      id: null,
      number: '',
      technology: ''
    },
    cnh: {
      id: null,
      cnh: '',
      category: '',
      expirationDate: '',
      expired: false
    },
    status: {
      active: false,
      cnhExpired: false,
      hasIdentifier: null
    },
    actions: {
      id: null,
      active: false
    },
  }];

  const columns = {
    driver: {
      name: "Motorista",
      align: "left",
      render: (data) => (
        <div style={styles.cellDouble}>
          <span className="label">{data.name}</span>
          <span className="complement">
            {data.cpf ? `CPF: ${data.cpf}` : ''}
            {data.phoneList ? (data.cpf ? ' / ' : '') : ''}
            {data.phoneList ? grammaticalNumberInflection(data.phoneList.length, 'Telefone: ', 'Telefones: ') + data.phoneList : ''}
          </span>
        </div>
      )
    },
    identifier: {
      name: "Identificador",
      align: "center",
      render: (data) => (
        data.id === undefined ? (
          <span
            style={{
              ...styles.status,
              backgroundColor: "var(--theme-disabled)"
            }}
          >
            Sem identificador
          </span>
        ) : (
          <div style={styles.cellDoubleCentered}>
            <span className="label">{data.number}</span>
            <span className="complement">{data.technology}</span>
          </div>
        )
      )
    },
    cnh: {
      name: "Carteira de habilitação",
      align: "left",
      render: (data) => (
        !data.id ? (<span></span>) : (
          data.number === '' ? (
            <span
              style={{
                ...styles.status,
                backgroundColor: "var(--theme-disabled)",
                marginLeft: '.5rem'
              }}
            >
              CNH não informada
            </span>
          ) : (
            <div style={styles.cellDouble}>
              <span className="label">Número: {data.number}</span>
              <span className="complement">
                Categoria: {data.category || ' — '} / Validade: <CNHStatus expirationDate={data.expirationDate} closeToExpiration={data.closeToExpiration} expired={data.expired} />
              </span>
            </div>
          )
        )
      )
    },
    status: {
      name: "Situação",
      align: "center",
      width: 160,
      render: (data) => (
        <AccountStatus active={data.active} />
      )
    },
    actions: {
      name: "Ações",
      align: "center",
      width: 180,
      render: (data) => (
        !data.id ? (<span></span>) : (
          <div
            className="actions"
            style={{
              ...styles.cellInline,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '.5rem',
            }}
          >
            <Button
              type="button"
              primary onClick={() => handleEditDriver(data.id)}
              title={'Editar motorista'}
            >
              <EditIcon size=".8rem" />
            </Button>
            {data.active ? (
            <Button
              type="button"
              color="red"
              onClick={() => handleDeactivateDriver(data.id, data.name)}
              title={'Desativar motorista'}
            >
              <BlockIcon size=".8rem" color="currentColor" />
            </Button>
            ) : (
            <Button
              type="button"
              color="green"
              onClick={() => handleActivateDriver(data.id, data.name)}
              title={'Ativar motorista'}
            >
              <ActivateIcon size=".8rem" color="currentColor" />
            </Button>
            )}
          </div>
        )
      )
    },
  };

  const CNHStatus = ({ expirationDate, closeToExpiration, expired }) => {
    const color = expired
      ? 'var(--theme-dangerous)'
      : closeToExpiration
        ? 'var(--theme-highrisk)'
        : 'var(--theme-safe)'
    ;

    if (!expirationDate) {
      return (
        <span style={{
            ...styles.smallStatus,
            backgroundColor: 'darkred',
            marginLeft: '.5rem'
          }}
        >
          Não informada
        </span>
      );
    }

    return (
      <>
      <span style={{ color: color, marginLeft: '.5rem', marginRight: '.5rem' }}>
        { expirationDate }
      </span>
      { expired ? (
        <span style={{
            ...styles.smallStatus,
            backgroundColor: 'darkred'
          }}
        >
          Vencida
        </span>
      ) : closeToExpiration && (
        <span style={{
            ...styles.smallStatus,
            backgroundColor: 'darkorange'
          }}
        >
          Próximo do vencimento
        </span>
      )}
      </>
    )
  };

  const AccountStatus = ({ active }) => {
    console.log('Está ativo?', active);
    const color = active
      ? "#008000"
      : "#cc0000"
    ;
    const label = active
      ? "Ativo"
      : "Inativo"
    ;
  
    return (
      <span style={{
          ...styles.status,
          backgroundColor: color
        }}
      >
        { label }
      </span>
    );
  };

  return (
    <Table
      data={drivers.length > 0 ? drivers : emptyDriverRegister}
      columns={columns}
      rowColor={() => {}}
      onClick={() => {}}
      isLoading={false}
      noContentMessage="Nenhum motorista"
      rowSize={45}
      pageSize={5}
      autoPageSize={true}
    />
  );
};

const styles = {
  cellDouble: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 .5rem',
    whiteSpace: "nowrap",
  },
  cellDoubleCentered: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 .5rem',
    whiteSpace: "nowrap",
  },
  status: {
    display: "inline-block",
    padding: ".3rem 1rem",
    color: "#fff",
    fontWeight: 600,
    fontSize: ".8rem",
    borderRadius: "2rem",
    whiteSpace: "nowrap"
  },
  smallStatus: {
    display: "inline-block",
    padding: ".1rem .5rem",
    color: "#fff",
    fontWeight: 600,
    fontSize: ".7rem",
    borderRadius: "1rem",
    whiteSpace: "nowrap"
  },
};

export default DriverTable;