import React, { useMemo } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

const Text = ({ position, label, color }) => {
  const icon = useMemo(() => {
    return L.divIcon({
      className: 'leaflet-label',
      iconSize: [10, 2],
      iconAnchor: [5, 2],
      html: `<span style="display: block; margin-left: -100px; width: 200px; text-align: center; text-wrap: balance; font-size: 1.1em; font-weight: 400; color:${color}">${label}</span>`,
    });
  }, [label, color]);

  return <Marker position={position} icon={icon} />;
};

export default Text;