import React from 'react';

import PhoneInput from 'react-phone-number-input/input';

import 'react-phone-number-input/style.css';

const PhoneNumberInput = ({
  label,
  name,
  value,
  onChange,
  maxLength = 50,
  onBlur = () => {},
  wrong = "",
  wrongMessage = ""
}) => {
  const handleInputChange = (value) => {
    onChange(value);
  };

  return (
    <div style={{ flex: 1 }} className="form-group">
      <label htmlFor={name}>{label}</label>
      <PhoneInput
        className={"medium" + (wrong ? " error" : "")}
        country="BR"
        name={name}
        value={value}
        onChange={handleInputChange}
        onBlur={onBlur}
      />
      {wrong && <span className="error">{wrongMessage}</span>}
    </div>
  );
};

export default PhoneNumberInput;
