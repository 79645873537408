const ActivateIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-play"
  >
    <polygon points="5 3 19 12 5 21 5 3"/>
  </svg>
);

export default ActivateIcon;