import { z } from "zod";

import {
  Cancel,
  Control,
  DecimalControl,
  Description,
  Error,
  FieldSet,
  Form,
  Group,
  Inline,
  IntegerControl,
  Label,
  Labeled,
  LabeledControl,
  Legend,
  LoadingButton,
  Option,
  Row,
  Select,
  Submit,
  TextArea,
  Toggle
} from '../components/form';

import VehicleNotificationList from '../components/VehicleNotificationList';

import '../components/form/form.css';

const FenceForm = ({
  fence,
  onSubmit,
  onCancel,
  onSaveAsLandmark,
  onFieldChange,
  notifications,
  customerVehicles,
  names
}) => {
  const notificationValues = notifications.map(notification => {
    if (notification.value === "DEFAULT") {
      console.log('Ignorando notificação padrão');
      return;
    }

    return notification.value;
  });
  const notificationValuesWithDefault = notifications.map(notification => {
    return notification.value;
  });
  const isNameUnique = async (value, context) => {
    // Verifica se name já existe em names, usando case insensitive e
    // ignorando espaços no início e fim e acentos
    const name = value
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
    ;

    return !names.includes(name);
  };

  const fenceSchema = z.object({
    id: z.number().int().optional(),
    customerid: z.number().positive().int(),
    name: z
      .string({
        required_error: "Nome é obrigatório",
        invalid_type_error: "Nome deve ser uma string",
      })
      .trim()
      .nonempty({
        message: "Nome não pode estar em branco",
      })
      .max(50, {
        message: "Deve ter no máximo 50 caracteres"
      })
      .refine(isNameUnique, {
        message: "Já exite outra cerca com este nome",
      }),
    note: z
      .optional(
        z.string({
          invalid_type_error: "Observação deve ser uma string",
        })
        .trim()
      ),
    format: z
      .string({
        required_error: "Formato é obrigatório",
        invalid_type_error: "Formato deve ser uma string",
      })
      .trim()
      .nonempty({
        message: "Tipo não pode estar em branco",
      })
      .max(50, {
        message: "Deve ter no máximo 50 caracteres"
      }),
    latitude: z.number({
        required_error: "Latitude é obrigatória",
        invalid_type_error: "Informe a latitude",
      })
      .min(-90).max(90),
    longitude: z.number({
        required_error: "Longitude é obrigatória",
        invalid_type_error: "Informe a longitude",
      })
      .min(-180).max(180),
    radius: z.number({
        required_error: "Raio é obrigatório",
        invalid_type_error: "Informe o raio da cerca",
      })
      .positive({
        message: "Informe um valor positivo"
      })
      .min(50, {
        message: "O raio deve ter no mínimo de 50 metros"
      }),
    notification: z.enum(notificationValues, {
      required_error: "Notificação é obrigatória",
      invalid_type_error: "Informe o tipo de notificação",
    }),
    showinmap: z.boolean({
      required_error: "Exibir no mapa é obrigatório",
      invalid_type_error: "Informe se deve ser exibido no mapa",
    }),
    disabled: z.boolean({
      required_error: "Desabilitado é obrigatório",
      invalid_type_error: "Informe se a cerca está desabilitada",
    }),
    temporary: z.boolean({
      required_error: "Temporário é obrigatório",
      invalid_type_error: "Informe se a cerca é temporária",
    }),
    vehicles: z.optional(
      z.array(
        z.object({
          vehicleid: z.number().int().positive(),
          plate: z
            .string({
              required_error: "Placa é obrigatória",
              invalid_type_error: "Placa deve ser uma string",
            })
            .trim()
            .nonempty({
              message: "Placa não pode estar em branco",
            }),
          notification: z.enum(notificationValuesWithDefault, {
            required_error: "Tipo de notificação é obrigatório",
            invalid_type_error: "Informe o tipo de notificação",
          }),
        })
      )
    )
  });

  return (
    <Form
      schema={fenceSchema}
      defaultValues={fence}
      onSubmit={onSubmit}
      onFieldChange={onFieldChange}
      watchFields={["name", "note", "latitude", "longitude", "radius"]}
    >
      <Control
        type="hidden"
        name="id"
      />
      <Control
        type="hidden"
        name="customerid"
      />
      <Group>
        <Label htmlFor="name">Nome</Label>
        <Control
          type="text"
          name="name"
        />
        <Error name="name" />
      </Group>
      <Control
        type="hidden"
        name="format"
      />
      <Row>
        <Group>
          <Label htmlFor="latitude">Latitude</Label>
          <DecimalControl
            name="latitude"
            size={10}
            decimals={6}
          />
          <Error name="latitude" />
        </Group>
        <Group>
          <Label htmlFor="longitude">Longitude</Label>
          <DecimalControl
            name="longitude"
            size={10}
            decimals={6}
            />
          <Error name="longitude" />
        </Group>
        <Group>
          <Label htmlFor="radius">Raio</Label>
          <LabeledControl right>
            <IntegerControl
              name="radius"
            />
            <Labeled>
              metros
            </Labeled>
          </LabeledControl>
          <Error name="radius" />
        </Group>
      </Row>
      <Group flex={1}>
        <Label htmlFor="notification">Comportamento padrão</Label>
        <Select
          name="notification"
          placeholder="Informe o comportamento..."
        >
          {notifications.map(notification => {
            if (notification.value === "DEFAULT") {
              return null;
            }
            
            return (
              <Option
                key={notification.value}
                value={notification.value}
              >
                {notification.label}
              </Option>
            );
          })}
        </Select>
      </Group>
      <FieldSet>
        <Legend>Veículos para os quais a cerca é válida</Legend>
        <VehicleNotificationList
          name="vehicles"
          notifications={notifications}
          customerVehicles={customerVehicles}
        />
      </FieldSet>
      <Group>
        <Label htmlFor="note">Observação</Label>
        <TextArea
          id="note"
          name="note"
          rows="3"
        />
        <Error name="note" />
      </Group>
      <FieldSet direction="col">
        <Legend>Opções adicionais</Legend>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ borderBottom: '1px solid var(--theme-list-group-border-color)', marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="showinmap"
              >
                <span>Exibir no mapa</span>
                <Description>
                  Controla a exibição desta cerca. Se desmarcado, apenas oculta ela do mapa, mantendo notificações se configurado.
                </Description>
              </Label>
            </Group>
            <Toggle
              name="showinmap"
            />
          </Inline>
          <Error name="fixed" />
        </Group>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="disabled"
              >
                <span>Desativar cerca</span>
                <Description>
                  Oculta esta cerca do mapa e desabilita quaisquer notificações configuradas.
                </Description>
              </Label>
            </Group>
            <Toggle
              name="disabled"
            />
          </Inline>
          <Error name="disabled" />
        </Group>
      </FieldSet>
      <Control
        type="hidden"
        name="temporary"
      />
      <Row rightAligned>
        <LoadingButton
          color={"green"}
          style={{
            marginRight: "auto"
          }}
          onClick={onSaveAsLandmark}
        >
          Salvar como ponto de referência
        </LoadingButton>
        <Cancel onClick={onCancel} >
          Cancelar
        </Cancel>
        <Submit value="Salvar">
          Salvar
        </Submit>
      </Row>
    </Form>
  );
};

export default FenceForm;

