import React from "react";
import { useController } from "react-hook-form";
import { DayPicker } from 'react-day-picker';
import { ptBR } from 'date-fns/locale';

import '../styles/Calendar.css';

function DatePickerField({ label, name, control, rules, onChange }) {
  const {
    field,
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules
  });

  const handleOnChange = (range) => {
    field.onChange(range);

    if (onChange) {
      onChange(range);
    }
  };

  return (
    <>
      <label htmlFor={field.name}>{ label }</label>
      <DayPicker
        styles={{
          container: 'rdp.smaller'
        }}
        defaultMonth={new Date()}
        locale={ptBR}
        mode="range"
        showOutsideDays
        selected={field.value}
        onSelect={handleOnChange}
        min={0}
        max={31}
        onBlur={() => {
          field.onBlur();
        }}
        toDate={new Date()}
      />
      {invalid && <span className="errorMsg">Informe um período válido</span>}
    </>
  );
}

export default DatePickerField;