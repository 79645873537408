import React, {
  forwardRef
} from "react";

import './styles.css';

const SquareButton = forwardRef(({
  color,
  selected,
  children,
  size = '2.5rem',
  ...props
}, ref) => (
  <button
    ref={ref}
    type="button"
    className="square-button"
    style={{
      ...styles.button,
      ...props.style,
      width: size,
      height: size,
      backgroundColor: color,
      borderColor: selected ? '#fff' : '#666'
    }}
    {...props}
  >
    {children}
  </button>
));

const styles = {
  button: {
    borderRadius: '.475rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px solid #666',
    backgroundColor: '#f2efe9',
    transition: 'border-color .5s ease-in-out, background-color .5s ease-in-out'
  }
};

export default SquareButton;