import React from 'react';

function RadioButton({
  name,
  value,
  id,
  onChange,
  children
}) {
  const handleOnChange = (event) => {
    const { value } = event.target;

    if (onChange) {
      // Propagar o evento para o componente principal
      onChange(value);
    }
  }

  return (
    <>
      <input
        type="radio"
        className="btn-check"
        value={value}
        id={id}
        autoComplete="off"
        onChange={handleOnChange}
        name={name}
      />
      <label
        className="btn btn-primary"
        htmlFor={id}
      >
        {children}
      </label>
    </>
  );
}

export default RadioButton;