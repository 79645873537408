import React from 'react';
import { useParams } from 'react-router-dom';

import {ReactComponent as NotFoundImage} from '../../assets/icons/pagenotfound.svg';

const NotFoundTenant = () => {
  const { tenantName } = useParams();

  return (
    <main className="site">
      <section>
        <NotFoundImage />
        <h2>Desculpe</h2>
        <p>
          A empresa <span>{ tenantName }</span> que você informou para
          acessar o <span>MR Fleet</span> não existe ou está incorreta.
        </p>
        <p>
          Entre em contato com o suporte da empresa para verificar o
          endereço correto de acesso ao sistema.
        </p>
      </section>
    </main>
  );
};

export default NotFoundTenant;