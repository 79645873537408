import React from 'react';

import {ReactComponent as SoundEnable} from '../assets/icons/soundenable.svg';
import {ReactComponent as SoundMute} from '../assets/icons/soundmute.svg';

function SoundSwitch({ sounds, setPlaySounds }) {
  return (
    <div className="theme-switch">
    { sounds === true
      ? (
          <button onClick={() => setPlaySounds(true)}>
            <SoundEnable />
          </button>
        )
      : (
          <button onClick={() => setPlaySounds(false)}>
            <SoundMute />
          </button>
        )
    }
    </div>
  );
}

export default SoundSwitch;