import { useFormContext } from "react-hook-form";

function Submit({ children }) {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <button className="form-button form-button-primary" type="submit" disabled={isSubmitting}>
      {children}
    </button>
  );
}

export default Submit;