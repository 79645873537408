import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import classNames from 'classnames';

import TimeElapsedLabel from "./TimeElapsedLabel";
import CommentBox from './CommentBox';
import { getDeviceInfo } from '../hooks/deviceInfo';
import * as EventService from '../services/EventService';
import { useAuthDispatch, doLogout } from '../features/authorization/AuthContext';

import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../assets/icons/clock.svg";
import { ReactComponent as ElapseTimeIcon } from "../assets/icons/elapsetime.svg";
import { ReactComponent as PointIcon } from "../assets/icons/point.svg";
import { ReactComponent as MarkAsReadIcon } from "../assets/icons/read.svg";

import '../styles/DeviceInfo.scss';

function Notification({ index, event, getEvent, updateEvent, deleteEvent }) {
  // As manipulações do usuário autenticado
  const dispatchToAuth = useAuthDispatch();
  const navigate = useNavigate();
  const { tenantName } = useParams();

  // Obtemos a data e hora formatados
  const date = new Date(event.datetime).toLocaleDateString('pt-br');
  const time = new Date(event.datetime).toLocaleTimeString('pt-br');
  const { label, identification, denomination } = getDeviceInfo(event);

  const markEventAsReadClick = (event) => {
    console.log('Marca o evento [', event.id,']', event.trackerEvent.name, 'na placa', event.vehicle?.plate, 'como lido');
    
    EventService.treatEvent(event.id, event.comments, true)
      .then( function (response) {
        const eventID = Object.keys(response.events[0])[0];
        
        deleteEvent(eventID);
        console.log('Marcado evento ID', eventID, 'como lido');
      })
      .catch((error) => {
        const { request, response } = error;

        if (response) {
          switch (response.status) {
            case 401:
              console.error('Sessão expirada');
              doLogout(dispatchToAuth);
              console.log(`Desautenticado. Redirecionando para /app/${tenantName}/login`);
              navigate(`/app/${tenantName}/login`);
            
              break;
            default:
              console.log(error);

              break;
          }
        } else if (request) {
          // Solicitação enviada, mas nenhuma resposta recebida
          console.log("Server time out");
        } else {
          // Algo aconteceu na configuração da solicitação que acionou
          // um erro
          console.log(
            "OPS! Algo deu errado ao configurar a marcação de evento como lido"
          );
        }
      })
    ;
  };

  return (
    <div key={index} className="event">
      <div className="event-data">
        <div className="main-data">
          <Title
            label={label}
            title={identification}
            denomination={denomination}
          />
          <Item type='noback larger middle'>
            <Content type='normal'>
              {event.trackerEvent.name}
            </Content>
          </Item>
        </div>
        <div className="actions">
          <div
            id={ `event_${event.id}` }
            className="action"
            onClick={e => markEventAsReadClick(event)}
          >
            <MarkAsReadIcon />
          </div>
        </div>
      </div>
      <div className="event-details">
        <Item type='emphasis'>
          <Icon
            side='right'
            type='highlighted'
            tooltip='A quanto tempo este evento ocorreu'
          >
            <ElapseTimeIcon />
          </Icon>
          <Content type='normal double rightDivider'>
            <TimeElapsedLabel eventDate={event.datetime} />
          </Content>
          <Icon
            type='highlighted'
            tooltip='Data do evento'
          >
            <CalendarIcon />
          </Icon>
          <Content type='normal autoBasedOnContent rightDivider'>
            { date }
          </Content>
          <Icon
            type='highlighted'
            tooltip='Hora do evento'
          >
            <ClockIcon />
          </Icon>
          <Content type='normal autoBasedOnContent'>
            { time }
          </Content>
        </Item>
        <Item type='emphasis'>
          <Icon
            side='right'
            type='highlighted'
            tooltip='Endereço do local do evento'
          >
            <PointIcon />
          </Icon>
          <Content type='normal'>
            { event.address }
          </Content>
        </Item>
        <CommentBox getEvent={getEvent} updateEvent={updateEvent} event={event} />
      </div>
    </div>
  );
}

function Title({ label, title, denomination }) {
  return (
    <span className="detailbox title">
      { label }: <strong>{ title }</strong>
      {denomination && denomination.length > 0 &&
        <>
          &nbsp;/&nbsp;&nbsp;
          <span className="denomination">{ denomination }</span>
        </>
      }
    </span>
  );
}

function Item({ type, children }) {
  return (
    <div className={classNames('detailbox', 'item', type)}>
      { children }
    </div>
  );
}

function Icon({ type, tooltip, side='up', children }) {
  const tooltipClass = classNames(side, '200');

  return (
    <span
      className={classNames('iconHolder', type)}
      data-tooltip={tooltipClass}
      aria-label={tooltip}
    >
      { children }
    </span>
  );
}

function Content({ type, children }) {
  return (
    <span className={classNames('content', type)}>
      { children }
    </span>
  );
}

export default Notification;