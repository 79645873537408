import Api from './Api';

/**
 * Obtém a lista de gêneros (sexo) para um indivíduo.
 * 
 * @returns
 *   Os dados de gêneros (sexo) para um indivíduo.
 */
const getGenders = async () => {
  return Api
    .get("/documents/genders", {})
    .then((response) => {
      if (response.status === 'Found') {
        return Promise.resolve(response.data);
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Obtém a lista de tipos de telefones.
 * 
 * @returns
 *   Os dados de tipos de telefones.
 */
const getPhoneTypes = async () => {
  return Api
    .get("/documents/phonetypes", {})
    .then((response) => {
      if (response.status === 'Found') {
        return Promise.resolve(response.data);
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Obtém o endereço pelo CEP.
 * 
 * @param {string} postalCode
 *   O CEP.
 * 
 * @returns
 *   Os dados do endereço.
 */
const addressLookUp = async (
  postalCode
) => {
  const queryParms = {
    postalCode: postalCode
  };

  return Api
    .get("/documents/address/lookup", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return Promise.resolve(response.data);
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Obtém as cidades por um tempo de pesquisa.
 * 
 * @param {string} searchTerm
 *   O termo de pesquisa.
 * 
 * @returns
 *   Os dados de gêneros (sexo) para um indivíduo.
 */
const citiesLookUp = async (
  searchTerm
) => {
  const queryParms = {
    searchTerm: searchTerm,
    limit: 10
  };

  return Api
    .get("/documents/cities", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        const cities = response.data.map((city) => {
          return {
            value: city.id,
            label: city.name,
            state: city.state
          };
        });

        return Promise.resolve(cities);
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getGenders,
  getPhoneTypes,
  addressLookUp,
  citiesLookUp
};
