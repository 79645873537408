import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet } from "react-router-dom";

import Footer from '../../components/Footer';
import { useThemeContext } from "../../components/ThemeProvider";
import ThemeSwitch from '../../components/ThemeSwitch';

import {ReactComponent as AppLogo} from '../../assets/images/logo.svg';
import {ReactComponent as HomeIcon} from '../../assets/icons/home.svg';

import '../../styles/site/styles.scss';

function Header({ theme, setChosenTheme }) {
  const location = useLocation();

  const MenuItem = ({ to, children }) => {
    const classes = (location.pathname === to)
      ? "active"
      : ""
    ;
    return (
      <li className={classes}>
        {location.pathname === to ? (
          <span>{children}</span>
        ) : (
          <Link to={to}>{children}</Link>
        )}
      </li>
    );
  };

  return (
    <header>
      <div className="logo">
        <AppLogo />
      </div>
      <nav>
        <ul>
          <MenuItem to="/"><HomeIcon /> Início</MenuItem>
          <MenuItem to="/about">Sobre</MenuItem>
          <MenuItem to="/privacypolicy">Política de privacidade</MenuItem>
        </ul>
      </nav>
      <ThemeSwitch theme={theme} setChosenTheme={setChosenTheme} />
    </header>
  );
}

const PublicLayout = () => {
  const { theme, changeTheme } = useThemeContext();
  const [currentTheme, setCurrentTheme] = useState(theme);

  const handleThemeChange = (chosenTheme) => {
    changeTheme(chosenTheme);
  }

  useEffect(() => {
    console.log(`Modificado tema da interface para '${theme}'`);
    setCurrentTheme(theme);
  }, [theme]);

  return (
    <div className="site">
      <Header theme={currentTheme} setChosenTheme={handleThemeChange} />
        <Outlet />
      <Footer />
    </div>
  );
};

export default PublicLayout;