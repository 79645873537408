import {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

import iButtonCRC from "../../utils/iButtonCRC";

const IdentifierControl = ({
  name,
  options = {},
  ...props
}) => {
  const {
    control,
    formState: { defaultValues, isSubmitting, errors },
    setError
  } = useFormContext();

  // Função para parsear o valor do iButton
  const parseValue = (value, fault = '') => {
    if (!value) return fault;

    if (typeof value === 'string' || value instanceof String) {
      // Limpa espaços em branco e quaisquer caracteres que não sejam
      // números ou letras
      //value = value.replace(/[^a-zA-Z0-9]/g, '');
      
      if (value === '') return fault;

      value = value.toLowerCase();

      return value;
    }

    return fault;
  }

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return parseValue(defaultValues[name]);
    }

    return '';
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(parseValue(defaultValues[name]));
    } else {
      setDefaultValue('');
    }
  }, [defaultValues, name]);

  const {
    field
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options }
  });

  const isIButtonValid = (value) => {
    return iButtonCRC(value) === 0;
  };

  // Manipulador para mudanças no input
  const handleOnInput = (event) => {
    const { value: inputValue } = event.target;

    const parsedValue = parseValue(inputValue);

    if (parsedValue === '') {
      field.onChange('');

      return;
    }

    if (parsedValue.length > 0) {
      if (isIButtonValid(parsedValue)) {
        setError(name, null);
      } else {
        setError(name, {
          type: 'iButton',
          message: 'iButton inválido'
        });
      }
    } else {
      if (errors[name]) {
        setError(name, null);
      }
    }

    field.onChange(parsedValue);
  };

  // Manipulador para colar dados no input
  const handleOnPaste = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    if (clipboardData) {
      if (typeof clipboardData === 'string' || clipboardData instanceof String) {
        event.target.value = clipboardData;
        handleOnInput(event);
      }
    }
  };

  // Manipulador para perda de foco do input
  const handleOnBlur = (event) => {
    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: field.value } };
    field.onBlur(newEvent);
  };

  return (
    <input
      type="text"
      id={name}
      name={name}
      value={field.value}
      onInput={handleOnInput}
      onPaste={handleOnPaste}
      onBlur={handleOnBlur}
      disabled={isSubmitting}
      maxLength={16}
      {...props}
    />
  );
};

export default IdentifierControl;
