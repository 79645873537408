import { useEffect, useState } from 'react'

import './styles.scss'

export const Toggle = ({ label, toggled, onClick }) => {
  const [isToggled, toggleState] = useState(toggled)

  const handleToggedClick = () => {
    toggleState(!isToggled)
    if (onClick) {
      onClick(!isToggled)
    }
  }

  useEffect(() => {
    console.log('Toggle', toggled)
    toggleState(toggled)
  }, [toggled])

  console.log('Toggle is', isToggled);

  return (
    <label className="toggle">
      <input
        type="checkbox"
        defaultChecked={isToggled}
         value={isToggled}
         checked={isToggled}
         onClick={handleToggedClick}
         onChange={() => {}}
      />
      <span />
      { label &&
        <strong>{label}</strong>
      }
    </label>
  )
}