import {
  createContext,
  useContext
} from 'react';

const APPContext = createContext();

function useAPPContext() {
  const context = useContext(APPContext);
  if (!context) {
    throw new Error('useAPPContext deve ser utilizado dentro de um APPProvider');
  }
  
  return context;
}

export { APPContext, useAPPContext };