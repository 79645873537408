import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TenantContext } from '../features/TenantContext';
import { getValidTenants } from '../services/TenantService';
import TenantLayout from '../pages/Private/TenantLayout';

function TenantRoute({ children }) {
  const navigate = useNavigate();
  const { tenantName } = useParams();

  const [loading, setLoading] = useState(true);
  const [validTenants, setValidTenants] = useState([]);
  const [validTenant, setValidTenant] = useState(null);

  const getValidTenantsFromLocalStorage = useCallback(() => {
    const storedTenants = localStorage.getItem('validTenants');

    if (storedTenants) {
      const { timestamp, tenants } = JSON.parse(storedTenants);
      const currentTime = Date.now();
      const expirationTime = 48 * 60 * 60 * 1000; // 48 hours in milliseconds

      if (currentTime - timestamp < expirationTime) {
        return tenants;
      }
    }

    return [];
  }, []);

  const setValidTenantsToLocalStorage = useCallback((tenants) => {
    //const timestamp = Date.now();
    //const data = { timestamp, tenants };
    // TODO: Criar um hook para armazenar os tenants válidos no localStorage
    //localStorage.setItem('validTenants', JSON.stringify(data));
  }, []);

  const fetchValidTenants = useCallback(async () => {
    try {
      // Fazer a requisição à API para obter os tenants válidos
      const tenants = await getValidTenants();
      setValidTenantsToLocalStorage(tenants);
      setValidTenants(tenants);
    } catch (error) {
      setValidTenantsToLocalStorage([]);
      setValidTenants([]);
    }
  }, [setValidTenantsToLocalStorage]);

  useEffect(() => {
    const storedTenants = getValidTenantsFromLocalStorage();

    if (storedTenants.length > 0) {
      setValidTenants(storedTenants);
    } else {
      fetchValidTenants(); // Não há tenants válidos armazenados localmente, buscar da API
    }
  }, [getValidTenantsFromLocalStorage, fetchValidTenants]);

  useEffect(() => {
    if (validTenants.length > 0) {
      const foundTenant = validTenants.find((tenant) => tenant.tenantname === tenantName);

      if (!foundTenant) {
        console.log(`O contratante ${tenantName} não é valido. `
          + `Redirecionando para /notfoundtenant/${tenantName}`
        );
        setValidTenant(null);
        setLoading(false);
        navigate(`/notfoundtenant/${tenantName}`);
      }
      setValidTenant(foundTenant);
      console.log(
        `Liberando acesso ao conteúdo privado de ${tenantName}.`
      );

      setLoading(false);
    }
  }, [validTenants, tenantName, navigate, setValidTenant]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{ margin: 'auto', background: 'transparent', display: 'block', shapeRendering: 'auto' }}
          width="120px"
          height="120px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(80,50)">
            <g transform="rotate(0)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="1">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.875s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(20,50)">
            <g transform="rotate(45)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.875">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.75s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(50,50)">
            <g transform="rotate(90)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.75">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.625s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(80,50)">
            <g transform="rotate(135)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.625">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.5s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(20,50)">
            <g transform="rotate(180)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.5">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.375s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(50,50)">
            <g transform="rotate(225)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.375">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.25s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(80,50)">
            <g transform="rotate(270)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.25">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.125s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
          <g transform="translate(20,50)">
            <g transform="rotate(315)">
              <circle cx="0" cy="0" r="6" fill="#1e75eb" fillOpacity="0.125">
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="0s"
                  values="1.5 1.5;1 1"
                  keyTimes="0;1"
                  dur="1s"
                  repeatCount="indefinite"
                />
                <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" />
              </circle>
            </g>
          </g>
        </svg>
      </div>
    );
  }

  const tenantContextValue = {
    tenant: validTenant
  };

  return (
    <TenantContext.Provider value={tenantContextValue}>
      <TenantLayout>
        { children }
      </TenantLayout>
    </TenantContext.Provider>
  );
}

export default TenantRoute;
