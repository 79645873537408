import React, {
  forwardRef,
  useState
} from "react";

import SquareButton from '../SquareButton';
import ColorBox from './ColorBox';
import OutsideClickHandler from "../OutsideClickHandler";

const ColorComboBox = forwardRef(({
  color,
  colors,
  disabled,
  onChange
}, ref) => {
  const [selectedColor, setSelectedColor] = useState(color);
  const [showColors, setShowColors] = useState(false);

  // Definimos um objeto de contexto com os estados necessários
  const context = { 
    showColors,
    setShowColors
  };

  const toggleShowColors = () => setShowColors(!showColors);

  const handleOutsideClick = () => context.setShowColors(false);

  const handleColorSelected = (newColor) => {
    setSelectedColor(newColor);
    setShowColors(false);
    if (onChange) {
      onChange(newColor);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%'
      }}
    >
      <div style={styles.colorSelector}>
        <SquareButton
          ref={ref}
          color={selectedColor}
          onClick={toggleShowColors}
          disabled={disabled}
          size="1.9rem"
        />
        {showColors && !disabled &&
          <div style={styles.colorBox}>
            <ColorBox color={selectedColor} columns={5} colors={colors} onChange={handleColorSelected} />
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
});

const styles = {
  colorSelector: {
    position: 'relative',
  },
  colorBox: {
    position: 'absolute',
    right: 0,
    top: '2rem',
    width: 'auto',
    zIndex: 1000,
    backgroundColor: 'var(--theme-dropdown-bg)',
    padding: '3px',
    borderRadius: '.475rem',
    border: '1px solid var(--theme-input-border-color)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
  }
};

export default ColorComboBox;
