import React from 'react';

function Head({ keys,  columns }) {
  return (
    <thead>
      <tr>
        {keys.map((key, index) => {
          if (!columns.hasOwnProperty(key)) {
            return null;
          }
          const classes = [
            columns[key].hidden?'hidden':'',
          ].join(' ');

          return (
            <th key={index} className={classes}>
              { columns[key].name }
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default Head;