const BlockIcon = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    stroke={color}
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
  >
    <circle cy="8" cx="8" r="6.25"/>
    <line x1="4.25" x2="12.25" y1="11.75" y2="3.75"/>
  </svg>
);

export default BlockIcon;