import Api from './Api';

/**
 * Obtém a lista de tentans válidos.
 * 
 * @returns
 *   Os dados de tentans válidos
 */
const getValidTenants = async () => {
  return Api
    .get("/auth/tenant/list", {})
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getValidTenants
};
