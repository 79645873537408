import {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

const PhoneControl = ({
  name,
  options = {},
  ...props
}) => {
  const {
    control,
    formState: { defaultValues, isSubmitting },
  } = useFormContext();

  // Função para parsear o valor do telefone
  const parseValue = (value, fault = '') => {
    if (!value) return fault;

    if (typeof value === 'string' || value instanceof String) {
      // Limpa quaisquer caracteres que não sejam números
      value = value.replace(/[^\d]/g, '');
      if (value === '') return fault;

      // Adiciona a máscara ao telefone de acordo com o seu tamanho em
      // dois formatos (99) 4444-5555 e (99) 99444-5555
      return value.length <= 10
        ? value
          .replace(/(\d{2})(\d)/, "($1) $2")
          .replace(/(\d{4})(\d)/, "$1-$2")
        : value
          .replace(/(\d{2})(\d)/, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2")
      ;
    }

    return fault;
  }

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return parseValue(defaultValues[name]);
    }

    return '';
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(parseValue(defaultValues[name]));
    } else {
      setDefaultValue('');
    }
  }, [defaultValues, name]);

  const {
    field
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options }
  });

  // Manipulador para mudanças no input
  const handleOnInput = (event) => {
    const { value: inputValue } = event.target;

    const parsedValue = parseValue(inputValue);

    if (parsedValue === '') {
      field.onChange('');

      return;
    }

    field.onChange(parsedValue);
  };

  // Manipulador para colar dados no input
  const handleOnPaste = (event) => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    if (clipboardData) {
      if (typeof clipboardData === 'string' || clipboardData instanceof String) {
        event.target.value = clipboardData;
        handleOnInput(event);
      }
    }
  };

  // Manipulador para perda de foco do input
  const handleOnBlur = (event) => {
    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: field.value } };
    field.onBlur(newEvent);
  };

  return (
    <input
      type="text"
      id={name}
      name={name}
      value={field.value}
      onInput={handleOnInput}
      onPaste={handleOnPaste}
      onBlur={handleOnBlur}
      disabled={isSubmitting}
      maxLength={15}
      {...props}
    />
  );
};

export default PhoneControl;
