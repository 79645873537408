import { useFormContext } from "react-hook-form";

function Clear({ children, onClick }) {
  const { 
    reset,
    formState: { isSubmitting }
  } = useFormContext();

  const handleClick = () => {
    reset();
  }

  return (
    <button
      className="form-button form-button-secondary"
      type="button"
      onClick={handleClick}
      disabled={isSubmitting}
    >
      {children}
    </button>
  );
}

export default Clear;