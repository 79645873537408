import React, { useState } from "react";
import "../styles/TimeInput.scss";

import { ReactComponent as AngleUp } from "../assets/icons/angle-up.svg";
import { ReactComponent as AngleDown } from "../assets/icons/angle-down.svg";

function TimeInput(props) {
  const [h, m] = (props.value !== undefined)
    ? props.value.split(":")
    : ["00", "00"]
  ;
  const [hours, setHours] = useState(h);
  const [minutes, setMinutes] = useState(m);

  const handleHoursChange = (event) => {
    let newHours = event.target.value.replace(/\D/g, "");
    if (newHours.length > 2) {
      newHours = newHours.slice(-2);
    }
    if (newHours < 0) {
      newHours = 0;
    } else if (newHours > 23) {
      newHours = 23;
    }
    setHours(newHours.toString().padStart(2, "0"));
    props.onChange(`${newHours.toString().padStart(2, "0")}:${minutes}`);
  };

  const handleMinutesChange = (event) => {
    let newMinutes = event.target.value.replace(/\D/g, "");
    if (newMinutes.length > 2) {
      newMinutes = newMinutes.slice(-2);
    }
    if (newMinutes < 0) {
      newMinutes = 0;
    } else if (newMinutes > 59) {
      newMinutes = 59;
    }
    setMinutes(newMinutes.toString().padStart(2, "0"));
    props.onChange(`${hours}:${newMinutes.toString().padStart(2, "0")}`);
  };

  const handleHoursKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      setHours((prevHours) => {
        let newHours = parseInt(prevHours, 10) + 1;
        if (newHours > 23) {
          newHours = 0;
        }
        props.onChange(`${newHours}:${minutes}`);
        return newHours.toString().padStart(2, "0");
      });
    } else if (event.key === "ArrowDown") {
      setHours((prevHours) => {
        let newHours = parseInt(prevHours, 10) - 1;
        if (newHours < 0) {
          newHours = 23;
        }
        props.onChange(`${newHours}:${minutes}`);
        return newHours.toString().padStart(2, "0");
      });
    } else {
      console.log(event.key);
    }
  };

  const handleMinutesKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      setMinutes((prevMinutes) => {
        let newMinutes = parseInt(prevMinutes, 10) + 1;
        if (newMinutes > 59) {
          newMinutes = 0;
        }
        props.onChange(`${hours}:${newMinutes}`);
        return newMinutes.toString().padStart(2, "0");
      });
    } else if (event.key === "ArrowDown") {
      setMinutes((prevMinutes) => {
        let newMinutes = parseInt(prevMinutes, 10) - 1;
        if (newMinutes < 0) {
          newMinutes = 59;
        }
        props.onChange(`${hours}:${newMinutes}`);
        return newMinutes.toString().padStart(2, "0");
      });
    }
  };

  const handleHoursIncrement = () => {
    let newHours = parseInt(hours) + 1;
    if (newHours > 23) {
      newHours = 0;
    }
    setHours(newHours.toString().padStart(2, "0"));
    props.onChange(`${newHours.toString().padStart(2, "0")}:${minutes}`);
  };

  const handleHoursDecrement = () => {
    let newHours = parseInt(hours) - 1;
    if (newHours < 0) {
      newHours = 23;
    }
    setHours(newHours.toString().padStart(2, "0"));
    props.onChange(`${newHours.toString().padStart(2, "0")}:${minutes}`);
  };

  const handleMinutesIncrement = () => {
    let newMinutes = parseInt(minutes) + 1;
    if (newMinutes > 59) {
      newMinutes = 0;
    }
    setMinutes(newMinutes.toString().padStart(2, "0"));
    props.onChange(`${hours}:${newMinutes.toString().padStart(2, "0")}`);
  };

  const handleMinutesDecrement = () => {
    let newMinutes = parseInt(minutes) - 1;
    if (newMinutes < 0) {
      newMinutes = 59;
    }
    setMinutes(newMinutes.toString().padStart(2, "0"));
    props.onChange(`${hours}:${newMinutes.toString().padStart(2, "0")}`);
  };

  const classDefinition = 'TimeInput'
    + (props.small ? ' smaller' : '')
  ;

  return (
    <div className={classDefinition}>
      <input
        type="text"
        value={hours}
        onChange={handleHoursChange}
        onKeyDown={handleHoursKeyDown}
        placeholder="HH"
      />
      <div>
        <button
          type="button"
          className="TimeInput-btn"
          onClick={handleHoursIncrement}
          aria-label="Increment hours"
        >
          <AngleUp />
        </button>
        <button
          type="button"
          className="TimeInput-btn"
          onClick={handleHoursDecrement}
          aria-label="Decrement hours"
        >
          <AngleDown />
        </button>
      </div>
      <div><span className="TimeInput-separator">:</span></div>
      <input
        type="text"
        value={minutes}
        onChange={handleMinutesChange}
        onKeyDown={handleMinutesKeyDown}
        placeholder="MM"
      />
      <div>
        <button
          type="button"
          className="TimeInput-btn"
          onClick={handleMinutesIncrement}
          aria-label="Increment minutes"
        >
          <AngleUp />
        </button>
        <button
          type="button"
          className="TimeInput-btn"
          onClick={handleMinutesDecrement}
          aria-label="Decrement minutes"
        >
          <AngleDown />
        </button>
      </div>
    </div>
  );
}

export default TimeInput;
