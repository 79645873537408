import React, { useCallback, useState } from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import {
  useAuthDispatch,
  doLogout
} from '../../features/authorization/AuthContext';
import { DeviceProvider } from '../../features/DeviceContext';
import { GeoProvider } from '../../features/GeoContext';
import { DriverProvider } from '../../features/DriverContext';
import { IdentifierProvider } from '../../features/IdentifierContext';
import { EventProvider } from '../../features/EventContext';
import { APIContext } from '../../features/APIContext';
import SystemUpdater from './SystemUpdater';
import NotificationSender from './NotificationSender';
import Container from '../../components/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const { tenantName } = useParams();
  const dispatchToAuth = useAuthDispatch();

  // O controle de inicialização do aplicativo
  const [isStarting, setIsStarting] = useState(true);

  // O controle de que estamos num processo de atualização
  const [onUpdate, setOnUpdate] = useState(false);

  // O controle de que estamos aguardando a conclusão da atualização
  const [waitingForUpdate, setWaitingForUpdate] = useState(false);

  // O controle de bloqueio de atualizações
  const [blockUpdate, setBlockUpdate] = useState(false);

  // O controle de qual API deve ser atualizada imediatamente
  const [updateNow, setUpdateNow] = useState(-1);

  // Força a atualização de uma API
  const forceUpdateNow = useCallback((API) => {
    setUpdateNow(API);
    setWaitingForUpdate(true);
  }, [setUpdateNow, setWaitingForUpdate]);

  // Reseta o estado de atualização imediata para indicar que a
  // solicitação de atualização foi atendida
  const resetUpdateNow = useCallback(() => {
    console.log('resetUpdateNow');
    setUpdateNow(-1);
  }, [setUpdateNow]);

  // Finaliza o processo de atualização forçada
  const endUpdate = useCallback(() => {
    setOnUpdate(false)
    setWaitingForUpdate(false);
  }, [setOnUpdate, setWaitingForUpdate]);

  // Atualiza o estado de bloqueio de atualizações
  const changeBlockUpdate = useCallback((newBlockUpdate) => {
    setBlockUpdate(newBlockUpdate);
  }, [setBlockUpdate]);

  const APIContextValues = {
    isStarting,
    setIsStarting,
    onUpdate,
    setOnUpdate,
    waitingForUpdate,
    forceUpdateNow,
    resetUpdateNow,
    endUpdate,
    blockUpdate,
    changeBlockUpdate,
  };

  const goLogin = () => {
    doLogout(dispatchToAuth);
    console.log(`Redirecionando para /app/${tenantName}/login`);
    navigate(`/app/${tenantName}/login`);
  };

  return (
    <Container>
      <ToastContainer />
      <APIContext.Provider value={APIContextValues}>
        <DeviceProvider>
          <DriverProvider>
            <IdentifierProvider>
              <GeoProvider>
                <EventProvider>
                  <SystemUpdater
                    blockUpdate={blockUpdate}
                    onUpdate={onUpdate}
                    setOnUpdate={setOnUpdate}
                    updateNow={updateNow}
                    forceUpdateNow={forceUpdateNow}
                    resetUpdateNow={resetUpdateNow}
                    endUpdate={endUpdate}
                    goLogin={goLogin}
                    clearInitialLoading={() => setIsStarting(false)}
                    forceInitialLoading={() => setIsStarting(true)}
                  />
                  <NotificationSender />
                  <Header />
                </EventProvider>
                { children }
              </GeoProvider>
            </IdentifierProvider>
          </DriverProvider>
        </DeviceProvider>
      </APIContext.Provider>
      <Footer
        appName='Sistema para rastreamento de veículos'
      />
    </Container>
  )
}

export default PrivateLayout;
