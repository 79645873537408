import React from 'react';
import classNames from 'classnames';

function SirenStatus({ status, size }) {
  const ArmedIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Sirene ligada"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m28.740739.000384v6.1063405a21.395746 19.461173 0 0 1 13.147099 17.8934685 21.395746 19.461173 0 0 1 -13.147099 17.893469v6.106338a27.667885 25.166194 0 0 0 19.420063-23.999807 27.667885 25.166194 0 0 0 -19.420063-23.999809zm0 13.338481v21.250605a10.852657 10.852657 0 0 0 8.851048-10.589277 10.852657 10.852657 0 0 0 -8.851048-10.661328zm-8.243973-7.1067241-8.547199 8.5452741h-9.8047184c-1.27386545 0-2.30565035 1.031773-2.30565035 2.305639v13.833833c0 1.272909 1.0317849 2.305639 2.30565035 2.305639h9.8047184l8.547199 8.545274c1.443907 1.443907 3.935922.429426 3.935922-1.630271v-32.2751054c0-2.0616332-2.493941-3.0722639-3.935922-1.6302827z" />
    </svg>
  );
  const DisarmedIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Sirene desligada"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m20.496767 6.2321409-8.547199 8.5452741h-9.8047181c-1.2738655 0-2.3056504 1.031773-2.3056504 2.305639v13.833833c0 1.272909 1.0317849 2.305639 2.3056504 2.305639h9.8047181l8.547199 8.545274c1.443907 1.443907 3.935922.429426 3.935922-1.630271v-32.2751054c0-2.0616332-2.493941-3.0722639-3.935922-1.6302827z" />
      <path d="m28.740739.00038396v6.10634024a21.395745 19.461172 0 0 1 13.147098 17.8934678 21.395745 19.461172 0 0 1 -13.147098 17.893468v6.106338a27.667883 25.166193 0 0 0 19.420061-23.999806 27.667883 25.166193 0 0 0 -19.420061-23.99980804zm0 13.33848004v21.250605a10.852657 10.852657 0 0 0 8.851047-10.589277 10.852657 10.852657 0 0 0 -8.851047-10.661328z" fill="var(--theme-gray-300)" />
    </svg>
  );
  const NoSirenIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Sem sirene"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m3.5232381-.0781253a2.3050848 2.3050848 0 0 0 -1.6297669.67306678 2.3050848 2.3050848 0 0 0 0 3.25953392l10.4966898 10.4944386-.436706.436706h-9.803363c-1.2735568 0-2.3050848 1.031527-2.3050848 2.305084v13.830509c0 1.272601 1.031528 2.305085 2.3050848 2.305085h9.803363l8.545022 8.54277c1.443557 1.443557 3.934851.42943 3.934851-1.629767v-13.74722l4.306277 4.306278v3.894332a10.850049 10.850049 0 0 0 2.861096-1.033237l4.018141 4.018142a21.390604 19.456496 0 0 1 -6.879237 4.317531v6.104873a27.661235 25.160146 0 0 0 11.138242-6.1634l1.926907 1.926907a2.3050848 2.3050848 0 0 0 3.259534 0 2.3050848 2.3050848 0 0 0 0-3.259534l-2.007945-2.007945-4.380562-4.380562-3.475636-3.473385-4.18697-4.18697-2.27357-2.27582-4.306277-4.304026-8.783632-8.783634-10.4966908-10.49668952a2.3050848 2.3050848 0 0 0 -1.6297671-.67306678zm25.2163669.09004238v6.10487292a21.390604 19.456496 0 0 1 13.143935 17.889169 21.390604 19.456496 0 0 1 -1.006223 5.791976l4.693459 4.693458a27.661235 25.160146 0 0 0 2.584217-10.485434 27.661235 25.160146 0 0 0 -19.415388-23.99404192zm-6.690148 5.55561452c-.547736.014364-1.100471.2248079-1.55098.6753178l-1.589248 1.5892479 5.524099 5.5240997v-5.4835808c0-1.4170289-1.178855-2.3366845-2.383871-2.3050846zm6.690148 7.7796614v4.31303l8.58554 8.58779a10.850049 10.850049 0 0 0 .263375-2.242054 10.850049 10.850049 0 0 0 -8.848915-10.658766z" />
    </svg>
  );

  const StatusIcon = ({ status, size }) => {
    if (status === null) {
      return <NoSirenIcon size={size} />
    }

    if (status === true) {
      return <ArmedIcon size={size} />;
    }

    return <DisarmedIcon size={size} />;
  };

  const color = (status === null) ? 'nosiren' : (status === true) ? 'armed' : 'disarmed';

  return (
    <div className={classNames('status', color)}>
      <StatusIcon status={status} size={size} />
    </div>
  );
}

export default SirenStatus;