import React from 'react';

import Main from '../../components/Main';

function AppHome() {
  return (
    <Main />
  );
}

export default AppHome;
