import { useFormContext } from "react-hook-form";

const Control = ({
  name,
  options = {},
  type,
  ...props
}) => {
  const {
    register,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <input
      id={name}
      type={type}
      {...register(name, options)}
      disabled={isSubmitting}
      {...props}
    />
  );
};

export default Control;