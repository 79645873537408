import React from 'react';

function Collapse({ isOpen, children }) {
  return (
    <>
      {isOpen &&
        <>
        {children}
        </>
      }
    </>
  );
}

export default Collapse;
