function responseFormatter(response, lineSeparator = null, fieldSeparators = null) {
  // Limpa possíveis caracteres inválidos
  const cleanerString = (value) => {
    return value
      .replace(/\x01/g, '')
      .replace(/\x02/g, '')
    ;
  };

  // Analisa uma linha, decodificando-a em função dos separadores de
  // campos informados (se necessário)
  const parseLine = (line, separators) => {
    if (!separators) {
      return { "value": line };
    }

    // Verificamos se a linha contém algum dos caracteres separadores
    for (const separator of separators) {
      if (line.indexOf(separator) > -1) {
        // Encontramos o caracter separador, então retornamos o par de
        // valores
        const regexPattern = new RegExp(`(.*?)${separator}(.*)`);
        const [, key, value] = line.match(regexPattern);

        return { "key": key, "value": value };
      }
    }
  
    // Não encontramos nenhum caracter separador
    return { "value": line };
  };

  // Decodifica uma linha em função dos separadores de linha e campo
  const responseDecoder = (data, lineSeparator, fieldSeparators = null) => {
    const elements = [];
    if (lineSeparator) {
      const lines = data.split(lineSeparator);
  
      // Itera pelas linhas
      for (const line of lines) {
        if (!line) {
          // Pulamos valores vazios
          continue;
        }

        const lineData = parseLine(line, fieldSeparators);
        if (!lineData.hasOwnProperty('key')) {
          if (elements.length > 0 && elements[elements.length - 1].hasOwnProperty('key')) {
            // Adicionamos o conteúdo deste campo na chave anterior
            let lastest = elements[elements.length - 1];
            lastest.value = lastest.value + ' ' + lineData.value;
            elements[elements.length - 1] = lastest;
          } else {
            // Adicionamos apenas o valor
            elements.push(lineData)
          }
        } else {
          elements.push(lineData)
        }
      }
    } else {
      const lineData = parseLine(data, fieldSeparators);
      elements.push(lineData);
    }

    return elements;
  };

  // Obtemos os elementos decodificados
  return responseDecoder(
    cleanerString(response), lineSeparator, fieldSeparators
  );
}

export default responseFormatter;
