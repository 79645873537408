import React from "react";
import { z } from "zod";

import {
  Button,
  Cancel,
  ColorSelector,
  Control,
  DecimalControl,
  Description,
  Error,
  FieldSet,
  Form,
  Group,
  IconSelector,
  Inline,
  Label,
  Legend,
  Row,
  Submit,
  TextArea,
  Toggle
} from '../components/form';

import '../components/form/form.css';

const LandmarkForm = ({
  landmark,
  onSubmit,
  onCancel,
  onSaveAsFence,
  onFieldChange,
  colors,
  emblems,
  names
}) => {
  const emblemsIDs = Object.keys(emblems).map(Number);

  const isNameUnique = async (value, context) => {
    // Verifica se name já existe em names, usando case insensitive e
    // ignorando espaços no início e fim e acentos
    const name = value
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
    ;

    return !names.includes(name);
  };

  const landmarkSchema = z.object({
    id: z.number().int().optional(),
    customerid: z.number().positive().int(),
    name: z
      .string({
        required_error: "Nome é obrigatório",
        invalid_type_error: "Nome deve ser uma string",
      })
      .trim()
      .nonempty({
        message: "Nome não pode estar em branco",
      })
      .max(50, {
        message: "Deve ter no máximo 50 caracteres"
      })
      .refine(isNameUnique, {
        message: "Já exite outro ponto de referência com este nome",
      }),
    note: z
      .optional(
        z.string({
          invalid_type_error: "Observação deve ser uma string",
        })
        .trim()
      ),
    latitude: z.number({
        required_error: "Latitude é obrigatória",
        invalid_type_error: "Informe a latitude",
      })
      .min(-90).max(90),
    longitude: z.number({
        required_error: "Longitude é obrigatória",
        invalid_type_error: "Informe a longitude",
      })
      .min(-180).max(180),
    emblemid: z.number({
        required_error: "Emblema é obrigatório",
        invalid_type_error: "Informe o emblema",
      }).int().positive(),
    color: z.enum(colors, {
        required_error: "Cor é obrigatória",
        invalid_type_error: "Selecione uma cor",
      }),
    showname: z.boolean({
      required_error: "Exibir nome no mapa é obrigatório",
      invalid_type_error: "Informe se o nome deve ser exibido no mapa",
    }),
    disabled: z.boolean({
      required_error: "Desabilitado é obrigatório",
      invalid_type_error: "Informe se o ponto de referência está desabilitada",
    })
  });

  return (
    <Form
      schema={landmarkSchema}
      defaultValues={landmark}
      onSubmit={onSubmit}
      onFieldChange={onFieldChange}
      watchFields={["name", "note", "latitude", "longitude", "emblemid", "color", "showname", "disabled"]}
    >
      <Control
        type="hidden"
        name="id"
      />
      <Control
        type="hidden"
        name="customerid"
      />
      <Group>
        <Label htmlFor="name">Nome</Label>
        <Control
          type="text"
          name="name"
        />
        <Error name="name" />
      </Group>
      <Row>
        <Group flex="5">
          <Label htmlFor="latitude">Latitude</Label>
          <DecimalControl
            name="latitude"
            size={10}
            decimals={6}
          />
          <Error name="latitude" />
        </Group>
        <Group flex="5">
          <Label htmlFor="longitude">Longitude</Label>
          <DecimalControl
            name="longitude"
            size={10}
            decimals={6}
            />
          <Error name="longitude" />
        </Group>
        <Group flex="0">
          <Label htmlFor="color">Cor</Label>
          <ColorSelector
            label="Cor da cerca:"
            name="color"
            colors={colors}
          />
          <Error name="color" />
        </Group>
        <Group flex="0">
          <Label htmlFor="emblemid">Ícone</Label>
          <IconSelector
            label="Ícone:"
            name="emblemid"
            icons={emblems}
          />
          <Error name="emblemid" />
        </Group>
      </Row>
      <Group>
        <Label htmlFor="note">Observação</Label>
        <TextArea
          id="note"
          name="note"
          rows="4"
        />
        <Error name="note" />
      </Group>
      <FieldSet direction="col">
        <Legend>Opções adicionais</Legend>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ borderBottom: '1px solid var(--theme-list-group-border-color)', marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="showname"
              >
                <span>Exibir nome no mapa</span>
                <Description>
                  Controla a exibição do nome logo abaixo do seu ícone
                  no mapa.
                </Description>
              </Label>
            </Group>
            <Toggle
              name="showname"
            />
          </Inline>
          <Error name="showname" />
        </Group>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="disabled"
              >
                <span>Desativar ponto de referência</span>
                <Description>
                  Oculta este ponto de referência do mapa e desabilita quaisquer referências a ele nos endereços
                </Description>
              </Label>
            </Group>
            <Toggle
              name="disabled"
            />
          </Inline>
          <Error name="disabled" />
        </Group>
      </FieldSet>
      <Row rightAligned>
        <Button
          color={"green"}
          style={{
            marginRight: "auto"
          }}
          onClick={onSaveAsFence}
        >
          Salvar como cerca
        </Button>
        <Cancel onClick={onCancel} >
          Cancelar
        </Cancel>
        <Submit value="Salvar">
          Salvar
        </Submit>
      </Row>
    </Form>
  );
};

export default LandmarkForm;

