import React from 'react';
import classNames from 'classnames';

function BlockStatus({ status, size }) {
  const BlockedIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Bloqueado"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m23.080084 5.3183594c-6.97985 0-12.66016 5.6803096-12.66016 12.6601566v6.033203h-1.9980495c-2.20722 0-3.99804 1.79082-3.99804 3.998047v15.992187c0 2.207226 1.79082 3.998047 3.99804 3.998047h29.3183595c2.20724 0 3.99805-1.790821 3.99805-3.998047v-15.992187c0-2.207227-1.79081-3.998047-3.99805-3.998047h-2v-6.033203c0-6.979847-5.68031-12.6601566-12.66015-12.6601566zm0 6.6621096c3.30667 0 5.99804 2.691361 5.99804 5.998047v6.033203h-11.99414v-6.033203c0-3.306686 2.68941-5.998047 5.9961-5.998047z" strokeWidth=".888446"
      />
    </svg>
  );
  const UnblockedIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Desbloqueado"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m23.082031 0c-6.979846 0-12.660156 5.6803092-12.660156 12.660156v6.033203h6.662109v-6.033203c0-3.3066858 2.691362-5.9960935 5.998047-5.9960935 3.306678 0 5.996094 2.6894077 5.996094 5.9960935v11.351563h-20.65625c-2.2072262 0-3.9980469 1.79082-3.9980469 3.998047v15.992187c0 2.207226 1.7908207 3.998047 3.9980469 3.998047h29.318359c2.207236 0 3.998047-1.790821 3.998047-3.998047v-15.992187c0-2.207227-1.790811-3.998047-3.998047-3.998047h-1.998046v-11.351563c0-6.9798468-5.680311-12.660156-12.660157-12.660156z" strokeWidth=".888446" />
    </svg>
  );
  const NoBlockerIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Sem bloqueador"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
    	<path d="m23.080078 5.3183594c-3.094887 0-5.932774 1.1190282-8.134766 2.9707031l4.746094 4.7460935c.964983-.664248 2.131199-1.054687 3.388672-1.054687 3.306664 0 5.998047 2.691367 5.998047 5.998047v4.443359l12.660156 12.658203v-7.070312c0-2.207223-1.790811-3.998047-3.998047-3.998047h-2v-6.033203c0-6.979833-5.68033-12.6601566-12.660156-12.6601566zm-16.1562499 3.0546875a2.5 2.5 0 0 0 -1.7675781.7324219 2.5 2.5 0 0 0 0 3.5351562l5.265625 5.265625c-.000138.024224-.001953.048009-.001953.072266v6.033203h-1.998047c-2.2072156 0-3.9980469 1.790824-3.9980469 3.998047v15.992187c0 2.207222 1.7908313 3.998047 3.9980469 3.998047h29.318359c.802261 0 1.547591-.239313 2.173828-.646484a2.5 2.5 0 0 0 3.40625-.085938 2.5 2.5 0 0 0 0-3.535156l-1.582031-1.582031-18.140625-18.138672-6.490234-6.490235-1.117188-1.117187-4.179687-4.179688-3.1191408-3.1191402a2.5 2.5 0 0 0 -1.7675781-.7324219z"/>
    </svg>
  );

  const StatusIcon = ({ status, size }) => {
    if (status === null) {
      return <NoBlockerIcon size={size} />
    }

    if (status === true) {
      return <BlockedIcon size={size} />;
    }

    return <UnblockedIcon size={size} />;
  };

  const color = (status === null) ? 'noblocker' : (status === true) ? 'blocked' : 'unblocked';

  return (
    <div className={classNames('status', color)}>
      <StatusIcon status={status} size={size} />
    </div>
  );
}

export default BlockStatus;