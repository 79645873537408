import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import L from "leaflet";
import {
  Marker,
  Popup,
  Tooltip,
  useMapEvents
} from 'react-leaflet';

import Text from "./Text";
import cross from '../../assets/icons/map/cross.svg';
import dot from '../../assets/icons/map/dot.svg';

import './EditableLandmark.css';

const crossIcon = L.icon({
  iconUrl: cross,
  iconSize: [8, 8],
  iconAnchor: [4, 4],
});
const dotIcon = L.icon({
  iconUrl: dot,
  iconSize: [8, 8],
  iconAnchor: [4, 4],
});

const EditableLandmark = ({
  id,
  name,
  note,
  position,
  icon,
  color = '#3388ff',
  showName = false,
  onPositionChange,
  onEditLandmark,
  editable = false,
  hidden = false,
  underEditing = false
}) => {
  const landmarkRef = useRef();
  const [landmarkName, setLandmarkName] = useState(name);
  const [landmarkPosition, setLandmarkPosition] = useState(position);
  const [landmarkColor, setLandmarkColor] = useState(color);
  const [showLandmarkName, setShowLandmarkName] = useState(showName);
  const [SVG, setSVG] = useState(icon);

  const [editMode, setEditMode] = useState(underEditing);
  const [isDragging, setIsDragging] = useState(false);
  
  useMapEvents({
    click: () => {
      // Lida com cliques fora do ponto de referência
      if (editMode && !underEditing) {
        setEditMode(false);
        setLandmarkColor(color);
      }
    },
  });

  useEffect(() => {
    //console.debug('Position changed');
    setLandmarkPosition(position);
  }, [position]);

  useEffect(() => {
    if (underEditing && !editMode) {
      setLandmarkColor('#665d1e');
      setEditMode(underEditing);
    }
  }, [underEditing]);

  useEffect(() => {
    setLandmarkName(name);
  }, [name]);

  useEffect(() => {
    setLandmarkColor(color);
  }, [color]);

  useEffect(() => {
    setSVG(icon);
  }, [icon]);

  useEffect(() => {
    setShowLandmarkName(showName);
  }, [showName]);

  const svgIcon = (svg, color) => {
    const parser = new DOMParser();
    const parsedSVG = parser.parseFromString(svg, "image/svg+xml");
    const paths = parsedSVG.querySelectorAll("path");

    paths.forEach((path) => {
      path.setAttribute("fill", color);
    });

    const img = parsedSVG.querySelectorAll("svg");
    if (img.length > 0) {
      img[0].setAttribute("width", '2.5em');
      img[0].setAttribute("height", '2.5em');
    }

    return parsedSVG.documentElement.outerHTML;
  };

  const landmarkIcon = useMemo(() => {
    return L.divIcon({
      html: svgIcon(SVG, landmarkColor),
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [ 0, -32],
      className: 'landmark-icon',
    });
  }, [SVG, landmarkColor]);

  const onLandmarkClick = (event) => {
    //console.log('onLandmarkClick');
    event.originalEvent.stopPropagation();

    if (!editable) {
      // Não faz nada se o ponto de referência não for editável
      return;
    }

    if (underEditing) {
      // Não faz nada se o ponto de referência já estiver em edição
      return;
    }

    if (isDragging) {
      // Não faz nada se o usuário estiver arrastando o ponto de
      // referência
      return;
    }

    if (onEditLandmark) {
      console.log('Trigger onEditLandmark', id);
      onEditLandmark(id);
    } else {
      console.debug('Toggle edit mode');
      setEditMode((prevEditMode) => {
        setLandmarkColor(prevEditMode ? color : '#665d1e');

        if (prevEditMode) {
          console.log('Landmark is not editing');
        } else {
          console.log('Landmark is editing');
        }

        return !prevEditMode;
      });
    }
  }

  // Inicia o processo de arrastar a posição da referência
  const handleDragStart = (event) => {
    //console.log('Landmark is dragging');
    event.originalEvent.stopPropagation();
    setIsDragging(true);
  };

  // Lida com o arraste da posição da referência
  const handleOnDragging = (event) => {
    //console.debug('Handle handleOnDragging', event);
    event.originalEvent.stopPropagation();
    const { latlng } = event;
    const newPosition = [latlng.lat, latlng.lng];
    setLandmarkPosition(newPosition);
  };

  // Finaliza o processo de arrastar a posição da referência
  const handleDragEnd = (event) => {
    //console.debug('Handle handleDragEnd', event);
    setIsDragging(false);
    if (onPositionChange) {
      onPositionChange(id, landmarkPosition);
    }
  };

  if (landmarkPosition === undefined ||
      landmarkPosition.length !== 2) {
    console.debug('Invalid landmark position');
    return null;
  }

  if (hidden) {
    return (
      <Marker
        key={`Landmark_Marker_${id}`}
        position={landmarkPosition}
        icon={dotIcon}
      />
    );
  }

  return (
    <>
      <Marker
        ref={landmarkRef}
        key={`Landmark_Marker_${id}`}        
        position={landmarkPosition}
        icon={landmarkIcon}
        eventHandlers={{
          click: onLandmarkClick,
          mousedown: handleDragStart,
          mouseup: handleDragEnd,
          drag: handleOnDragging,
        }}
        title={landmarkName}
        draggable={editMode}
      >
        <Popup
          key={`landmark-popup-${id}`}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {landmarkName}
            </span>
            {note && (
            <span
              style={{
                color: '#808080',
                fontSize: '0.8em',
                fontStyle: 'italic',
              }}
            >
              {note}
            </span>
            )}
          </div>
        </Popup>
        <Tooltip
          key={`Landmark_Tooltip_Position_${id}`}
          direction="top"
          offset={[0, -32]}
          opacity={1}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {showLandmarkName === false && (
            <span style={{ flex:1, fontWeight: 'bold', color: '#404040' }}>
              {landmarkName}
            </span>
          )}
          <span style={{ flex:1, marginRight: 8 }}>
            {landmarkPosition[0].toFixed(6)}, {landmarkPosition[1].toFixed(6)}
          </span>
          </div>
        </Tooltip>
      </Marker>
      <Text
        key={`Landmark_Text_${id}`}
        position={landmarkPosition}
        label={landmarkName}
        color={showLandmarkName ? color : 'transparent'}
      />
      {editMode && (
        <Marker
          key={`Landmark_Cross_${id}`}
          position={landmarkPosition}
          icon={crossIcon}
        />
      )}
    </>
  );
}

export default EditableLandmark;
