import axios from "axios";

import { HttpStatusCode } from './HttpStatusCode';
import { defaults } from '../settings/config';
import TokenService from "./TokenService";

const Api = axios.create({
  baseURL: defaults.api.baseURL
});

const debug = false;

/**
 * Intercepta as requisições e adiciona o token de autorização, se
 * informado.
 */
Api.interceptors.request.use(
  async config => {
    if (debug) {
      console.info('Interceptando a requisição');
    }

    // Verifica se o dispositivo tem conexão com a internet
    const isConnected = navigator.onLine;
    if (debug) {
      console.log('Is connected?', isConnected);
    }

    if (isConnected === false) {
      if (debug) {
        console.error('Sem conexão com a internet.');
      }

      return Promise.reject({
        message: 'Sem conexão com a internet.',
        name: 'NetworkError'
      });
    }

    // Obtemos a informação de token armazenado, se necessário
    const serverCallUrl = config.url;
    if (debug) {
      console.log('Requesting ', serverCallUrl);
    }
    const isPublicUrls = serverCallUrl.includes('/auth')
      && !(serverCallUrl === '/auth/renew')
    ;

    let token = null;
    if (!isPublicUrls) {
      if (debug) {
        console.info('Getting access token');
      }

      try {
        token = await TokenService.getAccessToken(serverCallUrl);
        console.log('Obtido token de acesso para ' + serverCallUrl);
      } catch (error) {
        // Lidamos com erros na obtenção do token
        const { message, name } = error;
        if (debug) {
          console.log('Falha ao obter o token de acesso: ', message);
        }
        if (name === 'Unauthorized' || name === 'Unauthentic') {
          // Forçamos o erro a ser tratado como um erro de autenticação,
          // para que o usuário seja desautenticado
          return Promise.reject({
            message: name
          });
        }
    
        return Promise.reject({
          message: message
        });
      }

      // Se não tivermos um token de acesso, precisamos abortar a
      // requisição
      if (!token) {
        if (debug) {
          console.log('Token de acesso inválido ou inexistente.');
        }

        return Promise.reject({
          message: 'Unauthorized',
        });
      }
    }

    // Adiciona o token de autorização, se informado
    if (token) {
      if ( !serverCallUrl.includes('/auth') ) {
        if (debug) {
          console.info('Adicionando token Bearer');
        }
        config.headers['Xauthorization'] = `Bearer ${token}`;
        //config.headers['Access-Control-Request-Headers'] = 'Authorization';
        //config.withCredentials = true;
      } else {
        if (debug) {
          console.debug(
            'Ignorando adição do token em URLs de autorização'
          );
        }
      }
    } else {
      if (debug) {
        console.debug('Nenhum token de authorization encontrado');
      }
    }

    // Adiciona cabeçalhos padrões em todas as requisições
    config.headers['Accept'] = 'application/json; charset=utf-8';
        
    if (debug) {
      // Fazemos a depuração da conexão
      const printable = `Request: ${ config.url } | Method: ${config.method.toUpperCase()} | Data: ${ JSON.stringify((config.data ?? 'No data sent')) } | DateTime: ${new Date()}`;
      console.info(printable);
      console.info('Headers', JSON.stringify(config.headers));
    }

    return config;
  },
  function (error) {
    // Faça algo com erro de solicitação
    return Promise.reject(error);
  }
);

/**
 * Intercepta as respostas e trata os erros.
 */
Api.interceptors.response.use(
  (response) => {
    return response.data;
  }, async (error) => {
    if (debug) {
      console.debug(
        'Interceptando erro na requisição à API'
      );
    }

    if (error.response) {
      // O pedido foi feito e o servidor respondeu com um código de status
      // que cai fora do intervalo de 2xx
      if (debug) {
        console.debug(
          'Tivemos um erro reportado pela resposta da API'
        );
      }
      
      const { status } = error.response;
      const errorMessage =
        ( error.response.data &&
          error.response.data.error &&
          error.response.data.error.message ) ||
        ( error.response.data &&
          error.response.data.message ) ||
        error.message ||
        error.toString()
      ;

      switch (status) {
        case HttpStatusCode.BAD_REQUEST:
          if (debug) {
            console.error('Resposta inválida');
          }

          return Promise.reject({
            message: errorMessage,
            name: 'BadRequest'
          });
        case HttpStatusCode.UNAUTHORIZED:
          if (debug) {
            console.error(
              'Não autorizado',
              error.response.data.error
            );
          }

          console.error(
            errorMessage
          );
          
          // Desautentica o usuário
          return Promise.reject({
            message: errorMessage,
            name: 'Unauthentic'
          });
        case HttpStatusCode.NOT_FOUND:
          // Recurso não encontrado
          if (debug) {
            console.error('Recurso não encontrado');
          }

          return Promise.reject({
            message: 'O recurso solicitado '
              + error.request._url + ' não está disponível',
            name: 'BadRequest'
          });
        case HttpStatusCode.UNPROCESSABLE_ENTITY:
          // Estamos lidando com erros de validação de um ou mais
          // campos, então recuperamos as violações ocorridas
          if (debug) {
            console.warn(
              'Erro de validação dos campos',
              JSON.stringify((error.response.data.error ?? ''))
            );
          }

          return Promise.reject({
            message: error.response.data.message,
            name: 'Validation',
            violations: error.response.data.error
          });
        default:
          // Qualquer código de status que esteja fora do intervalo
          // de 2xx e que não tenha sido tratado faz com que essa
          // função seja acionada
          if (debug) {
            console.error(
              'Outros erros: Código: ' + status,
              errorMessage
            );
          }

          return Promise.reject({
            message: errorMessage
          });
      }
    } else if (error.request) {
      // O pedido foi feito, mas não houve resposta
      if (error.request._response) {
        // Tivemos um erro na requisição ao servidor
        return Promise.reject({
          name: 'RequestError',
          message: 'Erro na requisição: ' + error.request._response
        });
      }
      
      return Promise.reject({
        name: 'RequestError',
        message: 'Erro na requisição: ' + error.message
      });
    } else {
      // Algo aconteceu na configuração do pedido que acionou um erro
      if (error.message === 'Unauthorized') {
        // Desautentica o usuário
        return Promise.reject({
          name: 'Unauthentic',
          message: 'Usuário não autenticado'
        });
      }
      
      return Promise.reject({
        name: 'UnknownError',
        message: error.message
      });
    }
  }
);

export default Api;
