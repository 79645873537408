import React from 'react';

import {ReactComponent as NotFoundImage} from '../../assets/icons/pagenotfound.svg';


const NotFoundPage = () => {
  return (
    <main className="site">
      <section>
        <NotFoundImage />
        <h2>Desculpe</h2>
        <p>
          Desculpe, a página que você está tentando acessar não existe ou
          não foi encontrada.
        </p>
      </section>
    </main>
  );
};

export default NotFoundPage;