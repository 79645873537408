import React, { useEffect, useState } from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

const AutocompleteControl = ({
  name,
  options = {},
  onItemSelected,
  getItems,
  ...props
}) => {
  const {
    control,
    register,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return defaultValues[name];
    }

    return '';
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(defaultValues[name]);
    } else {
      setDefaultValue('');
    }
  }, [defaultValues, name]);

  const {
    field
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options }
  });

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  // Manipulador para seleção de um item
  const handleItemSelect = (item) => {
    if (item) {
      field.onChange(item.label);
      onItemSelected && onItemSelected(item);
    } else {
      //field.onChange("");
      onItemSelected && onItemSelected(null);
    }
    setIsOpen(false);
    setFocusedIndex(-1);
  };

  // Manipulador para mudanças no input
  const handleOnInput = (event) => {
    const { value: inputValue } = event.target;

    field.onChange(inputValue);
    if (inputValue.length >= 3) {
      // Solicita os itens para autocomplete
      setLoading(true);
      getItems(inputValue)
        .then((items) => {
          setItems(items);
        })
        .finally(() => {
          setLoading(false);
          setIsOpen(true);
          setFocusedIndex(0);
        });
    } else {
      setIsOpen(false);
      setItems([]);
      setFocusedIndex(-1);
      handleItemSelect(null);
    }
  };

  // Manipulador para colar dados no input
  const handleOnPaste = (event) => {
    event.preventDefault();
    
    const clipboardData = event.clipboardData.getData('text');

    if (clipboardData) {
      if (typeof clipboardData === 'string' || clipboardData instanceof String) {
        event.target.value = clipboardData;
        handleOnInput(event);
      }
    }
  };

  // Manipulador para perda de foco do input
  const handleOnBlur = (event) => {
    if (focusedIndex >= 0 && focusedIndex < items.length) {
      handleItemSelect(items[focusedIndex]);
    }

    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: field.value } };
    field.onBlur(newEvent);
  };

  // Manipulador para eventos de teclado
  const handleKeyDown = (event) => {
    if (isOpen) {
      if (event.key === 'ArrowDown') {
        setFocusedIndex((prevIndex) => (prevIndex + 1) % items.length);
      } else if (event.key === 'ArrowUp') {
        setFocusedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < items.length) {
          handleItemSelect(items[focusedIndex]);
        }
      }
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <input
        type="text"
        style={{ paddingRight: '30px' }}
        id={name}
        name={name}
        value={field.value}
        onInput={handleOnInput}
        onKeyDown={handleKeyDown}
        onPaste={handleOnPaste}
        onBlur={handleOnBlur}
        disabled={isSubmitting}
        maxLength={10}
        {...props}
      />
      <span
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          right: '10px',
          top: 'calc(50% - 0.5rem)',
          //transform: 'translateY(-50%)',
          visibility: loading ? 'visible' : 'hidden',
          animation: !loading ? 'spin 1s linear infinite' : 'none'
        }}
      >
        <svg
          width="1rem"
          height="1rem"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-refresh-ccw"
        >
          <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"/>
          <path d="M3 3v5h5"/>
          <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"/>
          <path d="M16 16h5v5"/>
        </svg>
      </span>
      {isOpen && (
        <ul style={styles.dropdownContainer}>
          {loading && <li style={{ padding: '0.5em' }}>Carregando...</li>}
          {items.map((item, index) => (
            <li
              key={item.value}
              style={{
                ...styles.dropdownItem,
                ...(index === focusedIndex) ? {color: 'var(--theme-dropdown-hover-color)', backgroundColor: 'var(--theme-dropdown-hover-bg)'} : {}
              }}
              onClick={() => handleItemSelect(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  dropdownContainer: {
    position: 'absolute',
    zIndex: 1000,
    top: '100%',
    left: 0,
    right: 0,
    padding: 0,
    margin: 0,
    marginTop: '2px',
    listStyle: 'none',
    backgroundColor: 'var(--theme-dropdown-bg)',
    border: '1px solid var(--theme-dropdown-caret-bg)',
    borderRadius: '.475rem',
    boxShadow: '0 7px 14px 0 rgba(3, 12, 51, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.2)'
  },
  dropdownItem: {
    padding: '0.5em',
    cursor: 'pointer',
    color: 'var(--theme-dropdown-color)',
    transition: 'background-color .5s ease-in-out',
    '&:hover': {
      color: 'var(--theme-dropdown-hover-color)',
      backgroundColor: 'var(--theme-dropdown-hover-bg)'
    }
  }
};


export default AutocompleteControl;