import L from 'leaflet';

import PinEndIcon from '../../../../assets/icons/markers/pinend.svg'

const pinEnd = new L.Icon({
  iconUrl: PinEndIcon,
  iconSize: new L.Point(48, 48),
  iconAnchor: [23, 40],
  popupAnchor: [0, -48],
});

export { pinEnd };