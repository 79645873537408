import React, {
  useEffect,
  useState
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { formatPhoneNumber } from 'react-phone-number-input';

import 'react-toastify/dist/ReactToastify.css';

import FlatButton from './FlatButton';
import NotificationIndicatorNumber from './NotificationIndicatorNumber';
import {
  Dropdown,
  Toggle,
  Content,
  MenuItem,
  DocketItem,
  Divider,
  BlankSpace
} from './Dropdown';
import CardNotification from './CardNotification';
import { getUser, useAuthDispatch, doLogout } from '../features/authorization/AuthContext';
import { useThemeContext } from "./ThemeProvider";
import { useTenantContext } from "../features/TenantContext";
import SoundSwitch from './SoundSwitch';
import ThemeSwitch from './ThemeSwitch';
import { useAPPContext } from '../features/APPContext';
import { useEventContext } from '../features/EventContext';
import useNotification from '../hooks/useNotification';
import useNotificationSound from '../hooks/useNotificationSound';
import Dialog from './Dialog';
import PasswordInput from './PasswordInput';
import TextInput from './TextInput';
import PhoneNumberInput from './PhoneInput';
import * as UserService from '../services/UserService';

import {ReactComponent as MapIcon} from '../assets/icons/map.svg';
import {ReactComponent as BellIcon} from '../assets/icons/bell.svg';
import {ReactComponent as EventIcon} from '../assets/icons/event.svg';
import {ReactComponent as MenuIcon} from '../assets/icons/menu.svg';
import {ReactComponent as UserIcon} from '../assets/icons/user.svg';
import {ReactComponent as AppLogo} from '../assets/logos/app.svg';

import BuildVehiclesWithoutCommunication from '../pages/Private/BuildVehiclesWithoutCommunication';

import SoundForAlarm from '../assets/sounds/SoundForAlarm.mp3';
import SoundForAlert from '../assets/sounds/SoundForAlert.mp3';

import '../styles/Header.scss';
import '../components/form/form.css';

export default function Header() {
  // As informações do aplicativo
  const { version } = useAPPContext();

  const [showAbout, setShowAbout] = useState(false);
  
  // As informações do usuário autenticado
  const [showUserAccount, setShowUserAccount] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [violations, setViolations] = useState(null);

  // As informações de alteração de senha
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [wrongPassword, setWrongPassword] = useState(false);
  const [wrongPasswordMessage, setWrongPasswordMessage] = useState('');
  const [wrongNewPassword, setWrongNewPassword] = useState(false);
  const [wrongNewPasswordMessage, setWrongNewPasswordMessage] = useState('');
  const [wrongConfirmPassord, setWrongConfirmPassword] = useState(false);
  const [wrongConfirmPasswordMessage, setWrongConfirmPasswordMessage] = useState('');

  // Os eventos (mensagens e alertas) e alarmes
  const {
    eventsState,
    clearAlarms,
    clearNotices,
    deleteEvent,
    changeSoundState
  } = useEventContext();
  const {
    theme,
    changeTheme
  } = useThemeContext();
  const { tenant } = useTenantContext();
  const [currentTheme, setCurrentTheme] = useState(theme);

  const { playNotificationSound: playSoundForAlarm } = useNotificationSound(SoundForAlarm);
  const { playNotificationSound: playSoundForAlert } = useNotificationSound(SoundForAlert);

  // As manipulações do usuário autenticado
  const dispatchToAuth = useAuthDispatch();
  const navigate = useNavigate();
  const { tenantName } = useParams();
  const location = useLocation();
  const user = getUser();
  const isOnMap = (location.pathname === `/app/${tenantName}`);
  const [onMap, setOnMap] = useState(isOnMap);

  const { permissionForNotification, sendNotification, requestPermission } = useNotification();

  const handleCommands = () => {
    console.log('Selecionado envio de comandos');
    console.log(`Redirecionando para /app/${tenantName}/commands`);
    navigate(`/app/${tenantName}/commands`);
  };

  const handleDrivers = () => {
    console.log('Selecionado gerenciamento de motoristas');
    console.log(`Redirecionando para /app/${tenantName}/drivers`);
    navigate(`/app/${tenantName}/drivers`);
  };

  const handleReports = () => {
    console.log('Selecionado relatórios de histórico');
    console.log(`Redirecionando para /app/${tenantName}/reports/history`);
    navigate(`/app/${tenantName}/reports/history`);
  };

  const handleTripReport = () => {
    console.log('Selecionado relatório de histórico de percurso');
    console.log(`Redirecionando para /app/${tenantName}/reports/triphistory`);
    navigate(`/app/${tenantName}/reports/triphistory`);
  };

  const handleSpeedReport = () => {
    console.log('Selecionado relatório de gráfico de velocidade');
    console.log(`Redirecionando para /app/${tenantName}/reports/speedhistory`);
    navigate(`/app/${tenantName}/reports/speedhistory`);
  };

  const handleAmountOfSpeedingReport = () => {
    console.log('Selecionado relatório de gráfico quantitativo de excesso de velocidade');
    console.log(`Redirecionando para /app/${tenantName}/reports/speedinghistory`);
    navigate(`/app/${tenantName}/reports/speedinghistory`);
  };

  const handleGoToMap = () => {
    console.log('Selecionado mapa');
    console.log(`Redirecionando para /app/${tenantName}`);
    navigate(`/app/${tenantName}`);
  };

  const handleFences = () => {
    console.log('Selecionado cercas geográficas');
    console.log(`Redirecionando para /app/${tenantName}/fences`);
    navigate(`/app/${tenantName}/fences`);
  };

  const handleLandmarks = () => {
    console.log('Selecionado pontos de referência');
    console.log(`Redirecionando para /app/${tenantName}/landmarks`);
    navigate(`/app/${tenantName}/landmarks`);
  };

  const handleVehiclesWithoutCommunication = () => {
    console.log('Selecionado veículos sem comunicação');
    BuildVehiclesWithoutCommunication(tenant);
  };

  const getEvent = (eventID) => {
    return eventsState.alarms.find((event) => {
      return event.id === eventID;
    });
  }

  useEffect(() => {
    // Aqui você pode atualizar o estado do componente
    // com base na localização atual
    const { pathname } = location;
    if (pathname === `/app/${tenantName}`) {
      setOnMap(true);
    } else {
      setOnMap(false);
    }
  }, [location, tenantName]);

  const handleMyAccount = async () => {
    console.log('Minha conta');

    const userAccount = await UserService.getUser(user.id);
    setName(userAccount.name);
    setEmail(userAccount.email);
    setPhoneNumber('+55' + userAccount.phonenumber.replace(/\D/g, ""));
    setViolations(null);

    setShowUserAccount(true);
  };

  const updateUserAccount = () => {
    console.log('Atualizando informações da conta do usuário');
    //console.log('Nome:', name);
    //console.log('E-mail:', email);
    //console.log('Telefone:', formatPhoneNumber(phoneNumber));
    setViolations(null);

    UserService.updateMyAccount({
      id: user.id,
      name: name,
      email: email,
      phonenumber: formatPhoneNumber(phoneNumber)
    }).then((response) => {
      console.log('Informações da conta atualizadas com sucesso');
      setName('');
      setEmail('');
      setPhoneNumber('');
      setShowUserAccount(false);
    })
    .catch((error) => {
      // Analisa se temos um erro de validação
      if (error.violations) {
        console.error('Erro de validação:', error.message);
        setViolations(error.violations);
      } else {
        console.error('Ocorreu um erro:', error);
      }
    });
  };

  const handlePassword = () => {
    console.log('Change password');
    setShowPasswordChange(true);
  };

  const comparePasswords = (stage) => {
    if (currentPassword.length === 0) {
      setWrongPassword(true);
      setWrongPasswordMessage('Informe a senha atual');
    }

    switch (stage) {
      case 1:
        if (newPassword.length === 0) {
          setWrongNewPassword(true);
          setWrongNewPasswordMessage('Informe a nova senha');

          return;
        }

        if (newPassword.length < 8) {
          setWrongNewPassword(true);
          setWrongNewPasswordMessage('A senha deve ter pelo menos 8 caracteres');

          return;
        }
        break;
      default:
        if ((confirmPassword.length === 0) && (newPassword.length > 0)) {
          setWrongConfirmPassword(true);
          setWrongConfirmPasswordMessage('Confirme a nova senha');
        }
    }

    if (newPassword.length > 0 && confirmPassword.length > 0) {
      if (newPassword !== confirmPassword) {
        setWrongConfirmPassword(true);
        setWrongConfirmPasswordMessage('As senhas não conferem');
      }
    }
  };

  const updatePassword = () => {
    console.log('Atualizando informações da senha do usuário');
    //console.log('Senha atual:', currentPassword);
    //console.log('Nova senha:', newPassword);
    //console.log('Confirmação da nova senha:', confirmPassword);
    UserService.updateMyPassword({
      id: user.id,
      password: currentPassword,
      newpassword: newPassword,
      chkpassword: confirmPassword
    }).then((response) => {
      console.log('Senha da conta atualizadas com sucesso');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowPasswordChange(false);
    })
    .catch((error) => {
      // Analisa se temos um erro de validação
      if (error.violations) {
        console.error('Erro de validação:', error.message);
        console.table(error.violations);
        setViolations(error.violations);
      } else {
        console.error('Ocorreu um erro:', error);
        if (error.message === 'A senha atual está incorreta') {
          setWrongPassword(true);
          setWrongPasswordMessage(error.message);
        }
      }
    });
  };

  const handleLogout = () => {
    doLogout(dispatchToAuth);
    console.log(`Desautenticado. Redirecionando para /app/${tenantName}/login`);
    navigate(`/app/${tenantName}/login`);
  };

  const handleRequestPermission = () => {
    console.log('Solicitar permissão para notificações');
    requestPermission();
  };

  const handleSendAlarmNotification = () => {
    console.log('Envia notificação de teste de alarme');
    playSoundForAlarm();
    sendNotification('Teste de alarme', 'Esta é uma notificação de teste de alarme');
  }

  const handleSendAlertNotification = () => {
    console.log('Envia notificação de teste de alerta');
    playSoundForAlert();
    sendNotification('Teste de alerta', 'Esta é uma notificação de teste de alerta');
  }

  const handleAbout = () => {
    console.log('Versão', version);
    setShowAbout(true);
  }

  useEffect(() => {
    console.log(`Modificado tema da interface para '${theme}'`);
    setCurrentTheme(theme);
  }, [theme]);

  const setChosenTheme = (chosenTheme) => {
    changeTheme(chosenTheme);
  }

  const handleSoundClick = (e) => {
    changeSoundState(!eventsState.playSounds);
  };

  useEffect(() => {
    setWrongNewPassword(false);
    setWrongNewPasswordMessage('');
    setWrongConfirmPassword(false);
    setWrongConfirmPasswordMessage('');
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    setWrongPassword(false);
    setWrongPasswordMessage('');
  }, [currentPassword]);

  const permissionForCommands = (user)
    ? (user.groupID < 6)
        ? true
        : ((user.modules && user.modules.includes('commands')) ? true : false)
    : false;
  ;
  const permissionForDrivers = (user)
    ? (user.groupID < 6)
        ? true
        : ((user.modules && user.modules.includes('drivers')) ? true : false)
    : false;
  ;
  const permissionForGetVehiclesWithoutCommunication = (user)
    ? (user.groupID < 6)
        ? true
        : ((user.belongsToAnAssociation) ? true : false)
    : false;
  ;

  return (
    // Agrupa os elementos que compõe o cabeçalho
    <header className='toolbar'>
      <Dropdown side="left" large="true">
        <Toggle>
          <MenuIcon />
        </Toggle>
        <Content>
          <BlankSpace />
          <Divider />
          <MenuItem onClick={handleGoToMap} disabled={!onMap}>Rastreamento dos veículos</MenuItem>
          { permissionForCommands &&
          <MenuItem onClick={handleCommands}>Envio de comandos</MenuItem>
          }
          <MenuItem onClick={handleReports}>Relatórios</MenuItem>
          <MenuItem onClick={handleTripReport}>Histórico de percurso</MenuItem>
          <MenuItem onClick={handleSpeedReport}>Gráfico de velocidade</MenuItem>
          <MenuItem onClick={handleAmountOfSpeedingReport}>Gráfico quantitativo de excesso de velocidade</MenuItem>
          <Divider />
          <MenuItem onClick={handleFences}>Cercas virtuais</MenuItem>
          <MenuItem onClick={handleLandmarks}>Pontos de referência</MenuItem>
          { permissionForDrivers &&
          <>
          <Divider />
          <MenuItem onClick={handleDrivers}>Motoristas</MenuItem>
          </>
          }
          { permissionForGetVehiclesWithoutCommunication &&
          <>
          <Divider />
          <MenuItem onClick={handleVehiclesWithoutCommunication}>Veículos sem comunicação</MenuItem>
          </>
          }
          {/* Retirado temporariamente
          <Divider />
            { permissionForNotification === 'default' &&
              <MenuItem onClick={handleRequestPermission}>
                Permitir notificações
              </MenuItem>
            }
            { permissionForNotification === 'granted' &&
              <>
              <MenuItem onClick={handleSendAlarmNotification}>
                Teste de notificação de alarme
              </MenuItem>
              <MenuItem onClick={handleSendAlertNotification}>
                Teste de notificação de alerta
              </MenuItem>
              </>
            }
          */}
          <Divider />
          <MenuItem onClick={handleAbout} disabled={!onMap}>Sobre</MenuItem>
        </Content>
      </Dropdown>
      <MapButton mapEnabled={onMap} goToMap={handleGoToMap} />
      <MainMenu>
        <SoundSwitch
          sounds={eventsState.playSounds}
          setPlaySounds={handleSoundClick}
        />
        <Dropdown side="right" feature="alarm">
          <Toggle attribute={(1 > 0)?'danger':''}>
            <BellIcon />
            {(eventsState.alarms.length > 0) &&
              <NotificationIndicatorNumber
                number={eventsState.alarms.length}
                color="orange"
              />
            }
          </Toggle>
          <Content>
            <CardNotification
              alarm={true}
              events={eventsState.alarms}
              getEvent={getEvent}
              updateEvent={() => { return true; }}
              deleteEvent={deleteEvent}
              clearEvents={clearAlarms}
            />
          </Content>
        </Dropdown>
        <Dropdown side="right" feature="notice">
          <Toggle attribute={(1 > 0)?'alert':''}>
            <EventIcon />
            {(eventsState.notices.length > 0) &&
              <NotificationIndicatorNumber
                number={eventsState.notices.length}
                color="blue"
              />
            }
          </Toggle>
          <Content>
            <CardNotification
              events={eventsState.notices}
              getEvent={() => { return null; }}
              updateEvent={() => { return true; }}
              deleteEvent={deleteEvent}
              clearEvents={clearNotices}
            />
          </Content>
        </Dropdown>
        { user && (
        <Dropdown side="right">
          <Toggle>
            <UserIcon />
          </Toggle>
          <Content>
            <DocketItem>
              Olá<br />
              <span className="bold dark">
                {user.name}
              </span>
            </DocketItem>
            <MenuItem onClick={handleMyAccount}>Minha conta</MenuItem>
            <MenuItem onClick={handlePassword}>Mudar senha</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </Content>
        </Dropdown>
        )}
      </MainMenu>
      <ThemeSwitch theme={currentTheme} setChosenTheme={setChosenTheme} />
      <Dialog
        show={showAbout}
        title="Sobre"
        message={(
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <AppLogo style={{ maxWidth:'250px'}} />
            <p>Aplicativo de monitoramento de veículos - Versão <b>{version}</b></p>
            <p>Copyright &copy; 2023 - {new Date().getFullYear()} - MR Tecnologia</p>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                textAlign: 'justify',
                marginTop: '1rem'
              }}
            >
              <p>
                Com o MRFleet, você pode monitorar seu veículo, de forma
                fácil e eficaz. Você pode acompanha-lo em tempo real,
                visualizando a sua localização, velocidade, direção e
                status (ligado, desligado ou em movimento) em um mapa
                interativo.
              </p>
              <p>
                Você também pode consultar o trajeto diário e acessar o
                histórico de posições e eventos de seu veículo, como o de
                ignição, e muito mais.
              </p>
            </div>
          </div>
        )}
        onClose={() => setShowAbout(false)}
      />
      <Dialog
        show={showUserAccount}
        title="Minha conta"
        message={(
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
              <UserIcon width="3em" height="3em" fill="var(--theme-heading-color)" />
              <h3 style={{ fontSize: '1.2em' }}>Informações da conta</h3>
            </div>
            <p style={{ padding: '0 .5em', color: 'var(--theme-symbol-color)'}}>
              Veja e atualize as informações da sua conta de acesso.
            </p>
            <div className="form">
              <TextInput
                name="name"
                value={name}
                onChange={setName}
                label="Nome do usuário"
                wrong={violations && violations.name}
                wrongMessage={violations && violations.name}
              />
              <TextInput
                name="email"
                value={email}
                onChange={setEmail}
                label="E-mail"
                wrong={violations && violations.email}
                wrongMessage={violations && violations.email}
              />
              <div className="form-row">
                <div style={{flex:1}} className="form-group">
                  <label htmlFor="email">Usuário</label>
                  <input
                    className="medium"
                    style={{fontWeight: 500, color: 'var(--theme-highrisk)', backgroundColor:'var(--theme-notification-message-bg)', borderColor:'var(--theme-highrisk)'}}
                    type="text"
                    name="username"
                    value={user?.username}
                    readOnly
                  />
                </div>
                <PhoneNumberInput
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  name="phonenumber"
                  label="Telefone"
                  wrong={violations && violations.phonenumber}
                  wrongMessage={violations && violations.phonenumber}
                />
              </div>
            </div>
          </div>
        )}
        onClose={() => setShowUserAccount(false)}
        onConfirm={updateUserAccount}
      />
      <Dialog
        show={showPasswordChange}
        title="Senha de usuário"
        message={(
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
              <UserIcon width="3em" height="3em" fill="var(--theme-heading-color)" />
              <h3 style={{ fontSize: '1.2em' }}>Alteração de senha</h3>
            </div>
            <p style={{ padding: '0 .5em', color: 'var(--theme-symbol-color)'}}>
              Informe a sua senha atual, a nova senha e confirme ela
              para prosseguir.
            </p>
            <p style={{ padding: '0 .5em', color: 'var(--theme-symbol-color)'}}>
              Use uma senha forte, com pelo menos 8 caracteres, contendo
              letras, números e símbolos.
            </p>
            <div className="form">
              <PasswordInput
                name="currentPassword"
                value={currentPassword}
                onChange={setCurrentPassword}
                label="Senha atual"
                wrong={wrongPassword}
                wrongMessage={wrongPasswordMessage}
              />
              <PasswordInput
                name="newPassword"
                value={newPassword}
                onChange={setNewPassword}
                label="Nova senha"
                onBlur={() => comparePasswords(1)}
                wrong={wrongNewPassword}
                wrongMessage={wrongNewPasswordMessage}
              />
              <PasswordInput
                name="confirmPassword"
                value={confirmPassword}
                onChange={setConfirmPassword}
                label="Confirme a nova senha"
                onBlur={() => comparePasswords(2)}
                wrong={wrongConfirmPassord}
                wrongMessage={wrongConfirmPasswordMessage}
              />
            </div>
          </div>
        )}
        onClose={() => setShowPasswordChange(false)}
        onConfirm={updatePassword}
      />
    </header>
  );
}

const MainMenu = ({ children }) => (
  <nav className="menu">
    { children }
  </nav>
);

const MapButton = ({ mapEnabled, goToMap }) => {
  const handleClick = () => {
    if (!mapEnabled) {
      goToMap();
    }
  };

  return (
    <div className={`in-map ${mapEnabled ? 'active' : ''}`}>
      <button onClick={handleClick}>
        <MapIcon />
      </button>
    </div>
  );
}
