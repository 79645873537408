import React, {
  useEffect,
  useRef
} from 'react';

function OutsideClickHandler({
  onOutsideClick = () => {},
  children,
  ...rest
}) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // A lógica de detecção de clique externo baseia-se na verificação
      // da referência do componente em que ocorreu o clique. Caso ela
      // não encontra-se dentro da árvore de referências do componente
      // que estamos trabalhando, então o clique ocorreu fora do
      // componente
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    // Limpeza na desmontagem
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div
      ref={wrapperRef}
      {...rest}
    >
      {children}
    </div>
  );
}

export default OutsideClickHandler;
