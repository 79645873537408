import React, {
  useEffect,
  useState
} from "react";
import { useController } from "react-hook-form";

function BinaryCheckbox({
  name,
  label,
  control,
  options,
  size,
  defaultValue,
  rules,
  onChange,
  ...rest
}) {
  const {
    field,
  } = useController({
    name,
    control,
    rules
  });

  // Cria um array de checkboxes baseado nas opções
  const checkboxes = options.map((option) => {
    // Calcula o valor do bit
    const bitValue = 1 << option.value;
    // Verifica se o bit está setado
    const isChecked = (field.value & bitValue) !== 0;

    const handleCheckboxChange = () => {
      const newValue = isChecked ? field.value & ~bitValue : field.value | bitValue;
      // Atualiza o valor do campo escondido
      field.onChange(newValue);

      // Chama a função onChange fornecida, se existir
      if (onChange) {
        onChange(newValue);
      }
    };
  
    return (
      <div
        key={option.id}
        style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
      >
        <label
         className="toggle"
        >
          <input
            type="checkbox"
            id={`checkbox-${option.value}`}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className="slider" />
        </label>
        <label htmlFor={`checkbox-${option.value}`}>{option.label}</label>
      </div>
    );
  });

  // Convert the value to hexadecimal
  const intValue = field.value || 0;
  const hexValue = `${intValue}`.toString(16).padStart(2, '0');
  
  return (
    <>
      {checkboxes}
      <input type="hidden" value={hexValue} />
    </>
  );
}

export default BinaryCheckbox;