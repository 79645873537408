import React from 'react';

import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';

import './styles.css';

const Dialog = React.memo(({
  show,
  title,
  message,
  onClose,
  onConfirm,
  cancelLabel = "Cancelar",
  closeLabel = "Fechar",
  confirmLabel = "Modificar"
}) => {
  if (!show) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog-container">
        <div className='close-modal'>
          <button className="btn-circle" onClick={onClose}>
            <ClearIcon width='1rem' fill="var(--theme-modal-color)" />
          </button>
        </div>
        <div className="dialog-header">
          { title }
        </div>
        <div className="dialog-content">
          { message }
        </div>
        <div className="dialog-actions">
          <button className={ onConfirm ? "dialog-cancel":"dialog-close" } onClick={onClose}>
            {onConfirm ? cancelLabel:closeLabel}
          </button>
          { onConfirm && (
            <button className="dialog-confirm" onClick={onConfirm}>
              {confirmLabel}
            </button>
          ) }
        </div>
      </div>
    </div>
  );
});

export default Dialog;
