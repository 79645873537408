import React, {
  useMemo
} from 'react';
import { useFormContext, useController } from 'react-hook-form';
import Select from 'react-select';

/**
 * Componente Autocomplete
 * 
 * Este componente é um campo de seleção autocompletável que utiliza react-select e react-hook-form.
 * 
 * @param {string} name - Nome do campo no formulário.
 * @param {object} options - Opções de validação do react-hook-form.
 * @param {array} data - Dados para popular o dropdown.
 * @param {function} noOptionsMessage - Mensagem a ser exibida quando não houver opções.
 * @param {object} props - Outras propriedades a serem passadas para o componente Select.
 */
const Autocomplete = ({
  name,
  options = {},
  data = [],
  noOptionsMessage=() => 'Nenhum item encontrado.',
  ...props
}) => {
  // Obtém o contexto do formulário e o estado do formulário
  const {
    control,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  // Configura o controlador do campo usando react-hook-form
  const {
    field,
    fieldState: { invalid = false }
  } = useController({
    name,
    control,
    defaultValue: defaultValues?.[name] ?? null,
    rules: { options },
  });

  // Calcula o valor padrão do campo com base nos dados fornecidos
  const defaultValue = useMemo(() => {
    return data.find(option => option.value === field.value) || null;
  }, [field.value, data]);

  // Função para lidar com mudanças no campo de seleção
  const handleOnChange = (selectedOption) => {
    field.onChange(selectedOption ? selectedOption.value : null);
  };

  // Estilos personalizados para o componente Select
  const customStyles = useMemo(() => ({
    control: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      backgroundColor: invalid
        ? "#dc354510"
        : "var(--theme-input-bg)",
      borderRadius: ".375rem",
      borderColor: invalid
        ? "var(--theme-dangerous)"
        : "var(--theme-input-border-color)",
      boxShadow: invalid
        ? (state.isFocused ? "0 0 0 2px var(--theme-dangerous)" : "none")
        : (state.isFocused ? "0 0 0 2px #007bff" : "none"),
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      fontWeight: 400,
      lineHeight: 1.5,
      borderRadius: ".375rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-placeholder-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: state.isFocused
        ? "var(--theme-dropdown-hover-bg)"
        : "transparent",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: ".5rem .3rem",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: "var(--theme-dropdown-bg)",
      borderRadius: ".25rem",
      border: "1px solid var(--theme-input-border-color)",
      zIndex: 1000
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: "var(--theme-heading-color)",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: ".5rem .3rem",
      textTansform: "none",
    })
  }), [invalid]);

  return (
    <Select
      {...props}
      options={data}
      defaultValue={defaultValue}
      defaultInputValue=''
      isClearable
      isSearchable
      selectOnBlur
      onChange={handleOnChange}
      onBlur={field.onBlur}
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isSubmitting}
    />

  );
};

export default Autocomplete;