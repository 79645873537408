import React, {
  useEffect,
  useRef,
  useState
} from "react";

import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';

import './styles.css';

function Modal({
  title,
  message,
  isOpen,
  data,
  confirmAction = "Ok",
  cancelAction = "Cancelar",
  onConfirm = () => {},
  onClose = () => {},
  ...props
}) {
  const modalRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(isOpen);

  const handleCloseModal = () => {
    if (onClose) {
      onClose(data);
    }

    setModalOpen(false);
  };

  const handleCancelModal = () => {
    if (onClose) {
      onClose(data);
    }

    setModalOpen(false);
  }

  const handleConfirmModal = () => {
    if (onConfirm) {
      onConfirm(data);
    }

    setModalOpen(false);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  }

  useEffect(() => {
    console.log('isOpen changed', isOpen);
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalRef.current.showModal();
      } else {
        modalRef.current.close();
      }
    }
  }, [isModalOpen]);

  const centered = props?.centered ? true : false;

  return (
    <dialog
      className={centered ? "modal centered" : "modal"}
      ref={modalRef}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <div className="modal-header">
        <span className="modal-title">
          {title}
        </span>
        <button
          className="modal-close"
          onClick={handleCloseModal}
        >
          <ClearIcon />
        </button>
      </div>
      <div className="modal-body" dangerouslySetInnerHTML={{ __html: message }} />
      <div className="modal-footer">
        <button
          className="modal-button secondary"
          onClick={handleCancelModal}
        >
          {cancelAction}
        </button>
        <button
          className="modal-button primary"
          onClick={handleConfirmModal}
        >
          {confirmAction}
        </button>
      </div>
    </dialog>
  );
}

export default Modal;
