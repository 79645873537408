import {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';

const initialState = {
  identifiers: []
};

function identifierDataReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_IDENTIFIERS':
      const identifiers = action.payload;
      const identifierIdsToRemove = state.identifiers
        .map(identifier => identifier.id)
        .filter(identifierID => !identifiers.some(identifier => identifier.id === identifierID))
      ;
      const identifiersToKeep = state.identifiers
        .filter(identifier => !identifierIdsToRemove.includes(identifier.id))
      ;
      const updatedIdentifiers = [];
      identifiers.forEach((identifier) => {
        const existingIdentifierIndex = identifiersToKeep.findIndex(
          (f) => f.id === identifier.id
        );
        if (existingIdentifierIndex === -1) {
          // O identificador de motorista não existe, então a adicionamos
          updatedIdentifiers.push(identifier);
        } else {
          // O identificador de motorista existe, então a atualizamos
          updatedIdentifiers.push({
            ...identifiersToKeep[existingIdentifierIndex],
            ...identifier,
          });
        }
      });

      return {
        ...state,
        identifiers: updatedIdentifiers
      };
    case 'ADD_IDENTIFIER':
      const newIdentifier = action.payload;
      return {
        ...state,
        identifiers: [...state.identifiers, newIdentifier]
      };
    case 'UPDATE_IDENTIFIER':
      const updatedIdentifier = action.payload;
      const existingIdentifierIndex = state.identifiers.findIndex(
        (f) => f.id === updatedIdentifier.id
      );
      if (existingIdentifierIndex === -1) {
        throw new Error('Tentativa de atualizar identificador de motorista inexistente');
      }

      const changedIdentifiers = [...state.identifiers];
      changedIdentifiers[existingIdentifierIndex] = updatedIdentifier;

      return {
        ...state,
        identifiers: changedIdentifiers
      };
    case 'REMOVE_IDENTIFIER':
      const identifierToRemove = action.payload;
      const identifierIndex = state.identifiers.findIndex(
        (f) => f.id === identifierToRemove
      );
      if (identifierIndex === -1) {
        throw new Error('Tentativa de remover identificador de motorista inexistente');
      }

      const identifiersModified = [...state.identifiers];
      identifiersModified.splice(identifierIndex, 1);

      return {
        ...state,
        identifiers: identifiersModified
      };
    default:
      throw new Error(`Tipo de ação não tratada: ${action.type}`);
  }
}

const IdentifierContext = createContext();

function IdentifierProvider(props) {
  const [identifierState, dispatch] = useReducer(identifierDataReducer, initialState);

  const addIdentifier = useCallback((identifier) => {
    dispatch({ type: 'ADD_IDENTIFIER', payload: identifier });
  }, [dispatch]);
  const updateIdentifier = useCallback((identifier) => {
    dispatch({ type: 'UPDATE_IDENTIFIER', payload: identifier });
  }, [dispatch]);
  const removeIdentifier = useCallback((identifier) => {
    dispatch({ type: 'REMOVE_IDENTIFIER', payload: identifier });
  }, [dispatch]);
  const updateIdentifiers = useCallback((identifiers) => {
    dispatch({ type: 'UPDATE_IDENTIFIERS', payload: identifiers });
  }, [dispatch]);

  const value = {
    identifiers: identifierState.identifiers,
    addIdentifier,
    updateIdentifier,
    removeIdentifier,
    updateIdentifiers
  };

  return <IdentifierContext.Provider value={value} {...props} />;
}

function useIdentifierContext() {
  const context = useContext(IdentifierContext);
  if (!context) {
    throw new Error('useIdentifierContext deve ser utilizado dentro de um IdentifierProvider');
  }
  
  return context;
}

export { IdentifierProvider, useIdentifierContext };