import { jwtDecode } from "jwt-decode";
import { defaults } from '../settings/config';

const Mutex = require('async-mutex').Mutex;
const mutex = new Mutex();
const debug = false;
const USER_KEY = "@user";

/**
 * Força o uso de um token inválido para fins de teste.
 */
const forceBadTokenForTest = false;
const badToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDk4NTYxNzYsImlzcyI6ImFwaS5tcmZsZWV0LmNvbS5iciIsIm5iZiI6MTcwOTg1NjE3NiwiZXhwIjoxNzA5ODYzMzc2LCJjb250cmFjdG9ySUQiOjEsInV1aWQiOiI4MjdkODMzOC02ZDBjLWJmODUtM2VhMi1mZjFmNjZmZDcwZDMiLCJ1c2VySUQiOjIsImdyb3VwSUQiOjIsImVudGl0eUlEIjoxLCJiZWxvbmdUb0FuQXNzb2NpYXRpb24iOmZhbHNlLCJ1c2VybmFtZSI6ImVtZXJzb24ifQ.Sgv8N86dH6JOFm-0z9qWmtDVuDjXGrXjAFy7gGrh60A";
let badCountTest = 0;

/**
 * Obtém o token de acesso.
 * 
 * @returns string|null
 */
const getAccessToken = async (serverCallUrl) => {
  console.log('Obtendo o token de acesso', serverCallUrl);
  let accessToken = undefined;
  const release = await mutex.acquire();
  let user = undefined;
  let decodeToken;

  try {
    const userString = localStorage.getItem(USER_KEY);

    if (userString === null) {
      // O usuário está desautenticado
      return Promise.reject({
        message: 'Não foi possível obter o token de acesso.',
        name: 'Unauthorized'
      });
    }
    user = JSON.parse(userString);

    if (forceBadTokenForTest) {
      badCountTest++;
      console.log('Tentativa', badCountTest, 'de 3');
      if (badCountTest > 3) {
        console.log('Forçando um token inválido');
        accessToken = badToken;
        badCountTest = 0;
      } else {
        accessToken = user?.accessToken;
      }
    } else {
      accessToken = user?.accessToken;
    }
    if (debug) {
      console.log('Token de acesso obtido', accessToken);
    }

    if (accessToken) {
      if (debug) {
        console.log(
          'Verificando se o token de acesso expirou na requisição para',
          serverCallUrl
        );
      }

      // O JWT decodificado contém uma extrutura semelhante à:
      // {
      //   "belongToAnAssociation": false,
      //   "contractorID": 1,
      //   "entityID": 1,
      //   "exp": 1702059251,
      //   "groupID": 2,
      //   "iat": 1702052051,
      //   "iss": "api.mrfleet.com.br",
      //   "nbf": 1702052051,
      //   "userID": 2,
      //   "username": "emerson",
      //   "uuid": "827d8338-6d0c-bf85-3ea2-ff1f66fd70d3"
      // }
      // Como não precisamos de todas as informações, obtemos apenas a
      // data de expiração do token para analisar
      try {
        decodeToken = jwtDecode(accessToken);
      } catch (error) {
        if (error instanceof jwtDecode.InvalidTokenError) {
          // O token fornecido é inválido
          if (debug) {
            console.error(
              'O token fornecido é inválido. Erro: ' + error.getMessage()
            );
          }
        } else {
          // Trate outros erros aqui
          console.error(
            'O token fornecido é inválido. Erro: ' + error.getMessage()
          );
        }
        accessToken = undefined;

        return Promise.reject({
          name: 'Unauthorized',
          message: 'O token fornecido é inválido. Erro: '
           + error.getMessage()
        });
      }
  
      const { exp } = decodeToken;
      const expirationTime = new Date(exp * 1000);
      const now = new Date();
      if (debug) {
        console.log(
          'Agora é', now, 'e o Token expira em', expirationTime
        );
      }

      // Adiciona um minuto da hora atual para termos uma tolerância e
      // evitarmos que o token expire durante uma requisição
      now.setMinutes(now.getMinutes() + 1);
      const isExpired = expirationTime < now;
  
      if (isExpired) {
        // Renovamos o token de acesso e retornamos o novo token
        console.info('Token expirado (ou próximo de expirar), renovando-o');
        const refreshToken = user?.refreshToken;
        
        if (refreshToken) {
          if (debug) {
            console.log('Conseguimos o token de refresh');
          }
          let attempts = 0;
          const maxAttempts = 3;
          let success = false;

          while (attempts < maxAttempts && !success) {
            try {
              attempts++;
              if (debug) {
                console.log('Tentativa', attempts, 'de', maxAttempts);
              }
              const newToken = await renewToken(accessToken, refreshToken);

              // Armazenamos novamente o novo token
              if (debug) {
                console.log(
                  'Armazenando o novo token de acesso', newToken
                );
              }
              user.accessToken = newToken;
              localStorage.setItem(USER_KEY, JSON.stringify(user));

              // Atribuímos o novo token
              accessToken = newToken;
              success = true;
            } catch (error) {
              // Lidamos com erros da requisição
              const { message, name } = error;
              console.log('Ocorreu um erro ao renovar token', name);

              switch (name) {
                case 'NetworkError':
                  // Sem conexão com a internet
                  return Promise.reject({
                    message: 'Sem conexão com a internet.',
                    name: 'NetworkError'
                  });
                case 'Not Found':
                  case 'Not Authenticated':
                    case 'Unauthorized':
                  // Usuário ou token não encontrado ou não autenticado
                  // por alguma restrição na conta do usuário ou não
                  // autorizado

                  // Nestas condições, removemos o token de acesso
                  console.error(name, 'Erro:', message);
                  accessToken = undefined;

                  return Promise.reject({
                    message: message,
                    name: 'Unauthorized'
                  });
                default:
                  if (attempts >= maxAttempts) {
                    console.error(
                      'Falha ao renovar o token de acesso e excedido o '
                      + 'número máximo de tentativas'
                    );

                    return Promise.reject({
                      message: 'Não foi possível renovar o token de acesso.',
                      name: 'RequestError'
                    });
                  } else {
                    console.error(
                      'Falha ao renovar o token de acesso, tentando '
                      + 'novamente'
                    );
                  }
              }
            }
          }
        } else {
          console.error('Não autenticado');
          accessToken = undefined;

          return Promise.reject({
            message: 'Não foi possível renovar o token de acesso.',
            name: 'Unauthorized'
          });
        }
      } else {
        if (debug) {
          console.log('Token de acesso válido');
        }
      }
    }
  }
  catch (error) {
    console.error('Falha ao obter o token de acesso', error);
  }
  finally {
    release();
  }

  return accessToken;
};

/**
 * Define o token de acesso.
 * 
 * @param {string} token 
 *   O token de acesso
 */
const setAccessToken = (newToken) => {
  const userString = localStorage.getItem(USER_KEY);
  let user = JSON.parse(userString);
  user.accessToken = newToken;

  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * Obtém o token de renovação.
 * 
 * @returns string|null
 *   O token de renovação
 */
const getRefreshToken = () => {
  const userString = localStorage.getItem(USER_KEY);
  const user = JSON.parse(userString);

  return user?.refreshToken;
};

/**
 * Verifica se existe um token de acesso definido para um usuário do
 * tenant informado.
 * 
 * @returns bool
 *   Indica se existe um token de acesso definido
 */
const hasToken = (tenantName) => {
  const userString = localStorage.getItem(USER_KEY);

  if (userString !== null) {
    const user = JSON.parse(userString);

    if (user?.tenant === tenantName) {
      return user?.accessToken !== undefined;
    }
  }

  return false;
};

/**
 * Obtém os dados do usuário.
 * 
 * @returns object|null
 *   Os dados do usuário
 */
const getUser = () => {
  const userString = localStorage.getItem(USER_KEY);

  return JSON.parse(userString);
};

/**
 * Define os dados do usuário.
 * 
 * @param {object} user 
 *   Os dados do usuário
 */
const setUser = (user) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * Remove os dados do usuário.
 */
const removeUser = () => {
  localStorage.removeItem(USER_KEY);
};

/**
 * Renova o token de acesso.
 * 
 * @param {*} accessToken 
 *   O token de acesso atual.
 * @param {*} refreshToken 
 *   O token de atualização.
 * @returns 
 *   O novo token de acesso.
 */
const renewToken = async (
  accessToken,
  refreshToken
) => {
  // Usa o fetch para obter o novo token
  const baseURL = defaults.api.baseURL;
  const url = `${baseURL}/auth/renew`;

  // Verifica se o dispositivo tem conexão com a internet
  const isConnected = navigator.onLine;
  if (debug) {
    console.log('Is connected?', isConnected);
  }
  if (isConnected === false) {
    console.error('Sem conexão com a internet');

    return Promise.reject({
      message: 'Sem conexão com a internet',
      name: 'NetworkError'
    });
  }

  if (debug) {
    console.log('Solitando um novo token de acesso em', url);
  }
  const result = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'token': refreshToken,
      'credential': accessToken
    })
  });

  if (result.ok) {
    const response = await result.json();
    if (response.status === 'Authenticated') {
      const { token } = response.data;
      console.info(
        'Novo token emitido em', token.emission, 
        'Válido por', token.lifetime, 'minutos'
      );

      return Promise.resolve(
        token.credential
      );
    }

    return Promise.reject({
      message: response.message,
      name: 'Authentication',
      error: response.error
    });
  }

  if (result.status === 401) {
    const response = await result.json();
    if (debug) {
      console.error(
        'Não autorizado',
        response.message
      );
    }

    return Promise.reject({
      message: response.message,
      name: 'Unauthorized'
    });
  }

  return Promise.reject({
    message: 'Falha ao renovar o token de acesso',
    name: result.statusText
  });
};

const TokenService = {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  hasToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
