import React from "react";

import { AuthProvider } from "./features/authorization/AuthContext";
import { APPContext } from './features/APPContext';
import Router from './routes/Router';
import Theme from "./components/ThemeProvider";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './styles/Global.scss';

const queryClient = new QueryClient();

function App({ version }) {
  const AppContextValues = {
    version,
  };

  return (
    <Theme>
      <APPContext.Provider value={AppContextValues}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryClientProvider>
      </APPContext.Provider>
    </Theme>
  );
}

export default App;
