import React, { useMemo } from 'react';
import {
  CircleMarker,
  Marker,
  Popup
} from 'react-leaflet'
import shortid from 'shortid';

import PointPopup from './Popups/PointPopup';
import StopPopup from './Popups/StopPopup';
import PolylineDecorator from './PolylineDecorator';

import { pinStart } from './Pins/pinStart';
import { pinStop } from './Pins/pinStop';
import { pinEnd } from './Pins/pinEnd';

function RenderRoute({ route, showEnd, currentLocation }) {
  const color = '#0079d9';

  const distanceTo = (lat1, lng1, lat2, lng2) => {
    const rad1 = Math.PI * lat1/180;
    const rad2 = Math.PI * lat2/180;
    const theta = lng1-lng2;
    const radTheta = Math.PI * theta/180;
    let dist = Math.sin(rad1) * Math.sin(rad2) + Math.cos(rad1) * Math.cos(rad2) * Math.cos(radTheta);

    dist = Math.acos(dist) * 180/Math.PI * 60 * 1.1515 * 1609.344;

    return Number(dist.toFixed(2));
  };

  const routeLayer = useMemo(() => {
    const coords = [];
    const routeLayer = [];

    if (!route || route.length === 0) {
      return routeLayer;
    }

    for (let i = 0; i < route.length; i++) {
      coords.push(route[i].position);

      if (i === 0) {
        continue;
      }
      
      if (route[i].type === 'point') {
        routeLayer.push(
          <CircleMarker
            key={`circle_${i}`}
            center={route[i].position}
            radius={5}
            pathOptions={{
              color:"#004bad",
              fill: "true",
              fillColor:"#004bad",
              fillOpacity: 1,
              smoothFactor: 5
            }}
          >
            <Popup
              key={`popup_${i}`}
            >
              <PointPopup
                key={`point_${i}`}
                eventDate={route[i].eventDate}
                ignition={route[i].ignition}
                speed={route[i].speed}
              />
            </Popup>
          </CircleMarker>
        );
      } else {
        if (showEnd === false && i === route.length - 1) {
          continue;
        }

        routeLayer.push(
          <Marker
            key={`marker_${i}`}
            position={route[i].position}
            icon={route[i].type === 'stop'?pinStop:pinEnd}
          >
            <Popup
              key={`popup_${i}`}
            >
              <StopPopup
                key={`point_${i}`}
                type={route[i].type}
                ignition={route[i].ignition}
                eventDate={route[i].eventDate}
                speed={route[i].speed}
                info={route[i].info}
              />
            </Popup>
          </Marker>
        );
      }
    }

    routeLayer.push(
      <PolylineDecorator
        key={shortid.generate()}
        color={color}
        positions={coords}
      />
    );

    // Colocamos o ponto inicial da rota
    const startPosition = route[0].position;
    if (currentLocation && distanceTo(currentLocation[0], currentLocation[1], route[0].position[0], route[0].position[1]) < 10) {
      routeLayer.push(
        <CircleMarker
          key={`circle_0`}
          center={route[0].position}
          radius={5}
          pathOptions={{
            color:"#004bad",
            fill: "true",
            fillColor:"#004bad",
            fillOpacity: 1,
            smoothFactor: 5
          }}
        >
          <Popup
            key={`popup_0`}
          >
            <StopPopup
              key="point_0"
              type={route[0].type}
              ignition={route[0].ignition}
              eventDate={route[0].eventDate}
              speed={route[0].speed}
              info={route[0].info}
            />
          </Popup>
        </CircleMarker>
      );
    } else {
      routeLayer.push(
        <Marker
          key="marker_0"
          position={startPosition}
          icon={pinStart}
        >
          <Popup>
            <StopPopup
              key="point_0"
              type={route[0].type}
              ignition={route[0].ignition}
              eventDate={route[0].eventDate}
              speed={route[0].speed}
              info={route[0].info}
            />
          </Popup>
        </Marker>
      );
    }

    return routeLayer;
  }, [route, currentLocation]);

  return (
    <>
      {routeLayer}
    </>
  );
}

export default RenderRoute;
