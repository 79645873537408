export function addressFormatter(result) {
  if (!result) {
    return '';
  }
  
  const stateAbbreviation = {
    'Acre': 'AC',
    'Alagoas': 'AL',
    'Amapá': 'AP',
    'Amazonas': 'AM',
    'Bahia': 'BA',
    'Ceará': 'CE',
    'Distrito Federal': 'DF',
    'Espírito Santo': 'ES',
    'Goiás': 'GO',
    'Maranhão': 'MA',
    'Mato Grosso': 'MT',
    'Mato Grosso do Sul': 'MS',
    'Minas Gerais': 'MG',
    'Pará': 'PA',
    'Paraíba': 'PB',
    'Paraná': 'PR',
    'Pernambuco': 'PE',
    'Piauí': 'PI',
    'Rio de Janeiro': 'RJ',
    'Rio Grande do Norte': 'RN',
    'Rio Grande do Sul': 'RS',
    'Rondônia': 'RO',
    'Roraima': 'RR',
    'Santa Catarina': 'SC',
    'São Paulo': 'SP',
    'Sergipe': 'SE',
    'Tocantins': 'TO'
  };

  const withComma = (value) => {
    return value ? `${value}, ` : '';
  };

  // Extrai os campos relevantes do objeto result
  const { raw } = result;
  const { address } = raw;

  // Extrai informações específicas do endereço
  const waterway = address?.waterway || "";
  const tunnel = address?.tunnel || "";
  const bridge = address?.bridge || "";
  const tourism = address?.tourism || "";
  const shop = address?.shop || "";
  const mountain_pass = address?.mountain_pass || "";
  const office = address?.office || "";
  const leisure = address?.leisure || "";
  const craft = address?.craft || "";
  const club = address?.club || "";
  const aeroway = address?.aeroway || "";
  const amenity = address?.amenity || "";
  const boundary = address?.boundary || "";
  const aerialway = address?.aerialway || "";
  const man_made = address?.man_made || "";
  const railway = address?.railway || "";
  const place = address?.place || "";
  const building = address?.building || "";
  const landuse = address?.landuse || "";
  const natural = address?.natural || "";
  const military = address?.military || "";
  const historic = address?.historic || "";
  const emergency = address?.emergency || "";
  const highway = address?.highway || "";
  const road = address?.road || "";
  const house_number = address?.house_number || "";
  const house_name = address?.house_name || "";
  const retail = address?.retail || "";
  const commercial = address?.commercial || "";
  const industrial = address?.industrial || "";
  const farmyard = address?.farmyard || "";
  const farm = address?.farm || "";
  const residential = address?.residential || "";
  const city_block = address?.city_block || "";
  const suburb = address?.suburb || "";
  const allotments = address?.allotments || "";
  //const neighbourhood = address?.neighbourhood || "";
  const isolated_dwelling = address?.isolated_dwelling || "";
  const croft = address?.croft || "";
  const hamlet = address?.hamlet || "";
  //const subdivision = address?.subdivision || "";
  const quarter = address?.quarter || "";
  const borough = address?.borough || "";
  const district = address?.district || "";
  const village = address?.village || "";
  const town = address?.town || "";
  //const municipality = address?.municipality || "";
  //const city_district = address?.city_district || "";
  const city = address?.city || "";
  //const county = address?.county || "";
  //const state_district = address?.state_district || "";
  const state = address?.state || "";
  //const ISO3166 = address?["ISO3166-2-lvl4"] || "";
  const region = address?.region || "";
  const postcode = address?.postcode || "";
  //const country_code = address?.country_code || "";
  //const country = address?.country || "";

  // Monta o endereço formatado
  const formattedAddress = ''
    + withComma(waterway)
    + withComma(tunnel)
    + withComma(bridge)
    + withComma(tourism)
    + withComma(shop)
    + withComma(mountain_pass)
    + withComma(office)
    + withComma(leisure)
    + withComma(craft)
    + withComma(club)
    + withComma(aeroway)
    + withComma(amenity)
    + withComma(boundary)
    + withComma(aerialway)
    + withComma(man_made)
    + withComma(railway)
    + withComma(place)
    + withComma(building)
    + withComma(landuse)
    + withComma(natural)
    + withComma(military)
    + withComma(historic)
    + withComma(emergency)
    + withComma(highway)
    + (highway !== "" ? "" : withComma(road))
    + withComma(house_name)
    + withComma(house_number)
    + withComma(retail)
    + withComma(commercial)
    + withComma(industrial)
    + withComma(farmyard)
    + withComma(farm)
    + withComma(residential)
    + withComma(city_block)
    + withComma(suburb)
    + withComma(allotments)
    //+ withComma(neighbourhood)
    + withComma(isolated_dwelling)
    + withComma(croft)
    + withComma(hamlet)
    //+ withComma(subdivision)
    + withComma(quarter)
    + withComma(borough)
    + withComma(district)
    + withComma(village)
    + withComma(town)
    //+ withComma(municipality)
    //+ withComma(city_district)
    + withComma(city)
    //+ withComma(county)
    //+ withComma(state_district)
    + withComma(state !== "" ? stateAbbreviation[state] : "")
    //+ withComma(ISO3166)
    //+ withComma(region)
    + postcode
    //+ withComma(country_code)
    //+ withComma(country)
  ;

  return formattedAddress.trim(',');
}
