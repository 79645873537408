const VehicleItem = ({
  plate,
  stored,
  onDelete
}) => {
  return (
    <div
      style={{
        ...styles.item,
        color: stored ? 'var(--theme-list-group-active-color)' : 'var(--theme-table-color)',
        backgroundColor: stored ? 'var(--theme-list-group-active-bg)' : 'var(--theme-disabled)',
      }}
    >
      <div style={styles.labelContainer}>
        <span style={styles.label}>{plate}</span>
        <span style={styles.status}>{stored ? 'Habilitado' : 'Não transmitido'}</span>
      </div>
      <button style={styles.button} onClick={onDelete}>
        <svg
          width=".75rem"
          height=".75rem"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M3 6h18"/>
          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
          <line x1="10" x2="10" y1="11" y2="17"/>
          <line x1="14" x2="14" y1="11" y2="17"/>
        </svg>
      </button>
    </div>
  );
};

const styles = {
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.9rem 0.2rem .9rem 0.3rem',
    borderBottom: '1px solid ccc',
    borderRadius: '.475rem',
    border: '1px solid var(--theme-table-border-color)',
    width: '7.44rem',
    height: '1.1rem',
    fontWeight: 'bolder',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: '.8rem',
  },
  status: {
    fontSize: '.6rem',
    marginTop: '0rem',
  },
  button: {
    display: 'flex',
    padding: '.3rem .3rem',
    border: "none",
    borderRadius: ".475rem",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#db2828"
  }
}

export default VehicleItem;