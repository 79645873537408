import React from 'react';

function GSMSignalStrength({ width, value }) {
  const GSMIcon = ({ width, colors }) => (
    <svg
      width={width}
      height={width}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke="none" strokeLinecap="round" strokeLinejoin="round">
        <rect fill={colors[5]} stroke={colors[5]} height="46" rx="0" ry="3.084103" width="6.168205" x="40.915897" y="1"/>
        <rect fill={colors[4]} stroke={colors[4]} height="37" rx="0" ry="3.084103" width="6.168205" x="30.915897" y="10"/>
        <rect fill={colors[3]} stroke={colors[3]} height="27" rx="0" ry="3.084103" width="6.168205" x="20.915897" y="20"/>
        <rect fill={colors[2]} stroke={colors[2]} height="17" rx="0" ry="3.084103" width="6.168205" x="10.915897" y="30"/>
        <rect fill={colors[1]} stroke={colors[1]} height="7" rx="0" ry="3.084103" width="6.168205" x=".915897" y="40"/>
      </g>
      <path d="m2.6668422-1.0620412c-.2711248.27112469-.4377986.64654162-.4377986 1.0620412 0 .83099917.6688406 1.4998398 1.4998398 1.4998398h5.9993591v5.9993591c0 .8309991.6688405 1.4998397 1.4998395 1.4998397s1.49984-.6688406 1.49984-1.4998397v-5.9993591h5.999359c.830999 0 1.49984-.66884063 1.49984-1.4998398s-.668841-1.4998398-1.49984-1.4998398h-5.999359v-5.9993591c0-.8309991-.668841-1.4998397-1.49984-1.4998397s-1.4998395.6688406-1.4998395 1.4998397v5.9993591h-5.9993591c-.4154996 0-.7909165.1666738-1.0620412.4377986z" fill={colors[0]} transform="matrix(.70710678 .70710678 -.70710678 .70710678 0 0)"/>
    </svg>
  );
  
  const barColors = [
    [ 'var(--theme-danger)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)' ],
    [ 'none', 'var(--theme-gray-1100)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)' ],
    [ 'none', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-400)', 'var(--theme-gray-400)', 'var(--theme-gray-400)' ],
    [ 'none', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-400)', 'var(--theme-gray-400)' ],
    [ 'none', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-400)' ],
    [ 'none', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)', 'var(--theme-gray-1100)' ],
  ];

  const getActiveBars = (value) => {
    let activeBars = 0;
    switch (true) {
      case (value === 0):
        activeBars = 0;

        break;
      case (value <= 20):
        activeBars = 1;

        break;
      case (value <= 40):
        activeBars = 2;

        break;
      case (value <= 60):
        activeBars = 3;

        break;
      case (value <= 80):
        activeBars = 4;

        break;
      default:
        activeBars = 5;

        break;
    }

    return activeBars;
  };

  return (
    <GSMIcon width={width} colors={barColors[getActiveBars(value)]} />
  );
}

export default GSMSignalStrength;