function FieldSet({ direction = 'row', children, ...props }) {
  const classes = 'form-fieldset ' + direction;

  return (
    <fieldset className={classes} {...props}>
      {children}
    </fieldset>
  );
}

export default FieldSet;