import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  FeatureGroup,
  useMapEvents
} from 'react-leaflet';
import EditableCircle from './EditableCircle';

function FencesLayer({
  fences,
  onNewFence,
  onUpdatedFence,
  addingMode,
  setAddingMode,
  editable = false,
  onEditFence,
  underEditing = false
}) {
  const [hiddenLayer, setHiddenLayer] = useState(false);

  const map = useMapEvents({
    click(event) {
      if (addingMode) {
        const latLng = event.latlng;

        // Chamamos o callback para informar que uma nova cerca foi criada
        // do tipo circular, centro na posição do clique e raio de 200m
        console.log('Creating new fence', latLng);
        onNewFence('CIRCLE', [latLng.lat, latLng.lng], 200);

        // Desativamos o modo de adição
        const classList = map._container.classList;
        classList.remove('leaflet-interactive');
        classList.remove('leaflet-crosshair');
        setAddingMode(false);
      }
    },
    zoomend(event) {
      const zoom = map.getZoom();

      setHiddenLayer((zoom < 15)? true : false);
    }
  });

  useEffect(() => {
    const zoom = map.getZoom();

    setHiddenLayer((zoom < 15)? true : false);
  }, [map]);

  const handleFenceCenterChange = useCallback((id, center) => {
    console.log('handleFenceCenterChange', id, center);

    if (onUpdatedFence) {
      // Obtemos a cerca que foi alterada
      const fence = fences.find(fence => fence.id === id);

      // Chamamos o callback para atualizar a cerca
      onUpdatedFence({
        ...fence,
        latitude: center[0],
        longitude: center[1]
      });
    }
  }, [fences, onUpdatedFence]);
  
  const handleFenceRadiusChange = useCallback((id, radius) => {
    console.log('handleFenceRadiusChange', id, radius);

    if (onUpdatedFence) {
      // Obtemos a cerca que foi alterada
      const fence = fences.find(fence => fence.id === id);

      // Chamamos o callback para atualizar a cerca
      onUpdatedFence({ ...fence, radius: radius});
    }
  }, [fences, onUpdatedFence]);

  const RenderedFences = useMemo(() => {
    // Determina a cor da cerca em função de aspectos da mesma
    const fenceColor = (disabled, showInMap, temporary) => {
      if (disabled) return '#808080';
      if (showInMap === false) return '#f5711d';
      if (temporary === true) return '#f5711d';
      
      return '#3388ff';
    };

    return fences && fences.map((fence, index) => {
      //console.log('Renderizando cerca', fence.name, 'do tipo', fence.format);
      if (!underEditing && !editable) {
        if (fence.disabled || !fence.showinmap) {
          return null;
        }
      }
      if ((underEditing || editable) && fence.temporary) {
        // Não renderiza cercas temporárias em modo de edição
        return null;
      }

      switch (fence.format) {
        case 'CIRCLE':
          return (
            <EditableCircle
              key={`${fence.id}_${index}`}
              id={fence.id}
              name={fence.name}
              note={fence.note}
              center={[fence.latitude, fence.longitude]}
              radius={fence.radius}
              color={fenceColor(fence.disabled, fence.showinmap, fence.temporary)}
              onCenterChange={handleFenceCenterChange}
              onRadiusChange={handleFenceRadiusChange}
              editable={editable}
              hidden={hiddenLayer}
              underEditing={underEditing}
              onEditFence={onEditFence}
            />);
        default:
          return null;
      }
    });
  }, [fences, hiddenLayer]);

  const attribution = useMemo(() => {
    return (editable === undefined || editable === false)
      ? 'ignoreOnFit'
      : null
    ;
  }, [editable]);

  return (
    <FeatureGroup attribution={attribution}>
      {RenderedFences}
    </FeatureGroup>
  );
}

export default FencesLayer;