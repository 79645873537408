import { useFormContext } from "react-hook-form";

const TextArea = ({
  name,
  ...props
}) => {
  const {
    register,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <textarea
      id={name}
      {...register(name)}
      disabled={isSubmitting}
      {...props}
    />
  );
};

export default TextArea;