import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import classNames from 'classnames';

// Os tipos de estados permitidos
import {
  REJECTED
} from "../../features/authorization/Types";

import { isAuthenticated, useAuthState, useAuthDispatch, doLogin } from "../../features/authorization/AuthContext";
import Container from '../../components/Container';
import { useThemeContext } from "../../components/ThemeProvider";
import { useTenantContext } from "../../features/TenantContext";

import {ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import {ReactComponent as EyeSlashIcon } from '../../assets/icons/eye-slash.svg';

import '../../styles/Login.scss';
import '../../styles/Forms.scss';
import '../../styles/PrimaryButton.scss';

function LoginPage(props) {
  const { theme } = useThemeContext();
  const { tenant } = useTenantContext();
  const getTenantLogo = () => {
    if (theme === 'dark') {
      return tenant.logo.inverted;
    }

    return tenant.logo.normal;
  }
  const { status, error } = useAuthState();
  const dispatchToAuth = useAuthDispatch();

  const [firstTimeHere, setFirstTimeHere] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const inputRef = React.useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [tenant]);

  useEffect(() => {
    const alreadyVisited = localStorage.getItem("AlreadyVisited");
    if (alreadyVisited) {
      setFirstTimeHere(false);
    }

    // Salva que o usuário já visitou no localStorage
    localStorage.setItem("AlreadyVisited", true);
  }, []);

  if (isAuthenticated(tenant.tenantname)) {
    return <Navigate to={`/app/${tenant.tenantname}/`} replace={true} />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    doLogin(dispatchToAuth, user, pass, tenant);
  };

  const handleUserChange = (e) => {
    setUser(e.target.value)
  };

  const handlePassChange = (e) => {
    setPass(e.target.value);
  };

  return (
    <Container>
      <div className="backdraw">
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <FormContent>
              <Title>
                { firstTimeHere
                  ? (<>Olá, seja &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; bem-vindo!</>)
                  : (<>Bem-vindo novamente!</>)
                }
              </Title>
              <SmallText>Autentique-se para continuar!</SmallText>
              <div className="flex-center">
                <img src={getTenantLogo()} alt={"logo"} style={{ width: '200px', height: 'auto', marginBottom: '1em' }} />
              </div>
              <Field>
                <Label>Usuário</Label>
                <input
                  ref={inputRef}
                  type="text"
                  className="field-input"
                  name="username"
                  id="username"
                  placeholder="Informe seu usuário..."
                  value={user}
                  onChange={handleUserChange}
                  required
                  autoComplete="off"
                />
              </Field>
              <Field>
                <Label>Senha</Label>
                <InputWithIcon>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="field-input"
                    name="password"
                    id="password"
                    placeholder="Informe sua senha"
                    value={pass}
                    onChange={handlePassChange}
                    required
                    autoComplete="off"
                  />
                  <TogglePasswordIcon
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                </InputWithIcon>
              </Field>
              <div className="d-grid gap-2 mt-3">
                <SubmitButton>
                  Enviar
                </SubmitButton>
              </div>
              {status === REJECTED && (
                <p style={{ color: "var(--theme-error)", marginTop: "10px" }}>
                  {error}
                </p>
              )}
              <div className="auth-row">
                <div className="auth-col-auto">
                  <p className="medium-link paddingTop">
                    <Link
                      to={`/app/${tenant.tenantname}/forgotpassword`}
                    >
                      Esqueceu a senha?
                    </Link>
                  </p>
                </div>
              </div>
            </FormContent>
          </Form>
        </FormContainer>
      </div>
    </Container>
  );
}

function FormContainer({ children }) {
  return ( 
    <div className="auth-form-container">
      {children}
    </div>
  );
}

function Form({ children, onSubmit }) {
  return ( 
    <form className="auth-form" onSubmit={onSubmit}>
      {children}
    </form>
  );
}

function FormContent({ children }) {
  return ( 
    <div className="auth-form-content">
      {children}
    </div>
  );
}

function Title({ children }) {
  return ( 
    <h3 className="auth-form-title">
      {children}
    </h3>
  );
}
function SmallText({ children }) {
  return ( 
    <p className="auth-form-smalltext">
      {children}
    </p>
  );
}

function Field({ children }) {
  return ( 
    <div className="wrap-input">
      {children}
    </div>
  );
}

function InputWithIcon({ children }) {
  return ( 
    <div className="labeled-input">
      {children}
    </div>
  );
}

function Label({ children }) {
  return ( 
    <label className="label-input">
      {children}
    </label>
  );
}

function TogglePasswordIcon({
  showPassword,
  togglePasswordVisibility
}) {
  return (
    <span
      className="input-icon"
      onClick={togglePasswordVisibility}
    >
      {showPassword ? (
        <EyeSlashIcon width={'1em'} height={'1em'} fill='currentColor' />
      ) : (
        <EyeIcon width={'1em'} height={'1em'} fill='currentColor' />
      )}
    </span>
  );
}

function SubmitButton({ children, color="primary" }) {
  const buttonClass = classNames('primary',
    color
  );

  return (
    <button
      type="submit"
      className={buttonClass}
    >
      {children}
    </button>
  );
}


export default LoginPage;
