import React from "react";

import "../styles/LoadingOverlay.scss";

function LoadingOverlay(props) {
  return props.isLoading ? (
    <div className="overlay">
      <div className="loading-message">{props.message || "Carregando..."}</div>
      <div className="spinner"></div>
    </div>
  ) : null;
}

export default LoadingOverlay;
