import React from 'react';

import Copyright from './Copyright';
import Privacity from './Privacity';

import {ReactComponent as AppLogo} from '../assets/logos/app.svg';

import '../styles/Footer.scss';

export default function Footer({ appName }) {

  return (
    // Agrupa informações de autoria, direitos autorais, contato, 
    // mapa do site, links e documento relacionados
    <footer>
      <div className="footer_logo">
        <AppLogo />
      </div>
      <Copyright
          appName={ appName }
          startYear = '2018'
          companyName= 'MR Tecnologia'
        />
      <Privacity />
    </footer>
  );
}