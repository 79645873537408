import React, {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

import ColorComboBox from "../ColorComboBox";

function ColorSelector({
  name,
  label,
  colors,
  options = {},
  ...props
}) {
  const {
    control,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();
  
  const {
    field
  } = useController({
    name,
    control,
    defaultValue: defaultValues ? (defaultValues[name] ? defaultValues[name] : null) : null,
    rules: { options },
  });

  return (
    <ColorComboBox
      ref={field.ref}
      name={field.name}
      color={field.value}
      colors={colors}
      onChange={field.onChange}
      disabled={isSubmitting}
      {...props}
    />
  );
}

export default ColorSelector;