import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";

function SizeField({
  name,
  control,
  rules,
  defaultValue,
  ...rest
}) {
  const {
    field,
  } = useController({
    name,
    control,
    rules
  });

  const [value, setValue] = useState(() => {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (field.value === undefined) {
      return '';
    }

    return field.value;
  });

  useEffect(() => {
    console.log('field.value de', field.name, 'mudou para', field.value);
    if (field.value !== undefined && field.value !== null) {
      setValue(field.value);
    } else {
      setValue('');
    }
  }, [field.name, field.value]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  console.log('Field', name, 'rendered');

  return (
    <>
      <input
        type="hidden"
        ref={field.ref}
        name={name}
        value={value}
        {...rest}
      />
    </>
  );
}

export default SizeField;