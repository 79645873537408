import React from 'react';

const TextInput = ({
  label,
  name,
  value,
  onChange,
  maxLength = 50,
  onBlur = () => {},
  wrong = "",
  wrongMessage = ""
}) => {
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div style={{ flex: 1 }} className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        className={"medium" + (wrong ? " error" : "")}
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
        maxLength={maxLength}
        onBlur={onBlur}
      />
      {wrong && <span className="error">{wrongMessage}</span>}
    </div>
  );
};

export default TextInput;
