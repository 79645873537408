import React from 'react';

import { getDeviceInfo } from '../hooks/deviceInfo';

/**
 * Gera a formatação de uma mensagem de notificação.
 * 
 * @param {Object} event
 *   O evento
 * @param {Object} toastProps
 *   As propriedades do toast
 * 
 * @returns 
 *   O componente da mensagem
 */
function ToastMessage({ event, toastProps }) {
  const {
    label,
    identification,
    denomination
  } = getDeviceInfo(event);
  const date = new Date(event.datetime).toLocaleDateString('pt-br');
  const time = new Date(event.datetime).toLocaleTimeString('pt-br');

  return (
    <div style={{ fontWeight: 400, fontSize: '.9em' }}>
      { label }: <span style={{ fontWeight: 500 }}>{ identification }</span>
      {denomination && denomination.length > 0 &&
        <>
          &nbsp;/&nbsp;&nbsp;
          <span style={{ fontWeight: 400 }}>{ denomination }</span>
        </>
      }
      <br />
      <span style={{display: 'inline-block', fontSize: '1.2em', color: '#f7f62e', marginTop: '.5em', fontWeight: 'bold' }}>{event.trackerEvent.name}</span>
      <br />
      <span style={{display: 'inline-block', fontSize: '1em', marginTop: '.5em' }}>{ date } { time }</span>
    </div>
  );
}

export default ToastMessage;
