import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FeatureGroup,
  useMapEvents
} from 'react-leaflet';
import EditableLandmark from './EditableLandmark';

function LandmarksLayer({
  landmarks,
  onNewLandmark,
  onUpdatedLandmark,
  addingMode,
  setAddingMode,
  editable,
  onEditLandmark,
  underEditing = false,
  emblems
}) {
  const [hiddenLayer, setHiddenLayer] = useState(false);

  const map = useMapEvents({
    click(event) {
      if (addingMode) {
        const latLng = event.latlng;
  
        // Chamamos o callback para informar que um novo ponto de
        // referência foi criado na posição do clique
        console.log('Creating new landmark', latLng);
        onNewLandmark([latLng.lat, latLng.lng]);
  
        // Desativamos o modo de adição
        const classList = map._container.classList;
        classList.remove('leaflet-interactive');
        classList.remove('leaflet-crosshair');
        setAddingMode(false);
      }
    },
    zoomend(event) {
      const zoom = map.getZoom();

      if (editable === undefined || editable === false) {
        setHiddenLayer((zoom < 12)? true : false);
      }
    }
  });

  const handleLandmarkPositionChange = useCallback((id, center) => {
    console.log('handleLandmarkPositionChange', id, center);

    if (onUpdatedLandmark) {
      // Obtemos o ponto de referência que foi alterado
      const landmark = landmarks.find(landmark => landmark.id === id);

      // Chamamos o callback para atualizar o ponto de referência
      onUpdatedLandmark({
        ...landmark,
        latitude: center[0],
        longitude: center[1]
      });
    }
  }, [landmarks, onUpdatedLandmark]);

  useEffect(() => {
    const zoom = map.getZoom();

    if (editable === undefined || editable === false) {
      setHiddenLayer((zoom < 12)? true : false);
    }
  }, [map]);

  const getEmblem = (value) => {
    const emblem = emblems
      ? emblems[value]
      : '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><circle cx="8" cy="8" r="4" /></svg>'
    ;

    return emblem;
  };

  const RenderedLandMarks = useMemo(() => {
    return landmarks && landmarks.map((landmark, index) => {
      //console.log('Renderizando landmark', landmark);
      return (
        <EditableLandmark
          key={`Landmark_${landmark.id}`}
          id={landmark.id}
          name={landmark.name}
          note={landmark.note}
          position={[landmark.latitude, landmark.longitude]}
          icon={getEmblem(landmark.emblemid)}
          color={(landmark.disabled?'#c4b6ab':landmark.color)}
          showName={landmark.showname}
          onPositionChange={handleLandmarkPositionChange}
          editable={editable}
          underEditing={underEditing}
          onEditLandmark={onEditLandmark}
          hidden={hiddenLayer}
        />
      );
    });
  }, [landmarks, hiddenLayer]);

  const attribution = useMemo(() => {
    return (editable === undefined || editable === false)
      ? 'ignoreOnFit'
      : null
    ;
  }, [editable]);

  return (
    <FeatureGroup attribution={attribution}>
      {RenderedLandMarks}
    </FeatureGroup>
  );
}

export default LandmarksLayer;
