import {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';
import moment from 'moment';

const getInitialPlaySoundState = () => {
  const playSoundsValue = localStorage.getItem("playSounds");

  if (playSoundsValue === null || playSoundsValue === undefined) {
    // Atribui um valor padrão
    localStorage.setItem("playSounds", true);
    
    return true;
  }

  return playSoundsValue;
}

const initialState = {
  events: [],
  notices: [],
  alarms: [],
  newEvents: [],
  playSounds: getInitialPlaySoundState(),
  firstRunning: true
};

function eventReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_EVENTS':
      const events = action.payload;
      // Eliminamos os eventos com mais de 1 horas
      const cutoffTime = moment().subtract(1, 'hour');
      const eventsToKeep = state.events
        .filter(event => {
          const eventTime  = moment(event.datetime);

          return eventTime.isAfter(cutoffTime);
        })
      ;

      // Separamos os eventos de acordo com o status
      const newEvents = events
        .filter(event => !event.completed && !event.inTreatment)
      ;
      console.debug(
        'Novos eventos:',
        newEvents.length
      );
      const inTreatmentEvents = events
        .filter(event => !event.completed && event.inTreatment)
      ;
      console.debug(
        'Eventos em tratamento:',
        inTreatmentEvents.length
      );
      const completedEvents = events
        .filter(event => event.completed)
      ;
      console.debug(
        'Eventos concluídos:',
        completedEvents.length
      );
      // Limpamos os eventos já concluídos
      const eventsCleaned = eventsToKeep
        .filter(event => !completedEvents.includes(event))
      ;
      // Atualizamos os eventos em tratamento e adicionamos os novos
      const updatedEvents = eventsCleaned
        .map(event => {
          const updatedEvent = eventsCleaned.find(e => e.id === event.id);
          return updatedEvent ? {...event, ...updatedEvent} : event;
        })
        .concat(newEvents)
      ;

      return {
        ...state,
        events: updatedEvents,
        newEvents: state.firstRunning ? [] : newEvents,
        firstRunning: false,
        notices: updatedEvents
          .filter(event => {
            return event.type === 'Notice';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
        ,
        alarms: updatedEvents
          .filter(event => {
            return event.type === 'Alarm';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
      };
    case 'CLEAR_ALARMS':
      const alarmIdsToRemove = state.alarms
        .map(event => event.id)
      ;
      const eventsWithoutAlarms = state.events
        .filter(event => !alarmIdsToRemove.includes(event.id))
      ;

      return {
        ...state,
        events: eventsWithoutAlarms,
        notices: eventsWithoutAlarms
          .filter(event => {
            return event.type === 'Notice';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
        ,
        alarms: eventsWithoutAlarms
          .filter(event => {
            return event.type === 'Alarm';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
      };
    case 'CLEAR_NOTICES':
      const noticeIdsToRemove = state.notices
        .map(event => event.id)
      ;
      const eventsWithoutNotices = state.events
        .filter(event => !noticeIdsToRemove.includes(event.id))
      ;

      return {
        ...state,
        events: eventsWithoutNotices,
        notices: eventsWithoutNotices
          .filter(event => {
            return event.type === 'Notice';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
        ,
        alarms: eventsWithoutNotices
          .filter(event => {
            return event.type === 'Alarm';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
      };
    case 'DELETE_EVENT':
      const eventID = action.payload;
      const cleanedEvents = state.events
        .filter(event => event.id !== eventID)
      ;

      return {
        ...state,
        events: cleanedEvents,
        notices: cleanedEvents
          .filter(event => {
            return event.type === 'Notice';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
        ,
        alarms: cleanedEvents
          .filter(event => {
            return event.type === 'Alarm';
          })
          .sort((a, b) => {
            return a.datetime.localeCompare(b.datetime);
          })
          .reverse()
      };
    case 'SET_SOUND_STATE':
      const soundState = action.payload;

      return {
        ...state,
        playSounds: soundState
      };
    default:
      throw new Error(`Tipo de ação não tratada: ${action.type}`);
  }
}

const EventContext = createContext();

function EventProvider(props) {
  const [eventsState, dispatch] = useReducer(eventReducer, initialState);

  const updateEvents = useCallback((events) => {
    dispatch({ type: 'UPDATE_EVENTS', payload: events });
  }, [dispatch]);

  const getEvent = useCallback((eventID) => {
    return eventsState.events.find((event) => {
      return event.id === eventID;
    });
  }, [eventsState.events]);

  const clearAlarms = useCallback(() => {
    dispatch({ type: 'CLEAR_ALARMS', payload: null });
  }, [dispatch]);

  const clearNotices = useCallback(() => {
    dispatch({ type: 'CLEAR_NOTICES', payload: null });
  }, [dispatch]);

  const deleteEvent = useCallback((eventID) => {
    dispatch({ type: 'DELETE_EVENT', payload: eventID });
  }, [dispatch]);

  const changeSoundState = useCallback((soundState) => {
    if (soundState !== eventsState.playSounds) {
      localStorage.setItem("playSounds", soundState);
      console.debug('Modificado o estado do som para:',
        soundState ? 'ativado' : 'desativado'
      );
      dispatch({ type: 'SET_SOUND_STATE', payload: soundState });
    }
  }, [dispatch, eventsState.playSounds]);

  const value = {
    eventsState,
    updateEvents,
    clearAlarms,
    clearNotices,
    deleteEvent,
    getEvent,
    changeSoundState
  };

  return <EventContext.Provider value={value} {...props} />;
}

function useEventContext() {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEventContext deve ser utilizado dentro de um EventProvider');
  }
  
  return context;
}

export { EventProvider, useEventContext };