import Api from './Api';

const updateDenomination = async (
  vehicleID, denomination
) => {
  return Api
    .put('/vehicle/denomination', {
      'id': vehicleID,
      'denomination': denomination
    })
    .then((response) => {
      if (response.status === 'Success') {
        return true;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Recupera os veículos com rastreadores sem comunicar.
 * 
 * @returns
 *   Os dados da requisição do histórico de veículos sem comunicar
 */
const getVehiclesWithEquipmentsWithoutComunicationHistory = async () => {
  return Api
    .get('/vehicle/withoutcomunication', { params: {} })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  updateDenomination,
  getVehiclesWithEquipmentsWithoutComunicationHistory
};