import React from 'react';
import { Link, useLocation, useParams } from "react-router-dom";

import {ReactComponent as PrivacityIcon } from '../assets/icons/privacity.svg';

function Privacity() {
  const location = useLocation();
  const { tenantName } = useParams();

  const privacityPage = location.pathname.startsWith('/app')
    ? `/app/${tenantName}/privacypolicy`
    : '/privacypolicy'
  ;

  if (location.pathname.endsWith('privacypolicy')) {
    return null;
  }
  
  return (
    <Link to={privacityPage}>
      <PrivacityIcon className='icon' />
      Política de privacidade
    </Link>
  );
}

export default Privacity;