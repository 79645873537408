import React from 'react';

import { useThemeContext } from './ThemeProvider';
import { useTenantContext } from "../features/TenantContext";

import '../styles/FloatingBox.scss';

const FloatingBox = () => {
  const { theme } = useThemeContext();
  const { tenant } = useTenantContext();
  const getTenantLogo = () => {
    if (theme === 'dark') {
      return tenant.logo.inverted;
    }

    return tenant.logo.normal;
  }
  
  return (
    <div className="FloatingBox">
      <img src={getTenantLogo()} alt="Logomarca" />
    </div>
  );
};

export default FloatingBox;
