import { useFormContext } from 'react-hook-form';

function Error({ name }) {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    errors[name] &&
    <div className="form-error">{errors[name]?.message}</div>
  );
}

export default Error;