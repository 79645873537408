import React, { useEffect } from 'react';
import {
  GeoSearchControl,
  //EsriProvider
  OpenStreetMapProvider
} from 'leaflet-geosearch';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

import { addressFormatter } from './AddressFormatter';
import 'leaflet-geosearch/dist/geosearch.css';

import pin from '../../assets/icons/map/pin.svg';

const PinIcon = L.icon({
  iconUrl: pin,
  // Tamanho do ícone
  iconSize: [48, 48],
  // Ponto do ícone que corresponderá à localização do marcador
  iconAnchor: [24, 46],
  // ponto a partir do qual o pop-up deve abrir em relação ao iconAnchor
  popupAnchor: [ 0, -44]
});

const SearchField = () => {
  const provider = new OpenStreetMapProvider({
    params: {
      'accept-language': 'br',
      'countrycodes': 'br',
      'addressdetails': 1
    }
  });

  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    autoComplete: true,
    autoCompleteDelay: 250,
    showMarker: true,
    showPopup: true,
    marker: {
      icon: PinIcon,
      draggable: false,
    },
    popupFormat: ({ query, result }) => {
      const {
        x: longitude,
        y: latitude
      } = result;
      const address = addressFormatter(result);
      console.log('query', query);

      return `<b>Endereço:</b><br>${address}<br><br><b>Coordenadas:</b> ${latitude}, ${longitude}`;
    },
    resultFormat: ({ result }) => {
      const {
        x: longitude,
        y: latitude
      } = result;

      console.log('Latitude: ' + latitude + ' Longitude: ' + longitude);
      console.log(result);

      return addressFormatter(result);
    },
    maxMarkers: 1,
    retainZoomLevel: false,
    animateZoom: true,
    autoClose: false,
    searchLabel: 'Informe um endereço',
    keepResult: false,
    updateMap: true,    
    notFoundMessage: 'Desculpe, esse endereço não foi encontrado.'
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);

    map.on('geosearch/showlocation', (e) => {
      const { location } = e;
      //map.setView(location, 16);
      console.log(location);
    });

    map.on('geosearch/marker/popupopen', (e) => {
      const { location } = e;
      console.log(location);
    });

    return () => {
      map.removeControl(searchControl);
      map.removeEventListener('geosearch/showlocation');
      map.removeEventListener('geosearch/marker/popupopen');
    };
  }, []);

  return null;
};

export default SearchField;