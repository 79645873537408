import React from 'react';

import TimeElapsedLabel from '../TimeElapsedLabel';

function DateAndTime({ eventDate }) {
  const date = new Date(eventDate);

  return (
    <div className="doubleline center">
      <span className="line1">{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>
      <span className="line2"><TimeElapsedLabel eventDate={eventDate}/></span>
    </div>
  );
}

export default DateAndTime;