import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import classnames from "classnames";
//import { set } from "date-fns";
//import { fi } from "date-fns/locale";

function DecimalField({
  name,
  label,
  control,
  rules,
  onChange,
  min,
  max,
  size,
  decimals,
  defaultValue,
  ...rest
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules
  });

  const [value, setValue] = useState(() => {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (field.value === undefined) {
      return '';
    }

    return field.value;
  });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    console.log('field.value de', field.name, 'mudou para', field.value);
    if (field.value !== undefined) {
      // Primeiro removemos todos os caracteres que não sejam números ou
      // vírgula ou ponto
      let newValue = field.value.replace(/[^\d,.]/g, '');

      if (newValue === '') {
        // Atualiza o valor interno do componente (UI state)
        setValue(newValue);
  
        return;
      }
  
      // Converte o valor para o formato brasileiro (vírgula)
      newValue = newValue.replace(".", ",");

      // Limita a quantidade de casas decimais e de dígitos da parte
      // inteira
      if (decimals && (newValue.indexOf(',') !== -1)) {
        const [integerPart, decimalPart] = newValue.split(',');

        const wholeNumbers = size - decimals;
        newValue = integerPart.slice(0, wholeNumbers)
          + ',' + decimalPart.slice(0, decimals)
        ;

        // Garante que tenha um valor à esquerda da vírgula
        if (newValue.indexOf(',') === 0) {
          newValue = '0' + newValue;
        }
      } else {
        newValue = newValue.slice(0, size);
      }

      if (max) {
        const realValue = Number(newValue.replace(',', '.'));
  
        const limitedValue = Math.min(Math.max(realValue, min), max);
  
        if (limitedValue !== realValue) {
          newValue = limitedValue.toFixed(decimals);
          newValue = newValue.replace('.', ',');
        }
      }
  
      setValue(newValue);
    } else {
      setValue('');
    }
  }, [field.name, field.value, decimals, size, min, max]);

  const handleOnInput = (event) => {
    const { value: inputValue, selectionStart } = event.target;
  
    // Salva a posição do cursor antes da manipulação
    const cursorPosition = selectionStart;
  
    let newValue = inputValue;

    // Primeiro removemos todos os caracteres que não sejam números ou
    // vírgula
    newValue = newValue.replace(/[^\d,]/g, '');

    if (newValue === '') {
      // Se o valor for vazio, então não fazemos nada
      if (newValue !== value) {
        // Enviamos o valor para o hook form
        field.onChange(newValue);

        // Atualiza o valor interno do componente (UI state)
        setValue(newValue);
      }

      return;
    }

    // Remove todas as vírgulas, exceto a primeira
    newValue = newValue.replace(/(,)(?=.*\1)/g, '');

    if ((newValue.indexOf('0') === 0) && (newValue.length > 1) && (newValue.indexOf(',') !== 1)) {
      // Remove todos os zeros à esquerda
      newValue = newValue.replace(/^0+/, '');

      if (newValue.length === 0) {
        newValue = '0';
      }
    }

    // Limita a quantidade de casas decimais e de dígitos da parte
    // inteira
    if (decimals && (newValue.indexOf(',') !== -1)) {
      const [integerPart, decimalPart] = newValue.split(',');

      const wholeNumbers = size - decimals;
      newValue = integerPart.slice(0, wholeNumbers)
        + ',' + decimalPart.slice(0, decimals)
      ;

      // Garante que tenha um valor à esquerda da vírgula
      if (newValue.indexOf(',') === 0) {
        newValue = '0' + newValue;
      }
    } else {
      newValue = newValue.slice(0, size);
    }

    if (max) {
      const realValue = Number(newValue.replace(',', '.'));

      const limitedValue = Math.min(Math.max(realValue, min), max);

      if (limitedValue !== realValue) {
        newValue = limitedValue.toFixed(decimals);
        newValue = newValue.replace('.', ',');
      }
    }

    // Enviamos o valor para o hook form
    field.onChange(newValue);

    // Atualiza o valor interno do componente (UI state)
    setValue(newValue);

    if (onChange) {
      // Propagar o evento para o componente principal
      onChange(newValue);
    }

    // Restaura a posição do cursor depois da manipulação
    //event.target.value = newValue;
    event.target.setSelectionRange(cursorPosition, cursorPosition);
  };

  const handleOnBlur = (event) => {
    const { value } = event.target;

    let newValue = value;

    if (newValue === '') {
      return;
    }

    if (decimals) {
      if (newValue.indexOf(',') === -1) {
        // Não temos casas decimais no valor, então adicionamos
        newValue += ',' + '0'.repeat(decimals);
      } else {
        // Temos casas decimais, então adicionamos zeros à direita, se
        // necessário
        const [integerPart, decimalPart] = newValue.split(',');

        newValue = integerPart + ',' + decimalPart.padEnd(decimals, '0');
      }
    }

    if (newValue !== value) {
      if (onChange) {
        // Propagar o evento para o componente principal
        onChange(newValue);
      }
      setValue(newValue);
    }
  }

  useEffect(() => {
    // Lida com erros de validação
    setHasError(invalid);
  }, [invalid]);

  console.log('Field', name, 'rendered');

  return (
    <>
      <input
        type="text"
        ref={field.ref}
        name={name}
        value={value}
        onInput={handleOnInput}
        onBlur={handleOnBlur}
        min={min??undefined}
        max={max??undefined}
        pattern="[0-9,]*"
        className={classnames({ error: hasError })}
        {...rest}
      />
      {hasError && (
        <span className="errorMsg">
          {error?.type === 'required' 
            ? 'O valor de ' + label.toLowerCase() + ' deve ser informado'
            : error?.message
          }
        </span>
      )}
    </>
  );
}

export default DecimalField;