import React from "react";

function Block({ children, vertical, horizontal, ...rest }) {
  return (
    <div
      style={{
        ...styles.block,
        flexDirection: vertical ? "column" : "row"
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

const styles = {
  block: {
    width: "100%",
    display: "flex",
    minHeight: "calc(100vh - 70px)"
  }
}

export default Block;