import React from 'react';

import {ReactComponent as AppLogo} from '../../assets/logos/app.svg';

import './styles.css';

function SplashScreen({ show }) {
  return (
    <>
      { show && 
        <div className="splash-screen">
          <div className="splash_logo">
            <AppLogo />
          </div>
          Aguarde um momento enquanto o aplicativo está sendo carregado...
          <div className="loading-dot">.</div>
        </div>
      }
    </>
  );
}

export default SplashScreen;