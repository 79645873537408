import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useController } from 'react-hook-form';

const SelectField = ({
  name,
  control,
  options,
  placeHolder,
  defaultValue,
  rules,
  onChange,
  noOptionsMessage=() => 'Nenhum item encontrado.'
}) => {
  const {
    field,
    fieldState: { invalid }
  } = useController({
    name,
    control,
    defaultValue,
    rules
  });
  const [hasError, setHasError] = useState(false);

  const handleOnChange = (selectedOption) => {
    // Enviamos o valor para o hook form
    field.onChange(selectedOption);

    if (onChange) {
      // Propagar o evento para o componente principal
      onChange(selectedOption);
    }
  };

  const handleOnBlur = (event) => {
    field.onBlur();
  };

  useEffect(() => {
    // Lida com erros de validação
    setHasError(invalid);
  }, [invalid]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      backgroundColor: hasError
        ? "#dc354510"
        : "var(--theme-input-bg)",
      borderRadius: ".375rem",
      borderColor: hasError
        ? "var(--theme-dangerous)"
        : "var(--theme-input-border-color)",
      boxShadow: hasError
        ? (state.isFocused ? "0 0 0 2px var(--theme-dangerous)" : "none")
        : (state.isFocused ? "0 0 0 2px #007bff" : "none"),
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      fontWeight: 400,
      lineHeight: 1.5,
      borderRadius: ".375rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-placeholder-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: state.isFocused ? "var(--theme-dropdown-hover-bg)" : "transparent",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: ".5rem .3rem",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: "var(--theme-dropdown-bg)",
      borderRadius: ".25rem",
      border: "1px solid var(--theme-input-border-color)",
      zIndex: 1000
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: "var(--theme-heading-color)",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: ".5rem .3rem",
      textTansform: "none",
    })
  };

  const groupStyles = {
    fontSize: '1em',
    lineHeight: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const groupBadgeStyles = {
    backgroundColor: 'var(--theme-primary)',
    borderRadius: '2em',
    color: '#fff',
    display: 'inline-block',
    fontSize: '.8333333333rem',
    fontWeight: 'bold',
    minWidth: '1.9em',
    padding: '0.2em 0.3em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data) => {
    return data.options ? (
      <div className="group-label" style={groupStyles}>
        <span>{data.label}</span>
      <span className="group-count" style={groupBadgeStyles}>{data.options.length}</span>
    </div>
    ) : (
      <span>{data.label}</span>
    );
  };
  
  return (
    <Select
      {...field}
      options={options}
      isClearable={true}
      isSearchable={true}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      styles={customStyles}
      placeholder={placeHolder}
      formatGroupLabel={formatGroupLabel}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default SelectField;
