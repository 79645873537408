import React, { forwardRef, useEffect, useState } from "react";
import { useController } from "react-hook-form";

import IPV4Input from "../../IPV4Input";

function IPV4Field({
  name,
  control,
  onChange,
  onBlur,
  defaultValue,
  rules,
  ...rest
}, ref) {
  const isRequired = rules && rules.required ? true : false;
  const [isCleaned, setIsCleaned] = useState(false);

  const isValidIP = (ipAddress) => {
    // Usamos uma expressão regular para validar o endereço IPv4
    const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;

    // Verificamos se a string corresponde ao padrão do endereço IP
    return ipPattern.test(ipAddress);
  };

  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules: {
      required: isRequired,
      validate: (value) => {
        // Se não for obrigatório, não valida apenas se não contiver um
        // valor informado
        console.log('Is required: ', isRequired);

        if (!isRequired && (!value || value.trim() === '...')) {
          return true;
        }

        if (!value || value.trim() === '...') {
          if (isCleaned) {
            console.log('Ignorando validação pois o campo foi limpo');
            setIsCleaned(false);

            return true;
          }
          
          return "Informe um endereço de IP válido";
        }

        if (!isValidIP(value)) {
          return "Informe um endereço de IP válido";
        }
    
        return true;
      }
    },
  });

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if ((field.value === '...') || (field.value === undefined)) {
      console.log('Campo foi limpo');
      setIsCleaned(true);
    }
  }, [field.value]);

  const handleOnChange = (event) => {
    const { value } = event.target;

    // Enviamos o valor para o hook form
    if (isCleaned) {
      console.log('Ignorando mudança de valor pois o campo foi limpo');
      if (value !== '...') {
        setIsCleaned(false);
      }
    } else {
      field.onChange(value);
    }
  };

  useEffect(() => {
    // Lida com erros de validação
    setHasError(invalid);
  }, [invalid]);

  const handleOnBlur = (event) => {
    field.onBlur(event);
  };

  return (
    <>
      <IPV4Input
        ref={ref}
        name={name}
        value={field.value}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        isWrong={(value) => hasError}
        {...rest}
      />
      {hasError && (
        <span className="errorMsg">{error?.message}</span>
      )}
    </>
  );
}

export default forwardRef(IPV4Field);