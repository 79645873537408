import L from "leaflet";
import shortid from 'shortid';

import { useDeviceContext } from '../../features/DeviceContext';

const svgIcon = (color, icon) => { return `
<svg
  width="48"
  height="48"
  viewBox="0 0 48 48"
  xmlns="http://www.w3.org/2000/svg"
  >
  <filter id="a" color-interpolation-filters="sRGB" height="1.494121" width="1.311714" x="-.155857" y="-.24706">
    <feGaussianBlur stdDeviation="2.2698626" />
  </filter>
  <path d="m97.905241 19.666857a6.8609966 18.091485 73.774263 0 0 -24.00205 8.304396 6.8609966 18.091485 73.774263 0 0 -2.521136 4.493965l1.343316 9.250494 2.112082.0011 21.665212-9.243984h-.002a6.8609966 18.091485 73.774263 0 0 7.298945-4.50156 6.8609966 18.091485 73.774263 0 0 -5.894433-8.304396z" fill-opacity=".25" filter="url(#a)" transform="matrix(.72583874 0 0 .92154075 -29.870713 7.215898)" />
  <path d="m23.864366.00000005a16.948826 16.948826 0 0 0 -16.9478836 16.95009595 16.948826 16.948826 0 0 0 2.6973115 9.172629l12.9594951 18.881177h.0023a1.3189848 1.3189848 0 0 0 1.195852.766231 1.3189848 1.3189848 0 0 0 1.195852-.764016h.0023l13.136551-18.86789h-.0023a16.948826 16.948826 0 0 0 2.71065-9.188131 16.948826 16.948826 0 0 0 -16.950096-16.95009595z" fill="` + color + `" />
  <circle cx="23.865322" cy="16.949024" fill="#fff" r="13.002331" />
  ${icon}
</svg>`};

const MarkerIcon = (type, symbol, ignition) => {
  const {
    devicesState
  } = useDeviceContext();
  let vehicleIcon;

  if (type === null || type === undefined) {
    // Usamos o símbolo padrão
    vehicleIcon = devicesState.iconset['tracker'];
  } else {
    if (devicesState.iconset.hasOwnProperty(symbol)) {
      vehicleIcon = devicesState.iconset[symbol];
    } else {
      // Usamos o símbolo padrão
      vehicleIcon = devicesState.iconset['tracker'];
    }
  }

  let color = (ignition === true) ? '#329622':'#f00';
  let key = shortid.generate();

  const buildIcon = L.divIcon({
    key: {key},
    html: svgIcon(color, vehicleIcon),
    className: "",
    // Tamanho do ícone
    iconSize: [48, 48],
    // Ponto do ícone que corresponderá à localização do marcador
    iconAnchor: [21, 46],
    // ponto a partir do qual o pop-up deve abrir em relação ao iconAnchor
    popupAnchor: [ 0, -44]
  });
    
  return buildIcon;
};

export default MarkerIcon;