import React, {
  forwardRef,
  useRef,
  useState
} from 'react';

const Body = forwardRef(({
  data,
  keys,
  columns,
  onClick,
  rowColor,
  noContentMessage,
  autoHeight,
  groupBy = null,
  groupRender,
}, tbodyRef) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const noContentText = noContentMessage || 'Nenhum registro';

  const dateTimeFormatter = (value) => {
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return (
      date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    );
  };

  const handleRowClick = (item) => {
    console.log('O ID do item selecionado', item.id);
    setSelectedRow(item.id);
    if (onClick) {
      onClick(item);
    }
  };

  const handleBodyClick = (event) => {
    if (onClick && event.target === tbodyRef.current) {
      onClick(null);
    }
  };

  const getRowColor = (item) => {
    if (selectedRow && selectedRow === item.id) {
      return 'selected';
    }

    if (!rowColor) {
      return '';
    }
    
    return rowColor(item);
  };

  let totals = [];

  return (
    <tbody
      ref={tbodyRef}
      style={ autoHeight ? {height: 'auto'} : {}}
      onClick={handleBodyClick}
    >
      {Object.entries(data).map(([group, items], groupIndex) => (
        <React.Fragment key={`group_${groupIndex}`}>
          {groupBy && group !== '' && (
            <tr
              key={`group_${groupIndex}_${group}`}
              className="group"
            >
              <td colSpan={Object.entries(columns).length}>
                {groupRender(group)}
              </td>
            </tr>
          )}
          {items.map((item, rowindex) => (
            <tr
              key={`group_${group}_row_${rowindex}`}
              onClick={() => handleRowClick(item)}
              className={getRowColor(item)}
            >
              { rowindex === 0 && item.hasOwnProperty('id') && item.id === null
                ? (<td colSpan={keys.length} className="center" style={{ color:"var(--theme-table-color)", fontStyle:"italic", fontSize:"1rem"}}>{noContentText}</td>)
                : keys.map((key, index) => {
                  if (columns && !columns.hasOwnProperty(key)) {
                    return null;
                  }
                    
                  const classes = [
                    columns[key].align??'left',
                    columns[key].hidden?'hidden':'',
                  ].join(' ');
                  const width = columns[key].width??undefined;
                  if (columns[key].hasOwnProperty('render')) {
                    return (
                      <td key={index} className={classes} width={width}>
                        {columns[key].render(item[key])}
                      </td>
                    );
                  }
                  if (columns[key].hasOwnProperty('totalizer')) {
                    if (!totals[index]) {
                      totals[index] = 0;
                    }
                    totals[index] += item[key];
                  }
      
                  // Renderiza o valor
                  return (
                    <td key={index} className={classes} width={width}>
                      {columns[key].formatter === 'datetime' ? dateTimeFormatter(item[key]) : item[key]}
                    </td>
                  );
                })
              }
            </tr>
          ))}
        </React.Fragment>
      ))}
      { (totals.length > 0) &&
      <tr key="footer">
        {totals.map((value, i) => {
          return (
            <>
              <td
                key={totals}
                colSpan={i}
                className="totals right"
              >
                Total
              </td>
              <td
                key={`totals{i}`}
                className="totals center"
              >
                {value}
              </td>
            </>
          );
        })}
      </tr>
      }
    </tbody>
  );
});

export default Body;