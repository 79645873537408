import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import IPV4Field from './Fields/IPV4Field';
import NumericField from './Fields/NumericField';
import DecimalField from './Fields/DecimalField';
import SelectField from './Fields/SelectField';
import TextField from './Fields/TextField';
import HiddenField from './Fields/HiddenField';
import SizeField from './Fields/SizeField';
import BinaryCheckbox from './Fields/BinaryCheckboxField';
import parameterConverter from '../../hooks/parameterConverter';

import './styles.scss';
import { Toggle } from '../Toggle';

var
  sprintf = require('sprintf-js').sprintf
  //vsprintf = require('sprintf-js').vsprintf
;

function DeviceCommand({
  command,
  onSendCommand,
  showParameterDescription,
  onChangeShowParameterDescription
}) {
  // TODO: Precisamos lidar melhor com os Refs pois alguns componentes
  // não estão sendo renderizados corretamente quando o formulário é
  // resetado. Por enquanto, estamos usando o hook useRef() para
  //const inputRefs = useRef({});

  // Define valores padrão para o formulário
  const defaultValues = (incomingCommand) => {
    // Se não houver comando, não há valores padrão
    if (incomingCommand === null) {
      return {};
    }

    // Formata o valor padrão de acordo com a matriz "fields"
    const formattedDefaultValues = {};
    incomingCommand?.fields?.forEach((field, index) => {
      const fieldName = `field-${index}`;
      if (field.defaultValue !== undefined) {
        formattedDefaultValues[fieldName] = field.defaults.value;
      }
    });

    return formattedDefaultValues;
  };

  // Define condições iniciais do formulário
  const {
    control,
    handleSubmit,
    //formState,
    reset,
    setValue
  } = useForm({
    defaultValues: defaultValues(command)
  });

  useEffect(() => {
    if (command) {
      console.log('Resetando formulário');

      let fields = {};
      command?.fields?.forEach((field, index) => {
        const fieldName = `field-${index}`;
        fields[fieldName] = '';
        setValue(fieldName, '');
      });
      reset();
    }
  }, [command, reset, setValue]);

  const Field = ({ field, fieldName }) => {
    // console.log('Field', field.name, 'Tipo', field.type);
    // console.log('Field', field);
    // console.log('Control', control);
    switch (field.type) {
      case 'endereço ipv4':
        return (
          <IPV4Field
            key={fieldName}
            name={fieldName}
            control={control}
            rules={{required: field.required}}
            defaultValue={field.defaults.value??''}
            placeholder={field.placeholder}
          />
        );
      case 'inteiro':
        return (
          <NumericField
            key={fieldName}
            name={fieldName}
            label={field.name}
            control={control}
            rules={{required: field.required}}
            min={field.range.min??undefined}
            max={field.range.max??undefined}
            placeholder={field.placeholder}
            size={field.range.size??undefined}
          />
        );
      case 'real':
        return (
          <DecimalField
            key={fieldName}
            name={fieldName}
            label={field.name}
            control={control}
            rules={{required: field.required}}
            min={field.range.min??undefined}
            max={field.range.max??undefined}
            size={field.range.size??undefined}
            decimals={field.range.decimals??undefined}
            placeholder={field.placeholder}
          />
        );
      case 'seleção':
        return (
          <SelectField
            key={fieldName}
            name={fieldName}
            label={field.name}
            control={control}
            rules={{required: field.required}}
            options={field.options}
            placeHolder={field.placeholder}
          />
        );
      case 'espaço':
        return (
          <HiddenField
            key={fieldName}
            name={fieldName}
            control={control}
            rules={{required: field.required}}
          />
        );
      case 'tamanho':
        return (
          <SizeField
            key={fieldName}
            name={fieldName}
            control={control}
            rules={{required: field.required}}
          />
        );
      case 'bits de verificação':
        return (
          <BinaryCheckbox
            key={fieldName}
            name={fieldName}
            label={field.name}
            control={control}
            options={field.options}
            size={field.range.size??undefined}
            defaultValue={field.defaults.value??''}
            rules={{required: field.required}}
          />
        );
      default:
        return (
          <TextField
            key={fieldName}
            name={fieldName}
            label={field.name}
            control={control}
            rules={{required: field.required}}
            maxLength={field.range?.size??undefined}
            pattern={field.range?.pattern??undefined}
            placeholder={field.placeholder}
            size={field.range.size??undefined}
            defaultValue={field.defaults.value??''}
          />
        );
    }
  };
  
  const renderFormFields = useCallback((fields) => {
    return fields.map((field, index) => {
      const fieldName = `field-${index}`;
      const className = 'device-command__body__field '
        + (field.size ?? 'full')
      ;
      const isSizeOfPosition = field.isSizeOfPosition;

      if (field.type === 'tamanho') {
        return (
          <Field key={`${fieldName}_${index}`} field={field} fieldName={fieldName} />
        );
      }

      return (
        <div key={`div_${index}`} className={className}>
          <label key={`lbl_${index}`} htmlFor={fieldName}>
            { field.type === 'espaço' ? <>&nbsp;</> : field.name }
          </label>
          <Field key={`${fieldName}_${index}`} field={field} fieldName={fieldName} />
          { field.type !== 'espaço' && showParameterDescription && (
            <p key={`dsc_${index}`} className='description'>{field.description}</p>
          )}
        </div>
      );
    });
  }, [showParameterDescription]);

  const formFields = command?.fields;

  const renderFields = useMemo(() => {
    if (formFields === undefined) {
      return null;
    }

    return renderFormFields(formFields);
  }, [formFields, renderFormFields]);

  const onSubmit = async (data) => {
    if (onSendCommand) {
      const formValues =  Object.values(data);

      const replacementValues = [];

      // Convertemos os valores do formulário para o tipo esperado pelo
      // comando
      let value;
      formFields.forEach((field, index) => {
        switch (field.type) {
          case 'inteiro':
            value = formValues[index] !== undefined
              ? parseInt(formValues[index])
              : null
            ;
            if (field.needsConversion) {
              value = parameterConverter(value, command.value, index);
              console.log('Conversão do valor de', formValues[index], 'para', value);
            }

            break;
          case 'real':
            // Converte o valor do formato brasileiro para o formato
            // americano e garante que o resultado tenha as casas
            // decimais esperadas pelo comando
            value = formValues[index] !== undefined
              ? parseFloat(formValues[index].replace(',', '.'))
              : null
            ;
            if (field.range.decimals !== undefined) {
              value = value.toFixed(field.range.decimals);
            } else {
              value = value.toString();
            }
            if (field.needsConversion) {
              value = parameterConverter(value, command.value, index);
              console.log('Conversão do valor de', formValues[index], 'para', value);
            }

            break;
          case 'seleção': 
            value = formValues[index] !== undefined
              ? formValues[index].value
              : null
            ;
            if (field.needsConversion) {
              value = parameterConverter(value, command.value, index);
              console.log('Conversão do valor de', formValues[index].value, 'para', value);
            }

            break;
          case 'espaço':
            value = null;

            break;
          case 'tamanho':
            // O valor do campo é o tamanho de um outro campo
            const isSizeOfPosition = field.isSizeOfPosition;
            console.log('O campo', field.name, 'é o tamanho do campo', isSizeOfPosition);
            if (isSizeOfPosition > 0) {
              // O valor é o tamanho (comprimento) de um campo
              const fieldValue = formValues[isSizeOfPosition-1];
              value = fieldValue !== undefined
                ? fieldValue.length
                : 0
              ;
              console.log('O campo', field.name, 'tem valor', value);
            } else {
              value = 0;
            }

            break;
          default:
            value = formValues[index] !== undefined
              ? formValues[index]
              : null
            ;
            if (field.type === 'endereço ipv4') {
              if (value === '...') {
                value = null
              }
            }
            if (field.needsConversion) {
              value = parameterConverter(value, command.value, index);
              console.log('Conversão do valor de', formValues[index], 'para', value);
            }

            break;
        }
        if (!field.required && (value === null)) {
          value = '';
        }
        if (field.type !== 'espaço') {
          replacementValues.push(value);
        }
      });
      
      // Substituímos os valores no formato esperado pelo comando
      const commandData = sprintf(
        command.formatString,
        ...replacementValues
      );

      // Enviamos o comando
      onSendCommand(commandData, replacementValues);

      reset(defaultValues(command));
    }
  };

  return (
    <>
    { command === null ? (
        <div className="device-command">
          <div className="device-command__nocontent">
            Nenhum comando selecionado
          </div>
        </div>
      ) : (
          <div className="device-command">
        <div className="device-command__header">
          <div className="device-command__header_bar">
            <h3>{command.label}</h3>
            <div className="right_control"  title="Exibe uma breve descrição abaixo de cada um dos parâmetros do comando">
              <svg viewBox="0 0 48 48" width="2em" fill={showParameterDescription ? "var(--theme-gray-1000)" : "var(--theme-gray-700)"} xmlns="http://www.w3.org/2000/svg">
                <g strokeWidth=".131254" transform="translate(4.402695 -2.999369)">
                  <path d="m25.246572 43.089802c.52267.02162.996135.37901 1.138222.909297.175024.652666-.215874 1.329678-.868536 1.504556l-9.160894 2.454612c-.652661.174831-1.329678-.215871-1.504556-.868536-.174832-.652655.215874-1.329677.868536-1.504555l9.160895-2.454612c.122373-.03278.245717-.04575.366333-.04076z"/>
                  <path d="m19.618524 15.746319c1.056835.01138 1.580636.635895 1.656063 1.605048-.08208 1.116707-.71357 1.746086-1.694261 1.730149-.976479-.01575-1.687202-.684974-1.663752-1.704001.02273-.991555.644445-1.64273 1.70195-1.631196z"/>
                  <path d="m19.566483 5.999138c5.375142-.039122 10.548529 2.7919675 13.452557 7.588405 3.642881 6.016999 2.852319 13.7268-1.938566 18.808097-.580397.61538-1.194602 1.200207-1.811414 1.779883-1.152078 1.082536-1.860217 2.350857-1.896781 3.980189-.02451 1.091487-.609077 1.856134-1.683236 2.152625-.152099.04307-11.75112 3.160104-11.75112 3.160104-.639547.171339-1.302968-.211956-1.474305-.851359l-.02641-.0987c-.171338-.639543.211812-1.302971.85136-1.474305 0 0 10.108875-2.832397 9.936628-2.67226.921873-.227188 1.45553-.699292 1.678365-1.650934.476395-2.034702 1.799743-3.549691 3.252138-4.956908 2.406476-2.331574 4.028865-5.043719 4.465729-8.441558.653913-5.086366-1.813124-10.344407-6.348149-12.896518-4.884021-2.7484811-9.782935-2.5674103-14.343139.687804-6.3476137 4.530819-7.2778351 13.278737-2.1918467 19.196478 1.4170267 1.648811 2.8571867 3.277896 4.4385537 5.089957 4.160098-.607915 4.160021-.607945 4.160152-4.838728.000131-2.629838.01619-5.260046-.0069-7.88988-.0084-.936084.29781-1.666753 1.297933-1.6435.987869.02292 1.276372.778894 1.273065 1.697079-.01468 4.056337-.02353 8.112946.0031 12.169237.0067 1.005048-.372238 1.561507-1.369199 1.803724-1.944727.472374-3.86367 1.050566-5.808779 1.521218-1.400563.339037-1.584818-.0646-2.159803-1.355612-.573595-1.287854-1.508991-2.494878-2.5264372-3.491317-4.3380873-4.248685-6.0520264-9.322081-4.6749151-15.220391 1.3913262-5.959045 5.2174791-9.8340323 11.0858753-11.5485988 1.362105-.397978 2.74541-.5942598 4.115544-.6042312z"/>
                </g>
              </svg>
              <Toggle toggled={showParameterDescription} onClick={onChangeShowParameterDescription}/>
            </div>
          </div>
          <p className='description'>
            {command.description}
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div  className="device-command__body">
            <div className="device-command__body__fields">
            { (formFields == null || formFields.length === 0) && (
              <div className="device-command__body__field full">
                <p id="mensagem-no-params" className="no-params">
                  Este comando não requer parâmetros.
                </p>
              </div>) 
            }
            { renderFields }
            </div>
          </div>
          <div className="device-command__fotter">
            <button type="submit" className="btn btn-primary">Enviar comando</button>
            <button type="buttom" className="btn btn-primary" onClick={(e) => { e.preventDefault(); reset(defaultValues(command)); }}>Limpar</button>
          </div>
        </form>
      </div>
    )}
    </>
  );
}

export default DeviceCommand;
