import React from 'react';
import classnames from "classnames";

import './styles.scss';

function GroupedRadioButtons({
  hasError,
  children,
}) {
  return (
    <div
      className={classnames({ 'btn-group horizontal': true, error: hasError })}
      role="group"
    >
      {children}
    </div>
  );
}

export default GroupedRadioButtons;
