import React from 'react';

import {ReactComponent as BRFlag} from '../assets/icons/brflag.svg';

function Copyright({ appName, startYear, companyName }) {
  return (
    <div className="copyright">
      { appName && (
          <span className='mobile hidden'>
            {appName}&nbsp;&ndash;&nbsp;
          </span>
      )}
      Copyright © {startYear} - {new Date().getFullYear()} - {companyName}.
      <BRFlag className='flag' />
    </div>
  );
};

export default Copyright;