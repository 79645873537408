function IgnitionLabel({ state })
{
  let color = state === true ? '#53a04a' : '#ff0000';
  let text = state === true ? 'Ligada' : 'Desligada';

  return (
    <p style={{ color: '#808080', fontSize: '1.1em' }}>
      Ignição: <span style={{ color: color, fontWeight: 'bold' }}>{text}</span>
    </p>
  );
}

export default IgnitionLabel;