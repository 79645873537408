import Api from './Api';

/**
 * Recupera os dados do usuário.
 * 
 * @param {int} userID
 *   O id do usuário
 * 
 * @returns
 *   Os dados do usuário
 */
const getUser = async (
  userID
) => {
  return Api
    .get(`/user/reader/${userID}`, { params: {} })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

const updateMyAccount = async (
  user
) => {
  return Api
    .put('/user/update/myaccount', user)
    .then((response) => {
      if (response.status === 'Updated') {
        return true;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

const updateMyPassword = async (
  user
) => {
  return Api
    .put('/user/update/mypassword', user)
    .then((response) => {
      if (response.status === 'Updated') {
        return true;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getUser, updateMyAccount, updateMyPassword
};