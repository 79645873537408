import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';

const getUserDefinedPageSize = () => {
  return localStorage.getItem("pageSize") || 10;
}

const initialState = {
  firstCall: true,
  devices: [],
  devicesOnGrid: [],
  currentDevices: [],
  inUpdating: false,
  selectedDevice: null,
  lastCommunication: null,
  onCount: 0,
  offCount: 0,
  filter: '',
  currentPage: 1,
  pageSize: getUserDefinedPageSize(),
  totalOfPages: 0,
  gridMode: 'empty',
  commands: [],
  iconset: [],
  selectedEquipmentForHistory: 0,
  waitingPopulate: false,
  commandHistory: []
};

const filterDevices = (devices, searchValue, gridMode, selectedDevices) => {
  if (gridMode === 'empty') {
    return devices.filter(device => selectedDevices.includes(device.equipment.id));
  }
  
  return searchValue === ''
    ? devices
    : devices.filter(device => {
      const serialNumber = device.equipment.serialNumber;
      const { plate, denomination } = device.vehicle || {};

      return (
        serialNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        plate?.toLowerCase().includes(searchValue.toLowerCase()) ||
        denomination?.toLowerCase().includes(searchValue.toLowerCase())
      );
    })
  ;
};

function deviceReducer(state, action) {
  switch (action.type) {
    case 'SET_IN_UPDATING':
      return {
        ...state,
        inUpdating: action.payload
      };
    case 'UPDATE_DEVICES':
      const devices = action.payload;
      let lastCommunication = state.lastCommunication;
      let onCount = 0;
      let offCount = 0;
      const deviceIdsToRemove = state.devices
        .map(device => device.equipment.id)
        .filter(deviceID => !devices.some(device => device.equipment.id === deviceID))
      ;
      const devicesToKeep = state.devices
        .filter(device => !deviceIdsToRemove.includes(device.equipment.id))
      ;
      const updatedDevices = [];
      devices.forEach((device) => {
        const existingDeviceIndex = devicesToKeep.findIndex(
          (d) => d.equipment.id === device.equipment.id
        );
        if (!lastCommunication) {
          lastCommunication = device.datetime;
        };
        if (device.vehicle !== undefined) {
          device.ignition ? onCount++ : offCount++;
        }

        if (device.datetime > lastCommunication) {
          lastCommunication = device.datetime;
        }
        if (existingDeviceIndex === -1) {
          // O dispositivo não existe, então o adicionamos
          updatedDevices.push(device);
        } else {
          // O dispositivo existe, então o atualizamos
          if ( (device.vehicle === undefined && devicesToKeep[existingDeviceIndex].vehicle !== undefined)
               || (device.vehicle !== undefined && devicesToKeep[existingDeviceIndex].vehicle === undefined) ) {
            // Força a atualização completa em caso de vinculação ou
            // desvinculação do veículo
            updatedDevices.push(device);
          } else {
            updatedDevices.push({
              ...devicesToKeep[existingDeviceIndex],
              ...device,
            });
          }
        }
      });

      const filteredDevices = filterDevices(
        updatedDevices,
        state.filter,
        state.gridMode,
        state.devicesOnGrid
      );

      // Precisamos retirar do grid os dispositivos que não estão mais
      // na lista
      const devicesOnGridToKeep = state.devicesOnGrid
        .filter(deviceID => !deviceIdsToRemove.includes(deviceID));

      return {
        ...state,
        firstCall: false,
        devices: updatedDevices,
        devicesOnGrid: devicesOnGridToKeep,
        currentDevices: filteredDevices.slice(
          (state.currentPage - 1) * state.pageSize,
          state.currentPage * state.pageSize
        ),
        totalOfPages: Math.ceil(filteredDevices.length / state.pageSize) || 1,
        lastCommunication: lastCommunication,
        onCount: onCount,
        offCount: offCount
      };
    case 'SET_CURRENT_PAGE':
      const newPage = action.payload;
      const newPageData = filterDevices(
        state.devices,
        state.filter,
        state.gridMode,
        state.devicesOnGrid
      );

      return {
        ...state,
        currentPage: newPage,
        selectedDevice: null,
        currentDevices: newPageData.slice(
          (newPage - 1) * state.pageSize,
          newPage * state.pageSize
        ),
      };
    case 'SET_ITEMS_PER_PAGE':
      const newPageSize = action.payload;
      const newRefreshPage = filterDevices(
        state.devices,
        state.filter,
        state.gridMode,
        state.devicesOnGrid
      );

      localStorage.setItem("pageSize", newPageSize);

      return {
        ...state,
        currentPage: 1,
        selectedDevice: null,
        pageSize: newPageSize,
        currentDevices: newRefreshPage.slice(
          0, newPageSize
        ),
        totalOfPages: Math.ceil(newRefreshPage.length / newPageSize) || 1,
      };
    case 'SET_FILTER':
      const newFilter = action.payload;
      const newRefreshFilterData = filterDevices(
        state.devices,
        newFilter,
        state.gridMode,
        state.devicesOnGrid
      );

      return {
        ...state,
        currentPage: 1,
        selectedDevice: null,
        currentDevices: newRefreshFilterData.slice(
          0, state.pageSize
        ),
        totalOfPages: Math.ceil(newRefreshFilterData.length / state.pageSize) || 1,
        filter: newFilter,
      };
    case 'CLEAR_FILTER':
      const newClearedFilterData = filterDevices(
        state.devices,
        '',
        state.gridMode,
        state.devicesOnGrid
      );

      return {
        ...state,
        currentPage: 1,
        selectedDevice: null,
        currentDevices: newClearedFilterData.slice(
          0, state.pageSize
        ),
        totalOfPages: Math.ceil(newClearedFilterData.length / state.pageSize) || 1,
        filter: '',
      };
    case 'SET_SELECTED_DEVICE':
      return {
        ...state,
        selectedDevice: action.payload
      };
    case 'SET_SELECTED_EQUIPMENT_FOR_HISTORY':
      return {
        ...state,
        selectedEquipmentForHistory: action.payload,
        commandHistory: []
      };
    case 'UPDATE_COMMANDS':
      const commands = action.payload;
      const commandIdsToRemove = state.commands
        .map(command => command.id)
        .filter(commandID => !commands.some(command => command.id === commandID))
      ;
      const commandsToKeep = state.commands
        .filter(command => !commandIdsToRemove.includes(command.id))
      ;
      const updatedCommands = [];
      commands.forEach((command) => {
        const existingCommandIndex = commandsToKeep.findIndex(
          (c) => c.id === command.id
        );
        if (existingCommandIndex === -1) {
          // O comando não existe, então o adicionamos
          updatedCommands.push(command);
        } else {
          // O commando existe, então o atualizamos
          updatedCommands.push({
            ...commandsToKeep[existingCommandIndex],
            ...command,
          });
        }
      });

      return {
        ...state,
        commands: updatedCommands
      };
    case 'UPDATE_SYMBOLS':
      const symbols = action.payload;
      const iconSet = {};
      for (const symbol of symbols) {
        iconSet[symbol.name] = symbol.content;
      }

      return {
        ...state,
        iconset: iconSet
      };
    case 'UPDATE_DENOMINATION':
      const vehicle = action.payload;
      const devicesWithDenominationUpdated = [];
      state.devices.forEach((device) => {
        if (device.vehicle !== undefined) {
          if (device.vehicle.id === vehicle.id) {
            let updatedDevice = {...device};
            updatedDevice.vehicle.denomination = vehicle.denomination;
            
            devicesWithDenominationUpdated.push({
              ...updatedDevice
            });
          } else {
            devicesWithDenominationUpdated.push({
              ...device
            });
          }
        } else {
          devicesWithDenominationUpdated.push({
            ...device
          });
        }
      });

      const devicesFiltered = filterDevices(
        devicesWithDenominationUpdated,
        state.filter,
        state.gridMode,
        state.devicesOnGrid
      );

      return {
        ...state,
        devices: devicesWithDenominationUpdated,
        currentDevices: devicesFiltered.slice(
          (state.currentPage - 1) * state.pageSize,
          state.currentPage * state.pageSize
        )
      };
    case 'UPDATE_COMMAND_HISTORY':
      const updatedHistory = [];

      if (state.selectedEquipmentForHistory > 0) {
        const commandHistory = action.payload;
        const historyIdsToRemove = state.commandHistory
          .map(history => history.id)
          .filter(historyID => !commandHistory.some(history => history.id === historyID))
        ;
        const historyToKeep = state.commandHistory
          .filter(history => !historyIdsToRemove.includes(history.id))
        ;
        commandHistory.forEach((history) => {
          const existingHistoryIndex = historyToKeep.findIndex(
            (h) => h.id === history.id
          );
          if (existingHistoryIndex === -1) {
            // O comando não existe, então o adicionamos
            updatedHistory.push(history);
          } else {
            // O commando existe, então o atualizamos
            updatedHistory.push({
              ...historyToKeep[existingHistoryIndex],
              ...history,
            });
          }
        });
      }

      return {
        ...state,
        commandHistory: updatedHistory,
        waitingPopulate: false,
      };
    case 'SET_WAITING_POPULATE':
      return {
        ...state,
        waitingPopulate: action.payload
      };
    case 'SET_GRID_MODE':
      return {
        ...state,
        gridMode: action.payload
      };
    case 'ADD_DEVICES_TO_GRID':
      const devicesToAdd = action.payload;
      const devicesOnGrid = state.devicesOnGrid.concat(devicesToAdd);
      const newSetOfDevices = filterDevices(
        state.devices,
        state.filter,
        state.gridMode,
        devicesOnGrid
      );

      return {
        ...state,
        devicesOnGrid: devicesOnGrid,
        selectedDevice: null,
        currentDevices: newSetOfDevices.slice(
          0, state.pageSize
        ),
        totalOfPages: Math.ceil(newSetOfDevices.length / state.pageSize) || 1,
      };
    case 'DELETE_DEVICES_FROM_GRID':
      const devicesToDelete = action.payload === 'all'
        ? state.devicesOnGrid
        : action.payload
      ;
      const devicesOnGridAfterDelete = state.devicesOnGrid.filter(
        (device) => !devicesToDelete.includes(device)
      );
      const newClearedSetOfDevices = filterDevices(
        state.devices,
        state.filter,
        state.gridMode,
        devicesOnGridAfterDelete
      );
      const totalOfPages = Math.ceil(newClearedSetOfDevices.length / state.pageSize) || 1;
      const currentPage = (action.payload === 'all')
        ? 1
        : (state.currentPage > totalOfPages
            ? totalOfPages
            : state.currentPage)
      ;

      return {
        ...state,
        currentPage: currentPage,
        devicesOnGrid: devicesOnGridAfterDelete,
        selectedDevice: null,
        currentDevices: newClearedSetOfDevices.slice(
          (currentPage - 1) * state.pageSize,
          currentPage * state.pageSize
        ),
        totalOfPages: totalOfPages,
      };
    default:
      throw new Error(`Tipo de ação não tratada: ${action.type}`);
  }
}

const DeviceContext = createContext();

function DeviceProvider(props) {
  const [devicesState, dispatch] = useReducer(deviceReducer, initialState);

  const updateDevices = useCallback((devices) => {
    dispatch({ type: 'UPDATE_DEVICES', payload: devices });
  }, [dispatch]);
  const setDevicePage = useCallback((pageNumber) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: pageNumber });
  }, [dispatch]);
  const setDevicePageSize = useCallback((pageSize) => {
    dispatch({ type: 'SET_ITEMS_PER_PAGE', payload: pageSize });
  }, [dispatch]);
  const setDeviceFilter = useCallback((query) => {
    if (devicesState.filter !== query) {
      dispatch({ type: 'SET_FILTER', payload: query });
    }
  }, [dispatch, devicesState.filter]);
  const clearDeviceFilter = useCallback(() => {
    dispatch({ type: 'CLEAR_FILTER' });
  }, [dispatch]);
  const setSelectedDevice = useCallback((device) => {
    dispatch({ type: 'SET_SELECTED_DEVICE', payload: device });
  }, [dispatch]);
  const addDevicesToGrid = useCallback((devices) => {
    dispatch({ type: 'ADD_DEVICES_TO_GRID', payload: devices });
  }, [dispatch]);
  const deleteDeviceFromGrid = useCallback((deviceID) => {
    dispatch({ type: 'DELETE_DEVICES_FROM_GRID', payload: [ deviceID ] });
  }, [dispatch]);
  const clearDevicesFromGrid = useCallback(() => {
    dispatch({ type: 'DELETE_DEVICES_FROM_GRID', payload: 'all' });
  }, [dispatch]);
  const setGridMode = useCallback((mode) => {
    if (devicesState.gridMode !== mode) {
      dispatch({ type: 'SET_GRID_MODE', payload: mode });
    }
  }, [dispatch]);
  const setSelectedEquipmentForHistory = useCallback((device) => {
    dispatch({ type: 'SET_SELECTED_EQUIPMENT_FOR_HISTORY', payload: device });
  }, [dispatch]);
  const updateCommands = useCallback((commands) => {
    dispatch({ type: 'UPDATE_COMMANDS', payload: commands });
  }, [dispatch]);
  const updateCommandHistory = useCallback((history) => {
    dispatch({ type: 'UPDATE_COMMAND_HISTORY', payload: history});
  }, [dispatch]);
  const setWaitingPopulate = useCallback((state) => {
    dispatch({ type: 'SET_WAITING_POPULATE', payload: state});
  }, [dispatch]);
  const updateSymbols = useCallback((symbols) => {
    dispatch({ type: 'UPDATE_SYMBOLS', payload: symbols });
  }, [dispatch]);
  const updateVehicleDenomination = useCallback((vehicle) => {
    dispatch({ type: 'SET_IN_UPDATING', payload: true });
    dispatch({ type: 'UPDATE_DENOMINATION', payload: vehicle });
    dispatch({ type: 'SET_IN_UPDATING', payload: false });
  }, [dispatch]);

  // A instância do mapa
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);

  const onMapReferenced = useCallback((map) => {
    setMap(map);
  }, [setMap]);

  const value = {
    firstCall: devicesState.firstCall,
    devicesState,
    updateDevices,
    setDevicePage,
    setDevicePageSize,
    setDeviceFilter,
    clearDeviceFilter,
    setSelectedDevice,
    setSelectedEquipmentForHistory,
    addDevicesToGrid,
    deleteDeviceFromGrid,
    clearDevicesFromGrid,
    setGridMode,
    updateCommands,
    updateCommandHistory,
    updateSymbols,
    updateVehicleDenomination,
    map,
    onMapReferenced,
    markers,
    setMarkers,
    setWaitingPopulate
  };

  return <DeviceContext.Provider value={value} {...props} />;
}

function useDeviceContext() {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useDeviceContext deve ser utilizado dentro de um DeviceProvider');
  }
  
  return context;
}

export { DeviceProvider, useDeviceContext };