import { cancelCommand } from '../../services/CommandService';
import {ReactComponent as CancelIcon} from '../../assets/icons/cancel.svg';
import {ReactComponent as WaitingIcon} from '../../assets/icons/waiting.svg';
import {ReactComponent as ConfirmedIcon} from '../../assets/icons/confirmed.svg';
import {ReactComponent as WrongIcon} from '../../assets/icons/wrong.svg';

function handleCancelClick(data) {
  return async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await cancelCommand(
        data.id
      );
      console.log('Solicitação de cancelamento enviada', response);
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCommandHistoryColumns() {
  return {
    command: {
      name: "Comando",
      align: "left",
      render: (data) => {
        if (!data.id) {
          return (<span></span>);
        }

        // Renderiza o comando
        const label = data.name;
        const commandData = data.data ? data.data : '';
        const replacementValues = Array.isArray(data.values)
          ? data.values
          : Array.from(JSON.parse(data.values))
        ;
        const complement = replacementValues.length > 0
          ? replacementValues.filter(value => value !== null).filter(value => value !== '').join(' / ')
          : 'Sem dados'
        ;

        return (
          <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", paddingLeft: ".5em", gap: ".3em"}}>
            <span className="label">
              { label }
            </span>
            <span className="complement">
              { complement } { commandData !== complement ? '[ Comando: ' + commandData + ' ]' : '' }
              
            </span>
          </span>
        );
      }
    },
    requestedAt: {
      name: "Requisitado em",
      align: "center",
      width: 180,
      formatter: 'datetime'
    },
    requestedBy: {
      name: "Requisitado por",
      align: "center",
      width: 200
    },
    sentAt: {
      name: "Enviado em",
      align: "center",
      width: 180,
      render: (data) => {
        const dateTimeFormatter = (value) => {
          if (!value) {
            return '';
          }
          const date = new Date(value);
          return (
            date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
          );
        };
        
        if (data.sentAt) {
          // Renderiza a célula
          return (
            <span style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingBlock: ".5em", gap: ".3em"}}>
              <span className="label">
                { dateTimeFormatter(data.sentAt) }
              </span>
              <span className="complement">
                {data.attempts}º tentativa 
              </span>
            </span>
          );
        } else {
          return (<span></span>);
        }
      }
    },
    confirmedAt: {
      name: "Confirmado em",
      align: "center",
      width: 180,
      formatter: 'datetime'
    },
    response: {
      name: "Resposta",
      hidden: true,
    },
    status: {
      name: "Situação",
      align: "left",
      width: 280,
      render: (data) => {
        const dateTimeFormatter = (value) => {
          if (!value) {
            return '';
          }
          const date = new Date(value);
          return (
            date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
          );
        };
        
        if (data.id) {
          if (data.noReply) {
            return (
              <span style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "1em", gap: "1em"}}>
                <WrongIcon title="Sem resposta..." width={'1.5rem'} fill={'var(--theme-danger)'} />
                Sem resposta do dispositivo
              </span>
            );
          }

          // Renderiza os botões de ação
          if (data.canceledByUser || data.canceledByTimeout) {
            return (
              <span>
                Cancelado
                { data.canceledByUser ? ' pelo usuário ' + data.canceledByUserName + ' ': '' }
                { data.canceledByTimeout ? ' em função de tempo esgotado ' : '' }
                em { dateTimeFormatter(data.canceledAt) }
              </span>
             );
          } else {
            if (data.sentAt) {
              if (data.confirmedAt) {
                return (
                  <span style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "1em", gap: "1em"}}>
                    <ConfirmedIcon width={'1.5rem'} title="Enviado com sucesso..." fill={'#00a000'} />
                    Confirmado com sucesso
                  </span>
                )
              }
  
              return (
                <span style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "1em", gap: "1em"}}>
                  <WaitingIcon title="Aguardando uma resposta..." width={'1.5rem'} fill={'var(--theme-yellow)'} />
                  Aguardando uma resposta...
                </span>
              );
            }
          }

          return (
            <span style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "1em", gap: "1em"}}>
              <button type="button" title="Cancelar envio..." onClick={handleCancelClick(data)}>
                <CancelIcon width={'1.5rem'} fill={'#a00000'} />
              </button>
              Aguardando o envio...
            </span>
          );
        } else {
          return (<span></span>);
        }
      }
    },
  };
}

export function getCommandHistoryRowColor(data) {
  if (!data) {
    return '';
  }
  if (data.status.canceledByUser || data.status.canceledByTimeout || data.status.noReply) {
    return 'error';
  }
  if (!data.status.sentAt) {
    return 'waiting';
  }
  if (!data.status.confirmedAt) {
    return 'warning';
  }

  return '';
}

export function getEmptyCommandHistoryRegister() {
  return [{
    id: null,
    command: {
      id: null,
      name: '',
      values: '[]'
    },
    requestedAt: '',
    requestedBy: '',
    sentAt: null,
    confirmedAt: null,
    response: '',
    status: {
      id: null,
      sentAt: null,
      confirmedAt: null,
      canceledAt: null,
      canceledByUser: false,
      canceledByTimeout: false,
      canceledBy: null
    }
  }];
}

export function getBuiltCommands (commandForThisModel) {
  return commandForThisModel.reduce((acc, cur) => {
    const existingGroup = acc.find(group => group.label === cur.commandgroupname);
    const fields = cur.fields.map(field => ({
      name: field.name,
      type: field.commandValueTypeName.toLowerCase(),
      description: field.description,
      range: field.range,
      options: field.options,
      defaults: field.defaults,
      placeholder: field.placeholder,
      size: field.size,
      needsConversion: field.needsConversion,
      isSizeOfPosition: field.isSizeOfPosition,
      required: field.required
    }));
    if (existingGroup) {
      existingGroup.options.push({
        value: cur.id,
        protocol: cur.protocolname,
        variant: cur.protocolvariantname,
        label: cur.name,
        description: cur.description,
        formatString: cur.formatstring,
        fields: fields
      });
    } else {
      acc.push({
        label: cur.commandgroupname,
        options: [{
          value: cur.id,
          protocol: cur.protocolname,
          variant: cur.protocolvariantname,
          label: cur.name,
          description: cur.description,
          formatString: cur.formatstring,
          fields: fields
        }]
      });
    }
    return acc;
  }, []);
}
