import React, {
  forwardRef
} from "react";
import { Marker, Popup } from "react-leaflet";
import shortid from "shortid";

import PlateTag from "./PlateTag";
import MarkerIcon from "./MarkerIcon";
import DeviceInfo from "./DeviceInfo";

import "../../styles/MapPopup.scss";

function MapMarker({
  id,
  device
}, ref) {
  const title = (device.vehicle)
    ? device.vehicle.plate
    : device.equipment.serialNumber
  ;

  return (
    <Marker
      customID={shortid.generate()}
      id={id}
      ref={ref}
      position={[device.latitude, device.longitude]}
      icon={MarkerIcon(
        device.vehicle?.type.id,
        device.vehicle?.type.symbol,
        device.ignition
      )}
      title={title}
    >
      <PlateTag
        key={`plate-${device.id}`}
        course={device.course}
        equipment={device.equipment}
        label={
          (device.vehicle !== undefined)
            ? (device.vehicle.denomination ? device.vehicle.denomination : device.vehicle.plate)
            : device.equipment.serialNumber
        }
      />
      <Popup
        key={`popup-${device.id}`}
        className="themed-popup"
        maxWidth={320}
        maxHeight={"auto"}
      >
        <DeviceInfo device={device} />
      </Popup>
    </Marker>
  );
}

export default forwardRef(MapMarker);
