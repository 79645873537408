import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import SidePanel from '../SidePanel';
import SearchInput from '../../components/SearchInput';
import VirtualizedList from '../../components/VirtualizedList';
import LoadingOverlay from '../../components/LoadingOverlay';
import IdentifierForm from '../../forms/IdentifierForm';
import { useIdentifierContext } from '../../features/IdentifierContext';
import * as IdentifierService from '../../services/IdentifierService';
import { set } from 'date-fns';

const IButtonsPanel = ({
  selectedCustomer,
  selectedCustomerName,
  drivers,
  vehicles,
  user,
  updateNow,
  isOpen,
  onClose
}) => {
  const {
    identifiers,
    addIdentifier,
    updateIdentifier,
    removeIdentifier
  } = useIdentifierContext();

  const [searchValue, setSearchValue] = useState('');

  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(true);
  
  const [editingIdentifier, setEditingIdentifier] = useState(null);
  const [visibleIdentifiers, setVisibleIdentifiers] = useState([]);
  const [forceSelect, setForceSelect] = useState(null);
  const getIdentifierList = useCallback((current) => {
    // Retorna uma lista de identificadores, exceto o identificador
    // atual
    const filteredIdentifiers = identifiers
      .filter((identifier) => identifier.identifier !== current)
      .map((identifier) => {
        return identifier.identifier
          .trim()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        ;
      })
    ;

    return filteredIdentifiers;
  }, [identifiers]);

  const getDriverList = useCallback((currentID) => {
    // Filtra os itens que não estão em use e não seja o item atual
    const filtedData = drivers
      .filter(item => item.driver.id === currentID || (item.identifier.id === undefined))
    ;

    const filteredDrivers = filtedData.map((driver) => {
      return {
        value: driver.driver.id,
        label: driver.driver.name
      };
    });

    return filteredDrivers;
  }, [drivers]);

  const handleAddIdentifier = () => {
    console.log('handleAddIdentifier');
    const newIdentifier = {
      id: 0,
      customerid: selectedCustomer,
      identifiertechnologyid: 1,
      identifiertechnologyname: 'iButton',
      identifier: '',
      driverid: null,
      drivername: '',
      active: true
    };
    setEditingIdentifier(newIdentifier);
  }

  const handleSelectIdentifier = (identifier) => {
    setEditingIdentifier(identifier);
  };

  const handleUnselectIdentifier = () => {
    setEditingIdentifier(null);
  };

  const handleIdentifierUpdated = async (identifierData) => {
    // Fazemos algumas verificações antes de enviar os dados para a API

    // Aqui armazenamos uma cópia dos dados de equipamentos para poder
    // restaurá-los após a edição
    const storedata = identifierData.storedata;
    
    // Se não ouver um motorista associado, não teremos equipamentos
    // associados, pois o identificador (iButton) precisa ser enviado
    // para o equipamento justamente para identificá-lo
    if (identifierData.driverid) {
      // A API lida com os equipamentos onde o identificador está
      // associado usando duas listas, uma com os novos itens e outra
      // com os itens removidos. Precisamos preparar os dados para a API
      if (storedata) {
        if (identifierData.id === 0) {
          // Todos os equipamentos são novos
          identifierData.storedata = [
            storedata,
            []
          ];
        } else {
          // Criamos duas listas, uma para os novos equipamentos e outra
          // para os equipamentos que foram removidos
          
          // Recuperamos os dados atuais do identificador
          const currentIdentifier = identifiers.find((item) => item.id === identifierData.id);
          if (currentIdentifier) {
            const currentStoreData = currentIdentifier.storedata
              ? currentIdentifier.storedata
              : []
            ;
            const newItens = storedata.filter((item) => {
              return !currentStoreData.some((currentItem) => {
                return currentItem.vehicleid === item.vehicleid;
              });
            });
            const removedItens = currentStoreData.filter((item) => {
              return !storedata.some((currentItem) => {
                return currentItem.vehicleid === item.vehicleid;
              });
            })
            .map((item) => item.id);
            identifierData.storedata = [
              newItens,
              removedItens
            ];
          }
        }
      }
    } else {
      // Se não há motorista associado, não há equipamentos associados
      identifierData.storedata = [
        [],[]
      ];
    }

    // Fazemos a requisição para adicionar/modificar o identificador
    const result = (identifierData.id === 0)
      ? await IdentifierService.addIdentifier(identifierData)
      : await IdentifierService.updateIdentifier(identifierData)
    ;
    if (result) {
      // Restauramos os dados de equipamentos
      identifierData.storedata = identifierData.driverid
        ? storedata
        : []
      ;

      if (identifierData.id === 0) {
        // Atualizamos o ID do identificador
        identifierData.id = parseInt(result);

        // Adicionamos ele a lista de identificadores
        addIdentifier(identifierData);
        console.log('Identifier added', identifierData);
      } else {
        // Atualizamos o identificador na lista
        updateIdentifier(identifierData);
        console.log('Modified identifier', identifierData);
      }

      console.log('End identifier editing');
      updateNow();
      setEditingIdentifier(null);
    }
  };

  const handleDeleteIdentifier = async (identifier) => {
    console.log('handleDeleteIdentifier', identifier);
    const result = await IdentifierService.removeIdentifier(identifier.id);
    if (result) {
      removeIdentifier(identifier.id);
      console.log('Identifier removed', identifier);
      setEditingIdentifier(null);
    }
  };
  
  useEffect(() => {
    const allIdentifiers = identifiers
      .slice()
    ;
  
    if (searchValue) {
      // Limpa a string de pesquisa, removendo acentos e caracteres
      // especiais
      const search = searchValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      ;
      
      const filteredIdentifiers = allIdentifiers.filter((identifier) => {
        // Verifica se o ID da cerca é igual ao ID da que está em edição
        if (identifier.id === editingIdentifier?.id) {
          return true;
        }

        // Normaliza e remove acentuação
        const normalizedIdentifier = identifier.identifier
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        ;
        const normalizedDriverName = identifier.drivername
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        ;

        // Verifica se o valor de pesquisa está presente em qualquer um
        // dos campos
        const matchesSearchValue = normalizedIdentifier.includes(search) || normalizedDriverName.includes(search);

        return matchesSearchValue;
      });

      if (editingIdentifier) {
        if (editingIdentifier.id === 0) {
          // Adiciona também o novo identificador em edição
          console.log('Adicionando identificador em edição', editingIdentifier);
          filteredIdentifiers.push(editingIdentifier);
        }
      }

      setVisibleIdentifiers(filteredIdentifiers);
    } else {
      if (editingIdentifier) {
        if (editingIdentifier.id === 0) {
          // Adiciona também a nova cerca em edição
          allIdentifiers.push(editingIdentifier);
        }
      }
  
      setVisibleIdentifiers(allIdentifiers);
    }
  }, [searchValue, identifiers, editingIdentifier]);

  const handleClose = useCallback(() => {
    setEditingIdentifier(null);
    setForceSelect(-1);
    onClose();
  }, [onClose]);

  useEffect(() => {
    console.log('Editing identifier changed', editingIdentifier);
    if (editingIdentifier) {
      console.log('Set force select', editingIdentifier.id);
      setForceSelect(editingIdentifier.id);
    } else {
      console.log('Set force select to null');
      setForceSelect(null);
    }
  }, [editingIdentifier]); 

  return (
    <SidePanel
      side="left"
      title="iButtons"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <p className='text-muted'>
        Gerencie seus iButtons, permitindo cadastrar novos, visualizar
        qual motorista está usando, associar um iButton a um motorista
        e informar em quais veículos o iButton está habilitado.
      </p>
      <div style={styles.toolbar}>
        <SearchInput
          value={searchValue}
          placeholder="Localize um iButton..."
          onChange={setSearchValue}
          disabled={false}
        />
        <button
          style={{
            ...styles.button,
            backgroundColor: editingIdentifier !== null
              ? '#665d1e'
              : 'var(--theme-primary)',
            opacity: editingIdentifier !== null ? 0.5 : 1
          }}
          onClick={handleAddIdentifier}
          disabled={editingIdentifier !== null}
          title="Adicionar um novo iButton"
        >
          <svg
            width=".9rem"
            height=".9rem"
            viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" fill="currentColor" />
          </svg>
        </button>
      </div>
      <div style={styles.wrapper}>
        <VirtualizedList
          label="iButton"
          article="o"
          fieldName="identifier"
          renderName={(data) => {
            return (data.driverid)
              ? (<><span>{data.identifier}</span> <span style={{fontWeight: 'bolder', fontStyle: 'italic'}}>(Em uso)</span></>)
              : (<><span>{data.identifier}</span> <span style={{fontStyle: 'italic', opacity: .7}}>(Disponível)</span></>)
            ;
          }}
          items={visibleIdentifiers}
          onSelect={handleSelectIdentifier}
          onDelete={handleDeleteIdentifier}
          forceSelect={forceSelect}
          onUnselect={handleUnselectIdentifier}
          renderItem={(identifier, hideItem) => {
            return (
              <div style={{ margin: '0 .5em' }}>
                <IdentifierForm
                  identifier={identifier}
                  customerName={selectedCustomerName}
                  getIdentifiers={() => getIdentifierList(identifier.identifier)}
                  getDrivers={() => getDriverList(identifier.driverid)}
                  customerVehicles={vehicles}
                  onSubmit={(identifier) => {
                    console.log('Identifier is submitting');
                    handleIdentifierUpdated(identifier);
                    hideItem();
                  }}
                  onCancel={() => {
                    console.log('Identifier is canceling');
                    hideItem();
                    setEditingIdentifier(null);
                  }}
                />
              </div>
            );
          }}
          noContentMessage="Nenhum iButton encontrado"
        />
      </div>
    </SidePanel>
  );
};

const styles = {
  toolbar: {
    flexGrow: 0,
    display: 'flex',
    with: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '.5rem',
    gap: '.5rem',
  },
  button: {
    display: 'inline-flex',
    padding: '.58571429em .58571429em .58571429em',
    border: 'none',
    borderRadius: '.475rem',
    cursor: 'pointer',
    fontSize: '.9rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    gap: '.5rem',
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rounded: '.475rem',
    border: '2px solid var(--theme-border-color)',
    backgroundColor: 'var(--theme-modal-bg)',
    borderRadius: '.5rem',
    with: '100%',
    height: '100%',
  },
};

export default IButtonsPanel;