import React, {
  forwardRef,
  useState
} from "react";

import SquareButton from '../SquareButton';
import IconBox from './IconBox';
import OutsideClickHandler from "../OutsideClickHandler";

const IconComboBox = forwardRef(({
  name,
  value,
  icons,
  disabled,
  onChange
}, ref) => {
  const [selectedIcon, setSelectedIcon] = useState(value);
  const [icon, setIcon] = useState(icons[value]);
  const [showIcons, setShowIcons] = useState(false);

  // Definimos um objeto de contexto com os estados necessários
  const context = { 
    showIcons,
    setShowIcons
  };

  const toggleShowIcons = () => setShowIcons(!showIcons);

  const handleOutsideClick = () => context.setShowIcons(false);

  const handleIconSelected = (newIconID) => {
    setSelectedIcon(newIconID);
    setIcon(icons[newIconID]);
    setShowIcons(false);
    if (onChange) {
      onChange(newIconID);
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%'
      }}
    >
      <div style={styles.iconSelector}>
        <SquareButton
          ref={ref}
          name={name}
          onClick={toggleShowIcons}
          disabled={disabled}
          color="#040c11"
          size="1.9rem"
        >
          <div className="square-icon" style={styles.icon} dangerouslySetInnerHTML={{ __html: icon}} />
        </SquareButton>
        {showIcons && !disabled &&
          <div style={styles.iconBox}>
            <IconBox value={selectedIcon} columns={11} icons={icons} onChange={handleIconSelected} />
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
});

const styles = {
  iconSelector: {
    position: 'relative',
  },
  iconBox: {
    position: 'absolute',
    right: 0,
    top: '2rem',
    width: 'auto',
    zIndex: 1000,
    backgroundColor: 'var(--theme-dropdown-bg)',
    padding: '3px',
    borderRadius: '.475rem',
    border: '1px solid var(--theme-input-border-color)',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
  },
  icon: {
    padding: '.2rem',
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default IconComboBox;
