import React, {
  useCallback,
  useEffect
} from 'react';
import {
  useFieldArray,
  useFormContext,
  Controller
} from 'react-hook-form';

import Inline from './Inline';
import Group from './Group';
import PhoneControl from './PhoneControl';
import Button from './Button';

const Phones = ({
  name,
  phoneTypes
}) => {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: name,
  });

  useEffect(() => {
    // Adiciona um campo de telefone inicial se a lista estiver vazia
    if (fields.length === 0) {
      if (phoneTypes.length === 0) {
        append({ driverphoneid: 0, phonenumber: '', phonetypeid: null });
      } else {
        append({ driverphoneid: 0, phonenumber: '', phonetypeid: phoneTypes[0].value });
      }
    }
  }, [fields, append, phoneTypes]);

  const addPhone = useCallback(() => {
    if (phoneTypes.length === 0) {
      append({ driverphoneid: 0, phonenumber: '', phonetypeid: null });
    } else {
      append({ driverphoneid: 0, phonenumber: '', phonetypeid: phoneTypes[0].value });
    }
  }, [append, phoneTypes]);

  const removePhone = useCallback((index) => {
    // Impede a remoção se houver apenas um campo restante
    if (fields.length > 1) {
      remove(index);
    }
  }, [fields, remove]);

  return (
    <>
      {fields.map((field, index) => (
        <Inline
          style={styles.item}
          key={field.id}
        >
          <Controller
            name={`${name}[${index}].driverphoneid`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                type="hidden"
                {...field}
                value={field.value}
                onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
              />
            )}
          />
          <Group flex={3}>
            <PhoneControl
              type="text"
              name={`${name}[${index}].phonenumber`}
              placeholder="Telefone"
            />
          </Group>
          <Group flex={2}>
            <Controller
              name={`${name}[${index}].phonetypeid`}
              control={control}
              defaultValue={field.phonetypeid}
              render={({ field: { onChange, value, ref } }) => (
                <select
                  onChange={(e) => onChange(Number(e.target.value))}
                  value={value}
                  ref={ref}
                >
                  {phoneTypes.map((phoneType) => (
                    <option key={phoneType.value} value={phoneType.value}>
                      {phoneType.label}
                    </option>
                  ))}
                </select>
              )}
            />
          </Group>
          <Group flex={0}>
            <button style={styles.button} onClick={() => removePhone(index)}>
              <svg
                width="1rem"
                height="1rem"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 6h18"/>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                <line x1="10" x2="10" y1="11" y2="17"/>
                <line x1="14" x2="14" y1="11" y2="17"/>
              </svg>
            </button>
          </Group>
          <Group flex={4}>
            <span></span>
          </Group>
        </Inline>
      ))}
      <Button
        small
        color="green"
        onClick={addPhone}
        style={{ maxWidth: '10em' }}
      >
        Adicionar
      </Button>
    </>
  );
};

const styles = {
  item: {
    gap: '.5rem',
  },
  button: {
    marginTop: -4,
    display: 'flex',
    padding: '.3rem .35rem .4rem .35rem',
    border: "none",
    borderRadius: ".475rem",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#db2828"
  }
};
export default Phones;