export function formatMeasurement(value, unity, decimals = 0) {
  const formatedValue = value.toLocaleString('pt-br', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  return (
    <>
      {formatedValue}<span className="symbol">{unity}</span>
    </>
  );
}

export function formatMeasurementAsText(value, unity, decimals = 0) {
  const formatedValue = value.toLocaleString('pt-br', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  return formatedValue + unity;
}

export function getPosition(latitude, longitude) {
  return latitude.toFixed(6) + ', ' + longitude.toFixed(6);
};
