import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import L from "leaflet";
import {
  Circle,
  Marker,
  Popup,
  Tooltip,
  useMapEvents
} from 'react-leaflet';

import anchor from '../../assets/icons/map/anchor.svg';

const anchorIcon = L.icon({
  iconUrl: anchor,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

const EditableCircle = ({
  id,
  name,
  note,
  center,
  radius,
  color = '#3388ff',
  onCenterChange,
  onRadiusChange,
  onEditFence,
  editable = false,
  hidden = false,
  underEditing = false
}) => {
  const circleRef = useRef();
  const [circleName, setCircleName] = useState(name);
  const [circleCenter, setCircleCenter] = useState(center);
  const [circleRadius, setCircleRadius] = useState(radius);
  const [angle, setAngle] = useState(0);

  const [editMode, setEditMode] = useState(underEditing);
  const [isDragging, setIsDragging] = useState(false);
  const [anchorPoints, setAnchorPoints] = useState([]);

  useMapEvents({
    click: () => {
      // Lida com cliques fora do círculo
      if (editMode && !underEditing) {
        setEditMode(false);
      }
    },
  });

  // Calcula os pontos de ancoragem do círculo
  const getAnchorPoints = useCallback((
    centerPosition,
    size,
    angleOffset = 0
  ) => {
    //console.debug('Handle getAnchorPoints', centerPosition, size, angleOffset);
    // O raio da Terra em metros
    const EARTH_RADIUS = 6371e3;
    // A distância angular entre o centro e os pontos de ancoragem
    const ANGULAR_DISTANCE = size / EARTH_RADIUS;
    // Os ângulos padrão dos pontos de ancoragem
    const ANGLES = [0, 90, 180, 270];
    const anchorPoints = [];
  
    for (let i = 0; i < ANGLES.length; i++) {
      // Calculamos a posição do ponto de ancoragem levando em
      // considerando os ângulos e a distância angular
      const angle = ANGLES[i] + angleOffset;
      const lat1 = (Math.PI / 180) * centerPosition[0];
      const lon1 = (Math.PI / 180) * centerPosition[1];
      const bearing = (Math.PI / 180) * angle;
      const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(ANGULAR_DISTANCE) +
          Math.cos(lat1) * Math.sin(ANGULAR_DISTANCE) * Math.cos(bearing)
      );
      const lon2 =
        lon1 +
        Math.atan2(
          Math.sin(bearing) * Math.sin(ANGULAR_DISTANCE) * Math.cos(lat1),
          Math.cos(ANGULAR_DISTANCE) - Math.sin(lat1) * Math.sin(lat2)
        );
  
      anchorPoints.push([lat2 * (180 / Math.PI), lon2 * (180 / Math.PI)]);
    }
  
    return anchorPoints;
  }, []);

  useEffect(() => {
    //console.debug('Center or radius changed');
    //console.debug('new center', center, 'new radius', radius);
    setAngle(0);
    setCircleCenter(center);
    setCircleRadius(radius);
    setAnchorPoints(
      getAnchorPoints(center, radius, 0)
    );
  }, [center, radius, getAnchorPoints]);

  useEffect(() => {
    if (underEditing && !editMode) {
      setAngle(0);
      setAnchorPoints(getAnchorPoints(circleCenter, circleRadius, 0));
      setEditMode(underEditing);
    }
  }, [underEditing]);

  useEffect(() => {
    setCircleName(name);
  }, [name]);

  const onCircleClick = (event) => {
    console.debug('onCircleClick');
    event.originalEvent.stopPropagation();

    if (!editable) {
      // Não faz nada se o círculo não for editável
      return;
    }

    if (underEditing) {
      // Não faz nada se o círculo já estiver em edição
      return;
    }

    if (isDragging) {
      // Não faz nada se o usuário estiver arrastando o círculo
      return;
    }

    if (!editMode) {
      setAngle(0);
      setAnchorPoints(getAnchorPoints(circleCenter, circleRadius, 0));
    }
    if (onEditFence) {
      console.log('Trigger onEditFence', id);
      onEditFence(id);
    } else {
      console.debug('Toggle edit mode');
      setEditMode((editMode) => !editMode);
    }
  }

  // Calcula o ângulo entre dois pontos
  const getAngle = useCallback((centerPosition, point) => {
    //console.debug('Handle getAngle', centerPosition, point);
    const dy = point[1] - centerPosition[1];
    const dx = point[0] - centerPosition[0];
    let theta = Math.atan2(dy, dx) * (180 / Math.PI);
  
    // Ajusta o ângulo para que 0 graus esteja apontando para cima e os
    // ângulos sejam positivos no sentido horário
    theta = theta - 90;
    if (theta < 0) {
      theta = 360 + theta;
    }
  
    return theta;
  }, []);

  // Calcula a distância entre dois pontos usando a fórmula de Haversine
  function haversineDistance(lat1, lon1, lat2, lon2) {
    //console.debug('Handle haversineDistance', lat1, lon1, lat2, lon2);
    const R = 6371e3; // meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
  
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    return R * c;
  }

  useEffect(() => {
    //console.debug('EditableCircle mounted');
    if (editMode) {
      setAnchorPoints(getAnchorPoints(circleCenter, circleRadius, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Inicia o processo de arrastar o marcador para definir o tamanho do
  // círculo
  const handleAnchorDragStart = (event) => {
    //console.debug('Handle handleAnchorDragStart');
    setIsDragging(true);
  };

  // Lida com o arraste do marcador de tamanho do círculo
  const handleAnchorOnDragging = (index, event) => {
    //console.debug('Handle handleAnchorOnDragging', index, event);
    // Determinamos a nova distância entre o centro e o marcador
    const { latlng } = event;
    const distance = Math.trunc(
      haversineDistance(
        circleCenter[0],
        circleCenter[1],
        latlng.lat,
        latlng.lng
      )
    );
    setCircleRadius(distance);
    const anchorCenter = [ latlng.lat, latlng.lng ];
    const newAngle = getAngle(circleCenter, anchorCenter);
    setAngle(newAngle);
    let newAnchorPoints = getAnchorPoints(circleCenter, distance, newAngle);
    newAnchorPoints[index] = [latlng.lat, latlng.lng];
    setAnchorPoints(newAnchorPoints);
  };

  // Finaliza o processo de arrastar o marcador de tamanho do círculo
  const handleAnchorDragEnd = (event) => {
    //console.debug('Handle handleAnchorDragEnd', event);
    setIsDragging(false);
    setAnchorPoints(getAnchorPoints(circleCenter, circleRadius, angle));
    if (onRadiusChange) {
      onRadiusChange(id, circleRadius);
    }
  };

  // Inicia o processo de arrastar o centro do círculo
  const handleCircleDragStart = (event) => {
    //console.debug('Handle handleCircleDragStart', event);
    event.originalEvent.stopPropagation();
    setIsDragging(true);
  };

  // Lida com o arraste do centro do círculo
  const handleCircleOnDragging = (event) => {
    //console.debug('Handle handleCircleOnDragging', event);
    event.originalEvent.stopPropagation();
    const { latlng } = event;
    const newPosition = [latlng.lat, latlng.lng];
    setCircleCenter(newPosition);
    setAnchorPoints(getAnchorPoints(newPosition, circleRadius, angle));
  };

  // Finaliza o processo de arrastar o centro do círculo
  const handleCircleDragEnd = (event) => {
    //console.debug('Handle handleCircleDragEnd', event);
    setIsDragging(false);
    setAnchorPoints(getAnchorPoints(circleCenter, circleRadius, angle));
    if (onCenterChange) {
      onCenterChange(id, circleCenter);
    }
  };

  // Formata a distância para exibição
  const formatDistance = useCallback( (value) => {
    //console.debug('Handle formatDistance', value);
    // Exibimos o valor em metros ou km arredondado para 2 casas depois
    // da virgula
    if (value > 1000) {
      return Math.round(value, 2) + ' km';
    }

    return Math.round(value, 2) + ' metros';
  }, []);

  if (circleCenter === undefined ||
      circleRadius === undefined ||
      circleCenter.length !== 2 ||
      circleRadius <= 0) {
    console.debug('Invalid circle center or radius');
    return null;
  }

  return (
    <>
      <Circle
        ref={circleRef}
        center={circleCenter}
        radius={circleRadius}
        pathOptions={{
          color: editMode ? '#665d1e': color,
          fillColor: editMode ? '#665d1e': color,
          fillOpacity: 0.3,
        }}
        eventHandlers={{
          click: onCircleClick,
        }}
      >
        <Popup>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {circleName}
            </span>
            {note && (
            <span
              style={{
                color: '#808080',
                fontSize: '0.8em',
                fontStyle: 'italic',
              }}
            >
              {note}
            </span>
            )}
          </div>
        </Popup>
        <Tooltip
          direction="bottom"
          offset={[0, 16]}
          opacity={1}
        >
          {circleName}
        </Tooltip>
      </Circle>
      {editMode && anchorPoints.map((anchorPoint, index) => (
        <Marker
          key={index}
          position={anchorPoint}
          icon={anchorIcon}
          eventHandlers={{
            mousedown: handleAnchorDragStart,
            mouseup: handleAnchorDragEnd,
            drag: (event) => handleAnchorOnDragging(index, event),
          }}
          draggable
        >
          <Tooltip
            direction="top"
            offset={[0, -8]}
            opacity={1}
          >
            {formatDistance(circleRadius)}
          </Tooltip>
        </Marker>
      ))}
      {editMode && (
        <Marker
          position={circleCenter}
          icon={anchorIcon}
          eventHandlers={{
            mousedown: handleCircleDragStart,
            mouseup: handleCircleDragEnd,
            drag: handleCircleOnDragging,
          }}
          draggable
        >
          <Tooltip
            direction="top"
            offset={[0, -8]}
            opacity={1}
          >
            {circleCenter[0].toFixed(6)}, {circleCenter[1].toFixed(6)}
          </Tooltip>
        </Marker>
      )}
    </>
  );
}

export default EditableCircle;
