import Api from './Api';

/**
 * Recupera os eventos não tratados.
 * 
 * @param {int} pageNumber 
 *   O número da página sendo requisitada
 * @param {int} pageSize
 *   O tamanho da página
 * @param {string} lastRequisitionTime 
 *   O horário da última requisição
 * 
 * @returns
 *   Os dados da requisição dos eventos não tratados
 */
const getUntreatedEvents = async (
  pageNumber,
  pageSize,
  lastRequisitionTime
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: pageSize,
    ...(lastRequisitionTime && {mostRecentThan: lastRequisitionTime})
  };
  
  return Api
    .get("/event/untreated", { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Trata um evento, permitindo atualizar os comentários e marcar o mesmo
 * como lido.
 * 
 * @param {int} eventID
 *   O número de identificação do evento
 * @param {string}[] comments
 *   Os comentários associados ao evento
 * @param {bool} treated
 *   O indicativo se o evento foi considerado tratado (opcional)
 * 
 * @returns
 *   Os dados da resposta à requisição do tratamento do evento
 */
const treatEvent = async (
  eventID,
  comments,
  treated = null
) => {
  const events = [{
    id: eventID,
    comments: comments,
    ...(treated && {treated: treated})
  }];
  
  return Api
    .put("/event/treats", { events: events })
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Marca eventos como lido.
 * 
 * @param {array} eventList
 *   A relação de eventos a serem considerados como lidos
 * 
 * @returns
 *   Os dados da resposta à requisição do tratamento do evento
 */
const treatEvents = async (
  eventList
) => {
  return Api
    .put("/event/treats", { events: eventList })
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Recupera os últimos eventos do veículo do usuário.
 * 
 * @param {int} pageNumber 
 *   O número da página sendo requisitada
 * @param {int} pageSize
 *   A quantidade de itens por página
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * 
 * @returns
 *   Os dados da requisição do histórico de eventos
 */
const getEventsHistory = async (
  pageNumber,
  pageSize,
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: pageSize
  };
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;
  
  return Api
    .get(`/event/chronology/${uriData}`, { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};


/**
 * Recupera o histórico de quantidade de eventos de excesso de
 * velocidade do veículo do usuário.
 * 
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * 
 * @returns
 *   Os dados da requisição do histórico de posições
 */
const getAmountOfSpeeding = async (
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2
) => {
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;

  return Api
    .get(`/event/speeding/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getUntreatedEvents,
  treatEvent,
  treatEvents,
  getEventsHistory,
  getAmountOfSpeeding
};