import React, {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

const DecimalControl = ({
  name,
  options = {},
  size = 10,
  decimals = 2,
  ...props
}) => {
  const {
    control,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  const parseValue = (value, fault = null) => {
    if (value === null || value === undefined) {
      return fault;
    }

    if (typeof value === 'string' || value instanceof String) {
      // Limpa quaisquer caracteres que não sejam números, sinal de
      // menos, ponto e virgula
      value = value.replace(/[^\d,-]/g, '');

      if (value === '') {
        return fault;
      }

      return parseFloat(value.replace(',', '.'));
    }

    if (isNaN(value)) {
      return fault;
    }

    if (typeof value === 'number') {
      return value;
    }

    return fault;
  }


  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues === undefined) {
      return null;
    }

    if (defaultValues[name] === undefined) {
      return null;
    }

    return parseValue(defaultValues[name]);
  });

  useEffect(() => {
    if (defaultValues === undefined) {
      setDefaultValue(null);
      return;
    }

    if (defaultValues[name] === undefined) {
      setDefaultValue(null);
      return;
    }

    setDefaultValue(
      parseValue(defaultValues[name])
    );
  }, [defaultValues, name]);

  const {
    field,
    fieldState: { invalid, isTouched, isDirty }
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options },
  });

  const [floatValue, setFloatValue] = useState(parseValue(field.value));

  const [textValue, setTextValue] = useState(() => {
    const value = parseValue(field.value);

    if (value) {
      return value.toFixed(decimals).replace('.', ',');
    }

    return '';
  });

  const [changed, setChanged] = useState(false);

  const renderValue = (value) => {
    if (value === null) {
      setTextValue('');

      return;
    }

    setTextValue(value.toFixed(decimals).replace('.', ','));
  };

  useEffect(() => {
    console.log('field.value changed', field.value);
    
    if (changed) {
      console.log('internal change, ignore');
      setChanged(false);
      
      return;
    }

    const value = parseValue(field.value);

    setFloatValue(value);
    renderValue(value);
  }, [field.value]);


  const handleOnInput = (event) => {
    const { value: inputValue, selectionStart, selectionEnd } = event.target;
    let cursorStart = selectionStart;

    let newValue = inputValue;

    // Primeiro removemos todos os caracteres que não sejam números ou
    // vírgula ou o sinal de menos
    newValue = newValue.replace(/[^\d,-]/g, '');
    if (newValue !== inputValue) {
      // Se o valor foi alterado, então ajustamos a posição do cursor
      cursorStart = cursorStart - (inputValue.length - newValue.length);
    }

    if (newValue === '') {
      // Se o valor for vazio, então deixamos o valor como nulo
      setFloatValue(null);
      setTextValue('');
      setChanged(true);
      field.onChange(null);

      return;
    }

    // Verifica se temos mais de uma vírgula
    const commaCount = (newValue.match(/,/g) || []).length;
    if (commaCount > 1) {
      const commaPosition = newValue.indexOf(',');
      // Removemos todas as vírgulas, exceto a primeira
      let index = 0;
      newValue = newValue.replace(/,/g, (item) => (!index++ ? item : ""));
      // Ajusta a posição do cursor, se necessário
      console.log(cursorStart, '>', commaPosition);
      if (cursorStart > (commaPosition + 1)) {
        cursorStart = cursorStart - (commaCount - 1);
      }
    }

    // Verifica se temos o sinal de menos e o remove
    const hasMinus = newValue.startsWith('-');
    const minusCount = (newValue.match(/-/g) || []).length;
    newValue = newValue.replace(/-/g, '');
    if (minusCount > 1) {
      // Ajusta a posição do cursor
      cursorStart = cursorStart - (minusCount - 1);
    }
    if (hasMinus && (newValue === '')) {
      // Se o valor for vazio, então deixamos o valor como 0
      newValue = '0';

      // Ajusta a posição do cursor
      cursorStart = 2;
    }

    // Verifica se o valor começa com uma vírgula
    if (newValue.startsWith(',')) {
      // Se o valor começa com a vírgula, então acrescentamos um zero
      newValue = '0' + newValue;
      cursorStart = hasMinus
        ? cursorStart === 1 ? 2 : cursorStart
        : cursorStart === 0 ? 1 : cursorStart
      ;
    }

    // Verifica se a virgula foi removida
    const commaPosition = newValue.indexOf(',');
    if (commaPosition === -1) {
      if (textValue.includes(',')) {
        const oldValue = textValue.replace(',', '').replace('-', '');
        console.log(oldValue, '=', newValue);
        if (oldValue === newValue) {
          // Se a vírgula foi removida, então ajustamos a posição do
          // cursor para a última posição antes da vírgula
          cursorStart = textValue.indexOf(',');
          newValue = textValue.replace('-', '');
        }
      }
    }

    // Separa a parte inteira da parte decimal
    const [integerPart, decimalPart] = newValue.split(',');
    const integerPartSize = size - decimals - 1;

    let normalizedIntegerPart = (integerPart === '')
      ? '0'
      : String(parseInt(integerPart))
    ;
    if (integerPart.startsWith('0') && (normalizedIntegerPart !== '0')) {
      // Ajusta a posição do cursor
      const zeroCount = (integerPart.match(/0/g) || []).length;
      cursorStart = cursorStart - zeroCount;
    }

    if (normalizedIntegerPart.length > integerPartSize) {
      // Remove dígitos sobrando mais à esquerda
      normalizedIntegerPart = normalizedIntegerPart.slice(-integerPartSize);

      // Ajusta a posição do cursor
      cursorStart = cursorStart - (integerPart.length - normalizedIntegerPart.length);
    }
    let normalizedDecimalPart = decimalPart || '0';
    if (normalizedDecimalPart.length > decimals) {
      normalizedDecimalPart = normalizedDecimalPart.substr(0, decimals);
    }
    // Acrescenta zeros à direita da parte decimal, se necessário
    if (normalizedDecimalPart.length < decimals) {
      normalizedDecimalPart = normalizedDecimalPart.padEnd(decimals, '0');
    }

    // Monta o novo valor
    newValue = (hasMinus?'-':'')
      + normalizedIntegerPart
      + ','
      + normalizedDecimalPart
    ;
    setTextValue(newValue);
    const newFloatValue = newValue === ''
      ? null
      : parseFloat(newValue.replace(',', '.'))
    ;
    setFloatValue(newFloatValue);

    // Atualiza o valor do campo no formulário
    setChanged(true);
    field.onChange(newFloatValue);

    // Restaura a posição do cursor depois da manipulação
    requestAnimationFrame(() => {
      event.target.setSelectionRange(cursorStart, cursorStart);
    });
  };

  const handleOnPaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData('text');
    const cursorStart = event.target.selectionStart;
    const cursorEnd = event.target.selectionEnd;

    // Se o valor a ser colado for vazio, então não fazemos nada
    if (clipboardData === '') {
      return;
    }

    // Se o valor a ser colado for um número, então o tratamos como
    // um valor válido
    if (!isNaN(clipboardData)) {
      const newValue = textValue.slice(0, cursorStart)
        + clipboardData.replace('.', ',')
        + textValue.slice(cursorEnd)
      ;
      event.target.value = newValue;
      handleOnInput(event);

      return;
    }
  }

  const handleOnBlur = (event) => {
    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: floatValue } };
    field.onBlur(newEvent);
  };

  return (
    <input
      ref={field.ref}
      id={name}
      type="text"
      value={textValue}
      onInput={handleOnInput}
      onPaste={handleOnPaste}
      onBlur={handleOnBlur}
      disabled={isSubmitting}
      pattern="^-?[0-9]+([,\\.][0-9]+)?$"
      {...props}
    />
  );
};

export default DecimalControl;