import {
  useEffect,
  useRef
} from 'react';
import {
  useMap
} from 'react-leaflet';
import {
  DomEvent,
  FeatureGroup,
  LatLngBounds,
  TileLayer,
} from 'leaflet';

import { ReactComponent as FitIcon } from "../../assets/icons/map/fit.svg";

import '../../styles/LeafletButton.scss';

function CustomControls({ position }) {
  // Classes usadas pelo Leaflet para posicionar os controles
  const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right'
  };

  const map = useMap();
  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  const divRef = useRef(null);

  // O estado do modo de regua
  //const [ruleMode, setRuleMode] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      DomEvent.disableClickPropagation(divRef.current);
      DomEvent.disableScrollPropagation(divRef.current);
    }
  });

  const handleFitClick = () => {
    const bounds = new LatLngBounds();
    map.eachLayer(function (layer) {
      if (layer instanceof TileLayer) {
        console.log('Ignoring base layer', layer.options.id);
        return;
      }
      if (layer instanceof FeatureGroup) {
        if (layer._layers === undefined || Object.keys(layer._layers).length === 0) {
          console.log('Ignoring layer with no content');
          return;
        }
        // Se as options do layer contiverem uma atribuição
        // (attribution) e o seu valor for 'ignoreOfFit', então não
        // incluímos a camada no ajuste do mapa
        if (layer.options.attribution === 'ignoreOnFit') {
          console.log('Ignoring layer with hidden content');
          return;
        }

        bounds.extend(layer.getBounds());
      }
    });

    if (bounds.isValid()) {
      map.fitBounds(bounds, {
        animated: true,
        duration: 0.5,
        easeLinearity: 0.50,
        paddingTopLeft: [80, 80],
        paddingBottomRight: [40, 40],
        maxZoom: 17
      });
    }
  };
  
  return (
    <div ref={divRef} className={`leaflet-control-custom ${positionClass}`}>
      <div className="leaflet-control leaflet-bar">
        <button
          id="mapFitObjects"
          title="Ajustar veículos no mapa"
          onClick={handleFitClick}
        >
          <span>
            <FitIcon style={{ width: '100%', height: '100%', padding: '0 .5em' }}/>
          </span>
        </button>
      </div>
    </div>
  );
}

export default CustomControls;
