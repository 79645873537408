import React from 'react';

import { useDeviceContext } from '../../features/DeviceContext';


function Icon({ symbol }) {
  const svgIcon = (color, icon) => { return `
  <svg
    viewBox="10 10 28 14"
    style="fill: ${color} !important; width: 1.5em; height: 1.5em;"
    xmlns="http://www.w3.org/2000/svg"
    >
    <circle cx="23.865322" cy="16.949024" fill="#fff" r="13.002331" />
    ${icon}
  </svg>`};

  const {
    devicesState
  } = useDeviceContext();
  let vehicleIcon;

  if (symbol === null || symbol === undefined) {
    // Usamos o símbolo padrão
    vehicleIcon = devicesState.iconset['tracker'];
  } else {
    if (devicesState.iconset.hasOwnProperty(symbol)) {
      vehicleIcon = devicesState.iconset[symbol];
    } else {
      // Usamos o símbolo padrão
      vehicleIcon = devicesState.iconset['tracker'];
    }
  }

  const svgMarkup = svgIcon("#000", vehicleIcon);

  return (
    <div dangerouslySetInnerHTML={{ __html: svgMarkup }} />
  );
}

export default Icon;
