import Control from 'react-leaflet-custom-control';

const TogglePanelControl = ({
  position = 'topright',
  isPanelCollapsed,
  togglePanelCollapsed
}) => {
  return (
    <Control position={position}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          backgroundColor: '#007bff',
          borderTopLeftRadius: 'calc(0.75rem - 2px)',
          borderTopRightRadius: 'calc(0.75rem - 2px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          marginBottom: '-0.625rem',
          zIndex: '10'
        }}
      >
        <button
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '2.75rem',
            height: '1.75rem',
            border: 'none',
            backgroundColor: '#007bff',
            color: '#fff',
            borderTopLeftRadius: 'calc(0.75rem - 2px)',
            borderTopRightRadius: 'calc(0.75rem - 2px)',
          }}
          onClick={togglePanelCollapsed}
        >
          {
            isPanelCollapsed
              ? <ChevronUp />
              : <ChevronDown />
          }
        </button>
      </div>
    </Control>
  )
};

const ChevronUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m18 15-6-6-6 6"/>
    </svg>
  )
};

const ChevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6"/>
    </svg>
  )
}

export { TogglePanelControl };