import Api from './Api';

/**
 * Obtém a lista de emblemas disponíveis.
 * 
 * @returns
 *   Os dados de emblemas disponíveis.
 */
const getEmblems = async () => {
  return Api
    .get("/landmark/emblems", {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getEmblems
};
