import {
  TileLayer
} from 'react-leaflet';

function MapLayer({ name }) {
  // As chaves de API
  const apiKey = {
    here: 'XoExPUkKa7zE7VR94rR35wOfBH7UclzEsCB4LwaiV-M'
  };

  const maps = {
    OSM: {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxNativeZoom: 18,
      maxZoom: 21,
      minZoom: 0
    },
    OSMDE: {
      url: 'http://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxNativeZoom: 18,
      maxZoom: 21,
      minZoom: 0
    },
    ArcGISSatellite: {
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png',
      attribution: '&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors',
      maxNativeZoom: 18,
      maxZoom: 21,
      minZoom: 0
    },
    Here: {
      url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.day&apiKey=' + apiKey.here,
      attribution: 'Map &copy; 1987-2021 <a href="https://developer.here.com">HERE</a>',
      base: 'base',
      maxNativeZoom: 20,
      maxZoom: 21,
      minZoom: 0
    },
    HereSatellite: {
      url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&ppi=400&size=512&apiKey=' + apiKey.here,
      attribution: 'Map &copy; 1987-2021 <a href="https://developer.here.com">HERE</a>',
      base: 'base',
      maxNativeZoom: 20,
      maxZoom: 21,
      minZoom: 0
    },
    HereExploreSatellite: {
      url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&ppi=400&size=512&apiKey=' + apiKey.here,
      attribution: 'Map &copy; 1987-2021 <a href="https://developer.here.com">HERE</a>',
      base: 'base',
      maxNativeZoom: 20,
      maxZoom: 21,
      minZoom: 0
    },
    HereTraffic: {
      url: 'https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/png?style=explore.satellite.day&apiKey=' + apiKey.here,
      attribution: 'Map &copy; 1987-2021 <a href="https://developer.here.com">HERE</a>',
      base: 'base',
      maxNativeZoom: 20,
      maxZoom: 21,
      minZoom: 0
    },
    HereTopo: {
      url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=topo.day&ppi=400&size=512&apiKey=' + apiKey.here,
      attribution: 'Map &copy; 1987-2021 <a href="https://developer.here.com">HERE</a>',
      base: 'base',
      maxNativeZoom: 20,
      maxZoom: 21,
      minZoom: 0
    }
  };
  
  return (
    <TileLayer
      id={name}
      url={maps[ name ].url}
      attribution={maps[ name ].attribution}
      maxNativeZoom={maps[ name ].maxNativeZoom}
      maxZoom={maps[ name ].maxZoom}
      minZoom={maps[ name ].minZoom}
    />
  );
}

export default MapLayer;