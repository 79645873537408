import React from 'react';

import {ReactComponent as Light} from '../assets/icons/light.svg';
import {ReactComponent as Dark} from '../assets/icons/dark.svg';

function ThemeSwitch({ theme, setChosenTheme }) {
  return (
    <div className="theme-switch">
    { theme === 'light'
      ? (
          <button onClick={() => setChosenTheme('dark')}>
            <Dark />
          </button>
        )
      : (
          <button onClick={() => setChosenTheme('light')}>
            <Light />
          </button>
        )
    }
    </div>
  );
}

export default ThemeSwitch;