import Api from './Api';

/**
 * Recupera os comandos disponíveis para envio aos equipamentos do
 * usuário.
 * 
 * @param {int} pageNumber 
 *   O número da página sendo requisitada
 * @param {int} itemsPerPage
 *   A quantidade de itens por página
 * @param {string} filter
 *   O filtro de pesquisa
 * 
 * @returns
 *   Os dados da requisição do registro de comandos
 */
const getDeviceCommands = async (
  pageNumber,
  itemsPerPage,
  filter
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    filter: filter
  };

  return Api
    .get("/command/reader", { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

const sendCommand = async (
  equipmentID,
  commandID,
  replacementValues,
  commandData
) => {
  return Api
    .post("/command/send", {
      'equipmentID': equipmentID,
      'commandID': commandID,
      'replacementValues': replacementValues,
      'commandData': commandData
    })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

const cancelCommand = async (
  commandQueueID
) => {
  return Api
    .put(`/command/cancel/${commandQueueID}`)
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Recupera o histórico de comandos do equipamento.
 * 
 * @param {int} pageNumber 
 *   O número da página sendo requisitada
 * @param {int} pageSize
 *   A quantidade de itens por página
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * 
 * @returns
 *   Os dados da requisição do histórico de comandos
 */
const getCommandsHistory = async (
  pageNumber,
  pageSize,
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: pageSize
  };
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;
  console.log('Get', `/command/history/${uriData}`);

  return Api
    .get(`/command/history/${uriData}`, { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getDeviceCommands,
  sendCommand,
  cancelCommand,
  getCommandsHistory
};