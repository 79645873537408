import React, {
  useMemo,
  useState
} from 'react';
import { z } from "zod";
import { cpf } from 'cpf-cnpj-validator';

import {
  Autocomplete,
  AutocompleteControl,
  Cancel,
  Control,
  CPFControl,
  DatePicker,
  Description,
  Error,
  FieldSet,
  Form,
  Group,
  HiddenInteger,
  Inline,
  Label,
  Legend,
  Option,
  Phones,
  PostalCodeControl,
  Row,
  Select,
  Submit,
  Toggle
} from '../components/form';

import VehicleList from '../components/VehicleList';
import { addressLookUp, citiesLookUp } from '../services/DocumentsService';

import '../components/form/form.css';

const DriverForm = ({
  driver,
  onSubmit,
  onCancel,
  customerVehicles,
  customerName,
  driverNames,
  phoneTypes,
  getIdentifiers
}) => {
  const isDriverUnique = async (value, context) => {
    // Verifica se o nome já existe, usando case insensitive e
    // ignorando espaços no início e fim e acentos
    const newDriver = value
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      ;

    return !driverNames.includes(newDriver);
  };
  const [changedFields, setChangedFields] = useState(null);
  const [age, setAge] = useState('');
  const [previousPostalcode, setPreviousPostalcode] = useState(() => {
    if (driver && driver.postalcode) {
      return driver.postalcode;
    }

    return '';
  });
  const identifiers = useMemo(() => {
    const values = getIdentifiers();

    return values;
  }, [getIdentifiers]);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  };

  const driverSchema = z.object({
    id: z.number().int().optional(),
    customerid: z.number().positive().int(),
    name: z
      .string({
        required_error: "Nome é obrigatório",
        invalid_type_error: "Nome deve ser uma string",
      })
      .trim()
      .nonempty({
        message: "Nome não pode estar em branco",
      })
      .max(100, {
        message: "Deve ter no máximo 50 caracteres"
      })
      .refine(isDriverUnique, {
        message: "Já exite outro motorista com este nome",
      }),
    nickname: z
      .string()
      .trim()
      .max(100, {
        message: "Deve ter no máximo 50 caracteres"
      })
      .optional(),
    occupation: z
      .string()
      .trim()
      .max(100, {
        message: "Deve ter no máximo 100 caracteres"
      })
      .optional(),
    birthdate: z
      .string()
      .refine((val) => {
        if (val) {
          const parsedDate = parseDate(val);
          return !isNaN(Date.parse(parsedDate));
        }

        return true;
      }, {
        message: "Data de nascimento deve ser uma data válida",
      })
      .optional(),
    genderid: z.number().positive().int(),
    cnh: z
      .string()
      .trim()
      .max(20, {
        message: "Deve ter no máximo 20 caracteres"
      })
      .optional(),
    cnhcategory: z
      .string()
      .trim()
      .max(5, {
        message: "Deve ter no máximo 5 caracteres"
      })
      .optional(),
    cnhexpirationdate: z
      .string()
      .refine((val) => {
        if (val) {
          const parsedDate = parseDate(val);
          return !isNaN(Date.parse(parsedDate));
        }

        return true;
      }, {
        message: "Data de vencimento da CNH deve ser uma data válida",
      })
      .optional(),
    cpf: z
      .string()
      .trim()
      .max(14, {
        message: "Deve ter no máximo 14 caracteres"
      })
      .refine((val) => {
        if (val) {
          return cpf.isValid(val);
        }
        return true;
      }, {
        message: "CPF inválido"
      })
      .optional(),
    address: z
      .string()
      .trim()
      .max(100, {
        message: "Deve ter no máximo 100 caracteres"
      })
      .optional(),
    streetnumber: z
      .string()
      .trim()
      .max(10, {
        message: "Deve ter no máximo 10 caracteres"
      })
      .optional(),
    complement: z
      .string()
      .trim()
      .max(30, {
        message: "Deve ter no máximo 30 caracteres"
      })
      .optional(),
    district: z
      .string()
      .trim()
      .max(50, {
        message: "Deve ter no máximo 50 caracteres"
      })
      .optional(),
    cityid: z.number().int().nullable().optional(),
    postalcode: z
      .string()
      .trim()
      .max(9, {
        message: "Deve ter no máximo 9 caracteres"
      })
      .optional(),
    phones: z.optional(
      z.array(
        z.object({
          driverphoneid: z.number().int().optional(),
          phonetypeid: z.number().int().positive(),
          phonenumber: z
            .string()
            .trim()
            .optional(),
        })
      )
    ),
    identifiertechnologyid: z.number().positive().int(),
    driveridentifierid: z
      .number()
      .int()
      .nullable(),
    active: z.boolean({
      required_error: "Ativo é obrigatório",
      invalid_type_error: "Informe se está ativo",
    }),
    storedata: z.optional(
      z.array(
        z.object({
          vehicleid: z.number().int().positive(),
          plate: z
            .string({
              required_error: "Placa é obrigatória",
              invalid_type_error: "Placa deve ser uma string",
            })
            .trim()
            .nonempty({
              message: "Placa não pode estar em branco",
            }),
          equipmentid: z.number().int().positive(),
          stored: z.boolean({
            required_error: "Armazenado é obrigatório",
            invalid_type_error: "Informe se está armazenado",
          }),
        })
      )
    )
  });

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let ageValue = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      ageValue--;
    }
    return ageValue;
  };

  const onFieldChange = (field, value) => {
    if (field === 'birthdate') {
      // Valida se a data é válida
      if (value) {
        const parsedDate = parseDate(value);
        if (!isNaN(Date.parse(parsedDate))) {
          const ageOfDriver = calculateAge(parsedDate);

          setAge(`${ageOfDriver} anos`);
        } else {
          setAge('');
        }
      } else {
        setAge('');
      }
    } else if (field === 'postalcode') {
      // Fazemos a busca do endereço pelo CEP
      if (value) {
        // Verifica se o CEP está completo
        if (value.length === 9) {
          // Verifica se o CEP mudou
          if (value !== previousPostalcode) {
            console.log('Buscando endereço pelo CEP', value);
            setPreviousPostalcode(value);
            addressLookUp(value)
              .then((address) => {
                // Se encontrou o endereço, força a atualização dos
                // campos
                console.log('Endereço encontrado', address);
                setChangedFields({
                  address: address.address,
                  //complement: address.complement,
                  district: address.district,
                  cityid: address.cityID,
                  cityname: address.cityName,
                  state: address.state
                });
              })
              .catch((error) => {
                console.error('Erro ao buscar endereço', error);
              })
            ;
          }
        }
      }
    }
  };

  const handleGetItems = async (searchTerm) => {
    return citiesLookUp(searchTerm);
  };

  return (
    <Form
      schema={driverSchema}
      defaultValues={driver}
      onSubmit={onSubmit}
      onFieldChange={onFieldChange}
      changedFields={changedFields}
      clearChangedFields={() => setChangedFields(null)}
      watchFields={["birthdate", "postalcode", "address", "complement",
        "district", "cityid", "cityname", "state"
      ]}
    >
      <Control
        type="hidden"
        name="id"
      />
      <Control
        type="hidden"
        name="customerid"
      />
      {customerName && (
      <Row>
        <Group>
          <Label htmlFor="customername">Cliente</Label>
          <input
            style={{ flex:1, width: '100%', color: 'var(--theme-text-color)' }}
            type="text"
            name="customername"
            disabled
            value={customerName}
          />
        </Group>
      </Row>
      )}
      <Group>
        <Label htmlFor="name">Nome do motorista</Label>
        <Control
          type="text"
          name="name"
          placeholder="Informe o nome do motorista..."
          maxLength={100}
        />
        <Error name="name" />
      </Group>
      <Row>
        <Group>
          <Label htmlFor="name">Apelido</Label>
          <Control
            type="text"
            name="nickname"
            placeholder="Informe o apelido..."
            maxLength={50}
          />
          <Error name="nickname" />
        </Group>
        <Group>
          <Label htmlFor="occupation">Ocupação</Label>
          <Control
            type="text"
            name="occupation"
            placeholder="Informe a ocupação..."
            maxLength={100}
          />
          <Error name="occupation" />
        </Group>
        <Control
          type="hidden"
          name="genderid"
        />
      </Row>
      <Row>
        <Group>
          <Label htmlFor="birthdate">Data de nascimento</Label>
          <DatePicker
            name="birthdate"
            placeholder='Informe a data...'
          />
          <Error name="birthdate" />
        </Group>
        <Group>
          <Label htmlFor="age">Idade</Label>
          <FakeInput
            value={age}
          />
        </Group>
        <Group>
          <Label htmlFor="cpf">CPF</Label>
          <CPFControl
            name="cpf"
          />
          <Error name="cpf" />
        </Group>
      </Row>
      <Row>
        <Group>
          <Label htmlFor="cnh">Número da CNH</Label>
          <Control
            type="text"
            name="cnh"
            placeholder="Informe o número da CNH..."
            maxLength={20}
          />
          <Error name="cnh" />
        </Group>
        <Group>
          <Label htmlFor="cnhcategory">Categoria de habilitação</Label>
          <Control
            type="text"
            name="cnhcategory"
            placeholder="Categoria (Ex: A, B, E)..."
            maxLength={5}
          />
          <Error name="cnhcategory" />
        </Group>
        <Group>
          <Label htmlFor="cnhexpirationdate">Data de vencimento</Label>
          <DatePicker
            name="cnhexpirationdate"
            placeholder='Vencimento da CNH...'
          />
          <Error name="cnhexpirationdate" />
        </Group>
      </Row>
      <Row>
        <Group flex={1}>
          <Label htmlFor="postalcode">CEP</Label>
          <PostalCodeControl
            name="postalcode"
            placeholder="00000-000"
          />
          <Error name="postalcode" />
        </Group>
        <Group flex={5}>
          <Label htmlFor="address">Endereço</Label>
          <Control
            type="text"
            name="address"
            placeholder="Informe o endereço..."
            maxLength={100}
          />
          <Error name="address" />
        </Group>
        <Group flex={1}>
          <Label htmlFor="streetnumber">Número</Label>
          <Control
            type="text"
            name="streetnumber"
            placeholder="Número..."
            maxLength={10}
          />
          <Error name="streetnumber" />
        </Group>
      </Row>
      <Row>
        <Group flex={3}>
          <Label htmlFor="complement">Complemento</Label>
          <Control
            type="text"
            name="complement"
            placeholder="Casa, apto..."
            maxLength={30}
          />
          <Error name="complement" />
        </Group>
        <Group flex={4}>
          <Label htmlFor="district">Bairro</Label>
          <Control
            type="text"
            name="district"
            placeholder="Bairro..."
            maxLength={30}
          />
          <Error name="district" />
        </Group>
        <Group flex={4}>
          <Label htmlFor="cityname">Cidade</Label>
          <AutocompleteControl
            name="cityname"
            placeholder="Cidade..."
            getItems={handleGetItems}
            onItemSelected={(item) => {
              if (item) {
                setChangedFields({
                  cityid: parseInt(item.value, 10),
                  cityname: item.label,
                  state: item.state
                });
              } else {
                setChangedFields({
                  cityid: null,
                  state: ''
                });
              }
            }}
            maxLength={100}
          />
          <HiddenInteger
            name="cityid"
          />
          <Error name="cityname" />
        </Group>
        <Group flex={1}>
          <Label htmlFor="state">UF</Label>
          <Control
            type="text"
            name="state"
            placeholder="UF..."
            maxLength={30}
          />
          <Error name="state" />
        </Group>
      </Row>
      <FieldSet style={{ gap: '0' }}>
        <Legend>Telefones de contato</Legend>
        <Phones
          name="phones"
          phoneTypes={phoneTypes}
        />
        <Error name="phones" />
      </FieldSet>
      <Control
        type="hidden"
        name="identifiertechnologyname"
      />
      <Control
        type="hidden"
        name="identifiertechnologyid"
      />
      <Group flex={1}>
        <Label htmlFor="driveridentifierid">iButton que identifica o motorista</Label>
        <Autocomplete
          id="driveridentifierid"
          name="driveridentifierid"
          data={identifiers}
          placeholder="Escolha um identificador..."
          noOptionsMessage={() => 'Nenhum identificador encontrado.'}
          rules={{}}
        />
        <Error name="driveridentifierid" />
      </Group>
      <FieldSet>
        <Legend>Veículos nos quais o iButton está vinculado</Legend>
        <VehicleList
          name="storedata"
          noitemsLabel="Nenhum veículo associado."
          customerVehicles={customerVehicles}
        />
      </FieldSet>
      <FieldSet direction="col">
        <Legend>Opções adicionais</Legend>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ borderBottom: '1px solid var(--theme-list-group-border-color)', marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="active"
              >
                <span>Ativo</span>
                <Description>
                  Se este iButton está ativo. Se desmarcado, impede o seu uso.
                </Description>
              </Label>
            </Group>
            <Toggle
              name="active"
            />
          </Inline>
          <Error name="active" />
        </Group>
      </FieldSet>
      <Row rightAligned>
        <Cancel onClick={onCancel} >
          Cancelar
        </Cancel>
        <Submit value="Salvar">
          Salvar
        </Submit>
      </Row>
    </Form>
  );
};

const FakeInput = ({ value }) => {
  return (
    <input
      type="text"
      value={value}
      readOnly
      style={{
        width: '100%',
        border: '1px solid var(--theme-input-border-color)',
        borderRadius: '.475rem',
        fontSize: '.8rem',
        color: 'var(--theme-input-color)',
        backgroundColor: 'var(--theme-modal-bg)',
        boxSizing: 'border-box',
        lineHeight: 'normal',
      }}
    />
  );
}

export default DriverForm;