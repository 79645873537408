import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Tooltip from './Tooltip';
import { getTip } from '../DeviceStatus.js';
import {
  formatMeasurement,
  formatMeasurementAsText
} from '../Formatter.js';
import IgnitionStatus from "../StatusIcons/IgnitionStatus";
import BlockStatus from "../StatusIcons/BlockStatus";
import SirenStatus from "../StatusIcons/SirenStatus";
import Icon from './Icon';
import PlateAndDenomination from './PlateAndDenomination';
import DriverAndIdentifier from './DriverAndIdentifier';
import Customer from './Customer';
import DateAndTime from './DateAndTime';
import GSMSignalStrength from '../GSMSignalStrength';
import { ReactComponent as PointIcon } from "../../assets/icons/point.svg";
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as CleanIcon } from "../../assets/icons/clean.svg";
import { ReactComponent as TechnicianDataIcon } from "../../assets/icons/technician_data.svg";
import useCopyToClipboard from '../../hooks/clipboard';

import 'react-toastify/dist/ReactToastify.css';

const GPSStatus = ({ hasGPS }) => {
  const boxStyle = {
    backgroundColor: hasGPS ? 'var(--theme-on)' : 'var(--theme-danger)',
    color: '#fff',
    padding: '.2em .3em',
    borderRadius: '.375rem',
    fontSize: '.8em',
    fontWeight: 'bold',
    marginRight: '.5rem'
  };

  return (
    <span style={boxStyle}>
      {hasGPS === true ? 'Com GPS' : 'Sem GPS'}
    </span>
  );
};

function GridRow({
  index,
  device,
  isExpanded,
  toggleExpansion,
  showDriver,
  isSelected,
  onRowClick,
  onEditDenomination,
  deleteDeviceFromGrid,
  groupID,
  belongsToAnAssociation
}) {
  const { isClipboardApiSupported, copyToClipboard } = useCopyToClipboard();

  const isAdmin = groupID < 6;

  const handleCopyAddress = (event) => {
    event.stopPropagation();

    const address = device.address
      + (
          device.nearestLandmarks
            ? ', ' + device.nearestLandmarks
            : ''
        )
      + (
          device.insideFences
            ? ', ' + device.insideFences
            : ''
        )
    ;
    
    copyToClipboard(address)
      .then(() => {
        toast.info('Copiado o endereço para a área de transferência', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
    ;
  };

  const handleToggleStatus = (event, type, id, state, canToggle) => {
    event.stopPropagation();

    if (state === undefined || state === null) {
      return;
    }

    if (canToggle === false) {
      return;
    }

    const nameOfStatus = type === 'block'
      ? 'bloqueio'
      : 'sirene'
    ;
    const action = state === true
      ? 'Desativando'
      : 'Ativando'
    ;

    console.log(`${action} ${nameOfStatus} do equipamento ${id}`);
  };

  const handleCopyTechnician = (event) => {
    event.stopPropagation();

    const serialNumber = device.equipment.serialNumber;
    const { plate, denomination } = device.vehicle || {};
    const label = plate
      ? (`Placa: ${plate}` + (denomination ? ` - ${denomination}` : '') + '\n'
        + `Terminal: ${serialNumber}`)
      : `Equipamento: ${serialNumber}`
    ;
    const address = device.address;
    const date = new Date(device.datetime);
    const eventDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    const ignition = device.ignition
      ? 'ligada'
      : 'desligada'
    ;
    const hasGPS = (device.withGPS === true) ? '[Com GPS]' : '[Sem GPS]';
    const battery = formatMeasurementAsText(device.vehicleBattery, 'V', 2);

    const technicianData = ''
     + label + '\n'
     + `Data: ${eventDate}\n`
     + hasGPS + ' ' + address + '\n'
     + `Ignição: ${ignition}\n`
     + `Bateria: ${battery}\n`
     + `Qtde satélites: ${device.satellites}\n`
     + `Sinal celular: ${device.gsmSignalStrength}`
    ;

    copyToClipboard(technicianData)
      .then(() => {
        toast.info('Copiado os dados para o técnico para a área de transferência', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
    ;
  };

  return (
    <>
      <tr
        className={ isSelected ? 'selected' : '' }
        id={ device.id }
        onClick={onRowClick}
      >
        <td className="center">{index}</td>
        { isAdmin &&
          <>
            <td className="center" onClick={(event) => {
              event.stopPropagation();
              deleteDeviceFromGrid(device.equipment.id);
            }}>
              <Tooltip label="Remover do Grid">
                <CleanIcon />
              </Tooltip>
            </td>
            <td className="center">
              { isClipboardApiSupported() &&
                <Tooltip label="Clique para copiar os dados para o técnico">
                  <TechnicianDataIcon
                    className='copy-icon'
                    color="var(--theme-gray-400)"
                    onClick={handleCopyTechnician}
                  />
                </Tooltip>
              }
            </td>
          </>
        }
        { isAdmin && (
        <td className="center">
          <PlateAndDenomination
            vehicle={ null }
            equipment={ device.equipment }
          />
        </td>
        )}
        <td className="left">
          <Icon symbol={ device.vehicle?.type.symbol } />
        </td>
        <td className="left">
          <PlateAndDenomination
            vehicle={ device.vehicle }
            equipment={ null }
            align='left'
            enableEditDenomination={true}
            onEditDenomination={onEditDenomination}
          />
        </td>
        { showDriver && (
        <td className="left">
          <DriverAndIdentifier
            driver={ device.driver }
            identifier={ device.identifier }
            align='left'
          />
        </td>
        )}
        { (isAdmin || belongsToAnAssociation === true) && (
        <td className="left">
          <Customer
            customer={ device.customer }
            hideAssociation={ groupID = 6 && belongsToAnAssociation }
          />
        </td>
        )}
        <td className="center">
          <DateAndTime eventDate={device.gpsdate} />
        </td>
        { isAdmin && (
        <td className="center">
          <DateAndTime eventDate={device.systemdate} />
        </td>
        )}
        <td className="center">
          <Tooltip label={ getTip(device.ignition, 'ignition') }>
            <IgnitionStatus size="24" status={device.ignition} />
          </Tooltip>
        </td>
        <td
          className={device.block===null?"center":(device.equipment.availablecommands.includes("block")?"center clickable":"center")}
          onClick={(event) => handleToggleStatus(event, 'block', device.equipment.id, device.block, device.equipment.availablecommands.includes("block"))}
        >
          <Tooltip label={ getTip(device.block, 'block') }>
            <BlockStatus size="24" status={device.block} />
          </Tooltip>
        </td>
        <td
          className="center"
          onClick={(event) => handleToggleStatus(event, 'siren', device.equipment.id, device.siren, device.equipment.availablecommands.includes("sirenon"))}
        >
          <Tooltip label={ getTip(device.siren, 'siren') }>
            <SirenStatus size="24" status={device.siren} />
          </Tooltip>
        </td>
        <td className="right">
          { formatMeasurement(device.speed, 'km/h') }
        </td>
        <td className="right">
          { device.hasOwnProperty('odometer')
              ? formatMeasurement(device.odometer, 'km')
              : ''
          }
        </td>
        <td className="right">
          { formatMeasurement(device.vehicleBattery, 'V', 2) }
        </td>
        <td className="center">
          { formatMeasurement(device.internalBattery, 'V', 1) }
        </td>
        { isAdmin && (
        <>
        <td className="center">
          <Tooltip label={device.gsmSignalStrength}>
            <GSMSignalStrength width={15} value={device.gsmSignalStrength} />
          </Tooltip>
        </td>
        <td className="center">
          { device.satellites }
        </td>
        </>
        )}
        <td className="center">
          <Link to={`https://www.google.com/maps/search/?api=1&query=${device.latitude},${device.longitude}`} target="_blank">
            <Tooltip label="Abrir no Google Maps">
              <PointIcon />
            </Tooltip>
          </Link>
        </td>
        <td>
          { isClipboardApiSupported() &&
            <Tooltip label="Clique para copiar para a área de transferência">
              <CopyIcon
                className='copy-icon'
                color="var(--theme-gray-400)"
                onClick={handleCopyAddress}
              />
            </Tooltip>
          }
          { isAdmin && (<GPSStatus hasGPS={ device.withGPS } />)}{
            device.address
            + (
                device.nearestLandmarks
                  ? ', ' + device.nearestLandmarks
                  : ''
              )
            + (
                device.insideFences
                  ? ', ' + device.insideFences
                  : ''
              )
          }
        </td>
      </tr>
      {device.additionalInfo && isExpanded && (
        <tr>
          <td colSpan="3">{device.additionalInfo}</td>
        </tr>
      )}
    </>
  );
}

export default GridRow;