import React, {
  useEffect,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { useAPIContext } from '../../../features/APIContext';
import { useTenantContext } from "../../../features/TenantContext";
import { getUser } from '../../../features/authorization/AuthContext';
import PrimaryButton from '../../../components/PrimaryButton';
import SelectField from '../../../components/SelectField';
import DayPickerField from '../../../components/DayPickerField';
import TimePickerField from '../../../components/TimePickerField';
import { useDeviceContext } from '../../../features/DeviceContext';
import { getPositionsHistory } from '../../../services/PositionService';
import { getEventsHistory } from '../../../services/EventService';
import { getCommandsHistory } from '../../../services/CommandService';
import { getHourimeterHistory } from '../../../services/HourimeterService';
import { getOdometerHistory } from '../../../services/OdometerService';
import {
  getIgnitionHistory,
  getIgnitionOnHistory,
  getIgnitionOffHistory
} from '../../../services/IgnitionService';
import { HistoryReport } from '../../../features/reports/historyReport';
import { HistoryCommandReport } from '../../../features/reports/historyCommandReport';
import { HistoryHourimeterReport } from '../../../features/reports/historyHourimeterReport';
import { HistoryOdometerReport } from '../../../features/reports/historyOdometerReport';
import { HistoryIgnitionReport } from '../../../features/reports/historyIgnitionReport';
import { HistoryIgnitionOnReport } from '../../../features/reports/historyIgnitionOnReport';
import { HistoryIgnitionOffReport } from '../../../features/reports/historyIgnitionOffReport';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Dialog from '../../../components/Dialog';
import RadioButton from '../../../components/GroupedRadioButtons/RadioButton';
import GroupedRadioButtons from '../../../components/GroupedRadioButtons';
import {
  getSQLDate
} from '../../../hooks/dateUtils';

import '../../../styles/Report.scss';
import '../../../styles/Form.scss';
import '../../../styles/Calendar.css';

import bg_corner from '../../../assets/images/corner.png';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const HistoryPage = () => {
  const { tenant } = useTenantContext();
  const user = getUser();
  // O contexto de dispositivos
  const {
    devicesState
  } = useDeviceContext();
  const {
    changeBlockUpdate
  } = useAPIContext();

  // Os possíveis relatórios
  const reports = [
    {value:1, label:'Histórico de eventos'},
    {value:2, label:'Histórico de posições'},
    {value:3, label:'Histórico de comandos'},
    {value:4, label:'Histórico de odômetro'},
    {value:5, label:'Histórico de horímetro'},
    {value:6, label:'Histórico de tempo em movimento'},
    {value:7, label:'Histórico de tempo parado'},
    {value:8, label:'Histórico de tempo em movimento e parado'},
    {value:9, label:'Histórico de excesso de velocidade'},
  ];
  
  // Os equipamentos do usuário
  const [equipmentList, setEquipmentList] = useState([]);

  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(false);
  
  // O indicativo de período personalizado
  const [customPeriod, setCustomPeriod] = useState(false);

  const [showSpeedLimit, setShowSpeedLimit] = useState(false);

  // Os possíveis períodos
  const periods = [
    {value:1, hours: 2,  label:'Últimas 2 horas'},
    {value:2, hours: 6,  label:'Últimas 6 horas'},
    {value:3, hours: 12, label:'Últimas 12 horas'},
    {value:4, hours: 24, label:'Últimas 24 horas'},
    {value:5, hours: 0,  label:'Período personalizado'}
  ];

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);

  // Atualiza as informações de veículos e equipamentos
  useEffect(() => {
    const newVehicles = devicesState.devices
      .filter(device => device.vehicle !== undefined && device.vehicle !== null)
      .map(device => {
        return {
          value:device.equipment.id,
          label:device.vehicle.plate
        };
      })
    ;
    const newEquipments = devicesState.devices
      .filter(device => device.vehicle === undefined || device.vehicle === null)
      .map(device => {
        return {
          value:device.equipment.id,
          label:device.equipment.serialNumber
        };
      })
    ;
    setEquipmentList(previous => [
      {
        label: 'Veículos',
        options: [ ...newVehicles ]
      },
      {
        label: 'Equipamentos',
        options: [ ...newEquipments ]
      }
    ]);
  }, [devicesState.devices, setEquipmentList]);

  // Define condições iniciais do formulário
  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      // Define o valor inicial do período de horas
      starttime: "00:00",
      endtime: "23:59",
    }
  });

  /**
   * Lida com o envio do formulário, requisitando os dados e gerando o
   * relatório.
   * 
   * @param {array} data
   *   Os dados do formulário
   */
  const onSubmit = async (data) => {
    console.log('Solicitando informações do relatório');
    setIsLoading(true);

    try {
      // Obtemos os parâmetros do relatório
      const periodOfReport = periods
        .find(period => period.value === parseInt(data.period))
      ;
      const typeOfPeriod = periodOfReport.value < 5
        ? 'hours'
        : 'period'
      ;
      const endDate = (data.reportperiod !== undefined)
        ? (
            (data.reportperiod.to === undefined)
              ? data.reportperiod.from
              : data.reportperiod.to
          )
        : null
      ;
      const parm1 = typeOfPeriod === 'period' 
        ? getSQLDate(data.reportperiod.from, data.starttime)
        : periodOfReport.hours
      ;
      const parm2 = typeOfPeriod === 'period' 
        ? getSQLDate(endDate, data.endtime)
        : null
      ;
      const { value: selectedID } = data.equipment;
  
      // Encontra o equipamento selecionado para obtermos os dados
      const device = devicesState.devices.find(device => device.equipment.id === selectedID);
      const equipment = device.equipment;
      const vehicle = ('vehicle' in device)
        ? device.vehicle
        : null
      ;
      const typeOfDevice = vehicle ? 'vehicle' : 'equipment';
      const speedLimit = data.speedLimit ? parseInt(data.speedLimit) : 0;
  
      const { start, end } = getFormatedPeriod(
        data.reportperiod?.from,
        data.starttime,
        endDate,
        data.endtime,
        periodOfReport.hours
      );
      const documentInfo = {
        name: data.reporttype.value === 9
          ? data.reporttype.label + ` igual ou superior à ${speedLimit} km/h`
          : data.reporttype.label,
        period: {
          type: typeOfPeriod,
          value: periodOfReport.value < 5
            ? periodOfReport.label
            : '',
          start: start,
          end: end
        },
        device: typeOfDevice === 'vehicle' ? {
          plate: vehicle.plate,
          model: vehicle.model.name,
          brand: vehicle.brand.name,
          color: vehicle.color,
        } : {
          serialNumber: equipment.serialNumber,
          model: equipment.model.name,
          brand: equipment.brand.name,
        },
        info: {
          title: data.reporttype.label,
          author: tenant.name,
          subject: (typeOfDevice === 'vehicle'
            ? `Informações do veículo placa ${vehicle.plate}`
            : `Informações do equipamento ${equipment.serialNumber}`
          ) + `, no período de ${start} a ${end}`,
          keywords: `${data.reporttype.label}, relatório, veículo, placa`,
          creator: 'MR Fleet',
        },
        expanded: user ? (user.groupID < 6 ? true : false) : false
      };
      const deviceID = (typeOfDevice === 'vehicle')
        ? vehicle.id
        : equipment.id
      ;
      const mainTracker = equipment.mainTracker;
      const reportTypeName = data.reporttype.label;

      let currentPage = 1;
      let totalOfPages = 1;
      let response = null;
      let reportData = [];
      let functionName = null;
      let reportName = null;

      switch (data.reporttype.value) {
        case 1:
          // Histórico de eventos
          functionName = 'getEventsHistory';
          reportName = 'HistoryReport';
          
          break;
        case 2:
          // Histórico de posições
          functionName = 'getPositionsHistory';
          reportName = 'HistoryReport';

          break;
        case 3:
          // Histórico de comandos
          functionName = 'getCommandsHistory';
          reportName = 'HistoryCommandReport';

          break;
        case 4:
          // Histórico de odômetro
          functionName = 'getOdometerHistory';
          reportName = 'HistoryOdometerReport';

          break;
        case 5:
          // Histórico de horímetro
          functionName = 'getHourimeterHistory';
          reportName = 'HistoryHourimeterReport';

          break;
        case 6:
          // Histórico de tempo em movimento
          functionName = 'getIgnitionOnHistory';
          reportName = 'HistoryIgnitionOnReport';

          break;
        case 7:
          // Histórico de tempo parado
          functionName = 'getIgnitionOffHistory';
          reportName = 'HistoryIgnitionOffReport';

          break;
        case 8:
          // Histórico de tempo em movimento e parado
          functionName = 'getIgnitionHistory';
          reportName = 'HistoryIgnitionReport';

          break;
        case 9:
          // Histórico de excesso de velocidade
          functionName = 'getPositionsHistory';
          reportName = 'HistoryReport';

          break;
        default:
          console.error('Tipo de relatório inválido');
          break;
      }

      if (functionName) {
        // Define a função com base no nome da variável
        const dynamicFunction = {
          getPositionsHistory,
          getEventsHistory,
          getCommandsHistory,
          getOdometerHistory,
          getHourimeterHistory,
          getIgnitionOnHistory,
          getIgnitionOffHistory,
          getIgnitionHistory
        }[functionName];

        console.log(
          'Solicitando informações do relatório',
          reportTypeName
        );

        try {
          let needMorePage = false;
          do {
            changeBlockUpdate(true);
            needMorePage = false;
            if (speedLimit > 0) {
              response = await dynamicFunction(
                currentPage,
                1000,
                typeOfDevice,
                deviceID,
                mainTracker,
                typeOfPeriod,
                parm1,
                parm2,
                speedLimit
              );
            } else {
              response = await dynamicFunction(
                currentPage,
                1000,
                typeOfDevice,
                deviceID,
                mainTracker,
                typeOfPeriod,
                parm1,
                parm2
              );
            }
  
            console.log(
              'Recebido as informações do relatório',
              reportTypeName
            );
            const {data, numberOfPages } = response;
            if (data.length > 0) {
              reportData = [...reportData, ...data];
              totalOfPages = numberOfPages;

              if (currentPage < numberOfPages) {
                console.log('Solicitando mais informações do relatório');
                currentPage++;
                needMorePage = true;
              }
            } else {
              console.log('Nenhum histórico no período informado');
              setErrorMessage('Nenhum histórico no período informado');
              setShowError(true);
            }
          } while ((currentPage <= totalOfPages) && needMorePage);
        } catch (error) {
          console.log(error);
          setErrorMessage(error.message);
          setShowError(true);
        } finally {
          changeBlockUpdate(false);
        }

        if (reportData.length > 0) {
          console.log('Construindo relatório em PDF');

          // Define a função com base no nome da variável
          const dynamicReport = {
            HistoryReport,
            HistoryCommandReport,
            HistoryOdometerReport,
            HistoryHourimeterReport,
            HistoryIgnitionOnReport,
            HistoryIgnitionOffReport,
            HistoryIgnitionReport,
          }[reportName];
    
          const historyReportClass = new dynamicReport({
            report: documentInfo,
            data: reportData,
            logo: tenant.logo.normal
          });
      
          const documentDataForPDF = await historyReportClass.preparesDocumentDataForPDF();
          const filename = (typeOfDevice === 'vehicle')
            ? `${data.reporttype.label}_${vehicle.plate}`
            : `${data.reporttype.label}_${equipment.serialNumber}`
          ;
          const fullFilename = encodeURIComponent(filename.replace(/\s/g, '_') + '.pdf');
      
          pdfMake
            .createPdf(documentDataForPDF)
            //.open({}, window.open('', '_blank'))
            .getBuffer(function (buffer) {
              const blob = new Blob([buffer], { type: 'application/pdf' });
              const URL = window.URL || window.webkitURL;
              const headers = {
                'Content-Disposition': `attachment; filename="${fullFilename}"`
              };
              const downloadUrl = URL.createObjectURL(blob, headers);
              window.open(downloadUrl, '_blank');
            })
          ;
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getFormatedPeriod = (
    startDateString,
    startTimeString,
    endDateString,
    endTimeString,
    diffInHours
  ) => {
    const end = (diffInHours === 0)
      ? moment(endDateString)
      : moment().local()
    ;

    let start = null;
    if (diffInHours === 0) {
      start = moment( startDateString );
    } else {
      start = moment().local().subtract(diffInHours, 'hours')
    }

    return {
      start: (diffInHours === 0)
        ? start.format('DD/MM/YYYY') + ' ' + startTimeString
        : start.format('DD/MM/YYYY HH:mm'),
      end: (diffInHours === 0)
        ? end.format('DD/MM/YYYY') + ' ' + endTimeString
        : end.format('DD/MM/YYYY HH:mm')
    };
  };

  const handleCheck = (value) => {
    setValue('period', value, { shouldValidate: true, shouldDirty: true });
    console.log('Selecionado período', value);
    const periodOfReport = periods
      .find(period => period.value === parseInt(value))
    ;

    if (periodOfReport === null) {
      setCustomPeriod(false);
    } else {
      if (periodOfReport.hours === 0) {
        setCustomPeriod(true);
      } else {
        setCustomPeriod(false);
      }
    }
  }

  useEffect(() => {
    const currentPeriod = document.querySelector('input[name="periodOfReport"]:checked');

    if (currentPeriod) {
      setValue('period', currentPeriod.value, { shouldValidate: true, shouldDirty: true });
    }
  }, [setValue]);

  const handleDayPickerChange = (range) => {
    updateSelectedPeriod();
  }

  const handleStartTimeChanged = (range) => {
    updateSelectedPeriod();
  }

  const handleEndTimeChanged = (range) => {
    updateSelectedPeriod();
  }

  const updateSelectedPeriod = () => {
    let footer = <p>Por favor, escolha o primeiro dia do período</p>;
    const range = getValues('reportperiod');
    const start = getValues('starttime');
    const end = getValues('endtime');;

    if (range?.from) {
      if (!range.to) {
        footer = (
          <p>
            Apenas o dia <span className="bold">
              {format(range.from, 'PPP', { locale: ptBR })}
            </span> { (start !== '00:00' || end !== '23:59') ? (
              <>
                das <span className="bold">
                  {start}</span> às <span className="bold">{end}
                </span>
              </>) : (<>(o dia inteiro)</>)
            } 
          </p>
        );
      } else if (range.to) {
        if (range.from === range.to) {
          footer = (
            <p>
              Apenas o dia <span className="bold">
                {format(range.from, 'PPP', { locale: ptBR })}
              </span> { (start !== '00:00' || end !== '23:59') ? (
                <>
                  das <span className="bold">
                    {start}</span> às <span className="bold">{end}
                  </span>
                </>) : (<>(o dia inteiro)</>)
              } 
            </p>
          );
        } else {
          footer = (
            <p>
              Do dia <span className="bold">
                {format(range.from, 'PPP', { locale: ptBR })}
              </span> às <span className="bold">{start}
              </span> até o dia <span className="bold">
                {format(range.to, 'PPP', { locale: ptBR })}
              </span> às <span className="bold">{end}</span>
            </p>
          );
        }
      }
    }

    setSelectedPeriod(footer);
  }

  const handleAdditionalFields = (option) => {
    console.log('Selecionado relatório', option);
    if (option.value === 9) {
      // Exibimos o campo de seleção do limite de velocidade
      setShowSpeedLimit(true);
    } else {
      // Ocultamos o campo de seleção do limite de velocidade
      setShowSpeedLimit(false);
    }
  }

  return (
    <div className="report-container fixedSize">
      <div className="section mb-3">
        <div className="bg-holder d-none d-lg-block bg-section" style={{ backgroundImage: `url(${bg_corner})` }}></div>
        <div className="section-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3>Relatórios</h3>
              <p className="description">
                Explore nossos relatórios e tenha acesso a informações
                tais como o histórico completo de posições e de eventos,
                assim como a outros dados relevantes para acompanhar o
                desempenho dos seus veículos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <LoadingOverlay isLoading={isLoading} message="Aguarde enquanto carregamos os dados..." />
      <Dialog
        show={showError}
        title="Atenção!"
        message={errorMessage}
        onClose={() => setShowError(false)}
      />
      <div className="section mb-3">
        <div className="section-header">
          <h5>Selecione as informações do seu relatório</h5>
        </div>
        <div className='section-body'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
              <legend>Tipo de relatório</legend>
              <SelectField
                id="reporttype"
                name="reporttype"
                control={control}
                options={reports}
                placeHolder="Escolha um tipo de relatório..."
                rules={{ required: true }}
                onChange={handleAdditionalFields}
              />
              {errors.reporttype && errors.reporttype.type === "required" && (
                <span className="errorMsg">Escolha um tipo de relatório</span>
              )}
            </div>
            { showSpeedLimit && (
            <div className="form-control">
              <label htmlFor="speedLimit">
                Limite de velocidade (em km/h)
              </label>
              <input
                style={{
                  width: '100%',
                  padding: '0.3rem 0.5rem',
                  color: 'var(--theme-input-color)',
                  backgroundColor: 'var(--theme-input-bg)',
                  backgroundClip: 'padding-box',
                  border: '1px solid var(--theme-input-border-color)',
                  borderRadius: '0.375rem',
                  appearance: 'none',
                  fontWeight: '400',
                  lineHeight: '1.5'
                }}
                type="number"
                name="speedLimit"
                {...register('speedLimit', { required: true })}
                placeholder="Informe o limite de velocidade"
              />
              {errors.speedLimit && errors.speedLimit.type === "required" && (
                <span className="errorMsg">Informe o limite de velocidade</span>
              )}
            </div>
            )}
            <div className="form-control">
              <legend>Veículo ou equipamento</legend>
              <SelectField
                id="equipment"
                name="equipment"
                control={control}
                options={equipmentList}
                placeHolder="Selecione o veículo ou equipamento..."
                noOptionsMessage={() => 'Nenhum veículo ou equipamento encontrado.'}
                rules={{ required: true }}
              />
              {errors.vehicle && errors.vehicle.type === "required" && (
                <span className="errorMsg">Escolha um dos veículos</span>
              )}
            </div>
            
            <div className="form-control">
              <legend for="periodOfReport">Período desejado</legend>
              <GroupedRadioButtons
                hasError={errors.period && errors.period.type === "required"}
              >
                {periods.map((period, index) => {
                  return (
                    <RadioButton
                      key={`period${index}`}
                      name="periodOfReport"
                      value={period.value}
                      onChange={handleCheck}
                      id={`period${index}`}
                    >
                      { period.hours > 0
                        ? (
                          <>
                            <span className="smaller">
                              Últimas
                            </span>
                            {period.hours} horas
                          </>
                        ) : (
                          <>
                            {period.label}
                          </>
                        )
                      }
                    </RadioButton>
                  );
                })}
              </GroupedRadioButtons>
              <input type="hidden" name="period" {...register('period', {required: true})} />
              {errors.period && errors.period.type === "required" && (
                <span className="errorMsg">Informe um período para o relatório</span>
              )}
            </div>
            { customPeriod &&
            <div className="datetimecontrols">
              <div className="form-control">
                <DayPickerField
                  label="Escolha um dia ou período"
                  name="reportperiod"
                  control={control}
                  rules={{ required: true }}
                  onChange={handleDayPickerChange}
                />
              </div>
              <div className="timercontrols">
                <div className="form-control">
                  <TimePickerField
                    label="Informe a hora inicial"
                    footerLabel="Hora inicial"
                    name="starttime"
                    control={control}
                    rules={{ required: true }}
                    onChange={handleStartTimeChanged}
                  />
                </div>
                <div className="form-control">
                  <TimePickerField
                    label="Informe a hora final"
                    footerLabel="Hora final"
                    name="endtime"
                    control={control}
                    rules={{ required: true }}
                    onChange={handleEndTimeChanged}
                  />
                </div>
              </div>
              <div className="form-control selectedRange">
                <span>Período selecionado</span>
                {selectedPeriod}
              </div>
            </div>
            }
            <div className="form-control buttons">
              <PrimaryButton type="submit" color="primary">
                Obter relatório
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default HistoryPage;