import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Virtuoso } from "react-virtuoso";

import OutsideClickHandler from "../OutsideClickHandler";

import './styles.css';

// O contexto para lidarmos com o estado do menu dropdown neste
// componente
export const DropdownContext = createContext(null);

function SelectVehicle({
  vehicles,
  selected,
  onSelect,
  onClear
}) {
  // A referência para o elemento que contém a lista de veículos e que
  // é uma lista virtualizada, ou seja, ela não renderiza todos os itens
  // de uma vez, mas sim apenas os que estão visíveis na tela
  const virtuosoRef = useRef(null);

  // O estado que controla se o menu dropdown está visível ou não
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState(selected);
  
  const [searchText, setSearchText] = useState('');
  const [filteredVehicles, setFilteredVehicles] = useState(vehicles);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);

  // Definimos um objeto de contexto com os estados necessários
  const context = { 
    visible,
    setVisible
  };
  
  const clearSearch = useCallback(() => {
    setSearchText('');
    setFilteredVehicles(vehicles);
    setCurrentItemIndex(-1);
    if (onClear) {
      onClear();
    }
  }, [vehicles, onClear]);

  const handleOutsideClick = () => context.setVisible(false);

  const onItemPress = (item, index) => {
    onSelect(item);
    setVisible(false);
    setCurrentItemIndex(index);
    setSearchText(item.plate);
  };

  const keyDownCallback = useCallback((event) => {
    let nextIndex = null

    if (event.key === 'ArrowUp') {
      nextIndex = Math.max(0, currentItemIndex - 1)
    } else if (event.key === 'ArrowDown') {
      nextIndex = Math.min(filteredVehicles.length - 1, currentItemIndex + 1)
    } else if (event.key === 'Enter') {
      if (currentItemIndex >= 0) {
        onItemPress(filteredVehicles[currentItemIndex], currentItemIndex);
      }
    } else if (event.code === 'Escape') {
      clearSearch();
    } else if (event.code === 'Tab') {
      if (currentItemIndex >= 0) {
        onItemPress(filteredVehicles[currentItemIndex], currentItemIndex);
      } else {
        if (filteredVehicles.length > 0) {
          onItemPress(filteredVehicles[0], 0);
        } else {
          setVisible(false);
        }
      }

      return;
    }

    if (nextIndex !== null) {
      virtuosoRef.current.scrollIntoView({
        index: nextIndex,
        behavior: 'auto',
        done: () => {
          setCurrentItemIndex(nextIndex)
        },
      })
      event.preventDefault()
    }
  }, [filteredVehicles, currentItemIndex, virtuosoRef, setCurrentItemIndex]);

  useEffect(() => {
    if (vehicles) {
      if (searchText.length > 0) {
        const searchValue = searchText
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        ;
      
        const filtered = vehicles.filter((item) => {
          return item.plate.toLowerCase().includes(searchValue) ||
            item.denomination.toLowerCase().includes(searchValue) ||
            item.searchName.includes(searchValue) ||
            item.searchAffiliated.includes(searchValue)
          ;
        });
        setFilteredVehicles(filtered);
      } else {
        setFilteredVehicles(vehicles);
      }
    } else {
      setFilteredVehicles([]);
    }
  }, [vehicles]);

  useEffect(() => {
    if (selected) {
      if (select && selected.id === select.id) {
        return;
      }

      setFilteredVehicles([selected]);
      setSearchText(selected.plate);
      setCurrentItemIndex(0);
      setSelect(selected);

      return;
    }

    // Limpamos o estado do componente
    setSearchText('');
    setFilteredVehicles(vehicles);
    setCurrentItemIndex(-1);
  }, [selected]);

  useEffect(() => {
    if (searchText.length > 0) {
      const searchValue = searchText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      ;
    
      const filtered = vehicles.filter((item) => {
        return item.plate.toLowerCase().includes(searchValue) ||
          item.denomination.toLowerCase().includes(searchValue) ||
          item.searchName.includes(searchValue) ||
          item.searchAffiliated.includes(searchValue)
        ;
      });
      setFilteredVehicles(filtered);
    } else {
      setFilteredVehicles(vehicles);
    }
  }, [searchText]);

  const renderItem = useCallback((index, item) => {
    return (
      <div
        key={index}
        className='dropdown-item'
        style={{
          backgroundColor: index === currentItemIndex ? '#ffffff40' : 'transparent'
        }}
        onClick={() => onItemPress(item, index)}
      >
        <div className="text">
          {item.plate}
          {item.denomination && ` - ${item.denomination}`}
        </div>
        <div className="description">
          { item.brand } - { item.model }
        </div>
        <div className="owner">
          { item.affiliated
              ? item.name + ' - ' + item.affiliated
              : item.name
          }
        </div>
      </div>
    );
  }, [onItemPress, currentItemIndex]);

  const renderDropdown = useMemo(() => {
    console.log('RenderDropdown');
    return (
      <>
      {filteredVehicles && filteredVehicles.length === 0 ? (
        <div className="noItems">Nenhum veículo encontrado</div>
      ) : (
        <div className="dropdown">
          <Virtuoso
            ref={virtuosoRef}
            style={{ width: '100%', height: '268px' }}
            data={filteredVehicles}
            totalCount={filteredVehicles.length}
            itemContent={renderItem}
          />
        </div>
      )}
      </>
    );
  }, [filteredVehicles, virtuosoRef]);

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%'
      }}
    >
      <DropdownContext.Provider value={context}>
        <div className="select-vehicle">
          <div
            className={
              "search-box"
                + (visible ? " active" : "")
            }
            style={{
              borderBottomLeftRadius: visible ? 0 : '.475rem',
              borderBottomRightRadius: visible ? 0 : '.475rem',
            }}
          >
            <svg
              viewBox="0 0 485.104 485.104"
              aria-hidden="true"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m471.481 405.861-146.639-146.631c37.405-66.25 28.109-151.948-28.217-208.317-32.838-32.838-76.492-50.913-122.907-50.913-46.431 0-90.085 18.075-122.908 50.913-67.717 67.74-67.701 177.979.02 245.738 32.85 32.823 76.488 50.897 122.919 50.897 30.489 0 59.708-7.939 85.518-22.595l146.557 146.557c18.113 18.121 47.493 18.129 65.641 0 8.706-8.71 13.593-20.512 13.608-32.823 0-12.317-4.902-24.12-13.592-32.826zm-386.201-143.67c-48.729-48.756-48.729-128.079-.016-176.828 23.62-23.627 55.029-36.634 88.453-36.634 33.407 0 64.816 13.007 88.451 36.627 48.715 48.756 48.699 128.094-.015 176.85-23.62 23.612-55.014 36.612-88.406 36.612-33.406 0-64.828-13.007-88.467-36.627z" />
            </svg>
            <input
              type="text"
              className="search-input"
              value={searchText}
              onKeyDown={keyDownCallback}
              onClick={() => setVisible(true)}
              onFocus={() => setVisible(true)}
              onChange={(e) => {
                setSearchText(e.target.value);
                setVisible(true);
              }}
              placeholder="Pesquisar..."
            />
            <svg
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="clickable"
              onClick={clearSearch}
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
            </svg>
          </div>
          {visible && renderDropdown}
        </div>
      </DropdownContext.Provider>
    </OutsideClickHandler>
  );
}

const styles = {
  noItems: {
    padding: 10,
    fontStyle: 'italic',
    color: '#888',
  },
};

export default SelectVehicle;