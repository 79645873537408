import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Select from 'react-select';
import {
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import {
  useParams
} from "react-router-dom";

import { isAuthenticated } from '../../features/authorization/AuthContext';
import Block from '../../components/Block';
import Panel from '../../components/Panel';
import SearchInput from '../../components/SearchInput';
import SidePanel from '../../components/SidePanel';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useDriverContext } from '../../features/DriverContext';
import { useIdentifierContext } from '../../features/IdentifierContext';
import { useDeviceContext } from '../../features/DeviceContext';
import { getCurrentUser } from '../../services/AuthService';
import * as DriverService from '../../services/DriverService';
import * as IdentifierService from '../../services/IdentifierService';
import * as DocumentsService from '../../services/DocumentsService';
import IButtonsPanel from '../../components/IButtonsPanel';
import { buildDriverRegister } from '../../hooks/buildRegisterFromData';
import DriverForm from '../../forms/DriverForm';
import DriverTable from '../../components/DriverTable';
import Modal from '../../components/Modal';

const DriverPage = () => {
  const { tenantName } = useParams();
  const {
    drivers,
    addDriver,
    updateDriver,
    removeDriver,
    updateDrivers
  } = useDriverContext();

  const {
    identifiers,
    //addIdentifier,
    //updateIdentifier,
    //removeIdentifier,
    updateIdentifiers
  } = useIdentifierContext();

  const {
    devicesState
  } = useDeviceContext();

  const user = getCurrentUser();

  const queryClient = useQueryClient();

  // As unidades de tempo
  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour   = 60 * oneMinute;

  // O intervalo de tempo para atualização das informações
  const updateInterval  = 30 * oneSecond;
  const documenInterval =  6 * oneHour;
  const [updateNow, setUpdateNow] = useState(false);
  
  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(true);
  const [showDriver, setShowDriver] = useState(false);
  const [showIdentifiers, setShowIdentifiers] = useState(false);
  const [showVehicles, setShowVehicles] = useState(false);

  // O controle dos motoristas
  const [searchDriverValue, setSearchDriverValue] = useState('');
  const [visibleDrivers, setVisibleDrivers] = useState([]);
  const [driver, setDriver] = useState(null);

  // Os veículos
  const [vehicles, setVehicles] = useState([]);

  // Os tipos de telefones
  const [phoneTypes, setPhoneTypes] = useState([]);

  // A tela de modal
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("Ativar");

  // Os clientes
  const customers = useMemo(() => {
    if (user.groupID < 6) {
      // Filtra os dispositivos para obter apenas aqueles com cliente
      // definido
      const filteredDevices = devicesState.devices.filter(
        device => device.customer !== undefined && device.customer !== null
      );

      // Cria um conjunto de clientes únicos 
      const uniqueCustomers = new Set();
      filteredDevices.forEach(device => {
        uniqueCustomers.add(device.customer.id);
      });

      // Converte o conjunto de clientes de volta para um array
      const uniqueCustomersArray = Array.from(uniqueCustomers);

      // Ordena os clientes alfabeticamente com base no nome
      const sortedCustomers = uniqueCustomersArray
        .map(customerId => {
          const device = filteredDevices.find(d => d.customer.id === customerId);
          return {
            value: device.customer.id,
            label: device.customer.id === device.customer.payerID
              ? device.customer.name
              : device.customer.affiliated
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
      ;

      // Adicionamos no começo uma opção para os motoristas do usuário
      sortedCustomers.unshift({
        value: null,
        label: 'Meus motoristas'
      });

      return sortedCustomers;
    }
  }, [devicesState.devices, user.groupID]);
  const [selectedCustomer, setSelectedCustomer] = useState((customers && customers[0]) || null);

  const getIdentifierList = useCallback((currentID) => {
    // Filtra os itens que não estão em use e não seja o item atual
    const filtedData = identifiers
      .filter(item => item.id === currentID || !('driverid' in item))
    ;

    return filtedData.map((identifier) => {
      return {
        value: identifier.id,
        label: identifier.identifier
      };
    });
  }, [identifiers]);

  // -----[ Funções auxiliares ]----------------------------------------

  function shouldRetry(failureCount, error) {
    // Não reexecute a consulta se o erro for um erro de autenticação
    console.log('error.message', error.message);
    console.log('error.name', error.name);
    if (
      (error.message === 'Unauthorized') ||
      (error.message === 'Unauthentic') ||
      (error.name === 'Unauthorized') ||
      (error.name === 'Unauthentic')
    ) {
      return false;
    }
  
    // Caso contrário, reexecute a consulta até 3 vezes
    return failureCount < 3;
  }

  function handleError(error, queryKey) {
    console.log('Erro ao atualizar...', error, queryKey);
    //const { name, message } = error;
    const { name } = error;
  
    if ((name === 'Unauthorized') || (name === 'Unauthentic')) {
      // goLogin();
      queryClient.clear();
      queryClient.resetQueries({ queryKey: [queryKey], exact: true });
      queryClient.invalidateQueries({ queryKey: [queryKey], exact: true });
    } else {
      //toast.error(message, {
      //  position: "top-right",
      //  autoClose: 20000,
      //  hideProgressBar: false,
      //  closeOnClick: true,
      //  pauseOnHover: true,
      //  draggable: true,
      //  progress: undefined,
      //  theme: "colored",
      //});
    }
  }

  // -----[ Tipos de telefone ]-----------------------------------------

  const fetchPhoneTypes = async () => {
    const response = await DocumentsService.getPhoneTypes();

    // Converte os dados recebidos para o formato de seleção
    const phoneTypeData = response.map(item => ({
      value: parseInt(item.id),
      label: item.name
    }));
    
    return phoneTypeData;
  }

  const {
    //isPending: isPhoneTypesPending,
    isError: isPhoneTypesError,
    error: phoneTypesError,
    data: phoneTypesData,
    isSuccess: isPhoneTypesSuccess,
    //refetch: refetchPhoneTypes,
  } = useQuery({
    queryKey: ['phoneTypes', 1],
    queryFn: fetchPhoneTypes,
    refetchInterval: documenInterval,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated(tenantName),
    retry: shouldRetry
  });

  if (isPhoneTypesError) {
    handleError(phoneTypesError, 'phoneTypes');
  }

  useEffect(() => {
    if (phoneTypesData) {
      setPhoneTypes(phoneTypesData);
    }
  }, [phoneTypesData, setPhoneTypes]);

  // -----[ Motoristas ]------------------------------------------------

  const fetchDrivers = async () => {
    console.log('Obtendo motoristas...');
    setIsLoading(true);
    const response = await DriverService.getDrivers(
      (user.groupID < 6)
        ? (
            selectedCustomer && selectedCustomer.value
              ? selectedCustomer.value
              : user.customerID
          )
        : user.customerID
    );
    
    const builtDrivers = response.map((driver) => {
      return new buildDriverRegister(driver);
    });
    setIsLoading(false);

    return builtDrivers;
  }

  const {
    isPending: isDriversPending,
    isError: isDriversError,
    error: driversError,
    data: driversData,
    refetch: refetchDrivers,
  } = useQuery({
    queryKey: ['drivers', 1],
    queryFn: fetchDrivers,
    refetchInterval: updateInterval,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated(tenantName) && isPhoneTypesSuccess,
    retry: shouldRetry
  });

  if (isDriversError) {
    handleError(driversError, 'drivers');
  }

  useEffect(() => {
    if (driversData) {
      updateDrivers(driversData);
    }
  }, [driversData, updateDrivers]);

  // -----[ Identificadores ]-------------------------------------------

  const fetchIdentifiers = async () => {
    console.log('Obtendo identificadores de motoristas...');
    setIsLoading(true);
    const response = await IdentifierService.getIdentifiers(
      (user.groupID < 6)
        ? (
            selectedCustomer && selectedCustomer.value
              ? selectedCustomer.value
              : user.customerID
          )
        : user.customerID
    );
    
    return response;
  }

  const {
    isPending: isIdentifiersPending,
    isError: isIdentifiersError,
    error: identifiersError,
    data: identifiersData,
    refetch: refetchIdentifiers,
  } = useQuery({
    queryKey: ['identifiers', 1],
    queryFn: fetchIdentifiers,
    refetchInterval: updateInterval,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated(tenantName) && isPhoneTypesSuccess,
    retry: shouldRetry
  });

  if (isIdentifiersError) {
    handleError(identifiersError, 'identifiers');
  }

  useEffect(() => {
    if (identifiersData) {
      updateIdentifiers(identifiersData);
    }
  }, [identifiersData, updateIdentifiers]);

  // -----[ Seleção de cliente ]----------------------------------------

  useEffect(() => {
    if (selectedCustomer) {
      // Obtemos os motoristas e identificadores do cliente selecionado
      console.log(
        'Obtendo motoristas e identificadores do cliente',
        selectedCustomer.label
      );
      setIsLoading(true);
      refetchDrivers();
      refetchIdentifiers();
    }
  }, [selectedCustomer, refetchDrivers, refetchIdentifiers]);


  useEffect(() => {
    if (updateNow) {
      setUpdateNow(false);
      refetchDrivers();
      refetchIdentifiers();
    }
  }, [updateNow, setUpdateNow, refetchDrivers, refetchIdentifiers]);


  // -----[ Edição de motorista ]---------------------------------------

  useEffect(() => {
    const allDrivers = drivers.slice();
    if (searchDriverValue) {
      const searchValue = searchDriverValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      ;
      const filteredDrivers = allDrivers.filter(driver => {
        return driver.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(searchValue)
        ;
      });

      setVisibleDrivers(filteredDrivers);
    } else {
      setVisibleDrivers(allDrivers);
    }
  }, [searchDriverValue, drivers]);

  useEffect(() => {
    //const idOfCustomer = (user.groupID < 6)
    //  ? (
    //      selectedCustomer && selectedCustomer.value
    //        ? selectedCustomer.value
    //        : user.customerID
    //    )
    //  : user.customerID
    //;

    setVehicles(previous => {
      return devicesState.devices
        .filter(device => device.vehicle !== undefined && device.vehicle !== null)
        .filter(device => device.vehicle !== undefined && device.vehicle !== null)
        .filter(device => device.equipment.iButton.hasReader === true)
        .map(device => {
          return {
            id: device.vehicle.id,
            equipmentid: device.equipment.id,
            plate: device.vehicle.plate,
            denomination: device.vehicle.denomination,
            name: device.customer.name,
            searchName: device.customer.name
              ? device.customer.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            affiliated: device.customer.affiliated,
            searchAffiliated: device.customer.affiliated
              ? device.customer.affiliated
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            brand: device.vehicle.brand.name,
            model: device.vehicle.model.name,
            type: device.vehicle.type.name,
            article: device.vehicle.type.article,
          };
        })
      ;
    });
  }, [devicesState.devices, setVehicles, selectedCustomer, user.groupID, user.customerID]);

  const handleAddDriver = () => {
    setDriver({
      id: 0,
      customerid: (user.groupID < 6)
        ? (
            selectedCustomer && selectedCustomer.value
              ? selectedCustomer.value
              : user.customerID
          )
        : user.customerID,
      name: '',
      nickname: '',
      occupation: '',
      birthdate: '',
      genderid: 1,
      cnh: '',
      cnhcategory: '',
      cnhexpirationdate: '',
      cpf: '',
      address: '',
      streetnumber: '',
      complement: '',
      district: '',
      cityid: null,
      postalcode: '',
      phones: [
        {
          driverphoneid: 0,
          phonenumber: '',
          phonetypeid: 2
        }
      ],
      active: true,
      identifiertechnologyid: 1,
      identifiertechnologyname: 'iButton',
      driveridentifierid: null,
      storedata: []
    });
    setShowDriver(true);

    // Após alguns instantes, coloca o foco no campo de nome
    setTimeout(() => {
      const input = document.getElementById('name');
      if (input) {
        input.focus();
      }
    }, 100);
  };

  const handleEditDriver = async (driverID) => {
    // Obtemos os dados do motorista
    const driverData = await DriverService.getDriver(driverID);
    // Os campos de data precisam ser convertidos para o formato
    // dd/mm/yyyy
    const birthDate = driverData.birthdate
      ? driverData.birthdate.split('-').reverse().join('/')
      : ''
    ;
    const cnhExpirationDate = driverData.cnhexpirationdate
      ? driverData.cnhexpirationdate.split('-').reverse().join('/')
      : ''
    ;
    const phones = driverData.phones
      ? driverData.phones.map(phone => {
          return {
            driverphoneid: parseInt(phone.driverphoneid),
            phonenumber: phone.phonenumber,
            phonetypeid: parseInt(phone.phonetypeid)
          };
        })
      : []
    ;

    setDriver({
      ...driverData,
      birthdate: birthDate,
      genderid: 1,
      cnhexpirationdate: cnhExpirationDate,
      genderid: parseInt(driverData.genderid),
      cityid: driverData.cityid
      ? parseInt(driverData.cityid)
      : null
      ,
      postalcode: driverData.postalcode.trim(),
      phones: phones,
      identifiertechnologyid: 1,
      identifiertechnologyname: 'iButton'
    });
    setShowDriver(true);
  };

  const handleDriverUpdated = async (driverData) => {
    console.log('handleDriverUpdated', driverData);

    // Força que o gênero seja sempre 1 (não informado)
    driverData.genderid = 1;

    // Precisamos retirar as informações de equipamentos para os quais o
    // iButton está associado, de forma a preparar os dados para a
    // requisição
    const storedata = driverData.storedata;
    if (storedata) {
      if (driverData.id === 0) {
        // Todos os equipamentos são novos
        driverData.storedata = [
          storedata,
          []
        ];
      } else {
        // Criamos duas listas, uma para os novos equipamentos e outra
        // para os equipamentos que foram removidos
        
        // Recuperamos os dados atuais do identificador
        const currentIdentifier = identifiers.find((item) => item.id === driverData.driveridentifierid);
        if (currentIdentifier) {
          const currentStoreData = currentIdentifier.storedata
            ? currentIdentifier.storedata
            : []
          ;
          const newItens = storedata
            .filter((item) => {
              return !currentStoreData.some((currentItem) => {
                return currentItem.vehicleid === item.vehicleid;
              });
            })
          ;
          const removedItens = currentStoreData
            .filter((item) => {
              return !storedata.some((currentItem) => {
                return currentItem.vehicleid === item.vehicleid;
              });
            })
            .map((item) => item.id)
          ;
          driverData.storedata = [
            newItens,
            removedItens
          ];
        }
      }
    } else {
      // Não há dados de equipamentos, então envia valores vazios
      driverData.storedata = [
        [],
        []
      ];
    }

    // Fazemos a requisição para adicionar/modificar o motorista'
    const result = (driverData.id === 0)
      ? await DriverService.addDriver(driverData)
      : await DriverService.updateDriver(driverData)
    ;
    if (result) {
      if (driverData.id === 0) {
        // Atualizamos o ID do identificador
        driverData.id = parseInt(result);
        // Restauramos os dados de equipamentos
        driverData.storedata = storedata;
        // Adicionamos o identificador na lista
        console.log('Driver added', driverData);
      } else {
        // Restauramos os dados de equipamentos
        driverData.storedata = storedata;
        // Atualizamos o identificador na lista
        console.log('Modified driver', driverData);
      }

      console.log('End driver editing');
      setDriver(null);

      // Forçamos a atualização da lista de motoristas
      setUpdateNow(true);
    }
  };

  const handleActivateDriver = (driverID, name) => {
    // Pergunta ao usuário se deseja realmente ativar o motorista
    setModalTitle(`Ativar motorista`);
    setModalMessage("Deseja realmente ativar o(a) motorista "
      + name + "?"
    );
    setModalData(driverID);
    setModalAction('Ativar');
    setModalOpen(true);
  };

  const handleDeactivateDriver = (driverID, name) => {
    // Pergunta ao usuário se deseja realmente desativar o motorista
    setModalTitle(`Desativar motorista`);
    setModalMessage("Deseja realmente desativar o(a) motorista <b>"
      + name + "</b>?<br /><br />A desativação do motorista fará com que ele não possa mais acessar os veículos.");
    setModalData(driverID);
    setModalAction('Desativar');
    setModalOpen(true);
  };

  const onActivateDriver = async (driverID) => {
    // Fazemos a requisição para ativar o motorista
    DriverService.activateDriver(driverID)
      .then(() => {
        // Atualizamos a lista de motoristas
        setUpdateNow(true);
      })
    ;
  };

  const onDeactivateDriver = async (driverID) => {
    // Fazemos a requisição para desativar o motorista
    DriverService.deactivateDriver(driverID)
      .then(() => {
        // Atualizamos a lista de motoristas
        setUpdateNow(true);
      })
    ;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      backgroundColor: "var(--theme-input-bg)",
      borderRadius: ".375rem",
      borderColor: "var(--theme-input-border-color)",
      boxShadow: state.isFocused ? "0 0 0 2px #007bff" : "none",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      fontWeight: 400,
      lineHeight: 1.5,
      borderRadius: ".375rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-placeholder-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: state.isFocused ? "var(--theme-dropdown-hover-bg)" : "transparent",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: ".5rem .3rem",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: "var(--theme-dropdown-bg)",
      borderRadius: ".25rem",
      border: "1px solid var(--theme-input-border-color)"
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: "var(--theme-heading-color)",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: ".5rem .3rem",
      textTansform: "none",
    })
  };

  return (
    <Block horizontal={true}>
      <Modal
        title={modalTitle}
        message={modalMessage}
        data={modalData}
        isOpen={isModalOpen}
        confirmAction={modalAction}
        onConfirm={(id) => {
          setModalOpen(false);
          if (modalAction === 'Ativar') {
            onActivateDriver(id);
          } else {
            onDeactivateDriver(id);
          }
        }}
        onClose={() => setModalOpen(false)}
        style={{
          left: '50%',
          top: '30%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <Panel style={styles.panel}>
        <div style={styles.headerBar}>
          <div style={styles.headerContainer}>
            <h3 style={styles.title}>Motoristas</h3>
            <p className='text-muted'>Visualize e gerencie os motoristas, seus identificadores e em quais veículos eles terão acesso.</p>
            {user.groupID < 6 && (
            <div style={styles.headerContent}>
              <span style={styles.customerLabel}>Cliente:</span>
              <Select
                id="customer"
                value={selectedCustomer}
                onChange={(customer) => {
                  setSelectedCustomer(customer);
                }}
                options={customers}
                placeholder="Selecione um cliente..."
                styles={customStyles}
              />
            </div>
            )}
            <div style={styles.headerContentRow}>
              <button
                style={{
                  ...styles.button,
                  backgroundColor: 'var(--theme-primary)',
                }}
                onClick={() => { handleAddDriver(); }}
              >
                <svg
                  width=".9rem"
                  height=".9rem"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" fill="currentColor" />
                </svg>
                Novo motorista
              </button>
              <SearchInput
                value={searchDriverValue}
                placeholder="Localize um motorista..."
                onChange={setSearchDriverValue}
                size="30rem"
              />
            </div>
          </div>
          <div style={styles.headerItem}>
            <p style={{color: 'var(--theme-gray-800)', height: '6rem'}}>
              Gerencie seus iButtons, permitindo cadastrá-los e visualizar
              qual o motorista associado
            </p>
            <button
              style={{
                ...styles.button,
                backgroundColor: 'var(--theme-secondary)',
              }}
              onClick={() => { setShowIdentifiers(true); }}
            >
              Gerenciar iButtons
            </button>
          </div>
          <div
            style={{
              ...styles.headerItem,
              display: 'none'
            }}
          >
            <p style={{color: 'var(--theme-gray-800)', height: '6rem'}}>
              Gerencie os motoristas cadastrados em cada veículo,
              podendo incluir ou excluir e
              visualizar o sincronismos de dados com o veículo.
            </p>
            <button
              style={{
                ...styles.button,
                backgroundColor: 'var(--theme-secondary)',
              }}
              onClick={() => { setShowVehicles(true); }}
            >
              Motoristas por veículo
            </button>
          </div>
        </div>
        <div style={styles.tableWrapper}>
          <DriverTable
            drivers={visibleDrivers}
            handleEditDriver={handleEditDriver}
            handleActivateDriver={handleActivateDriver}
            handleDeactivateDriver={handleDeactivateDriver}
          />
        </div>
      </Panel>
      <LoadingOverlay
        isLoading={isLoading}
        message="Aguarde enquanto carregamos os dados..."
      />
      <SidePanel
        side="left"
        title="Editar motorista"
        isOpen={showDriver}
        onClose={() => { setShowDriver(false); }}
      >
        <p className='text-muted'>
        Visualize e edite os dados do motorista selecionado.
        </p>
        {driver && (
        <DriverForm
          driver={driver}
          getIdentifiers={() => getIdentifierList(driver.driveridentifierid)}
          phoneTypes={phoneTypes}
          customerVehicles={vehicles}
          customerName={(user.groupID < 6)
            ? (
                selectedCustomer && selectedCustomer.label
                  ? selectedCustomer.label
                  : user.customerName
              )
            : user.customerName
          }
          onCancel={() => { setDriver(null); setShowDriver(false); }}
          onSubmit={(driverData) => {
            console.log('Driver is submitting');
            handleDriverUpdated(driverData);
            setShowDriver(false);
          }}
          driverNames={drivers.map(driver => driver.name)}
        />
        )}
      </SidePanel>
      <IButtonsPanel
        selectedCustomer={
          (user.groupID < 6)
            ? (
                selectedCustomer && selectedCustomer.value
                  ? selectedCustomer.value
                  : user.customerID
              )
            : user.customerID
        }
        selectedCustomerName={
          (user.groupID < 6)
            ? (
                selectedCustomer && selectedCustomer.label
                  ? selectedCustomer.label
                  : user.customerName
              )
            : user.customerName
        }
        drivers={drivers}
        vehicles={vehicles}
        user={user}
        updateNow={() => setUpdateNow(true)}
        isOpen={showIdentifiers}
        onClose={() => { setShowIdentifiers(false); }}
      />
      <SidePanel
        side="left"
        title="Motoristas por veículo"
        isOpen={showVehicles}
        onClose={() => { setShowVehicles(false); }}
      >
        <p className='text-muted'>
          Gerencie os motoristas cadastrados em cada veículo,
          permitindo rapidamente incluir ou excluir motoristas e
          visualizar o sincronismos de dados com o dispositivo.
        </p>
      </SidePanel>
    </Block>
  );
};

const styles = {
  panel: {
    borderRadius: "0",
    flexDirection: "column",
    width: '100%',
    padding: '.5rem .5rem',
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem',
  },
  headerContainer: {
    flexGrow: 1,
  },
  headerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '.5rem',
    gap: '.5rem',
    borderRadius: '.475rem',
    border: '1px solid var(--theme-gray-800)',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '300px',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: '.5rem'
  },
  customerLabel: {
    fontSize: '.9rem',
    paddingBottom: '.1rem',
    color: 'var(--theme-text-color)',
  },
  title: {
    marginBottom: '.5rem',
  },
  headerContentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '.5rem',
    gap: '.5rem',
  },
  button: {
    display: 'inline-flex',
    padding: '.58571429em .58571429em .58571429em',
    border: 'none',
    borderRadius: '.475rem',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    gap: '.5rem',
  },
  tableWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    rounded: '.475rem',
    flex: 1,
    border: '2px solid var(--theme-border-color)',
    backgroundColor: 'var(--theme-modal-bg)',
    borderRadius: '.5rem',
  },
  cellInline: {
    display: "inline-flex",
    justifyContent: "center",
    alignContent: "center",
    gap: ".5rem",
    padding: "0 .5rem",
    whiteSpace: "nowrap"
  },
};

export default DriverPage;
