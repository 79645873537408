import React from 'react';
import { toast } from 'react-toastify';

import Tooltip from './Tooltip';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import useCopyToClipboard from '../../hooks/clipboard';

import 'react-toastify/dist/ReactToastify.css';

function Customer({ customer, hideAssociation }) {
  
  const { isClipboardApiSupported, copyToClipboard } = useCopyToClipboard();

  if (!customer) {
    return null;
  }

  const limitedLabel = (value, size) => {
    if (value.length > size) {
      // Obtém os primeiros 'n' caracteres
      return value.slice(0, size) + '…';
    }

    return value;
  };

  const firstLabel = hideAssociation ?
    (
      customer.affiliated
        ? limitedLabel(customer.affiliated, 50)
        : limitedLabel(customer.name, 50)
    )
    : limitedLabel(customer.name, 30)
  ;
  const secondLabel = customer.affiliated
    ? (
        hideAssociation
          ? ''
          : limitedLabel(customer.affiliated, 50)
      )
    : ''
  ;
  const clipboardText = (secondLabel !== '')
    ? customer.affiliated
    : (
        (hideAssociation && customer.affiliated)
          ? `${customer.affiliated}`
          : customer.name
      )
  ;
  
  const handleCopyPlate = (event) => {
    event.stopPropagation();
    
    copyToClipboard(clipboardText)
      .then(() => {
        toast.info(`Copiado ${clipboardText} para a área de transferência`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
    ;
  };

  return (
    <div className="doubleline left">
      <span className="line1">
        { isClipboardApiSupported() &&
          <Tooltip
            label="Clique para copiar para a área de transferência"
            side="right"
          >
            <CopyIcon
              className='copy-icon'
              color="var(--theme-gray-400)"
              onClick={handleCopyPlate}
            />
            &nbsp;
          </Tooltip>
        }
        {firstLabel}
      </span>
      <span className="line2 highlight">
        {secondLabel}
      </span>
    </div>
  );
}

export default Customer;
