import React from 'react';

import WebClientImage from "../../assets/images/webclient.png";
import AppImage1 from "../../assets/images/app1.png";
import AppImage2 from "../../assets/images/app2.png";
import AppImage3 from "../../assets/images/app3.png";

const HomePage = () => {
  return (
    <main className="site">
      <section id="home">
        <div className="content">
          <h2>
            O aplicativo MR Fleet
          </h2>
          <div className="sub header">
            Conheça o aplicativo e seus recursos
          </div>
        </div>
        <p>
          Você já imaginou poder monitorar o seu veículo a qualquer hora
          e em qualquer lugar, com apenas alguns cliques no seu celular?
        </p>
        <img src={WebClientImage} alt="MR Fleet no navegador" />
        <p>
          Pois isso é possível com o <span>MR Fleet</span>, um novo
          aplicativo de rastreamento de veículos que oferece diversas
          funcionalidades para você ter mais segurança e controle sobre
          o seu patrimônio.
        </p>
        <p>
          O <span>MR Fleet</span> é um aplicativo desenvolvido pela
          <span>MR Tecnologia</span>, uma empresa especializada em
          soluções de rastreamento e telemetria para frotas de todos os
          portes e segmentos. O aplicativo é compatível com os
          principais sistemas operacionais de smartphones e tablets,
          como Android e iOS, e permite que você acesse as informações
          do seu veículo de forma rápida e fácil.
        </p>
        <section className="galery">
          <div className="container">
            <div className="image-container">
              <img src={AppImage1} alt="Mapa no aplicativo" />
            </div>
            <div className="image-container">
              <img src={AppImage2} alt="Grid" />
            </div>
            <div className="image-container">
              <img src={AppImage3} alt="Tela inicial" />
            </div>
          </div>
          <p>O aplicativo para celular</p>
        </section>
        <p>
          Com o <span>MR Fleet</span>, você pode acompanhar o seu veículo
          em tempo real, visualizando a sua localização, velocidade,
          direção e status (ligado, desligado ou em movimento) em um mapa
          interativo. Você também pode acessar o histórico de posições e
          eventos do seu veículo, como ignição, frenagem, aceleração,
          curva, parada e muito mais. Assim, você pode verificar o
          percurso realizado pelo seu veículo, analisar o seu
          comportamento ao volante e identificar possíveis desvios ou
          irregularidades.
        </p>
        <p>
          Além disso, o <span>MR Fleet</span> permite que você seja
          avisado em tempo real de alarmes e alertas ocorridos com o
          seu veículo, como violação de cerca eletrônica, excesso de
          velocidade, bateria baixa, pânico acionado e outros. Você pode
          configurar os tipos de alarmes e alertas que deseja receber e
          ser notificado por push no aplicativo. Dessa forma, você pode
          agir rapidamente em caso de emergência ou situação suspeita.
        </p>
        <p>
          O <span>MR Fleet</span> é um aplicativo que traz mais
          tranquilidade e praticidade para você que possui um veículo
          rastreado pela <span>MR Tecnologia</span>. Com ele, você pode
          ter acesso às informações do seu veículo a qualquer momento e
          em qualquer lugar, sem precisar ligar para a central de
          atendimento ou acessar o site da empresa. Você pode baixar o
          aplicativo gratuitamente na Google Play Store ou na App Store
          e começar a usar agora mesmo. Não perca tempo e experimente o
          <span>MR Fleet</span>!
        </p>
      </section>
    </main>
  );
};

export default HomePage;