import React, { useEffect, createContext, useContext, useState } from "react";
import classNames from 'classnames';

import Button from './Button';
import OutsideClickHandler from "./OutsideClickHandler";

import '../styles/Dropdown.scss';

// O contexto para lidarmos com o estado do menu dropdown
export const DropdownContext = createContext(null);

function useToggleClick({
  clickEvent,
  setClickEvent,
  clickEventRef,
  setMenuIsOpen,
}) {
  return function () {
    setClickEvent(() => !clickEvent);
    setMenuIsOpen(() => clickEventRef);
  };
}

export function Dropdown({ children, ...rest }) {
  // Rastreamos cliques que deveriam fechar o menu dropdown
  const [clickEvent, setClickEvent, clickEventRef] = useState(false);
  // Lidamos com o referenciamento do botão de toggle
  const [toggleRef, setToggleRef] = useState(null);

  // Estado para rastrear se o menu dropdown está aberto ou não
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Estado para rastrear se o menu dropdown está com o cursor sobre o
  // mesmo
  const [hovered, setHovered] = useState(false);

  // Definimos um objeto de contexto com os estados necessários
  const context = { 
    clickEvent,
    setClickEvent,
    clickEventRef,
    toggleRef,
    setToggleRef,
    menuIsOpen,
    setMenuIsOpen,
    hovered,
    setHovered
  };

  const handleMouseEnter = () => context.setHovered(true);
  const handleMouseLeave = () => context.setHovered(false);
  const handleOutsideClick = () => context.setMenuIsOpen(false);

  const menuSize = rest.hasOwnProperty('large') ? 'large' : 'normal';

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
    >
      <DropdownContext.Provider value={context}>
        <div
          className={`dropdown ${menuSize}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...rest}
        >
          { children }
        </div>
      </DropdownContext.Provider>
    </OutsideClickHandler>
  );
}

export function Toggle({ children, attribute='', ...rest }) {
  const context = useContext(DropdownContext);

  const [toggleRef, setToggleRef] = useState(null);
  useEffect(() => {
    context.setToggleRef(toggleRef);
  }, [context, toggleRef, setToggleRef]);

  const toggleClick = () => context.setMenuIsOpen(() => !context.menuIsOpen);

  return (
    <Button
      className={classNames('flat', attribute)}
      onClick={toggleClick}
      ref={toggleRef}
      {...rest}
    >
      { children }
    </Button>
  );
}

export function Content({ children }) {
  const context = useContext(DropdownContext);

  const contentClass=classNames('dropdown-content',{
    //'show': (context.menuIsOpen || context.hovered)
    'show': (context.menuIsOpen)
  });

  return (
    <div className={contentClass}>
      { children }
    </div>
  );
}

export function MenuItem({ children, disabled, onClick }) {
  const context = useContext(DropdownContext);
  const handleSideClick = useToggleClick(context);

  const [toggleRef, setToggleRef] = useState();
  useEffect(() => {
    setToggleRef(context.toggleRef);
  }, [context, setToggleRef]);

  // Quando ocorrer um clique em um botão ou link que deveria fechar o
  // menu, feche-o (handleSideClick altera clickEvent, portanto,
  // useEffect detectará essa alteração)
  useEffect(handleClickSideEffect, [context, context.clickEvent, toggleRef]);
  function handleClickSideEffect() {
    // Não alterna se já estiver fechado
    if (context.menuIsOpen) {
      // O clique no botão de alternância usa a refêrência para o
      // toggleDropdown ao qual pertence para propagar o click
      if (toggleRef && toggleRef.current) {
        toggleRef.current.click();

        // Reflete a alteração no menu em seu estado. Podemos ser
        // explícitos e defini-lo como falso, pois sabemos que o estamos
        // fechando
        context.setMenuIsOpen(() => false);
      }
    }
  }
  const handleClick = () => {
    onClick();
    handleSideClick();
  };

  return (
    <span className={`item${disabled ? ' disabled':''}`} onClick={handleClick}>{ children }</span>
  );
}

export function DocketItem({ children }) {
  return (
    <span className="docket">{ children }</span>
  );
}

export function Divider() {
  return (
    <div className="ui divider"></div>
  );
}
export function BlankSpace() {
  return (
    <div className="ui space"></div>
  );
}
