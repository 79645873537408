import React, {
  useEffect,
  useState
} from 'react';
import { format, parse } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { ptBR } from 'date-fns/locale';
import {
  useController,
  useFormContext
} from "react-hook-form";

import 'react-day-picker/dist/style.css';

const DatePickerInput = ({
  name,
  options = {},
  ...props
}) => {
  const {
    control,
    register,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  // Função para parsear o valor da data
  const parseValue = (value, fault = '') => {
    if (!value) return fault;

    if (typeof value === 'string' || value instanceof String) {
      // Limpa quaisquer caracteres que não sejam números
      value = value.replace(/[^\d]/g, '');
      if (value === '') return fault;

      // Adiciona a máscara à data
      if (value.length >= 3 && value.length <= 4) {
        value = value.replace(/(\d{2})(\d{1,2})/, '$1/$2');
      } else if (value.length >= 5) {
        value = value.replace(/(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3');
      }

      return value;
    }

    return fault;
  }

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return parseValue(defaultValues[name]);
    }

    return '';
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(parseValue(defaultValues[name]));
    } else {
      setDefaultValue('');
    }
  }, [defaultValues, name]);

  const {
    field
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options }
  });

  const [month, setMonth] = useState(() => {
    const parsedDate = parse(field.value, 'dd/MM/yyyy', new Date());
    
    return !isNaN(parsedDate)
      ? parsedDate
      : new Date()
    ;
  });
  
  const [selectedDate, setSelectedDate] = useState(() => {
    const parsedDate = parse(field.value, 'dd/MM/yyyy', new Date());
    
    return !isNaN(parsedDate) ? parsedDate : undefined;
  });
  
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Atualiza a data selecionada e o mês quando o valor do campo muda
  useEffect(() => {
    if (field.value) {
      const parsedDate = parse(field.value, 'dd/MM/yyyy', new Date());
      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
        setMonth(parsedDate);
      }
    }
  }, [field.value]);

  // Manipulador para seleção de data no calendário
  const handleDayPickerSelect = (date) => {
    if (!date) {
      field.onChange("");
      setSelectedDate(undefined);
      setMonth(new Date());
    } else {
      setSelectedDate(date);
      field.onChange(format(date, 'dd/MM/yyyy'));
      setIsCalendarOpen(false);
    }
  };

  // Manipulador para mudanças no input
  const handleOnInput = (event) => {
    const { value: inputValue } = event.target;

    const parsedValue = parseValue(inputValue);

    if (parsedValue === '') {
      field.onChange("");
      setSelectedDate(undefined);
      setMonth(new Date());

      return;
    }

    const parsedDate = parse(parsedValue, 'dd/MM/yyyy', new Date());
    field.onChange(parsedValue);

    if (!isNaN(parsedDate)) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
    } else {
      setSelectedDate(undefined);
      setMonth(new Date());
    }
  };

  // Manipulador para colar dados no input
  const handleOnPaste = (event) => {
    event.preventDefault();
    
    const clipboardData = event.clipboardData.getData('text');

    if (clipboardData) {
      if (typeof clipboardData === 'string' || clipboardData instanceof String) {
        event.target.value = clipboardData;
        handleOnInput(event);
      }
    }
  };

  // Manipulador para perda de foco do input
  const handleOnBlur = (event) => {
    // Cria um novo evento baseado no evento original
    const newEvent = { ...event, target: { ...event.target, value: field.value } };
    field.onBlur(newEvent);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <input
        type="text"
        style={{ paddingRight: '30px' }}
        id={name}
        name={name}
        value={field.value}
        onInput={handleOnInput}
        onPaste={handleOnPaste}
        onBlur={handleOnBlur}
        disabled={isSubmitting}
        maxLength={10}
        {...props}
      />
      <span
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
      >
        <svg
          width="1rem"
          height="1rem"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-calendar">
          <path d="M8 2v4" />
          <path d="M16 2v4" />
          <rect width="18" height="18" x="3" y="4" rx="2" />
          <path d="M3 10h18" />
        </svg>
      </span>
      {isCalendarOpen && (
        <div style={styles.dropdownContainer}>
          <DayPicker
            locale={ptBR}
            month={month}
            onMonthChange={setMonth}
            mode="single"
            selected={selectedDate}
            onSelect={handleDayPickerSelect}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  dropdownContainer: {
    position: 'absolute',
    zIndex: 1000,
    top: '100%',
    left: 0,
    backgroundColor: 'var(--theme-dropdown-bg)',
    border: '1px solid var(--theme-dropdown-caret-bg)',
    borderRadius: '.475rem',
    boxShadow: "0 7px 14px 0 rgba(3, 12, 51, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.2)"
  },
};

export default DatePickerInput;
