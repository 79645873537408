import { toast } from 'react-toastify';

import Tooltip from './Tooltip';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import useCopyToClipboard from '../../hooks/clipboard';

import 'react-toastify/dist/ReactToastify.css';

function DriverAndIdentifier({
  driver,
  identifier,
  align='center'
}, ) {
  const { isClipboardApiSupported, copyToClipboard } = useCopyToClipboard();

  const limitedLabel = (value, size) => {
    if (value.length > size) {
      return '…' + value.slice(-size)
    }

    return value;
  };
  const firstLabel = (driver)
    ? limitedLabel(driver, 15)
    : (identifier?'Motorista não identificado':'Sem motorista')
  ;
  const secondLabel = (identifier)
    ? `ID: ${identifier}`
    : ''
  ;
  
  const handleCopyDriverName = (event) => {
    event.stopPropagation();
    
    copyToClipboard(firstLabel)
      .then(() => {
        toast.info(`Copiado ${firstLabel} para a área de transferência`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
    ;
  };

  return (
    <div className={`doubleline ${align}`}>
      <span className="line1">
        { isClipboardApiSupported() &&
          <Tooltip
            label="Clique para copiar para a área de transferência"
            side="right"
          >
            <CopyIcon
              className='copy-icon'
              color="var(--theme-gray-400)"
              onClick={handleCopyDriverName}
            />
            &nbsp;
          </Tooltip>
        }
        {firstLabel}
      </span>
      <span className="line2 highlight">
        {secondLabel}
      </span>
    </div>
  );
}

export default DriverAndIdentifier;
