function Row({ rightAligned, children }) {
  const classes = "form-row" + (rightAligned ? " right-aligned" : "");
  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export default Row;
