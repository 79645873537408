import React, {
  useMemo,
  useRef
} from 'react';
import {
  useFormContext,
  Controller,
  useFieldArray
} from 'react-hook-form';

import {
  Button,
  Group,
  Row,
} from '../form';
import SelectVehicle from '../SelectVehicle';

function VehicleNotificationList({
  customerVehicles,
  notifications
}) {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vehicles',
  });

  const [newVehicle, setNewVehicle] = React.useState(null);

  const viewBoxRef = useRef(null);

  const handleAddVehicle = () => {
    if (newVehicle) {
      append({
        vehicleid: newVehicle.id,
        plate: newVehicle.plate,
        notification: 'DEFAULT'
      });
      setNewVehicle(null);

      // Movemos o scroll para o final da lista depois de um tempo
      // para que o novo item seja visível
      setTimeout(() => {
        viewBoxRef.current.scrollTop = viewBoxRef.current.scrollHeight;
      }, 100);
    }
  }

  const handleAddAllVehicle = () => {
    vehicleList.forEach(vehicle => {
      append({
        vehicleid: vehicle.id,
        plate: vehicle.plate,
        notification: 'DEFAULT'
      });
    });

    // Movemos o scroll para o final da lista depois de um tempo
    // para que o novo item seja visível
    setTimeout(() => {
      viewBoxRef.current.scrollTop = viewBoxRef.current.scrollHeight;
    }, 100);
  }
  
  // Armazena uma lista local dos veículos que podemos selecionar, que
  // é a lista de veículos do cliente menos os veículos que já foram
  // adicionados
  const vehicleList = useMemo(() => {
    return customerVehicles.filter(vehicle => {
      return !fields.some(field => field.vehicleid === vehicle.id);
    });
  }, [fields, customerVehicles]);

  return (
    <div style={styles.wrap}>
      {fields.length === 0 && <div style={styles.noitems}>Todos os veículos</div>}
      <div ref={viewBoxRef} style={styles.viewBox}>
      {fields.map((vehicle, index) => (
        <div key={vehicle.vehicleid}>
          <Row>
            <Controller
              name={`vehicles[${index}].vehicleid`}
              control={control}
              render={({ field }) => (
                <input type="hidden" {...field} />
              )}
            />
            <Controller
              name={`vehicles[${index}].plate`}
              control={control}
              render={({ field }) => (
                <Group flex={1}><input type="text" {...field} readOnly tabIndex={-1} /></Group>
              )}
            />

            {/* Campo para a configuração de notificação */}
            <Controller
              name={`vehicles[${index}].notification`}
              control={control}
              render={({ field }) => (
                <Group flex={2}>
                  <select {...field}>
                    {notifications.map(notification => (
                      <option key={notification.value} value={notification.value}>
                        {notification.label}
                      </option>
                    ))}
                  </select>
                </Group>
              )}
            />

            {/* Botão para remover o veículo */}
            <Button
              type="button"
              small
              color="red"
              onClick={() => remove(index)}
            >
              <svg
                height="14"
                viewBox="0 0 24.50898 24.50898"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m20.61402 1.26258h-5.3121v-1.00427999c0-.14262001-.11562-.25830001-.2583-.25830001h-5.5782599c-.14262 0-.25824.11568-.25824.25830001v1.00421999h-5.3121601c-.4275 0-.774.34656-.774.77406v2.4316201h18.26706v-2.4315601c0-.4275-.3465-.77406-.774-.77406zm-15.3491399 22.06446c.02766.660601.57126 1.18194 1.23234 1.18194h11.5146599c.66108 0 1.20468-.521339 1.23234-1.18194l.8223-17.3589599h-15.62394zm9.5944199-13.0473c0-.2766.22428-.5009399.501-.5009399h.801301c.276539 0 .500999.2242799.500999.5009399v9.91758c0 .27666-.22428.50094-.500999.50094h-.801301c-.2766 0-.501-.22416-.501-.50094zm-3.50634 0c0-.2766.22428-.5009399.50094-.5009399h.8013c.27654 0 .50094.2242799.50094.5009399v9.91758c0 .27666-.22422.50094-.50094.50094h-.8013c-.2766 0-.50094-.22416-.50094-.50094zm-3.5064599 0c0-.2766.22428-.5009399.50094-.5009399h.80136c.2766 0 .50094.2242799.50094.5009399v9.91758c0 .27666-.22428.50094-.50094.50094h-.80136c-.2766 0-.50094-.22416-.50094-.50094z" strokeWidth=".06" fill="currentColor" />
              </svg>
            </Button>
          </Row>
        </div>
      ))}
      </div>

      <div style={styles.footer}>
        <SelectVehicle
          vehicles={vehicleList}
          selected={newVehicle}
          onSelect={setNewVehicle}
          onClear={() => setNewVehicle(null)}
          height={150}
        />
        <Button
          small
          color="green"
          onClick={handleAddVehicle}
        >
          Adicionar
        </Button>
        <Button
          small
          color="gray"
          onClick={handleAddAllVehicle}
          disabled={vehicleList.length === 0}
        >
          Adicionar todos
        </Button>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  viewBox: {
    display: 'block',
    width: '100%',
    maxHeight: 'calc((33.063 * 3px) - 1px)',
    overflowY: 'auto',
    boxSizing: 'border-box',
    padding: '1px 0',
    marginBottom: '.2rem',
  },
  noitems: {
    fontSize: '.9rem',
    fontStyle: 'italic',
    color: '#888',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  footer: {
    display: 'inline-grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    alignItems: 'center',
    gap: '.5rem',
  }
};

export default VehicleNotificationList;
