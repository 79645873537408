import React from "react";

import "./styles.scss";

const PortStatus = ({ children }) => {
  return (
    <div className="portstatus">
      {children}
    </div>
  );
}

const PortStatusItem = ({ label, ports, index }) => {
  return (
    <div
      className="portstatus__row"
    >
      <div key={`portstatus_label_${index}`} className="portstatus__label">
        {label}
      </div>
      <div key={`portstatus_ports_${index}`} className="portstatus_ports">
        {Object.entries(ports).map(([portNumber, state]) => (
        <div
          className="portstatus__port"
          key={`portstatus_port_number_${portNumber}`}
          style={{
            backgroundColor: state === true ? "var(--theme-on)" : "var(--theme-danger)"
          }}
        >
          {portNumber}
        </div>
        ))}
      </div>
    </div>
  );
}

export { PortStatus, PortStatusItem };