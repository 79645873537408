const paginate = (data, pageNumber, itemsPerPage) => {
  const result = [];
  let itemCount = 0;
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  for (const group in data) {
    if (itemCount >= endIndex) {
      break;
    }
    
    const groupItems = data[group];
    result[group] = result[group] || [];

    for (let i = 0; i < groupItems.length; i++) {
      if (itemCount >= startIndex && itemCount < endIndex) {
        result[group].push(groupItems[i]);
      }

      itemCount++;
    }
  }

  // Removemos os grupos vazios
  for (const group in result) {
    if (result[group].length === 0) {
      delete result[group];
    }
  }

  return result;
};

export default paginate;