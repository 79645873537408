import React from 'react';

import {ReactComponent as NotFoundImage} from '../../assets/icons/pagenotfound.svg';

const UninformedTenant = () => {
  return (
    <main className="site">
      <section>
        <NotFoundImage />
        <h2>Desculpe</h2>
        <p>
          Você está tentando acessar o <span>MR Fleet</span> sem informar
          a empresa a ser acessada.
        </p>
        <p>
          Entre em contato com o suporte da empresa para verificar o
          endereço correto de acesso ao sistema.
        </p>
      </section>
    </main>
  );
};

export default UninformedTenant;