import React, {
  useState,
  useMemo
} from 'react';

import SquareButton from '../SquareButton';

const IconBox = ({ value, icons, columns = 3, onChange }) => {
  const [iconValue, setIconValue] = useState(value);

  const handleChangeIcon = (newIcon) => {
    setIconValue(newIcon);
    if (onChange) {
      onChange(newIcon);
    }
  }

  const Rows = useMemo(() => {
    return Object.entries(icons).map(([idKey, svg], index) => {
      const id = parseInt(idKey);
      
      return (
      <SquareButton
        key={index}
        value={id}
        selected={iconValue === id}
        onClick={() => handleChangeIcon(id)}
        color="#040c11"
        size="2.0rem"
      >
        <div className="square-icon" style={styles.icon} dangerouslySetInnerHTML={{ __html: svg}} />
      </SquareButton>
      );
    });
  }, [icons, iconValue, onChange]);

  return (
    <div
      style={{
        ...styles.container,
        gridTemplateColumns: `repeat(${columns}, 1fr)`
      }}
    >
      {Rows}
    </div>
  );
}

const styles = {
  container: {
    display: 'grid',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    padding: '.1rem',
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export default IconBox;