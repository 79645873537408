import React, { useState } from 'react';
import classNames from 'classnames';

import Tabs, { Tab } from '../Tabs';
import TimeElapsedLabel from "../TimeElapsedLabel";
import { getTip } from '../DeviceStatus.js';
import { formatMeasurement } from '../Formatter.js';
import GSMSignalStrength from '../GSMSignalStrength';

import { getDeviceInfo } from '../../hooks/deviceInfo';
import IgnitionStatus from '../StatusIcons/IgnitionStatus';
import BlockStatus from '../StatusIcons/BlockStatus';
import SirenStatus from "../StatusIcons/SirenStatus";
import { getUser } from '../../features/authorization/AuthContext';

import { ReactComponent as BatteryIcon } from "../../assets/icons/vehiclebattery.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as ElapseTimeIcon } from "../../assets/icons/elapsetime.svg";
import { ReactComponent as EntityIcon } from "../../assets/icons/entity.svg";
import { ReactComponent as InternalBatteryIcon } from "../../assets/icons/internalbattery.svg";
import { ReactComponent as PointIcon } from "../../assets/icons/point.svg";
import { ReactComponent as SpeedIcon } from "../../assets/icons/speed.svg";

import '../../styles/DeviceInfo.scss';

function DeviceInfo({ device }) {
  const [activeTab, setActiveTab] = useState(1);
  const user = getUser();

  const date = new Date(device.datetime).toLocaleDateString('pt-br');
  const time = new Date(device.datetime).toLocaleTimeString('pt-br');

  const {
    label,
    identification,
    denomination,
    typeName,
    brandName,
    modelName,
    colorName
  } = getDeviceInfo(device);

  return (
    <>
      <Title label={label} title={identification} denomination={denomination} />
      {device.customer &&
      <Item type='noback'>
        <Icon
          type='highlighted'
          tooltip='Cliente'
        >
          <EntityIcon />
        </Icon>
        <Content type='bold'>
          { device.customer.name }
        </Content>
      </Item>
      }
      {device.customer && device.customer.affiliated &&
      <Item type='noback'>
        <Content type='smaller'>
          Associado: <strong>{device.customer.affiliated}</strong>
        </Content>
      </Item>
      }
      <Tabs>
        <Tab
          additions="info"
          label='Informações'
          tabNumber={1}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <Item type='emphasis'>
            <Icon
              type='highlighted'
              tooltip='Endereço do local do posicionamento'
              side='right'
            >
              <PointIcon />
            </Icon>
            <Content type='normal'>
            { device.address }
            </Content>
          </Item>

          <Item type='emphasis'>
            <Icon
              type='highlighted'
              tooltip='A quanto tempo este posicionamento ocorreu'
            >
              <ElapseTimeIcon />
            </Icon>
            <Content type='normal truncate triple rightDivider'>
              <TimeElapsedLabel eventDate={device.datetime} />
            </Content>
            <Icon
              type='highlighted'
              tooltip='Data do posicionamento'
            >
              <CalendarIcon />
            </Icon>
            <Content type='normal one rightDivider'>
              { date }
            </Content>
            <Icon
              type='highlighted'
              tooltip='Hora do posicionamento'
            >
              <ClockIcon />
            </Icon>
            <Content type='normal one'>
              { time }
            </Content>
          </Item>

          <Item type='emphasis'>
            <Icon
              type='status rightSpace'
              tooltip={ getTip(device.ignition, 'ignition') }
            >
              <IgnitionStatus status={device.ignition} />
            </Icon>
            <Icon
              type='status rightSpace'
              tooltip={ getTip(device.block, 'block') }
            >
              <BlockStatus status={device.block} />
            </Icon>
            <Icon
              type='status rightSpace'
              tooltip={ getTip(device.siren, 'siren') }
            >
              <SirenStatus status={device.siren} />
            </Icon>
            <Icon
              type='status fixed'
              tooltip='Velocidade'
            >
              <SpeedIcon style={{ width: '1.8em', marginTop: '-.1em' }} />
            </Icon>
            <Content type='normal rightDivider'>
              {formatMeasurement(device.speed, 'km/h')}
            </Content>
            <Icon
              type='status fixed'
              tooltip='Bateria do veículo'
            >
              <BatteryIcon />
            </Icon>
            <Content type='normal rightDivider'>
              {formatMeasurement(device.vehicleBattery, 'V', 2)}
            </Content>
            <Icon
              type='status fixed'
              tooltip='Bateria interna'
            >
              <InternalBatteryIcon />
            </Icon>
            <Content type='normal'>
              {formatMeasurement(device.internalBattery, 'V', 1)}
            </Content>
          </Item>

          <Item type='emphasis spaced noBottomMargin'>
            <Content type='normal noGrow base10 highlighted'>
              Odômetro:
            </Content>
            <Content type='normal grow rightDivider'>
              {formatMeasurement(device.odometer, 'km')}
            </Content>
            <Content type='normal noGrow base10 highlighted'>
              Horímetro:
            </Content>
            <Content type='normal grow rightDivider'>
              {formatMeasurement(device.horimeter, 'h')}
            </Content>
            <Icon
              type='status fixed'
              tooltip={`Sinal ${device.gsmSignalStrength}%`}
            >
              <GSMSignalStrength width={30} value={device.gsmSignalStrength} />
            </Icon>
          </Item>
        </Tab>
        <Tab
          additions="info"
          label='Detalhes'
          tabNumber={2}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <Item type='emphasis'>
            <Content type='normal noGrow base10 highlighted'>
              Tipo:
            </Content>
            <Content type='normal grow rightDivider'>
              { typeName }
            </Content>
            <Content type='normal noGrow base10 highlighted'>
              Marca:
            </Content>
            <Content type='normal grow'>
              { brandName }
            </Content>
          </Item>
          <Item type='emphasis'>
            <Content type='normal noGrow base10 highlighted'>
              Modelo:
            </Content>
            <Content type='normal grow'>
              { modelName }
            </Content>
          </Item>
          <Item type='emphasis'>
            <Content type='normal noGrow base10 highlighted'>
              Cor:
            </Content>
            <Content type='normal grow'>
              { colorName }
            </Content>
          </Item>
          { user && user.groupID < 6 && (
          <Item type='emphasis'>
            <Content type='normal noGrow base20 highlighted'>
              Número de série:
            </Content>
            <Content type='normal grow'>
              { device.equipment.serialNumber }
            </Content>
          </Item>
          )}
        </Tab>
        <Tab
          additions="info"
          label='Comandos'
          tabNumber={3}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          Comandos
        </Tab>
      </Tabs>
    </>
  );
}

function Title({ label, title, denomination }) {
  return (
    <span className="detailbox title">
      { label }: <strong>{ title }</strong>
      {denomination && denomination.length > 0 &&
        <>
          &nbsp;/&nbsp;&nbsp;
          <span className="denomination">{ denomination }</span>
        </>
      }
    </span>
  );
}

function Item({ type, children }) {
  return (
    <div className={classNames('detailbox', 'item', type)}>
      { children }
    </div>
  );
}

function Icon({ type, tooltip, side='up', children }) {
  const tooltipClass = classNames(side, '200');

  return (
    <span
      className={classNames('iconHolder', type)}
      data-tooltip={tooltipClass}
      aria-label={tooltip}
    >
      { children }
    </span>
  );
}

function Content({ type, children }) {
  return (
    <span className={classNames('content', type)}>
      { children }
    </span>
  );
}
  
export default DeviceInfo;