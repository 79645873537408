import Api from './Api';

/**
 * Recupera os identificadores de motoristas cadastrados.
 * 
 * @param {int} customerID
 *   O ID do cliente (opcional)
 * 
 * @returns
 *   Os dados da requisição de identificadores de motoristas cadastrados
 */
const getIdentifiers = async (
  customerID
) => {
  const queryParms = {
    customerID: customerID || 0
  };

  return Api
    .get("/driver/identifier/list", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const addIdentifier = async (
  driver
) => {
  console.log('addIdentifier', driver);
  return Api
    .post("/driver/identifier/add", driver)
    .then((response) => {
      if (response.status === 'Created') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const updateIdentifier = async (
  driver
) => {
  return Api
    .put("/driver/identifier/update", driver)
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const removeIdentifier = async (
  id
) => {
  return Api
    .delete(`/driver/identifier/delete/${id}`, {})
    .then((response) => {
      if (response.status === 'Deleted') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
}

export {
  getIdentifiers,
  addIdentifier,
  updateIdentifier,
  removeIdentifier
};