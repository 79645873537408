import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useEventContext } from '../../features/EventContext';
import useNotification from '../../hooks/useNotification';
import { getDateTimeFromSQL } from '../../hooks/dateUtils';
import useNotificationSound from '../../hooks/useNotificationSound';
import SoundForAlarm from '../../assets/sounds/SoundForAlarm.mp3';
import SoundForAlert from '../../assets/sounds/SoundForAlert.mp3';
import ToastMessage from '../../components/ToastMessage';

function NotificationSender() {
  // O contexto de eventos
  const {
    eventsState
  } = useEventContext();

  const [lastNotification, setLastNotification] = useState(null);

  // O controle de notificações
  const { playNotificationSound: playSoundForAlarm } = useNotificationSound(SoundForAlarm);
  const { playNotificationSound: playSoundForAlert } = useNotificationSound(SoundForAlert);
  const { sendNotification } = useNotification();

  useEffect(() => {
    if (eventsState.newEvents.length > 0) {
      let emitAlarmSound = false;
      let emitEventSound = false;
      let newAlarms = 0;
      let newNotices = 0;
      let latestSystemDate = lastNotification
      const latestNotificationDate = lastNotification
      console.debug('Iniciando notificação', latestSystemDate);

      eventsState.newEvents.forEach(event => {
        const eventSystemDate = getDateTimeFromSQL(event.systemdate);

        if (latestSystemDate === null) {
          latestSystemDate = eventSystemDate;
          console.debug('Definindo útima notificação', latestSystemDate);
        } else {
          if (eventSystemDate.getTime() > latestSystemDate.getTime()) {
            latestSystemDate = eventSystemDate;
          }
        }
        
        if ( (latestNotificationDate && eventSystemDate.getTime() > latestNotificationDate.getTime())
             || latestNotificationDate === null ) {
          // Disparamos a mensagem de alerta de acordo com o
          // tipo do evento
          switch (event.type) {
            case 'Alarm':

              setTimeout(
                toast.error(<ToastMessage event={event} />,
                {
                  theme: "colored",
                  style: { backgroundColor: "#cc0000" },
                  pauseOnFocusLoss: false,
                  pauseOnHover: false
                }), 200
              );
              
              emitAlarmSound = true;
              newAlarms++;

              break;
            case 'Notice':
              setTimeout(
                toast.info(<ToastMessage event={event} />,
                {
                  theme: "colored",
                  style: { backgroundColor: "#2a76dd" },
                  pauseOnFocusLoss: false,
                  pauseOnHover: false
                }), 200
              );
              emitEventSound = true;
              newNotices++;
    
              break;
            default:
              // Não notifica outros eventos
              break;
          }
        }
      });

      setLastNotification(latestSystemDate);
      console.info('Notificação mais recente', latestSystemDate);

      // Disparamos uma notificação do sistema, se disponível
      if (newAlarms > 0 || newNotices > 0) {
        const title = (newAlarms + newNotices) > 1
          ? 'Ocorreram novos eventos'
          : 'Ocorreu um novo evento'
        ;
        const message = ((newAlarms + newNotices) > 1 ? 'Foram recebidos ' : 'Foi recebido ')
          + (
              (newAlarms > 0)
                ? `${newAlarms} ${newAlarms > 1 ? 'novos alarmes' : 'novo alarme'}`
                : ''
            )
          + (
            ((newAlarms > 0) && (newNotices > 0))
              ? ' e '
              : ''
            )
          + (
            (newNotices > 0)
              ? `${newNotices} ${newNotices > 1 ? 'novos alertas' : 'novo alerta'}`
              : ''
            )
        ;

        console.info(message);
        sendNotification(title, message + '. Por favor, verifique!');
      }

      if (eventsState.playSounds === true) {
        if (emitAlarmSound) {
          // Toca o som de alarme
          playSoundForAlarm();
        } else {
          if (emitEventSound) {
            // Toca o som de alerta
            playSoundForAlert();
          }
        }
      }
    }
  }, [
    eventsState.newEvents,
    eventsState.playSounds,
    playSoundForAlarm,
    playSoundForAlert,
    sendNotification,
    lastNotification
  ]);

  return null;
}

export default NotificationSender;
