import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { getVehiclesWithEquipmentsWithoutComunicationHistory } from '../../services/VehicleService';
import { HistoryVehicleWithoutCommunicationReport } from '../../features/reports/historyVehicleWithoutCommunicationReport';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const BuildVehiclesWithoutCommunication = (tenant) => {
  const documentInfo = {
    name: 'Relatório de veículos sem comunicação à mais de 48 horas',
    info: {
      title: 'Veículos sem comunicação',
      author: tenant.name,
      subject: 'Relatório de veículos sem comunicação à mais de 48 horas',
      keywords: 'Sem comunicação, relatório, veículo, placa',
      creator: 'MR Fleet',
    }
  };

  console.log(
    'Solicitando informações do relatório de veículos sem comunicação à mais de 48 horas...'
  );

  getVehiclesWithEquipmentsWithoutComunicationHistory()
    .then(async (response) => {
      console.log(
        'Recebido as informações do relatório de veículos sem '
        + 'comunicação à mais de 48 horas'
      );
      const reportData = response;

      if (reportData.length > 0) {
        console.log('Construindo relatório em PDF');

        const historyVehicleWithoutCommunicationReport = new HistoryVehicleWithoutCommunicationReport({
          report: documentInfo,
          data: reportData,
          logo: tenant.logo.normal
        });
        const documentDataForPDF = await historyVehicleWithoutCommunicationReport.preparesDocumentDataForPDF();
        const filename = 'Veículos sem comunicação à mais de 48 horas';
        const fullFilename = encodeURIComponent(filename.replace(/\s/g, '_') + '.pdf');
    
        pdfMake
          .createPdf(documentDataForPDF)
          //.open({}, window.open('', '_blank'))
          .getBuffer(function (buffer) {
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const URL = window.URL || window.webkitURL;
            const headers = {
              'Content-Disposition': `attachment; filename="${fullFilename}"`
            };
            const downloadUrl = URL.createObjectURL(blob, headers);
            window.open(downloadUrl, '_blank');
          })
        ;

      }
    })
    .catch((error) => {
      console.error('Erro ao solicitar informações do relatório de veículos sem comunicação à mais de 48 horas...');
      console.error(error);
    })
  ;
};

export default BuildVehiclesWithoutCommunication;