import {
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  useParams
} from "react-router-dom";
import { toast } from 'react-toastify';
import {
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

import { isAuthenticated } from '../../features/authorization/AuthContext';
import { useDeviceContext } from '../../features/DeviceContext';
import { useEventContext } from '../../features/EventContext';
import { useGeoContext } from '../../features/GeoContext';
import {
  dataTimeStringAtHoursPast
} from '../../hooks/dateUtils';
import * as SymbolService from '../../services/SymbolService';
import * as PositionService from '../../services/PositionService';
import * as EventService from '../../services/EventService';
import * as EmblemService from '../../services/EmblemService';
import * as GeoService from '../../services/GeoService';
import { buildDeviceRegister } from '../../hooks/buildRegisterFromData';

import 'react-toastify/dist/ReactToastify.css';

function SystemUpdater({
  blockUpdate,
  setOnUpdate,
  updateNow,
  resetUpdateNow,
  endUpdate,
  goLogin,
  clearInitialLoading,
  forceInitialLoading
}) {
  // O contexto autenticação
  const { tenantName } = useParams();
  const queryClient = useQueryClient();
  const [ignoreNextError, setIgnoreNextError] = useState(null);

  // As unidades de tempo
  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour   = 60 * oneMinute;

  // O intervalo de tempo para atualização das APIs
  const deviceInterval   = 30 * oneSecond;
  const eventInterval    = 30 * oneSecond;
  const symbolInterval   =  6 * oneHour;
  const emblemInterval   =  6 * oneHour;
  const fenceInterval    =  30 * oneSecond;
  const landmarkInterval =  30 * oneSecond;

  // O contexto de dispositivos
  const {
    firstCall,
    devicesState,
    updateDevices,
    updateSymbols
  } = useDeviceContext();

  // Obtém a lista de dispositivos visíveis no grid
  const devicesOnPage = useMemo(() => {
    return devicesState.currentDevices && devicesState.currentDevices.map((device) => device.equipment.id);
  }, [devicesState.currentDevices]);

  // O contexto de eventos
  const {
    updateEvents
  } = useEventContext();
  const [lastRequestTime, setLastRequestTime] = useState(
    dataTimeStringAtHoursPast(1)
  );

  // O contexto de geolocalização
  const {
    updateEmblems,
    updateFences,
    updateLandmarks
  } = useGeoContext();

  // -----[ Funções auxiliares ]----------------------------------------

  function shouldRetry(failureCount, error) {
    // Não reexecute a consulta se o erro for um erro de autenticação
    console.log('error.message', error.message);
    console.log('error.name', error.name);
    if (
      (error.message === 'Unauthorized') ||
      (error.message === 'Unauthentic') ||
      (error.name === 'Unauthorized') ||
      (error.name === 'Unauthentic')
    ) {
      return false;
    }
  
    // Caso contrário, reexecute a consulta até 3 vezes
    return failureCount < 3;
  }

  function handleError(error, queryKey) {
    console.log('Erro ao atualizar...', error, queryKey);
    const { name, message } = error;
  
    if ((name === 'Unauthorized') || (name === 'Unauthentic')) {

      if (ignoreNextError === queryKey) {
        // Ignora o erro imediatamente após o login
        setIgnoreNextError(null);
      } else {
        setIgnoreNextError(queryKey);
        goLogin();
        queryClient.clear();
        queryClient.resetQueries({ queryKey: [queryKey], exact: true });
        queryClient.invalidateQueries({ queryKey: [queryKey], exact: true });
      }
    } else {
      if (ignoreNextError !== null) {
        setIgnoreNextError(null);
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  // -----[ Símbolos ]--------------------------------------------------

  const fetchAllSymbols = async () => {
    const response = await SymbolService.getSymbols();
  
    return response;
  }

  const {
    //isPending: isSymbolsPending,
    isError: isSymbolsError,
    error: symbolsError,
    data: symbolsData,
    isSuccess: isSymbolSuccess,
    //refetch: refetchSymbols,
  } = useQuery({
    queryKey: ['symbols', 1],
    queryFn: fetchAllSymbols,
    refetchInterval: symbolInterval,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated(tenantName),
    retry: shouldRetry
  });

  if (isSymbolsError) {
    handleError(symbolsError, 'symbols');
  }

  useEffect(() => {
    if (symbolsData) {
      updateSymbols(symbolsData);
    }
  }, [symbolsData, updateSymbols]);

  // -----[ Dispositivos ]----------------------------------------------

  const fetchDevices = async (page, pageSize) => {
    console.log('Obtendo página', page, 'de dispositivos...');
    const response = await PositionService.getLastPositions(
      page, pageSize, null, firstCall ? null : devicesOnPage
    );

    const { data, numberOfPages } = response;
    
    const builtDevices = data.map( (device) => {
      return new buildDeviceRegister(device);
    });

    return {
      devices: builtDevices,
      hasNextPage: page < numberOfPages
    };
  }

  const fetchAllDevices = async () => {
    let allDevices = [];
    let currentPage = 1;
    let hasNextPage = true;
    const pageSize = 1000;

    console.log('Iniciando a atualização de dispositivos...');
    setOnUpdate(true);

    while (hasNextPage) {
      console.log(
        'Obtendo página', currentPage, 'de dispositivos...'
      );
      const response = await fetchDevices(currentPage, pageSize);
    
      allDevices = allDevices.concat(response.devices);
      hasNextPage = response.hasNextPage;
      currentPage++;
    }

    setOnUpdate(false);
    console.log('Atualização de dispositivos concluída...');

    return allDevices;
  }

  const {
    isPending: isDevicesPending,
    isError: isDevicesError,
    error: devicesError,
    data: devicesData,
    refetch: refetchDevices,
  } = useQuery({
    queryKey: ['devices', 1],
    queryFn: fetchAllDevices,
    refetchInterval: deviceInterval,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated(tenantName) && isSymbolSuccess && !devicesState.inUpdating,
    retry: shouldRetry
  });

  if (isDevicesError) {
    handleError(devicesError, 'devices');
  }

  useEffect(() => {
    if (updateNow === 1) {
      resetUpdateNow();
      refetchDevices();
    }
  }, [updateNow, resetUpdateNow, refetchDevices]);

  useEffect(() => {
    if (isDevicesPending === false) {
      clearInitialLoading();
    }
  }, [isDevicesPending, clearInitialLoading]);

  useEffect(() => {
    if (devicesData) {
      updateDevices(devicesData);
      endUpdate();
    }
  }, [devicesData, updateDevices, endUpdate]);

  useEffect(() => {
    if (forceInitialLoading === true) {
      console.log('Forçando a atualização de dispositivos...');
      refetchDevices();
    }
  }, [forceInitialLoading, refetchDevices]);

  // -----[ Eventos ]---------------------------------------------------

  const fetchEvents = async (page, pageSize, lastRequest) => {
    const response = await EventService.getUntreatedEvents(
      page, pageSize, lastRequest
    );

    const { data, requestTime, numberOfPages } = response;

    if (page === 1) {
      console.log('requestTime', requestTime);
      setLastRequestTime(requestTime);
    }
    
    if (data) {
      const builtEvents = data.map( (deviceEvent) => {
        return new buildDeviceRegister( deviceEvent );
      });

      return {
        events: builtEvents,
        hasNextPage: page < numberOfPages
      };
    }

    return {
      events: [],
      hasNextPage: false
    };
  }

  const fetchAllEvents = async () => {
    let allEvents = [];
    let currentPage = 1;
    let hasNextPage = true;
    //const lastRequest = lastRequestTime;
    const lastRequest = '2024-06-21T00:23:01';
    const pageSize = 1000;

    console.log('Iniciando a atualização de eventos...');

    while (hasNextPage) {
      console.log(
        'Obtendo página', currentPage, 'de eventos...'
      );
      const response = await fetchEvents(currentPage, pageSize, lastRequest);
    
      allEvents = allEvents.concat(response.events);
      hasNextPage = response.hasNextPage;
      currentPage++;
    }

    console.log('Atualização de eventos concluída...');

    return allEvents;
  }

  const {
    //isPending: isEventsPending,
    isError: isEventsError,
    error: eventsError,
    data: eventsData,
    //refetch: refetchEvents,
  } = useQuery({
    queryKey: ['events', 1],
    queryFn: fetchAllEvents,
    refetchInterval: eventInterval,
    refetchIntervalInBackground: true,
    enabled: isAuthenticated(tenantName) && isSymbolSuccess,
    retry: shouldRetry
  });

  if (isEventsError) {
    handleError(eventsError, 'events');
  }

  useEffect(() => {
    if (eventsData) {
      updateEvents(eventsData);
      endUpdate();
    }
  }, [eventsData, updateEvents, endUpdate]);

  // -----[ Cercas ]----------------------------------------------------

  const fetchAllFences = async () => {
    const response = await GeoService.getFences();
  
    return response;
  }

  const {
    //isPending: isFencesPending,
    isError: isFencesError,
    error: fencesError,
    data: fencesData,
    //refetch: refetchFences,
  } = useQuery({
    queryKey: ['fences', 1],
    queryFn: fetchAllFences,
    refetchInterval: fenceInterval,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: isAuthenticated(tenantName),
    retry: shouldRetry
  });

  if (isFencesError) {
    handleError(fencesError, 'fences');
  }

  useEffect(() => {
    if (fencesData) {
      updateFences(fencesData);
    }
  }, [fencesData, updateFences]);

  // -----[ Emblemas ]--------------------------------------------------

  const fetchAllEmblems = async () => {
    const response = await EmblemService.getEmblems();
  
    return response;
  }

  const {
    //isPending: isEmblemsPending,
    isError: isEmblemsError,
    error: emblemsError,
    data: emblemsData,
    isSuccess: isEmblemsSuccess,
    //refetch: refetchEmblems,
  } = useQuery({
    queryKey: ['emblems', 1],
    queryFn: fetchAllEmblems,
    refetchInterval: emblemInterval,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: isAuthenticated(tenantName),
    retry: shouldRetry
  });

  if (isEmblemsError) {
    handleError(emblemsError, 'emblems');
  }

  useEffect(() => {
    if (emblemsData) {
      updateEmblems(emblemsData);
    }
  }, [emblemsData, updateEmblems]);

  // -----[ Pontos de referência ]--------------------------------------

  const fetchAllLandmarks = async () => {
    const response = await GeoService.getLandmarks();
  
    return response;
  }

  const {
    //isPending: isLandmarksPending,
    isError: isLandmarksError,
    error: landmarksError,
    data: landmarksData
    //refetch: refetchLandmarks
  } = useQuery({
    queryKey: ['landmarks', 1],
    queryFn: fetchAllLandmarks,
    refetchInterval: landmarkInterval,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated(tenantName) && isEmblemsSuccess,
    retry: shouldRetry
  });

  if (isLandmarksError) {
    handleError(landmarksError, 'landmarks');
  }

  useEffect(() => {
    if (landmarksData) {
      updateLandmarks(landmarksData);
    }
  }, [landmarksData, updateLandmarks]);
}

export default SystemUpdater;
