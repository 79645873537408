import { createContext, useContext } from 'react';

const TenantContext = createContext();

function useTenantContext() {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error(
      'useTenantContext deve ser utilizado dentro de um TenantProvider'
    );
  }
  
  return context;
}

export { TenantContext, useTenantContext };