import {
  LayersControl,
  MapContainer,
  ScaleControl,
  ZoomControl
} from 'react-leaflet'

import FloatingBox from '../FloatingBox';
import SearchField from '../Maps/SearchField';
import CustomControls from './CustomControls.js';
import MapLayer from './MapLayer';
import MapRuler from './MapRuler';
import { TogglePanelControl } from './TogglePainelControl.jsx';

import 'leaflet/dist/leaflet.css';
import './customLeaflet.css';

// Classes usadas pelo Leaflet para posicionar os controles
// const POSITION_CLASSES = {
//   bottomleft: 'leaflet-bottom leaflet-left',
//   bottomright: 'leaflet-bottom leaflet-right',
//   topleft: 'leaflet-top leaflet-left',
//   topright: 'leaflet-top leaflet-right',
// };

function Map({
  onRef,
  onMapReady,
  center,
  children,
  togglePanelCollapsed,
  isPanelCollapsed,
}) {
  /* Alguns exemplos de camadas que podem ser adicionadas ao mapa
   * Mapa padrão do Here
   * <LayersControl.BaseLayer name="Here">
   *   <MapLayer name="Here" />
   * </LayersControl.BaseLayer>
   * 
   * Mapa de topologia do Here
   * <LayersControl.BaseLayer name="Topologia">
   *   <MapLayer name="HereTopo" />
   * </LayersControl.BaseLayer>
   */
  return (
    <MapContainer
      ref={onRef}
      center={center.position}
      zoom={center.zoom}
      style={{ height: "100%", width: "100%", zIndex: "0" }}
      zoomControl={false}
      zoomSnap={0.5}
      whenReady={(map) => {
        if (onMapReady) {
          onMapReady(map)
        }
      }}
    >
      <ZoomControl position="topright" />
      <CustomControls position="topright" />
      <ScaleControl />
      <MapRuler />
      <FloatingBox />
      <SearchField />
      <LayersControl position="topright" style={{ width: '48px', height: '48px' }}>
        <LayersControl.BaseLayer name="Padrão" checked={true}>
          <MapLayer name="OSM" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satélite">
          <MapLayer name="HereSatellite" />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Trânsito" checked={false}>
          <MapLayer name="HereTraffic" />
        </LayersControl.Overlay>
        {children}
      </LayersControl>
      { togglePanelCollapsed && (
        <TogglePanelControl
          position="bottomright"
          togglePanelCollapsed={togglePanelCollapsed}
          isPanelCollapsed={isPanelCollapsed}
        />
      )}
    </MapContainer>
  );
}

export default Map;
