import React, {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { toast } from 'react-toastify';
import {
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

import { useAPIContext } from '../../features/APIContext';
import { isAuthenticated } from '../../features/authorization/AuthContext';
import { useDeviceContext } from '../../features/DeviceContext';
import * as CommandService from '../../services/CommandService';
import DeviceDetails from '../../components/DeviceDetails';
import DeviceCommand from '../../components/DeviceCommand';
import ResponseDisplay from '../../components/ResponseDisplay';
import SelectInput from '../../components/SelectInput';
import Table from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import {
  getCommandHistoryColumns,
  getCommandHistoryRowColor,
  getEmptyCommandHistoryRegister,
  getBuiltCommands
} from './commandOptions';
import { sendCommand } from '../../services/CommandService';
import { buildCommandHistoryRegister } from '../../hooks/buildRegisterFromData';

import '../../styles/Command.scss';
import '../../styles/Form.scss';

import bg_corner from '../../assets/images/corner.png';

const CommandPage = () => {
  // O contexto autenticação
  const navigate = useNavigate();
  const { tenantName } = useParams();
  const queryClient = useQueryClient();
  const [ignoreNextError, setIgnoreNextError] = useState(null);

  // As unidades de tempo
  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour   = 60 * oneMinute;

  // O intervalo de tempo para atualização das APIs
  const commandInterval  =  6 * oneHour;
  const historyInterval  =  2 * oneSecond;

  const {
    devicesState,
    updateCommands,
    setSelectedEquipmentForHistory,
    setWaitingPopulate,
    updateCommandHistory
  } = useDeviceContext();
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const getInitialShowParameterDescriptionState = () => {
    const showParameterDescriptionValue = localStorage.getItem("showParameterDescription");
  
    if (showParameterDescriptionValue === null || showParameterDescriptionValue === undefined) {
      // Atribui um valor padrão
      localStorage.setItem("showParameterDescription", true);
      
      return true;
    }
  
    return showParameterDescriptionValue === 'true';
  }
  
  const [showParameterDescription, setShowParameterDescription] = useState(getInitialShowParameterDescriptionState());

  const {
    changeBlockUpdate
  } = useAPIContext();

  // O controle de comandos
  const [commands, setCommands] = useState([]);
  const [command, setCommand] = useState(null);
  const emptyCommandHistoryRegister = getEmptyCommandHistoryRegister();
  const commandHistoryColumns = getCommandHistoryColumns();
  const [responseData, setResponseData] = useState('Nenhum histórico selecionado');
  const [lineSeparator, setLineSeparator] = useState(null);
  const [fieldSeparators, setFieldSeparators] = useState(null);
  
  // Os equipamentos do usuário
  const [equipmentList, setEquipmentList] = useState([]);

  // -----[ Funções auxiliares ]----------------------------------------

  function shouldRetry(failureCount, error) {
    // Não reexecute a consulta se o erro for um erro de autenticação
    if (error.name === 'Unauthorized' || error.name === 'Unauthentic') {
      return false;
    }
  
    // Caso contrário, reexecute a consulta até 3 vezes
    return failureCount < 3;
  }

  function handleError(error, queryKey) {
    console.log('Erro ao atualizar...', error, queryKey);
    const { name, message } = error;
  
    if ((name === 'Unauthorized') || (name === 'Unauthentic')) {
      if (ignoreNextError === queryKey) {
        // Ignora o erro imediatamente após o login
        setIgnoreNextError(null);
      } else {
        setIgnoreNextError(queryKey);
        queryClient.clear();
        queryClient.resetQueries({ queryKey: [queryKey], exact: true });
        queryClient.invalidateQueries({ queryKey: [queryKey], exact: true });
        console.log(`Desautenticado. Redirecionando para /app/${tenantName}/login`);
        navigate(`/app/${tenantName}/login`);
      }
    } else {
      if (ignoreNextError !== null) {
        setIgnoreNextError(null);
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  // -----[ Comandos ]--------------------------------------------------

  const fetchCommands = async (page, pageSize) => {
    const response = await CommandService.getDeviceCommands(page, pageSize);
  
    const { data, numberOfPages } = response;
  
    return {
      commands: data,
      hasNextPage: page < numberOfPages
    };
  }
  
  const fetchAllCommands = async () => {
    let allCommands = [];
    let currentPage = 1;
    let hasNextPage = true;
    const pageSize = 1000;
  
    console.log('Iniciando atualização de comandos...');
  
    while (hasNextPage) {
      const response = await fetchCommands(currentPage, pageSize);
  
      allCommands = allCommands.concat(response.commands);
      hasNextPage = response.hasNextPage;
      currentPage++;
    }
  
    console.log('Encerrando a atualização de comandos...');
  
    return allCommands;
  }
  
  const {
    isPending: isCommandsPending,
    isError: isCommandsError,
    error: commandsError,
    data: commandsData,
    status: commandsStatus,
    refetch: refetchCommands,
  } = useQuery({
    queryKey: ['commands', 1],
    queryFn: fetchAllCommands,
    refetchInterval: commandInterval,
    retry: shouldRetry
  });

  if (isCommandsError) {
    handleError(commandsError, 'commands');
  }
  
  useEffect(() => {
    if (commandsData) {
      updateCommands(commandsData);
    }
  }, [commandsData, updateCommands]);

  // -----[ Histórico de Comandos ]-------------------------------------

  const fetchCommandHistory = async (page, pageSize, equipmentID) => {
    const response = await CommandService.getCommandsHistory(
      page,
      pageSize,
      'equipment',
      equipmentID,
      false,
      'hours',
      48,
      null
    );
  
    const { data, numberOfPages } = response;
  
    return {
      commands: data,
      hasNextPage: page < numberOfPages
    };
  }
  
  const fetchAllCommandHistory = async () => {
    let allCommandHistory = [];
    let currentPage = 1;
    let hasNextPage = true;
    const pageSize = 1000;
    const equipmentID = selectedEquipment.value;
  
    console.log('Iniciando atualização de histórico de comandos...');
  
    while (hasNextPage) {
      const response = await fetchCommandHistory(
        currentPage,
        pageSize,
        equipmentID
      );
  
      allCommandHistory = allCommandHistory.concat(response.commands);
      hasNextPage = response.hasNextPage;
      currentPage++;
    }
  
    console.log('Encerrando a atualização de histórico de comandos...');
  
    return allCommandHistory;
  }
  
  const {
    isPending: isHistoryPending,
    isError: isHistoryError,
    error: historyError,
    data: historyData,
    status: historyStatus,
  } = useQuery({
    queryKey: ['commandhistory', 1],
    queryFn: fetchAllCommandHistory,
    refetchInterval: historyInterval,
    enabled: isAuthenticated(tenantName) && (selectedEquipment !== null),
    retry: shouldRetry
  });

  if (isHistoryError) {
    handleError(historyError, 'commandhistory');
  }
  
  useEffect(() => {
    if (isHistoryPending) {
      setResponseData('Aguarde enquanto carregamos os dados...');
    }
    if (historyData) {
      const builtHistory = historyData.map((history) => {
        return new buildCommandHistoryRegister(history);
      });
      console.log(
        `Recuperados ${historyData.length} histórico de comandos`
      );
      updateCommandHistory(builtHistory);
    }
  }, [historyData, selectedEquipment, updateCommandHistory, isHistoryPending]);

  // -----[ Página ]----------------------------------------------------

  // Atualiza as informações de veículos e equipamentos
  useEffect(() => {
    const newVehicles = devicesState.devices
      .filter(device => device.vehicle !== undefined && device.vehicle !== null)
      .map(device => {
        return {
          value:device.equipment.id,
          label:device.vehicle.plate
        };
      })
    ;
    const newEquipments = devicesState.devices
      .filter(device => device.vehicle === undefined || device.vehicle === null)
      .map(device => {
        return {
          value:device.equipment.id,
          label:device.equipment.serialNumber
        };
      })
    ;
    setEquipmentList(previous => [
      {
        label: 'Veículos',
        options: [ ...newVehicles ]
      },
      {
        label: 'Equipamentos',
        options: [ ...newEquipments ]
      }
    ]);

    if (selectedEquipment) {
      const { value: equipmentID } = selectedEquipment;
      const device = devicesState.devices.find(device => device.equipment.id === equipmentID);
      setSelectedDevice(previous => device);
    }
  }, [devicesState.devices, setEquipmentList, selectedEquipment]);

  const handleChangeEquipment = (value) => {
    setSelectedEquipment(value);
    setResponseData('Nenhum histórico selecionado');
    if (value) {
      const { value: vehicleID } = value;

      // Encontra o equipamento selecionado e o define como selecionado
      const device = devicesState.devices.find(device => device.equipment.id === vehicleID);
      setSelectedDevice(device);
      setSelectedEquipmentForHistory(device.equipment.id);
      setWaitingPopulate(true);

      // Separamos os comandos disponíveis para este modelo de
      // equipamento
      const { protocolID, protocolVariantID: variantID } = device.equipment.model;
      const commandForThisModel = devicesState.commands.filter(data => 
        data.protocolid === protocolID && 
        (data.protocolvariantid === variantID || data.protocolvariantid === null)
      );
      const sortCommands = (a, b) => {
        // Compara os valores da coluna commandGroupID
        if (a.commandgroupid !== b.commandgroupid) {
          return a.commandgroupid - b.commandgroupid;
        }
      
        // Se os valores da coluna commandGroupID forem iguais, compara os valores da coluna name
        return a.name.localeCompare(b.name);
      };
      commandForThisModel.sort(sortCommands);
      
      const builtCommands = getBuiltCommands(commandForThisModel);
      setCommands(builtCommands);
      setCommand(null);
    } else {
      setSelectedDevice(null);
      setSelectedEquipmentForHistory(0);
      setCommands([]);
      setCommand(null);
    }
  };

  const handleCommandSelected = (selectedCommand) => {
    console.log('Selecionado o comando', selectedCommand);
    setCommand(selectedCommand);
  };

  const handleHistoryCommandClicked = (history) => {
    console.log('Selecionado o histórico do comando', history);
    if (history) {
      setResponseData(history.response);
      setLineSeparator(history.lineSeparator);
      setFieldSeparators(history.fieldSeparators);
    } else {
      setResponseData('Nenhum histórico selecionado');
    }
  }

  const handleSendCommand = async (commandData, replacementValues) => {
    console.log(
      "Enviando comando", command.value, command.label, "[", commandData, "]",
      "para o equipamento", selectedDevice.equipment.id
      );
    console.log('Parâmetros utilizados', replacementValues);
    try {
      changeBlockUpdate(true);
      const response = await sendCommand(
        selectedDevice.equipment.id,
        command.value,
        replacementValues,
        commandData
      );
      // TODO: Verificar se o comando foi enviado com sucesso
      console.log('Comando ', command.value, command.label, "[", commandData, "]", ' enviado com sucesso');
    } catch (error) {
      console.log(error);
    } finally {
      changeBlockUpdate(false);
    }
  };

  useEffect(() => {
    return () => {
      // Your code you want to run on unmount.
      setSelectedDevice(null);
      setSelectedEquipmentForHistory(0);
      setCommands([]);
      setCommand(null);
    };
  }, []); 

  const toggleShowParameterDescription = () => {
    const newValue = !showParameterDescription;
    setShowParameterDescription(newValue);
    localStorage.setItem("showParameterDescription", newValue);
  }

  return (
    <div className="command-container">
      <div className="section mb-3">
        <div className="section-body position-relative"  style={{ backgroundImage: `url(${bg_corner})`, backgroundPosition: '50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <div className="row">
            <div className="col-lg-16">
              <h3>Envio de comandos</h3>
              <div className="description" style={{display:"block"}}>
                Informe o veículo para o qual deseja enviar:
                <div className="select-devicedetail-container">
                  <div className="select-container">
                    <SelectInput
                      name="equipment"
                      options={equipmentList}
                      value={selectedEquipment}
                      placeholder="Selecione o veículo ou equipamento..."
                      noOptionsMessage={() => 'Nenhum veículo ou equipamento encontrado.'}
                      onChange={handleChangeEquipment}
                    />
                  </div>
                  <DeviceDetails device={selectedDevice} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingOverlay isLoading={isCommandsPending} message="Aguarde enquanto carregamos os dados..." />
      <div className="container_area">
        <div className="command_area">
          <div className="select_field_area">
            <SelectInput
              name="comands"
              options={commands}
              value={command}
              placeholder="Selecione um comando..."
              noOptionsMessage={() => 'Nenhum comando encontrado.'}
              onChange={handleCommandSelected}
            />
          </div>
          <DeviceCommand
            command={command}
            onSendCommand={handleSendCommand}
            showParameterDescription={showParameterDescription}
            onChangeShowParameterDescription={toggleShowParameterDescription}
          />
        </div>
        <div className="result_area">
          <h3>Resposta do dispositivo</h3>
          <p className="description">
            O conteúdo da resposta para o comando solicitado. Clique em
            uma linha na tabela para ver o seu conteúdo.
          </p>
          <ResponseDisplay
            response={responseData}
            lineSeparator={lineSeparator}
            fieldSeparators={fieldSeparators}
          />
        </div>
        <div className="table_area">
          <Table
            data={selectedEquipment && devicesState.commandHistory.length > 0 
              ? devicesState.commandHistory
                  .sort((a, b) => {
                    return b.requestedAt.localeCompare(a.requestedAt);
                  })
              : emptyCommandHistoryRegister}
            columns={commandHistoryColumns}
            rowColor={getCommandHistoryRowColor}
            onClick={handleHistoryCommandClicked}
            isLoading={devicesState.waitingPopulate}
            noContentMessage="Nenhum histórico de comandos disponível nas últimas 48 horas"
          />
        </div>
      </div>
    </div>
  );
}

export default CommandPage;
