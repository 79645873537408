import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as ClearIcon } from '../assets/icons/clear.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';

import '../styles/SearchBar.scss';

function SearchMenu({ options, onSelect }) {
  return (
    <div
      className="search-menu"
      onClick={(event) => event.stopPropagation()}
    >
      {options.length === 0 && (
        <div className="search-menu-item">
          Nenhum resultado encontrado
        </div>
      )}
      {options.map((option, index) => (
        <div
          key={index}
          className="search-menu-item"
          onClick={(event) => {
            event.stopPropagation();
            onSelect(option)
          }}
        >
          {option.name}
        </div>
      ))}
    </div>
  );
}

function SearchBar({
  searchValue,
  onChange,
  onClear,
  isLoading,
  placeholder,
  onDemand,
  withOptions,
  getFilteredOptions,
  onSelect,
}) {
  const [searchInput, setSearchInput] = useState(searchValue);
  const searchButtonRef = useRef(null);
  const searchClearRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false); // Estado para controlar a exibição do menu
  const [menuOptions, setMenuOptions] = useState([]); // Estado para armazenar as opções localizadas

  useEffect(() => {
    setSearchInput(searchValue);
  }, [searchValue]);

  const handleChange = (event) => {
    setSearchInput(event.target.value);

    if (onDemand && onDemand === true) {
      if (onChange) {
        onChange(event.target.value);
      }
    }

    if (withOptions && withOptions === true) {
      // Atualize o estado do menu com as opções localizadas
      const options = getFilteredOptions(event.target.value); // Implemente a função getFilteredOptions para retornar as opções localizadas
      setMenuOptions(options);
      setShowMenu(true);
    }
  };

  const handleSearchClick = (event) => {
    event.preventDefault();
    if (onChange) {
      onChange(searchInput);
    }
    if (withOptions && withOptions === true) {
      setMenuOptions([]);
      setShowMenu(false);
    }
  };

  const handleClearClick = (event) => {
    event.preventDefault();
    setSearchInput('');
    if (onClear) {
      onClear();
    }
    if (withOptions && withOptions === true) {
      setMenuOptions([]);
      setShowMenu(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Tecla Enter
      searchButtonRef.current?.click();
    } else if (event.keyCode === 27) {
      // Tecla Esc
      searchClearRef.current?.click();
    }
  };

  const handleFocus = () => {
    if (withOptions && withOptions === true) {
      if (searchInput.length > 0) {
        // Atualize o estado do menu com as opções localizadas
        const options = getFilteredOptions(searchInput); // Implemente a função getFilteredOptions para retornar as opções localizadas
        setMenuOptions(options);
        setShowMenu(true);
      }
    }
  };

  const handleBlur = () => {
    if (withOptions && withOptions === true) {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  };

  const handlePaste = (event, octetIndex) => {
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text/plain');
    const pastedDataArray = pastedData.split('\n');
    const pastedDataArrayFiltered = pastedDataArray.filter((item) => item !== '');

    const pastedDataArrayFilteredWithOctetIndex = pastedDataArrayFiltered.map((item) => `${item}`);
    const pastedDataArrayFilteredWithOctetIndexString = pastedDataArrayFilteredWithOctetIndex.join('\n');

    setSearchInput(pastedDataArrayFilteredWithOctetIndexString);

    if (onDemand && onDemand === true) {
      if (onChange) {
        onChange(pastedDataArrayFilteredWithOctetIndexString);
      }
    }

    if (withOptions && withOptions === true) {
      // Atualize o estado do menu com as opções localizadas
      const options = getFilteredOptions(pastedDataArrayFilteredWithOctetIndexString); // Implemente a função getFilteredOptions para retornar as opções localizadas
      setMenuOptions(options);
      setShowMenu(true);
    }
  };

  return (
    <div className="search-bar">
      <div className="search-bar-input-wrapper">
        <input
          className="search-bar-input"
          type="input"
          placeholder={placeholder}
          onChange={handleChange}
          value={searchInput}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {searchInput !== '' && (
          <span
            ref={searchClearRef}
            className="search-bar-clear"
            onClick={handleClearClick}
          >
            <ClearIcon />
          </span>
        )}
      </div>
      <button
        ref={searchButtonRef}
        className="search-bar-search-button"
        type="button"
        onClick={handleSearchClick}
        disabled={isLoading}
      >
        <SearchIcon color="#fff" />
      </button>
      {withOptions && withOptions === true && showMenu &&
        <SearchMenu
          options={menuOptions}
          onSelect={(option) => {
            setSearchInput(option);
            setShowMenu(false);
            setSearchInput(current => '');
            if (onSelect) {
              onSelect(option);
            }
          }}
        />}
    </div>
  );
}

export default SearchBar;
