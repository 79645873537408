import { useMemo } from "react";
import { z } from "zod";

import iButtonCRC from "../utils/iButtonCRC";

import {
  Autocomplete,
  Cancel,
  Control,
  Description,
  Error,
  FieldSet,
  Form,
  Group,
  HiddenInteger,
  IdentifierControl,
  Inline,
  Label,
  Legend,
  Row,
  Submit,
  Toggle
} from '../components/form';

import VehicleList from '../components/VehicleList';

import '../components/form/form.css';

const IdentifierForm = ({
  identifier,
  getIdentifiers,
  getDrivers,
  onSubmit,
  onCancel,
  onFieldChange,
  customerVehicles,
  customerName
}) => {
  const isIdentifierUnique = async (value, context) => {
    // Verifica se name já existe em names, usando case insensitive e
    // ignorando espaços no início e fim e acentos
    const newIdentifier = value
      .trim()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
    ;
    const identifierList = getIdentifiers();

    return !identifierList.includes(newIdentifier);
  };

  const isIButtonValid = (value) => {
    return iButtonCRC(value) === 0;
  };

  const identifierSchema = z.object({
    id: z.number().int().optional(),
    customerid: z.number().positive().int(),
    identifiertechnologyid: z.number().positive().int(),
    identifier: z
      .string({
        required_error: "Identificador é obrigatório",
        invalid_type_error: "Identificador deve ser uma string",
      })
      .trim()
      .nonempty({
        message: "Identificador não pode estar em branco",
      })
      .max(16, {
        message: "Deve ter no máximo 16 caracteres"
      })
      .refine(isIButtonValid, {
        message: "Identificador de iButton inválido",
      })
      .refine(isIdentifierUnique, {
        message: "Já exite outro identificador com este código",
      }),
    driverid: z.number().int().nullable().optional(),
    active: z.boolean({
      required_error: "Ativo é obrigatório",
      invalid_type_error: "Informe se está ativo",
    }),
    storedata: z.optional(
      z.array(
        z.object({
          vehicleid: z.number().int().positive(),
          plate: z
            .string({
              required_error: "Placa é obrigatória",
              invalid_type_error: "Placa deve ser uma string",
            })
            .trim()
            .nonempty({
              message: "Placa não pode estar em branco",
            }),
          equipmentid: z.number().int().positive(),
          stored: z.boolean({
            required_error: "Armazenado é obrigatório",
            invalid_type_error: "Informe se está armazenado",
          }),
        })
      )
    )
  });

  const drivers = useMemo(() => getDrivers(), [getDrivers]);

  return (
    <Form
      schema={identifierSchema}
      defaultValues={identifier}
      onSubmit={onSubmit}
      onFieldChange={onFieldChange}
      watchFields={["identifier"]}
    >
      <HiddenInteger
        name="id"
      />
      <HiddenInteger
        name="customerid"
      />
      <HiddenInteger
        name="identifiertechnologyid"
      />
      {customerName && (
      <Row>
        <Group>
          <Label htmlFor="customername">Cliente</Label>
          <input
            style={{ flex:1, width: '100%', color: 'var(--theme-text-color)' }}
            type="text"
            name="customername"
            disabled
            value={customerName}
          />
        </Group>
      </Row>
      )}
      <Row>
        <Group flex={0}>
          <Label htmlFor="identifiertechnologyname">Tecnologia</Label>
          <Control
            type="text"
            name="identifiertechnologyname"
            disabled
          />
        </Group>
        <Group flex={1}>
          <Label htmlFor="identifier">Identificador</Label>
          <IdentifierControl
            name="identifier"
          />
          <Error name="identifier" />
        </Group>
      </Row>
      <Group>
        <Label htmlFor="driverid">Motorista</Label>
        <Autocomplete
          id="driverid"
          name="driverid"
          data={drivers}
          placeholder="Escolha um motorista..."
          noOptionsMessage={() => 'Nenhum motorista encontrado.'}
          rules={{}}
        />
        <Error name="driverid" />
      </Group>
      <FieldSet>
        <Legend>Veículos nos quais o iButton está vinculado</Legend>
        <VehicleList
          name="storedata"
          noitemsLabel="Nenhum veículo associado."
          customerVehicles={customerVehicles}
        />
      </FieldSet>
      <FieldSet direction="col">
        <Legend>Opções adicionais</Legend>
        <Group style={{ flex: 1, marginBottom: 0 }}>
          <Inline style={{ borderBottom: '1px solid var(--theme-list-group-border-color)', marginBottom: 0 }}>
            <Group style={{ flex: 1, marginBottom: 0 }}>
              <Label
                className="title"
                htmlFor="active"
              >
                <span>Ativo</span>
                <Description>
                  Se este iButton está ativo. Se desmarcado, impede o seu uso.
                </Description>
              </Label>
            </Group>
            <Toggle
              name="active"
            />
          </Inline>
          <Error name="active" />
        </Group>
      </FieldSet>
      <Row rightAligned>
        <Cancel onClick={onCancel} >
          Cancelar
        </Cancel>
        <Submit value="Salvar">
          Salvar
        </Submit>
      </Row>
    </Form>
  );
};

export default IdentifierForm;

