function LabeledControl({left, children}) {
  const position = left ? 'left' : 'right';

  return (
    <div className={`labeled input ${position}`}>
      {children}
    </div>
  )
}

export default LabeledControl;