import { getDateTimeFromSQL } from '../hooks/dateUtils';

function TimeElapsed({ eventDate }) {
  /**
   * Retorna um valor seguido de uma palavra no singular ou plural em
   * função do valor.
   * 
   * @param {value} value
   *   O valor a ser expresso
   * @param {number} noun
   *   O nome a ser colocado após o valor
   * @param {string} plural (opcional)
   *   O plural do nome a ser acrescentado
   * 
   * @returns {string}
   */
  const pluralize = (value, noun, plural = null) => {
    const pluralNoun = (plural !== null) ? plural : `${noun}s`;
    const inflection = (value !== 1) ? pluralNoun : noun;

    return `${value} ${inflection}`;
  }

  /**
   * Retorna a diferença de tempo entre a data e hora atual e a data e
   * hora inforamada, por extenso.
   * 
   * @param {string} previous
   *   O valor da data e hora para a qual iremos calcular a diferença
   * 
   * @returns {string}
   */
  const timeDifferenceInFull = (previous) => {
    const now = new Date();
    const previousDate = getDateTimeFromSQL(previous);

    // Calcula a diferença em segundos
    const elapsed = Math.round((now - previousDate) / 1000) + 1;

    const secondsPerMinute = 60;
    const secondsPerHour = secondsPerMinute * 60;
    const secondsPerDay = secondsPerHour * 24;
    const secondsPerMonth = secondsPerDay * 30;
    const secondsPerYear = secondsPerDay * 365;
    
    switch (true) {
      case (elapsed < secondsPerMinute):
        return 'Agora mesmo';
      case (elapsed < secondsPerHour):
        return 'Há '
          + pluralize(Math.floor(elapsed/secondsPerMinute), 'minuto')
          + ' atrás'
        ;
      case (elapsed < secondsPerDay ):
        const hours = Math.floor(elapsed/secondsPerHour);
        const leewayInMinutes = hours * secondsPerHour
          + secondsPerMinute * 5
        ;

        return 'Há '
          + ((elapsed > leewayInMinutes)?'mais de ':'')
          + pluralize(Math.floor(elapsed/secondsPerHour), 'hora')
          + ' atrás'
        ;
      case (elapsed < secondsPerMonth):
        const days = Math.floor(elapsed/secondsPerDay);
        const leewayInHours = days * secondsPerDay
          + secondsPerHour * 1
        ;

        return 'Há '
          + ((elapsed > leewayInHours)?'mais de ':'')
          + pluralize(days, 'dia')
          + ' atrás'
        ;
      case (elapsed < secondsPerYear):
        const months = Math.floor(elapsed/secondsPerMonth);
        const leewayInDays = months * secondsPerMonth
          + secondsPerDay * 5
        ;

        return 'Há '
          + ((elapsed > leewayInDays)?'mais de ':'')
          + pluralize(months, 'mês', 'meses')
          + ' atrás'
        ;
      default:
        return 'Há '
          + pluralize(Math.floor(elapsed/secondsPerYear), 'ano')
          + ' atrás'
        ;
    }
  }
  
  return timeDifferenceInFull(eventDate);
}

export default TimeElapsed;
