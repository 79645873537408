import React from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import classNames from 'classnames';

import NoEvent from "./NoEvent";
import Notification from "./Notification";
import PrimaryButton from "./PrimaryButton";
import * as EventService from '../services/EventService';
import {
  doLogout,
  useAuthDispatch
} from '../features/authorization/AuthContext';

import {ReactComponent as SettingsIcon} from '../assets/icons/settings.svg';

import '../styles/CardNotification.scss';

function CardNotification({
  events,
  alarm=false,
  getEvent,
  updateEvent,
  deleteEvent,
  clearEvents
}) {
  // O contexto autenticação
  const dispatchToAuth = useAuthDispatch();
  const navigate = useNavigate();
  const { tenantName } = useParams();

  let notifications;

  const title = (alarm?'Alarmes':'Notificações');
  const bodyClass = classNames('body',{
    'alarm': alarm
  });
  const colorButton = alarm
    ? 'danger'
    : 'primary'
  ;

  const handleMarkAllAsReadClick = (e) => {
    console.log('Marca todos os eventos como lidos')
    
    // Obtemos os ID's de todos os eventos
    const listOfEvents = events.map( (event) => {
      return event.id;
    });

    EventService.treatEvents(listOfEvents)
      .then( function (response) {
        clearEvents();
      })
      .catch((error) => {
        const { request, response } = error;
      
        if (response) {
          switch (response.status) {
            case 401:
              console.error('Sessão expirada');
              doLogout(dispatchToAuth);
              console.log(`Desautenticado. Redirecionando para /app/${tenantName}/login`);
              navigate(`/app/${tenantName}/login`);
              
              break;
            default:
              console.error(error);
      
              break;
          }
        } else if (request) {
          // Solicitação enviada, mas nenhuma resposta recebida
          console.error("Server time out");
        } else {
          // Algo aconteceu na configuração da solicitação que acionou
          // um erro
          console.error(
            "OPS! Algo deu errado ao configurar a marcação de evento como lido",
            error
          );
        }
      });
  };

  if (events.length) {
    notifications = events.map((notification, index) =>
      <Notification
        key={index}
        index={index}
        event={notification}
        getEvent={getEvent}
        updateEvent={updateEvent}
        deleteEvent={deleteEvent}
      />
    );
  } else {
    notifications = <NoEvent noEventMessage='Nenhum evento ocorrido' />;
  }

  return (
    <div className="card notification">
      <div className="header">
        <div className="title">{title}</div>
        <PrimaryButton
          onClick={handleMarkAllAsReadClick}
          color={colorButton}
        >
          Marcar tudo como lido
        </PrimaryButton>
      </div>
      <div className={bodyClass}>
        <div className="scrollableContent">
          {notifications}
        </div>
      </div>
      <div className="footer">
        <PrimaryButton color={colorButton} contains="icon">
          <SettingsIcon />
        </PrimaryButton>
      </div>
    </div>
  );
}

export default CardNotification;
