import React from 'react';

import IgnitionLabel from '../IgnitionLabel';

function PointPopup({ eventDate, ignition, speed }) {
  const titleStyle = {
    display: 'block',
    padding: '0 0 .3em 0',
    width: '100%',
    fontWeight: 'bold',
    color: '#005dc7',
    fontSize: '1.3em',
    borderBottom: '2px solid #8a8a8a',
    minWidth: '200px'
  };
  const contentStyle = {
    color: '#808080',
    fontSize: '1.1em'
  };
  const boldStyle = {
    fontWeight: 'bold',
    color: '#192d45'
  };

  return (
    <>
      <span style={titleStyle}>Local de passagem</span>
      <IgnitionLabel state={ignition} />
      <p style={contentStyle}>
        Passou neste local
        em <span style={boldStyle}>{eventDate}</span>.<br />
        Velocidade: <span style={boldStyle}>{speed} km/h</span>.
      </p>
    </>
  );
}

export default PointPopup;
