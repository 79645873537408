import React from 'react';
import moment from 'moment';

import { ReactComponent as AirConnectionIcon } from "../assets/icons/airconnection.svg";

function LastCommunication({ dateString }) {
  if (!dateString) {
    return null;
  }

  const lastEvent = moment(dateString);
  const now = moment();
  const formattedDate = lastEvent.format('DD/MM/YYYY');
  const formattedTime = lastEvent.format('HH:mm:ss');
  let formattedLabel = `Em ${formattedDate} às ${formattedTime}`;
  const duration = moment.duration(now.diff(lastEvent));
  const diffInMinutes = duration.asMinutes();

  if (lastEvent.clone().startOf('day').isSame(now.clone().startOf('day'))) {
    
    if (diffInMinutes <= 5) {
      // O evento ocorreu nos últimos 5 minutos
      formattedLabel = `Há pouco, às ${formattedTime}`;  
    } else {
      // O evento ocorreu hoje
      formattedLabel = `Hoje, às ${formattedTime}`;
    }
  }

  if (lastEvent.clone().startOf('day').isSame(now.clone().subtract(1, 'days').startOf('day'))) {
    // O evento ocorreu ontem
    formattedLabel = `Ontem, às ${formattedTime}`;
  }

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1em',
    flexWrap: 'nowrap'
  };
  const innerContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: ".3em"
  };
  const labelStyle = {
    fontWeight: "300",
    fontSize: ".8em",
    marginBottom: ".3em"
  };

  return (
    <span className="caption" style={containerStyle}>
      <AirConnectionIcon width="1.5em" height="1.5em" style={{ marginRight: '.3em', verticalAlign: 'middle' }}/>
      <span style={innerContainerStyle}>
        <span style={labelStyle}>Última comunicação:</span>
        <span style={{ color: "var(--theme-heading-color)", fontWeight: "600" }}>
          {formattedLabel}
        </span>
      </span>
    </span>
  );
}

export default LastCommunication;