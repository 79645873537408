import React, {
  useMemo
} from "react";

import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

import './styles.css';

function SearchInput({
  value,
  placeholder,
  onChange,
  disabled = false,
  size = '100%'
}) {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  const handleKeyDown = (event) => {
    // Lidamos com o esc para limpar o campo
    if (event.key === 'Escape') {
      onChange('');
    }
  }

  const wrapClass = useMemo(() => {
    let classes = 'search-input';
    if (disabled) {
      classes += ' disabled';
    }
    return classes;
  }, [disabled]);

  return (
    <div className={wrapClass} style={{ maxWidth: size }}>
      <SearchIcon fill="var(--theme-gray-600)" />
      <input
        type="text"
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {value && (
        <ClearIcon
          fill="var(--theme-gray-600)"
          onClick={() => onChange('')}
        />
      )}
    </div>
  );
}
 export default SearchInput;