import Api from './Api';

/**
 * Recupera os motoristas cadastrados.
 * 
 * @param {int} customerID
 *   O ID do cliente (opcional)
 * 
 * @returns
 *   Os dados da requisição de motoristas cadastrados
 */
const getDrivers = async (
  customerID
) => {
  const queryParms = {
    customerID: customerID || 0
  };

  return Api
    .get("/driver/list", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

/**
 * Recupera o motorista cadastrado
 * 
 * @param {int} driverID
 *   O ID do motorista
 * @param {int} customerID
 *   O ID do cliente (opcional)
 * 
 * @returns
 *   Os dados da requisição de motoristas cadastrados
 */
const getDriver = async (
  driverID,
  customerID
) => {
  const queryParms = {
    customerID: customerID || 0
  };

  return Api
    .get(`/driver/list/${driverID}`, { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const addDriver = async (
  driver
) => {
  return Api
    .post("/driver/add", driver)
    .then((response) => {
      if (response.status === 'Created') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const updateDriver = async (
  driver
) => {
  return Api
    .put("/driver/update", driver)
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const activateDriver = async (
  id
) => {
  return Api
    .put(`/driver/activate/${id}`, {})
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
}

const deactivateDriver = async (
  id
) => {
  return Api
    .put(`/driver/deactivate/${id}`, {})
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
}

export {
  getDrivers,
  getDriver,
  addDriver,
  updateDriver,
  activateDriver,
  deactivateDriver
};