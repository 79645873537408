import React, {
  useState,
  useMemo
} from 'react';

import SquareButton from '../SquareButton';

const ColorBox = ({ color, colors, columns = 3, onChange }) => {
  const [colorValue, setColorValue] = useState(color);

  const handleChangeColor = (newColor) => {
    setColorValue(newColor);
    if (onChange) {
      onChange(newColor);
    }
  }

  const Rows = useMemo(() => {
    return colors.map((hexColor, index) => {
      return (
      <SquareButton
        key={index}
        color={hexColor}
        selected={colorValue === hexColor}
        onClick={() => handleChangeColor(hexColor)}
        size="2.5rem"
      >
        {colorValue === hexColor &&
          <span style={styles.checked}>&#10004;</span>
        }
      </SquareButton>
      );
    });
  }, [colors, colorValue, onChange]);

  return (
    <div
      style={{
        ...styles.container,
        gridTemplateColumns: `repeat(${columns}, 1fr)`
      }}
    >
      {Rows}
    </div>
  );
}

const styles = {
  container: {
    display: 'grid',
    gap: '3px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checked: {
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 'bold'
  }
};

export default ColorBox;