import React, {
  useEffect,
  useState
} from "react";
import {
  useController,
  useFormContext
} from "react-hook-form";

import IconComboBox from "../IconComboBox";

function IconSelector({
  name,
  icons,
  options = {},
  ...props
}) {
  const {
    control,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();
  
  const {
    field
  } = useController({
    name,
    control,
    defaultValue: defaultValues ? (defaultValues[name] ? defaultValues[name] : null) : null,
    rules: {...options, valueAsNumber: true},
  });

  return (
    <IconComboBox
      ref={field.ref}
      name={field.name}
      value={field.value}
      icons={icons}
      onChange={field.onChange}
      disabled={isSubmitting}
      {...props}
    />
  );
}

export default IconSelector;