import React from 'react';

import IgnitionLabel from '../IgnitionLabel';

function StopPopup({ type, ignition, info, speed, eventDate }) {
  const titleStyle = {
    display: 'block',
    padding: '0 0 .3em 0',
    width: '100%',
    fontWeight: 'bold',
    color: '#005dc7',
    fontSize: '1.3em',
    borderBottom: '2px solid #8a8a8a',
    minWidth: '200px'
  };
  const contentStyle = {
    color: '#808080',
    fontSize: '1.1em'
  };
  const boldStyle = {
    fontWeight: 'bold',
    color: '#192d45'
  };

  const title = type === 'start'
    ? 'Início do trajeto'
    : (type === 'stop'
      ? 'Ponto de parada'
      : 'Fim do trajeto'
  );

  const formatDuration = (duration) => {
    if (duration < 60) {
      return `${duration} minutos`;
    } else {
      const hours = Math.floor(duration / 60);
      const remainingMinutes = duration % 60;
      if (remainingMinutes === 0) {
        return `${hours} hora${hours > 1 ? 's' : ''}`;
      }

      return `${hours} hora${hours > 1 ? 's' : ''} e ${remainingMinutes} minuto${remainingMinutes > 1 ? 's' : ''}`;
    }
  };

  if (type === 'point') {
    console.log('Ignição no ponto', ignition);
  }

  return (
    <>
      <span style={titleStyle}>{title}</span>
      <IgnitionLabel state={ignition} />
      {info ? (
        <p style={contentStyle}>
          Permaneceu neste local
          por <span style={boldStyle}>{formatDuration(info.total)}</span> no
          dia <span style={boldStyle}>{info.startDate}</span>, das <span style={boldStyle}>{info.startTime}</span> até {
            (info.startDate === info.endDate)
              ? (<>as <span style={boldStyle}>{info.endTime}</span></>)
              : (<span style={boldStyle}>{info.endDate}</span>) + ' as ' + (<span style={boldStyle}>{info.endTime}</span>)
          }.
          { ignition === true &&
            <><br />Velocidade: <span style={boldStyle}>{speed} km/h</span>.<br /></>
          }
        </p>
      ) : (
        <p style={contentStyle}>
          { (ignition === true)
            ? <>
                Passou neste local em <span style={boldStyle}>{eventDate}</span>.<br />
                Velocidade: <span style={boldStyle}>{speed} km/h</span>.<br />
              </>
            : <>Sem informações de permanência neste local.</>
          }
        </p>
      )}
    </>
  );
}

export default StopPopup;
