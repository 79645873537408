import React from 'react';

import TimeElapsed from './TimeElapsed';
import { getDateTimeFromSQL } from '../hooks/dateUtils';

import '../styles/TimeElapsedLabel.scss';

function TimeElapsedLabel({ eventDate }) {

  /**
   * Retorna um nível de advertência em função do quão antigo é o tempo
   * em que o evento ocorreu, seguindo a seguinte lógica:
   *   - até 2 horas: safe,
   *   - até 24 horas: caution,
   *   - até 48 horas: highrisk,
   *   - mais de 48 horas: dangerous
   * 
   * @param {string} previous
   *   O valor da data e hora para a qual iremos determinar
   * 
   * @returns {string}
   */
  const levelColor = (previous) => {
    const now = new Date();
    const previousDate = getDateTimeFromSQL(previous);

    // Calcula a diferença em segundos
    const elapsed = Math.round((now - previousDate) / 1000);

    const secondsPerMinute = 60;
    const secondsPerHour = secondsPerMinute * 60;
    const secondsPerDay = secondsPerHour * 24;
    
    if (elapsed < (secondsPerHour * 2)) {
      // Seguro
      return 'level safe';
    } else if (elapsed < secondsPerDay ) {
      // Cuidado
      return 'level caution';
    } else if (elapsed < (secondsPerDay * 2) ) {
      // Risco alto
      return 'level highrisk';
    } else {
      // Perigoso
      return 'level dangerous';
    }
  }

  const className = levelColor(eventDate);

  return ( 
    <span className={className}>
      <TimeElapsed eventDate={eventDate} />
    </span>
  );
}

export default TimeElapsedLabel;
