import React, {
  Children,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  useController,
  useFormContext
} from 'react-hook-form';

function Select({
  name,
  children,
  options = {},
  ...props
}) {
  const {
    control,
    setValue,
    register,
    formState: { defaultValues, isSubmitting }
  } = useFormContext();

  // Estado inicial do valor padrão
  const [defaultValue, setDefaultValue] = useState(() => {
    if (defaultValues && name in defaultValues) {
      return defaultValues[name];
    }

    return null;
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(defaultValues[name]);
    } else {
      setDefaultValue(null);
    }
  }, [defaultValues, name]);

  const {
    field
  } = useController({
    name,
    control,
    defaultValue,
    rules: { options }
  });

  // Atualiza o valor padrão quando `defaultValues` ou `name` mudam
  useEffect(() => {
    if (defaultValues && name in defaultValues) {
      setDefaultValue(defaultValues[name]);
    } else {
      setDefaultValue(null);
    }
  }, [defaultValues, name]);

  const [selected, setSelected] = useState(field.value || null);
  const [open, setOpen] = useState(false);

  const handleSelectChange = useCallback((value) => {
    setSelected(value);
  }, [name]);

  useEffect(() => {
    if (field && field.value) {
      setSelected(field.value);
    }
  }, [field.value]);

  const childArray = Children.toArray(children);

  return (
    <div
      className='select-container'
      onBlur={() => setOpen(false)}
    >
      <select
        id={name}
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          border: 'none',
          fontSize: 0,
          margin: 0,
          padding: 0,
        }}
        onFocus={() => setOpen(true)}
        onChange={(e) => handleSelectChange(e.target.value)}
        {...register(name)}
        disabled={isSubmitting}
        {...props}
      >
        {children}
      </select>

      <div
        className='select'
        onClick={() => setOpen(!open)}
      >
        <label
          className='select-label'
          htmlFor={name}
        >
          {childArray.find(child => child.props.value === selected)?.props.children || props.placeholder}
        </label>
        <svg
          style={{
            rotate: open?'180deg':'0deg',
            transition: 'rotate 0.3s ease'
          }}
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </div>
      <div
        className='select-options'
        style={{
          height: open?'auto':'0',
          opacity: open?1:0,
          zIndex: open?1000:-1
        }}
      >
        {childArray.map((child, index) => {
          return (
            <div
              data-hover="option"
              key={`option_${index}`}
              onClick={() => {
                setSelected(child.props.value);
                setValue(name, child.props.value);
                setOpen(false);
              }}
              style={{
                padding: '.7rem',
                color: '#fff',
                border: '1px solid transparent',
                borderColor: 'transparent transparent rgba(0, 0, 0, 0.1) transparent',
                cursor: 'pointer',
                textWrap: 'none',
              }}
            >
            <style>
              {`[data-hover="option"]:hover {
                background-color: #ffffff15;
              }`}
            </style>
              {child.props.children}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Select;