import Api from './Api';

/**
 * Recupera as cercas definidas.
 * 
 * @param {int} customerID
 *   O ID do cliente (opcional)
 * 
 * @returns
 *   Os dados da requisição de cercas definidas
 */
const getFences = async (
  customerID
) => {
  const queryParms = {
    customerID: customerID || 0
  };

  return Api
    .get("/fence/list", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const addFence = async (
  fence
) => {
  return Api
    .post("/fence/add", fence)
    .then((response) => {
      if (response.status === 'Created') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const updateFence = async (
  fence
) => {
  return Api
    .put("/fence/update", fence)
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const removeFence = async (
  id
) => {
  return Api
    .delete(`/fence/delete/${id}`, {})
    .then((response) => {
      if (response.status === 'Deleted') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
}

/**
 * Recupera os pontos de referência definidos.
 * 
 * @param {int} customerID
 *   O ID do cliente
 * @param {int} pageNumber
 *   O número da página sendo requisitada
 * @param {int} itemsPerPage
 *   A quantidade de itens por página
 * 
 * @returns
 *   Os dados da requisição de pontos de referência definidos
 */
const getLandmarks = async (
  customerID,
  pageNumber,
  itemsPerPage
) => {
  const queryParms = {
    customerID: customerID || 0,
    page: pageNumber,
    itemsPerPage: itemsPerPage
  };

  return Api
    .get("/landmark/list", { params: queryParms })
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const addLandmark = async (
  landmark
) => {
  return Api
    .post("/landmark/add", landmark)
    .then((response) => {
      if (response.status === 'Created') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const updateLandmark = async (
  landmark
) => {
  return Api
    .put("/landmark/update", landmark)
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

const removeLandmark = async (
  id
) => {
  return Api
    .delete(`/landmark/delete/${id}`, {})
    .then((response) => {
      if (response.status === 'Deleted') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
}

export {
  getFences,
  addFence,
  updateFence,
  removeFence,
  getLandmarks,
  addLandmark,
  updateLandmark,
  removeLandmark
};