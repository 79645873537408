import L from 'leaflet';

import PinStopIcon from '../../../../assets/icons/markers/pinstop.svg'

const pinStop = new L.Icon({
  iconUrl: PinStopIcon,
  iconSize: new L.Point(48, 48),
  iconAnchor: [23, 40],
  popupAnchor: [0, -48],
});

export { pinStop };