import moment from 'moment';

/**
 * Obtém um objeto Date a partir de uma data no formato SQL.
 * 
 * @param {string} dateTimeString
 *   A data no formato SQL (YYYY-MM-DD HH:MM:SS)
 * 
 * @returns
 *   Um objeto Date
 */
export function getDateTimeFromSQL(dateTimeString)
{
  if (!dateTimeString) {
    return null;
  }

  const [dateString, timeString] = dateTimeString.split(' ');
  const [year, month, day] = dateString.split('-');
  const [hours, minutes, seconds] = timeString.split(':');

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

/**
 * Obtém uma string data e hora formatada no padrão brasileiro à partir
 * de uma data no formato SQL.
 * @param {string} dateTimeString
 *   A data no formato SQL (YYYY-MM-DD HH:MM:SS)
 * @param {string} separator
 *   O separador entre a data e a hora
 * 
 * @returns
 *   Uma string data e hora formatada no padrão brasileiro
 */
export function getFormatedDateTime(dateTimeString, separator = ' ')
{
  if (!dateTimeString) {
    return null;
  }
  
  const date = getDateTimeFromSQL(dateTimeString);

  return date.toLocaleDateString('pt-BR')
    + separator
    + date.toLocaleTimeString('pt-BR')
  ;
}

/**
 * Obtém um objeto Date a partir de uma data no formato brasileiro.
 * 
 * @param {string} dateTimeString
 *   A data no formato brasileiro (DD/MM/YYYY HH:MM:SS)
 * 
 * @returns
 *   Um objeto Date
 */
export function getDateTimeFromString(dateTimeString)
{
  const [dateString, timeString] = dateTimeString.split(' ');
  const [day, month, year] = dateString.split('/');
  const [hours, minutes, seconds] = timeString.split(':');

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

/**
 * Obtém uma string data e hora formatada no padrão SQL à partir de uma
 * string data e hora no formato brasileiro sem os segundos.
 * 
 * @param {string} dateTimeString
 *   A data no formato brasileiro (DD/MM/YYYY HH:MM)
 * @param {string} defaultHour
 *   A hora padrão a ser utilizada caso não seja informada
 * @param {string} defaultMinute
 *   O minuto padrão a ser utilizado caso não seja informado
 * 
 * @returns
 *   Uma string data e hora formatada no padrão SQL
 */
export function getValidDateTimeString(dateTimeString, defaultHour = 0, defaultMinute = 0)
{
  // Regex para validar a string no formato DD/MM/YYYY HH:MM
  const dateTimeRegex = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})( ([0-9]{2}))?(:(?=[0-9]))?([0-9]{2})?$/;
  const match = dateTimeString.trim().match(dateTimeRegex);

  if (match === null) {
    // Não foi informado nenhum valor
    return false;
  }
  
  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const year = parseInt(match[3], 10);
  const hour = match[5] ? parseInt(match[5], 10) : defaultHour;
  const minute = match[7] ? parseInt(match[7], 10) : defaultMinute;

  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900 || year > 2100 || hour < 0 || hour > 23 || minute < 0 || minute > 59) {
    // Data inválida
    return false;
  }

  return day.toString().padStart(2,0)
    + '/' + month.toString().padStart(2,0)
    + '/' + year.toString().padStart(4,0)
    + ' ' + hour.toString().padStart(2,0)
    + ':' + minute.toString().padStart(2,0)
  ;
}

/**
 * Verifica se o período informado é válido.
 * 
 * @param {string} startDate
 *   A data de início do período no formato brasileiro
 *   (DD/MM/YYYY HH:MM:SS)
 * @param {string} endDate
 *   A data de término do período no formato brasileiro
 *   (DD/MM/YYYY HH:MM:SS)
 * 
 * @throws
 *   Um objeto com a mensagem de erro caso o período seja inválido
 */
export function validatePeriod(startDate, endDate)
{
  const startingAt = getDateTimeFromString(startDate + ':00');
  const endingAt = getDateTimeFromString(endDate + ':00');
  const now = new Date();

  if (startingAt > endingAt) {
    throw new Error('A data de início não pode ser maior do que a data de '
        + 'término do relatório'
    );
  }

  if (startingAt > now) {
    throw new Error(
      'O período informado é superior à data/hora atual'
    );
  }
};

/**
 * Obtém a data no formato SQL (YYYY-MM-DD HH:MM:SS) a partir de uma
 * data no formato de data UTC e na hora informada.
 * 
 * @param {string} dateString
 *   A data no formato de data UTC
 *   Ex: Tue Apr 04 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
 * @param {string} timeString
 *   A hora no formato HH:MM
 * @returns
 *   A data no formato SQL (YYYY-MM-DD HH:MM:SS) 
 */
export function getSQLDate(dateString, timeString) {
  const date = moment(dateString);

  return date.format('YYYY-MM-DD') + ' ' + timeString + ':00';
}

/**
 * Converte uma data no formato brasileiro para o formato SQL.
 * 
 * @param {string} dateTimeString
 *   A data no formato brasileiro (DD/MM/YYYY HH:MM:SS)
 * 
 * @returns
 *   A data no formato SQL (YYYY-MM-DD HH:MM:SS)
 */
export function toSQLDate(dateTimeString)
{
  const [dateString, timeString] = dateTimeString.split(' ');

  const [day, month, year] = dateString.split('/');
  const [hours, minutes, seconds] = timeString.split(':');

  return `${year}-${month}-${day}%20${hours}:${minutes}-${seconds}`
};

export function getReportDate()
{
  const date = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/Sao_Paulo'
  };

  return date.toLocaleDateString('pt-BR', options)
    + ' às '
    + date.toLocaleTimeString('pt-BR')
  ;

}

/**
 * Formata o valor de uma data no formato dd/mm/yyyy hh:mm:ss.
 * 
 * @param {string} value
 *   O valor da data
 * 
 * @returns 
 *   O valor da data formatado
 */
export function formatDate(value)
{
  const date = new Date(value);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return date.toLocaleDateString('pt-BR', options).replace(/,/, ', às');
}

/**
 * Obtém uma string data e hora formatada no padrão SQL para um momento
 * ocorrido à quantidade de horas informadas no passado.
 * 
 * @param {int} hours 
 * 
 * @returns string
 */
export function dataTimeStringAtHoursPast(hours) {
  const pastDateTime = moment();
  pastDateTime.subtract(hours, 'hours');

  return pastDateTime.format('YYYY-MM-DDTHH:mm:ss');
}
