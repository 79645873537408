import React from 'react';

import responseFormatter from '../../hooks/responseFormatter';
import './styles.scss';

function ResponseDisplay({ response, lineSeparator, fieldSeparators, ...rest }) {
  if (!response) {
    return <div className="response_lines_container">Nenhum conteúdo</div>;
  }

  const rows = responseFormatter(response, lineSeparator, fieldSeparators);

  const lines = rows.map((line, index) => {
    return (
      <div key={index} className="response_line">
        {line.key && <span className="name">{line.key}: </span>}
        <span className="value">{line.value}</span>
      </div>
    );
  });

  return (
    <div className="response_lines_container" {...rest}>
      {lines}
    </div>
  );
}

export default ResponseDisplay;
