/**
 * Constrói um objeto de registro de um dispotivo (alarme ou posição) a
 * partir dos dados recebidos da API.
 * 
 * @param {Object} data
 *   Objeto de dados.
 * 
 * @return {Object}
 *   O objeto de registro.
 */
export function buildDeviceRegister(data)
{
  const register = {}

  register.id = data.id;
  if (data.typeofevent) {
    register.type = data.typeofevent;
  }

  // Adicionamos sempre os dados do equipamento
  register.equipment = {
    id: data.equipmentid,
    mainTracker: data.maintracker,
    serialNumber: data.terminalid,
    firmwareversion: data.firmwareversion??'Não disponível',
    brand: {
      name: data.equipmentbrandname
    },
    model: {
      name: data.equipmentmodelname,
      protocolID: data.protocolid??0,
      protocolVariantID: data.protocolvariantid??0
    },
    iButton: {
      hasInput: data.hasibuttoninput,
      hasReader: data.hasibuttonreader,
      isActive: data.ibuttonactive
    },
    availablecommands: data.availablecommands??[] 
  };

  // Verifica se o dispositivo está vinculado
  if (data.vehicleid) {
    // Adicionamos os dados do veículo
    register.vehicle = {
      id: data.vehicleid,
      plate: data.trackereventid
        ? data.plate
        : data.plate + (data.maintracker?'':' (C)'),
      denomination: data.denomination,
      brand: {
        id: data.vehiclebrandid,
        name: data.vehiclebrandname
      },
      model: {
        id: data.vehiclemodelid,
        name: data.vehiclemodelname
      },
      type: {
        id: data.vehicletypeid,
        subtype: data.vehiclesubtypeid,
        name: data.vehicletypename,
        symbol: data.vehiclesymbol
      },
      color: data.vehiclecolorname
    };

    // Adicionamos os dados do cliente
    register.customer = {
      id: data.customerid,
      unity: data.subsidiaryid,
      name: data.customername,
      affiliated: data.affiliatedname,
      payerID: data.customerpayerid,
      subsidiaryPayerID: data.subsidiarypayerid,
    };
  }

  // Sempre adiciona os demais dados do registro
  register.datetime = data.eventdate;
  register.gpsdate = data.gpsdate;
  register.systemdate = data.systemdate;
  register.latitude = data.latitude;
  register.longitude = data.longitude;
  register.withGPS = data.withgps;
  register.route = data.route;
  register.course = data.course;
  register.ignition = data.ignitionstatus;
  register.block = data.blocked;
  register.siren = data.siren;
  register.speed = data.speed;
  register.gsmSignalStrength = data.gsmsignalstrength;
  register.satellites = data.satellites;
  register.odometer = data.odometer;
  register.horimeter = data.horimeter;
  register.vehicleBattery = data.powervoltage;
  register.internalBattery = data.batteryvoltage;
  register.charge = data.charge;
  register.address = data.address;
  register.nearestLandmarks = data.nearestlandmarks;
  register.insideFences = data.insidefences;
  register.driver = data.drivername;
  register.identifier = data.identifier;

  // Verifica se é um evento (alarme ou alerta)
  if (data.trackereventid) {
    // Adicionamos as informações do evento
    register.trackerEvent = {
      id: data.trackereventid,
      name: data.trackereventname
    };

    // Adicionamos o tratador do evento
    register.treaterID = data.treaterid;
    // Adicionamos a situação do tratamento
    register.treated = data.treated;
    // Adicionamos a informação se está completado
    register.inTreatment = data.intreatment;
    // Adicionamos a informação se está completado
    register.completed = data.completed;
    // Adicionamos quaisquer comentários existentes
    register.comments = data.comments;
  }

  return register;
};

function getCharArray(value)
{
  if (!value) {
    return null
  }

  // Remove os colchetes
  const chars = value.slice(1, -1).split('');

  // Retorna o array de chars
  return chars;  
}

/**
 * Constrói um objeto de registro do histórico de um comando a partir
 * dos dados recebidos da API.
 * 
 * @param {Object} data
 *   Objeto de dados.
 * 
 * @return {Object}
 *   O objeto de registro.
 */
export function buildCommandHistoryRegister(data)
{
  const register = {}

  register.id = data.id;

  // Os dados do comando
  register.command = {
    id: data.commandid,
    name: data.name,
    values: data.replacementvalues,
    data: data.commanddata
  };

  // Sempre adiciona os demais dados do registro
  register.requestedAt = data.requestedat;
  register.requestBy = data.requestbyusername;
  register.requestedBy = data.requestedbyusername;
  register.sentAt = {
    sentAt: data.sentat,
    attempts: data.attempts
  };
  register.confirmedAt = data.confirmedat;
  register.response = data.response;
  register.lineSeparator = data.lineseparator;
  register.fieldSeparators = getCharArray(data.fieldseparators);

  register.status = {
    id: data.id,
    sentAt: data.sentat,
    confirmedAt: data.confirmedat,
    noReply: data.noreply,
    canceledByUser: data.canceledbyuser,
    canceledByUserName: data.canceledbyusername,
    canceledByTimeout: data.canceledbytimeout,
    canceledAt: data.canceledat
  }

  return register;
};

function formatDate(sqlDate) {
  const [year, month, day] = sqlDate.split('-').map(Number);
  return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
}

export function buildDriverRegister(data) {
  const register = {}

  register.id = data.id;
  register.customerID = data.customerid;
  register.customerName = data.customername;

  // Os dados do usuário
  register.driver = {
    id: data.id,
    name: data.name,
    cpf: data.cpf,
    phoneList: data.phones
      ? data.phones
          .map(phone => phone.phonenumber)
          .filter(phone => phone.trim() !== '')
          .join(', ')
      : null,
  };

  // Os dados do identificador de motorista
  register.identifier = {
    id: data.driveridentifierid,
    number: data.identifier,
    technology: data.identifiertechnologyname
  };

  // Os dados da carteira de habilitação
  register.cnh = {
    id: data.id,
    number: data.cnh,
    category: data.cnhcategory,
    expirationDate: data.cnhexpirationdate
      ? formatDate(data.cnhexpirationdate)
      : null,
    closeToExpiration: data.cnhclosetoexpiration,
    expired: data.cnhexpired,
  };

  // O estado da conta
  register.status = {
    active: data.active
  };

  // As ações a serem executadas
  register.actions = {
    id: data.id,
    active: data.active,
    name: data.name
  };

  return register;
}
