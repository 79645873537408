import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { isAuthenticated } from "../features/authorization/AuthContext";
import PrivateLayout from "../pages/Private/PrivateLayout";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { tenantName } = useParams();

  useEffect(() => {
    if (!isAuthenticated(tenantName)) {
      // O usuário não está autenticado
      console.log(`O usuário não está autenticado. Redirecionando para /app/${tenantName}/login`);
      navigate(`/app/${tenantName}/login`);
    } else {
      // O usuário está autenticado
      console.log('O usuário está autenticado');
    }
  }, [tenantName, navigate]);

  return isAuthenticated(tenantName)
    ? (
        <PrivateLayout>
          { children }
        </PrivateLayout>
      )
    : null
  ;
};

export default PrivateRoute;
