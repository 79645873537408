import {
  useFormContext
} from "react-hook-form";

function Toggle({
  name,
  options = {},
  ...props
}) {
  const {
    register,
    setValue,
    formState: { isSubmitting }
  } = useFormContext();

  const handleChange = (event) => {
    setValue(name, event.target.checked);
  };

  return (
    <label
      className="toggle-button"
    >
      <input
        id={name}
        type="checkbox"
        {...register(name, { ...options })}
        disabled={isSubmitting}
        onChange={handleChange}
        {...props}
      />
      <span className="slider" />
    </label>
  );
}

export default Toggle;
