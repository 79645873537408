import React from 'react';
import classNames from 'classnames';

import '../styles/Tabs.scss';

function Tab({ label, tabNumber, activeTab, setActiveTab, children, additions='' }) {
  const tabClass = classNames('tab', additions, {
    'active': (tabNumber === activeTab)
  });
  const labelClass = classNames({
    'active': (tabNumber === activeTab)
  });

  const handleClick = () => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <label className={labelClass} onClick={handleClick}>{ label }</label>
      <div className={tabClass}>{ children }</div>
    </>
  );
}

function Tabs({ children }) {
  return (
    <div className="tabs">
      { children }
    </div>
  );
}

export default Tabs;
export { Tab };