import {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';

const initialState = {
  colors: [
    '#ed6e01', // Laranja
    '#9f0000', // Vermelho
    '#0297ba', // Azul claro
    '#0000bf', // Azul forte
    '#034700', // Verde
    '#ff00ff', // Rosa
    '#49007e', // Roxo
    '#470900', // Marrom
    '#2c3e50', // Cinza
    '#000000', // Black
  ],
  fences: [],
  iconSet: [],
  landmarks: [],
};

function geoDataReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_EMBLEMS':
      const emblems = action.payload;
      const iconSet = {};
      for (const emblem of emblems) {
        iconSet[emblem.id] = emblem.content;
      }

      return {
        ...state,
        iconSet: iconSet
      };
    case 'ADD_FENCE':
      const newFence = action.payload;
      return {
        ...state,
        fences: [...state.fences, newFence]
      };
    case 'UPDATE_FENCE':
      const updatedFence = action.payload;
      const existingFenceIndex = state.fences.findIndex(
        (f) => f.id === updatedFence.id
      );
      if (existingFenceIndex === -1) {
        throw new Error('Tentativa de atualizar cerca inexistente');
      }

      const changedFences = [...state.fences];
      changedFences[existingFenceIndex] = updatedFence;

      return {
        ...state,
        fences: changedFences
      };
    case 'REMOVE_FENCE':
      const fenceToRemove = action.payload;
      const fenceIndex = state.fences.findIndex(
        (f) => f.id === fenceToRemove.id
      );
      if (fenceIndex === -1) {
        throw new Error('Tentativa de remover cerca inexistente');
      }

      const fencesModified = [...state.fences];
      fencesModified.splice(fenceIndex, 1);

      return {
        ...state,
        fences: fencesModified
      };
    case 'UPDATE_FENCES':
      const fences = action.payload;
      const fenceIdsToRemove = state.fences
        .map(fence => fence.id)
        .filter(fenceID => !fences.some(fence => fence.id === fenceID))
      ;
      const fencesToKeep = state.fences
        .filter(fence => !fenceIdsToRemove.includes(fence.id))
      ;
      const updatedFences = [];
      fences.forEach((fence) => {
        const existingFenceIndex = fencesToKeep.findIndex(
          (f) => f.id === fence.id
        );
        if (existingFenceIndex === -1) {
          // A cerca não existe, então a adicionamos
          updatedFences.push(fence);
        } else {
          // A cerca existe, então a atualizamos
          updatedFences.push({
            ...fencesToKeep[existingFenceIndex],
            ...fence,
          });
        }
      });

      return {
        ...state,
        fences: updatedFences
      };
    case 'ADD_LANDMARK':
      const newLandmark = action.payload;
      return {
        ...state,
        landmarks: [...state.landmarks, newLandmark]
      };
    case 'UPDATE_LANDMARK':
      const updatedLandmark = action.payload;
      const existingLandmarkIndex = state.landmarks.findIndex(
        (l) => l.id === updatedLandmark.id
      );
      if (existingLandmarkIndex === -1) {
        throw new Error('Tentativa de atualizar ponto de referência inexistente');
      }

      const changedLandmarks = [...state.landmarks];
      changedLandmarks[existingLandmarkIndex] = updatedLandmark;

      return {
        ...state,
        landmarks: changedLandmarks
      };
    case 'REMOVE_LANDMARK':
      const landmarkToRemove = action.payload;
      const landmarkIndex = state.landmarks.findIndex(
        (l) => l.id === landmarkToRemove.id
      );

      if (landmarkIndex === -1) {
        throw new Error('Tentativa de remover ponto de referência inexistente');
      }

      const landmarksModified = [...state.landmarks];
      landmarksModified.splice(landmarkIndex, 1);

      return {
        ...state,
        landmarks: landmarksModified
      };
    case 'UPDATE_LANDMARKS':
      const landmarks = action.payload;
      const landmarkIdsToRemove = state.landmarks
        .map(landmark => landmark.id)
        .filter(landmarkID => !landmarks.some(landmark => landmark.id === landmarkID))
      ;
      const landmarksToKeep = state.landmarks
        .filter(landmark => !landmarkIdsToRemove.includes(landmark.id))
      ;
      const updatedLandmarks = [];
      landmarks.forEach((landmark) => {
        const existingLandmarkIndex = landmarksToKeep.findIndex(
          (l) => l.id === landmark.id
        );
        if (existingLandmarkIndex === -1) {
          // O ponto de referência não existe, então a adicionamos
          updatedLandmarks.push(landmark);
        } else {
          // O ponto de referência existe, então a atualizamos
          updatedLandmarks.push({
            ...landmarksToKeep[existingLandmarkIndex],
            ...landmark,
          });
        }
      });

      return {
        ...state,
        landmarks: updatedLandmarks
      };
    default:
      throw new Error(`Tipo de ação não tratada: ${action.type}`);
  }
}

const GeoContext = createContext();

function GeoProvider(props) {
  const [geoState, dispatch] = useReducer(geoDataReducer, initialState);

  const updateEmblems = useCallback((emblems) => {
    dispatch({ type: 'UPDATE_EMBLEMS', payload: emblems });
  }, [dispatch]);
  const addFence = useCallback((fence) => {
    dispatch({ type: 'ADD_FENCE', payload: fence });
  }, [dispatch]);
  const updateFence = useCallback((fence) => {
    dispatch({ type: 'UPDATE_FENCE', payload: fence });
  }, [dispatch]);
  const removeFence = useCallback((fence) => {
    dispatch({ type: 'REMOVE_FENCE', payload: fence });
  }, [dispatch]);
  const updateFences = useCallback((fences) => {
    dispatch({ type: 'UPDATE_FENCES', payload: fences });
  }, [dispatch]);
  const addLandmark = useCallback((landmark) => {
    dispatch({ type: 'ADD_LANDMARK', payload: landmark });
  }, [dispatch]);
  const updateLandmark = useCallback((landmark) => {
    dispatch({ type: 'UPDATE_LANDMARK', payload: landmark });
  }, [dispatch]);
  const removeLandmark = useCallback((landmark) => {
    dispatch({ type: 'REMOVE_LANDMARK', payload: landmark });
  }, [dispatch]);
  const updateLandmarks = useCallback((landmarks) => {
    dispatch({ type: 'UPDATE_LANDMARKS', payload: landmarks });
  }, [dispatch]);

  const value = {
    colors: geoState.colors,
    emblems: geoState.iconSet,
    fences: geoState.fences,
    landmarks: geoState.landmarks,
    updateEmblems,
    addFence,
    updateFence,
    removeFence,
    updateFences,
    addLandmark,
    updateLandmark,
    removeLandmark,
    updateLandmarks
  };

  return <GeoContext.Provider value={value} {...props} />;
}

function useGeoContext() {
  const context = useContext(GeoContext);
  if (!context) {
    throw new Error('useGeoContext deve ser utilizado dentro de um GeoProvider');
  }
  
  return context;
}

export { GeoProvider, useGeoContext };