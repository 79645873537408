import React, { createContext, useContext, useReducer } from "react";

import * as AuthService from "../../services/AuthService";
import TokenService from "../../services/TokenService";

// Os tipos de estados permitidos
import {
  IDLE,
  PENDING,
  RESOLVED,
  REJECTED
} from "./Types";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

/**
 * Permite definir o estado da autorização.
 */
function useAuthState() {
  const context = useContext(AuthStateContext);

  if (!context) {
    throw new Error("useAuthState deve ser usado em AuthProvider");
  }

  return context;
}

/**
 * Permite controlar o despacho das mudanças de estado da autorização.
 */
function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (!context) {
    throw new Error("useAuthDispatch deve ser usado em AuthProvider");
  }

  return context;
}

/**
 * O estado inicial do contexto de autorização
 */
const initialState = {
  status: IDLE,
  error: null
};

/**
 * Uma função que lida com os estados do processo de autenticação.
 * 
 * @param Object currentState
 *   O estado atual
 * @param Object newState
 *   O novo estado
 * 
 * @returns 
 */
function reducer(currentState, newState) {
  // Simplesmente atualizamos o estado atual através de 'Shallow Merge'
  // dos objetos contendo os estados da aplicação
  return { ...currentState, ...newState };
}

/**
 * O provedor de estado de autenticação. Cria dois contextos onde o
 * primeiro armazena o estado da aplicação em si e o segundo as mudanças
 * de estados (despachos).
 * 
 * @param Object props 
 *   As propriedades
 * 
 * @returns <AuthProvider>
 */
function AuthProvider(props) {
  const [state, dispatchToAuth] = useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatchToAuth}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

/**
 * Executa o login do usuário.
 * 
 * @param Object dispatch 
 *   O evento de despacho
 * @param string username 
 *   O nome do usuário
 * @param string password 
 *   A senha do usuário
 * @param array tenant
 *   Os dados do tenant
 */
async function doLogin(dispatch, username, password, tenant) {
  // Avisamos que o pedido de registro está pendente
  dispatch({ status: PENDING });

  // Executamos a autenticação do usuário
  AuthService.login(username, password, tenant).then(
    (data) => {
      // Damos um tempo para o token ser armazenado
      dispatch({
        status: RESOLVED,
        error: null
      });
    },
    (error) => {
      const message =
        error.message ||
        error.toString();
      
      dispatch({
        status: REJECTED,
        error: message
      });
    }
  );
}

/**
 * Executa a desautenticação do usuário.
 * 
 * @param Object dispatch 
 *   O evento de despacho
 * 
 * @returns void
 */
async function doLogout(dispatch) {
  dispatch(initialState);
  AuthService.logout();
}

/**
 * Obtém se o usuário está autenticado.
 * 
 * @param string tenantName
 *   O nome do tenant
 * 
 * @returns bool
 */
function isAuthenticated(tenantName) {
  return TokenService.hasToken(tenantName);
}

/**
 * Obtém o usuário autenticado.
 * 
 * @param string tenantName
 *   O nome do tenant
 * 
 * @returns bool
 */
function getUser(tenantName) {
  return TokenService.getUser(tenantName);
}

export { AuthProvider, useAuthState, useAuthDispatch, doLogin, doLogout, isAuthenticated, getUser };
