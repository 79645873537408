import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  FeatureGroup
} from 'react-leaflet';

import Block from '../../../components/Block';
import Panel from '../../../components/Panel';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { useAPIContext } from '../../../features/APIContext';
import PrimaryButton from '../../../components/PrimaryButton';
import SelectField from '../../../components/SelectField';
import DayPickerField from '../../../components/DayPickerField';
import TimePickerField from '../../../components/TimePickerField';
import { useDeviceContext } from '../../../features/DeviceContext';
import { getRouteHistory } from '../../../services/PositionService';
import Dialog from '../../../components/Dialog';
import Map from '../../../components/Maps/Map';
import RenderRoute from '../../../components/Maps/RenderRoute';
import RadioButton from '../../../components/GroupedRadioButtons/RadioButton';
import GroupedRadioButtons from '../../../components/GroupedRadioButtons';
import {
  getSQLDate
} from '../../../hooks/dateUtils';

import '../../../styles/Report.scss';
import '../../../styles/Form.scss';
import '../../../styles/Calendar.css';

import bg_corner from '../../../assets/images/corner.png';

const TripHistoryPage = () => {
  // O contexto de dispositivos
  const {
    devicesState
  } = useDeviceContext();
  const {
    changeBlockUpdate
  } = useAPIContext();

  // O controle do mapa
  const mapRef = useRef();
  const mapCenter = {
    position: [-23.3292135, -46.7273893],
    zoom: 11
  };
  const [route, setRoute] = useState([]);
  const featureGroupRef = useRef();

  // Os veículos do usuário
  const [vehicles, setVehicles] = useState([]);

  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(false);
  
  // O indicativo de período personalizado
  const [customPeriod, setCustomPeriod] = useState(false);

  // Os possíveis períodos
  const periods = [
    {value:1, hours: 2,  label:'Últimas 2 horas'},
    {value:2, hours: 6,  label:'Últimas 6 horas'},
    {value:3, hours: 12, label:'Últimas 12 horas'},
    {value:4, hours: 24, label:'Últimas 24 horas'},
    {value:5, hours: 0,  label:'Período personalizado'}
  ];

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);

  // Atualiza os alarmes e alertas quando os eventos são atualizados
  useEffect(() => {
    setVehicles(previous => {
      return devicesState.devices
        .filter(device => device.vehicle !== undefined && device.vehicle !== null)
        .map(device => {
          return {value:device.vehicle.id, label:device.vehicle.plate, equipment: device.equipment, vehicle: device.vehicle};
        })
      ;
    });
  }, [devicesState.devices, setVehicles]);
  
  // Define condições iniciais do formulário
  const { control, register, getValues, setValue, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      // Define o valor inicial do período de horas
      starttime: "00:00",
      endtime: "23:59",
    }
  });

  // Lida com a mudança de período
  const handlePeriodChanged = (period) => {
    if (period === null) {
      setCustomPeriod(false);
    } else {
      if (period.hours === 0) {
        setCustomPeriod(true);
      } else {
        setCustomPeriod(false);
      }
    }
  };

  const handleCheck = (value) => {
    setValue('period', value, { shouldValidate: true, shouldDirty: true });
    console.log('Selecionado período', value);
    const periodOfReport = periods
      .find(period => period.value === parseInt(value))
    ;

    if (periodOfReport === null) {
      setCustomPeriod(false);
    } else {
      if (periodOfReport.hours === 0) {
        setCustomPeriod(true);
      } else {
        setCustomPeriod(false);
      }
    }
  }

  /**
   * Lida com o envio do formulário, requisitando os dados e gerando o
   * relatório.
   * 
   * @param {array} data
   *   Os dados do formulário
   */
  const onSubmit = async (data) => {
    console.log('Solicitando informações do percurso');
    setIsLoading(true);

    try {
      // Obtemos os parâmetros do relatório
      const periodOfReport = periods
        .find(period => period.value === parseInt(data.period))
      ;
      const typeOfPeriod = periodOfReport.value < 5
        ? 'hours'
        : 'period'
      ;
      const endDate = (data.reportperiod !== undefined)
        ? (
            (data.reportperiod.to === undefined)
              ? data.reportperiod.from
              : data.reportperiod.to
          )
        : null
      ;
      const parm1 = typeOfPeriod === 'period' 
        ? getSQLDate(data.reportperiod.from, data.starttime)
        : periodOfReport.hours
      ;
      const parm2 = typeOfPeriod === 'period' 
        ? getSQLDate(endDate, data.endtime)
        : null
      ;
  
      const vehicleID = data.vehicle.value;
      const mainTracker = data.vehicle.equipment.mainTracker;

      console.log('Solicitando informações do percurso');
      try {
        const response = await getRouteHistory(
          'vehicle',
          vehicleID,
          mainTracker,
          typeOfPeriod,
          parm1,
          parm2
        );
        console.log('Recebido as informações do percurso');
        if (response.route.length > 0) {
          setRoute(response.route);
        } else {
          console.log('Nenhum percurso no período informado');
          setErrorMessage('Nenhum percurso no período informado');
          setShowError(true);
          setRoute([]);
        }
      } catch (error) {
        console.log(error);
        setErrorMessage(error.message);
        setShowError(true);
      } finally {
        changeBlockUpdate(false);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const RenderedRoute = useMemo(() => {
    if (route === undefined || route.length === 0) {
      return null;
    }

    return (
      <RenderRoute
        route={route}
        showEnd={true}
      />
    )
  }, [route]);

  useEffect(() => {
    const map = mapRef.current;
    const featureGroup = featureGroupRef.current;

    if (map && featureGroup) {
      let bounds = featureGroup.getBounds();

      if (bounds && bounds.isValid()) {
        map.fitBounds(bounds);
      }
    };
  }, [mapRef, featureGroupRef]);

  useEffect(() => {
    const featureGroup = featureGroupRef.current;
    const map = mapRef.current;

    if (map && featureGroup) {
      let bounds = featureGroup.getBounds();

      if (bounds && bounds.isValid()) {
        map.fitBounds(bounds);
      }
    };
  }, [RenderedRoute]);

  useEffect(() => {
    const currentPeriod = document.querySelector('input[name="periodOfReport"]:checked');

    if (currentPeriod) {
      setValue('period', currentPeriod.value, { shouldValidate: true, shouldDirty: true });
    }
  }, [setValue]);

  const handleDayPickerChange = (range) => {
    updateSelectedPeriod();
  }

  const handleStartTimeChanged = (range) => {
    updateSelectedPeriod();
  }

  const handleEndTimeChanged = (range) => {
    updateSelectedPeriod();
  }

  const updateSelectedPeriod = () => {
    let footer = <p>Por favor, escolha o primeiro dia do período</p>;
    const range = getValues('reportperiod');
    const start = getValues('starttime');
    const end = getValues('endtime');;

    if (range?.from) {
      if (!range.to) {
        footer = (
          <p>
            Apenas o dia <span className="bold">
              {format(range.from, 'PPP', { locale: ptBR })}
            </span> { (start !== '00:00' || end !== '23:59') ? (
              <>
                das <span className="bold">
                  {start}</span> às <span className="bold">{end}
                </span>
              </>) : (<>(o dia inteiro)</>)
            } 
          </p>
        );
      } else if (range.to) {
        if (range.from === range.to) {
          footer = (
            <p>
              Apenas o dia <span className="bold">
                {format(range.from, 'PPP', { locale: ptBR })}
              </span> { (start !== '00:00' || end !== '23:59') ? (
                <>
                  das <span className="bold">
                    {start}</span> às <span className="bold">{end}
                  </span>
                </>) : (<>(o dia inteiro)</>)
              } 
            </p>
          );
        } else {
          footer = (
            <p>
              Do dia <span className="bold">
                {format(range.from, 'PPP', { locale: ptBR })}
              </span> às <span className="bold">{start}
              </span> até o dia <span className="bold">
                {format(range.to, 'PPP', { locale: ptBR })}
              </span> às <span className="bold">{end}</span>
            </p>
          );
        }
      }
    }

    setSelectedPeriod(footer);
  }

  return (
    <Block horizontal={true}>
      <Panel style={styles.panel}>
        <div className="section mb-3">
          <div className="bg-holder d-none d-lg-block bg-section" style={{ backgroundImage: `url(${bg_corner})` }}></div>
          <div className="section-body position-relative">
            <div className="row">
              <div className="col-lg-12">
                <h3 style={{fontSize: 'calc(1rem + 0.5736vw)'}}>Histórico de percurso</h3>
                <p className="description">
                  Visualize no mapa os pontos de parada e os locais
                  percorridos por um veículo durante um determinado
                  período. Esse relatório fornece uma representação
                  visual do trajeto feito, destacando os momentos de
                  parada e as rotas seguidas ao longo do tempo.
                </p>
              </div>
            </div>
          </div>
        </div>
        <form style={{ color: 'var(--theme-card-color)'}} onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control no-margin col-lg-3">
            <legend>Veículos</legend>
            <SelectField
              name="vehicle"
              control={control}
              options={vehicles}
              placeHolder="Selecione o veículo..."
              rules={{ required: true }}
            />
            {errors.vehicle && errors.vehicle.type === "required" && (
              <span className="errorMsg">Escolha um dos veículos</span>
            )}
          </div>
          <div className="form-control no-margin">
            <legend>Período desejado</legend>
            <GroupedRadioButtons
              hasError={errors.period && errors.period.type === "required"}
            >
              {periods.map((period, index) => {
                return (
                  <RadioButton
                    key={`period${index}`}
                    name="periodOfReport"
                    value={period.value}
                    onChange={handleCheck}
                    id={`period${index}`}
                  >
                    { period.hours > 0
                      ? (
                        <>
                          <span className="smaller">
                            Últimas
                          </span>
                          {period.hours} horas
                        </>
                      ) : (
                        <>
                          {period.label}
                        </>
                      )
                    }
                  </RadioButton>
                );
              })}
            </GroupedRadioButtons>
            <input type="hidden" name="period" {...register('period', {required: true})} />
            {errors.period && errors.period.type === "required" && (
              <span className="errorMsg">Informe um período para o relatório</span>
            )}
          </div>
          { customPeriod &&
          <div className="datetimecontrols smaller">
            <div className="form-control no-margin">
              <DayPickerField
                label="Escolha um dia ou período"
                name="reportperiod"
                control={control}
                rules={{ required: true }}
                onChange={handleDayPickerChange}
              />
            </div>
            <div className="timercontrols small">
              <div className="form-control no-margin">
                <TimePickerField
                  small={true}
                  label="Informe a hora inicial"
                  footerLabel="Hora inicial"
                  name="starttime"
                  control={control}
                  rules={{ required: true }}
                  onChange={handleStartTimeChanged}
                />
              </div>
              <div className="form-control no-margin">
                <TimePickerField
                  small={true}
                  label="Informe a hora final"
                  footerLabel="Hora final"
                  name="endtime"
                  control={control}
                  rules={{ required: true }}
                  onChange={handleEndTimeChanged}
                />
              </div>
            </div>
            <div className="form-control no-margin selectedRange">
              <legend>Período selecionado</legend>
              {selectedPeriod}
            </div>
          </div>
          }
          <div className="form-control no-margin buttons right-align">
            <PrimaryButton type="submit" color="primary" size="smaller">
              Obter relatório
            </PrimaryButton>
          </div>
        </form>
      </Panel>
      <LoadingOverlay isLoading={isLoading} message="Aguarde enquanto carregamos os dados..." />
      <Dialog
        show={showError}
        title="Atenção!"
        message={errorMessage}
        onClose={() => setShowError(false)}
      />
      <div
        className="section map"
        style={styles.map}
      >
        <Map
          onRef={mapRef}
          center={mapCenter}
        >
          <FeatureGroup ref={featureGroupRef}>
            {RenderedRoute}
          </FeatureGroup>
        </Map>
      </div>
    </Block>
  )
};

const styles = {
  panel: {
    borderRadius: "0",
    flexDirection: "column",
    width: '500px',
    padding: '.5rem',
  },
  title: {
    marginBottom: '.5rem',
  },
  map: {
    flex: 1,
    height: '100%',
    minHeight: '89vh',
    boxShadow: 'none',
    borderLeft: '1px solid var(--theme-border-color)',
  }
};

export default TripHistoryPage;
