import React from 'react';

function Tootip({ children, label, side = "left" }) {
  return (
    <span
      data-tooltip={`${side} 200`}
      aria-label={ label }
    >
      { children }
    </span>
  );
}

export default Tootip;