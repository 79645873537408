import React, { useState } from "react";

function LoadingButton({ children, color, primary, small, onClick, ...rest }) {
  const [isLoading, setIsLoading] = useState(false);

  const classes = "form-button "
    + (small ? "small " : "")
    + (
        color
          ? `colors ${color} `
          : primary
              ? "form-button-primary"
              : "form-button-secondary"
      )
  ;

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={classes}
      type="button"
      onClick={handleClick}
      disabled={isLoading}
      {...rest}
    >
      {isLoading && (
        <div className="form-spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
      {children}
    </button>
  );
}

export default LoadingButton;