import React from 'react';
import classNames from 'classnames';

function IgnitionStatus({ status, size }) {
  const StatusIcon = ({ size }) => (
    <svg
      viewBox="0 0 48 48"
      title="Ignição"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m23.411932-.0000004a4.3636364 4.3636364 0 0 0 -4.363636 4.3636364v15.490057a4.3636364 4.3636364 0 0 0 4.363636 4.363636 4.3636364 4.3636364 0 0 0 4.363637-4.363636v-15.490057a4.3636364 4.3636364 0 0 0 -4.363637-4.3636364zm-13.301847 5.7400572a4.3636364 4.3636364 0 0 0 -3.0852269 1.278409c-6.85714733 6.8571472-8.9163175 17.2033472-5.2052558 26.1626422 3.7110616 8.959295 12.4829267 14.818892 22.1803977 14.818892 9.697472 0 18.469336-5.859597 22.180397-14.818892 3.711062-8.959295 1.651893-19.305496-5.205255-26.1626422a4.3636364 4.3636364 0 0 0 -6.170454 0 4.3636364 4.3636364 0 0 0 0 6.1704542c4.37889 4.378891 5.680917 10.929983 3.311079 16.651279-2.369838 5.721295-7.923081 9.432528-14.115767 9.432528s-11.748059-3.711233-14.1178975-9.432528c-2.3698387-5.721296-1.0656802-12.272388 3.3132095-16.651279a4.3636364 4.3636364 0 0 0 0-6.1704542 4.3636364 4.3636364 0 0 0 -3.085227-1.278409z" />
    </svg>
  );

  const color = (status === null) ? 'unknown' : (status === true) ? 'on' : 'off';

  return (
    <div className={classNames('status', color)}>
      <StatusIcon size={size} />
    </div>
  );
}

export default IgnitionStatus;