import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Virtuoso } from "react-virtuoso";
import ResizeObserver from 'resize-observer-polyfill'

import Collapse from "../Collapse";
import Modal from '../Modal';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

function VirtualizedList({
  article = "a",
  label,
  items,
  onSelect,
  forceSelect,
  onUnselect,
  onDelete,
  renderItem,
  fieldName = "name",
  renderName = (item) => item.name,
  noContentMessage = "Nenhum item encontrado"
}) {
  const [currentOpenIndex, setcurrentOpenIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  //const data = useMemo(() => items, [items]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalData, setModalData] = useState(null);
  const virtuosoRef = useRef(null);

  useEffect(() => {
    if (forceSelect !== null) {
      const index = items.findIndex(item => item.id === forceSelect);
      setcurrentOpenIndex(index);
    }
  }, [forceSelect]);

  useEffect(() => {
    // Sempre que o item selecionado for alterado, deslocamos a lista
    // para que o item selecionado fique visível
    if (currentOpenIndex !== null && items.length > 0) {
      if (currentOpenIndex < 0) {
        virtuosoRef.current.scrollToIndex({
          index: 0,
          align: "start"
        });
      } else {
        console.log('Current open index', items.length, currentOpenIndex, currentOpenIndex === null ? 'nulo': 'não nulo');
        virtuosoRef.current.scrollToIndex({
          index: currentOpenIndex,
          align: "start"
        });
      }
    }
  }, [currentOpenIndex, items]);

  return (
    <>
    <Modal
      title={modalTitle}
      message={modalMessage}
      data={modalData}
      isOpen={isModalOpen}
      confirmAction="Remover"
      onConfirm={(fence) => {
        setModalOpen(false);
        if (onDelete) {
          onDelete(fence);
        }
      }}
      onClose={() => setModalOpen(false)}
    />
    {items && items.length === 0 ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 'calc(100% - 0px)',
          width: "100%",
          color: "var(--theme-table-color)",
          boxSizing: "border-box",
          fontSize: "1.2rem"
        }}
      >
        {noContentMessage}
      </div>
    ) : (
    <Virtuoso
      ref={virtuosoRef}
      style={{
        width: '100%',
        height: 'calc(100% - 0px)',
        borderRadius: '.475rem'
      }}
      data={items}
      totalCount={items.length}
      itemContent={(index, data) => {
        const bgColor = (index === currentOpenIndex)
          ? "var(--theme-table-row-selected-bg)"
          : (hoveredIndex === index)
              ? "var(--theme-table-row-hover-bg)"
              : "var(--theme-table-bg)"
        ;

        const hideItem = () => setcurrentOpenIndex("");

        return (
          <div
            style={{
              ...styles.wrapper,
              marginTop : index === 0 ? 0 : 1
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div
              key={`div_${data.id}`}
              style={{
                ...styles.item,
                color: (currentOpenIndex === index)
                  ? "var(--theme-gray-900)"
                  : "var(--theme-gray-600)",
                backgroundColor: bgColor,
                borderTopLeftRadius: index === 0 ? 10: 0,
                borderTopRightRadius: index === 0 ? 10: 0,
              }}
              onClick={() => {
                if (currentOpenIndex === index) {
                  setcurrentOpenIndex("");
                  onUnselect(data);
                } else {
                  setcurrentOpenIndex(index);
                  onSelect(data);
                }
              }}
            >
              <svg
                style={{
                  rotate: (currentOpenIndex === index) ?'180deg':'0deg',
                  transition: 'rotate 0.3s ease'
                }}
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" fill='var(--theme-gray-600)'></path>
              </svg>
              <span
                style={styles.label}
              >
                {renderName(data)}
              </span>
              <button
                style={{
                  ...styles.button,
                  backgroundColor: "var(--theme-primary)",
                }}
                title={`Editar ${label}`}
              >
                <svg
                  width=".8rem"
                  height=".8rem"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" fill="currentColor" />
                </svg>
              </button>
              <button
                style={{
                  ...styles.button,
                  backgroundColor: "#db2828",
                }}
                title={`Remover ${label}`}
                onClick={(event) => {
                  event.stopPropagation();
                  const correctedArticle = (article === 'o')
                    ? 'e'
                    : article
                  ;
                  const question = (data.name === '')
                    ? `Deseja realmente remover est${correctedArticle} ${label}?`
                    : `Deseja realmente remover ${article} ${label} "${data[fieldName]}"?`
                  ;
                  setModalTitle(`Remover ${label}`);
                  setModalMessage(question);
                  setModalData(data);
                  setModalOpen(true);
                }}
              >
                <svg
                  width=".8rem"
                  height=".8rem"
                  viewBox="0 0 24.50898 24.50898"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m20.61402 1.26258h-5.3121v-1.00427999c0-.14262001-.11562-.25830001-.2583-.25830001h-5.5782599c-.14262 0-.25824.11568-.25824.25830001v1.00421999h-5.3121601c-.4275 0-.774.34656-.774.77406v2.4316201h18.26706v-2.4315601c0-.4275-.3465-.77406-.774-.77406zm-15.3491399 22.06446c.02766.660601.57126 1.18194 1.23234 1.18194h11.5146599c.66108 0 1.20468-.521339 1.23234-1.18194l.8223-17.3589599h-15.62394zm9.5944199-13.0473c0-.2766.22428-.5009399.501-.5009399h.801301c.276539 0 .500999.2242799.500999.5009399v9.91758c0 .27666-.22428.50094-.500999.50094h-.801301c-.2766 0-.501-.22416-.501-.50094zm-3.50634 0c0-.2766.22428-.5009399.50094-.5009399h.8013c.27654 0 .50094.2242799.50094.5009399v9.91758c0 .27666-.22422.50094-.50094.50094h-.8013c-.2766 0-.50094-.22416-.50094-.50094zm-3.5064599 0c0-.2766.22428-.5009399.50094-.5009399h.80136c.2766 0 .50094.2242799.50094.5009399v9.91758c0 .27666-.22428.50094-.50094.50094h-.80136c-.2766 0-.50094-.22416-.50094-.50094z" strokeWidth=".06" fill="currentColor" />
                </svg>
              </button>
            </div>
            <Collapse isOpen={index === currentOpenIndex}>
              {renderItem(data, hideItem)}
            </Collapse>
          </div>
        );
      }}
      />
    )}
    </>
  );
}

const styles = {
  wrapper: {
    borderBottom: "1px solid var(--theme-table-border-color)",
    boxSizing: "border-box"
  },
  item: {
    display: 'flex',
    gap: '.5rem',
    padding: "0.25rem",
    cursor: "pointer",
    boxSizing: "border-box",
    alignItems: "center",
  },
  label: {
    width: "calc(100% - 60px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: 'inherit',
    boxSizing: "border-box",
    fontSize: ".95em"
  },
  button: {
    padding: '.18571429em .38571429em .18571429em',
    border: "none",
    borderRadius: ".475rem",
    color: "#fff",
    cursor: "pointer"
  }
};

export default VirtualizedList;
