import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import moment from 'moment';
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { defaults } from '../../settings/config';
import { useThemeContext } from "../../components/ThemeProvider";
import Table from '../../components/Table';
import { PortStatus, PortStatusItem } from '../../components/PortStatus';

import '../../styles/monitor.scss';

ChartJS.register(
  ArcElement,
  ChartDataLabels,
  Legend,
  Tooltip
);

function MonitorPage() {
  const onSecond = 1000;
  const [headingThemeColor, setHeadingThemeColor] = useState('#304050');
  const [borderThemeColor, setBorderThemeColor] = useState('#edf2f9');
  const [lastPortUpdate, setLastPortUpdate] = useState(null);
  const { theme } = useThemeContext();

  const getThemeColors = () => {
    // Obtém a cor do tema do CSS
    const style = getComputedStyle(document.body);
    const headingColor = style.getPropertyValue('--theme-heading-color');
    const borderColor = style.getPropertyValue('--theme-body-bg');
    
    return {
      headingColor,
      borderColor
    };
  };

  useEffect(() => {
    // Obtém a cor do tema do CSS
    const { headingColor, borderColor } = getThemeColors();

    // Atualiza a cor do gráfico
    setHeadingThemeColor(headingColor);
    setBorderThemeColor(borderColor);
  }, []);

  useEffect(() => {
    // Obtém a cor do tema do CSS
    const { headingColor, borderColor } = getThemeColors();

    // Atualiza a cor do gráfico
    setHeadingThemeColor(headingColor);
    setBorderThemeColor(borderColor);

    // Atualizar a cor do gráfico
    setData(prevData => ({
      ...prevData,
      datasets: [
        {
          ...prevData.datasets[0],
          borderColor: borderColor
        },
      ],
    }));
  }, [theme]);

  const [data, setData] = useState({
    labels: [
      'Comunicando',
      'Sem comuncicar à mais de 1 horas',
      'Sem comuncicar à mais de 2 horas',
      'Sem comuncicar à mais de 6 horas',
      'Sem comuncicar à mais de 24 horas'
    ],
    datasets: [
      {
        label: 'Veículos ',
        data: [0, 0, 0, 0],
        backgroundColor: [
          'rgba( 80, 161, 66, 1)',
          'rgba(219, 235, 80, 1)',
          'rgba(255, 225,  0, 1)',
          'rgba(253, 152, 37, 1)',
          'rgba(222,  22,  0, 1)'
        ],
        borderColor: borderThemeColor,
        borderWidth: 3,
      },
    ],
  });
  const [lastCommunications, setLastCommunications] = useState([]);
  const [queues, setQueues] = useState([
    {
      service: 'Obtenção de endereços por geolocalização',
      amount: 0
    },
    {
      service: 'Envio de e-mails',
      amount: 0
    }
  ]);

  const [portData, setPortData] = useState({});

  const options = {
    plugins: {
      colors: {
        forceOverride: true
      },
      legend: {
        position: "top",
        align: "start",
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 18,
            weight: "bold"
          },
          generateLabels(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
  
              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                
                return {
                  text: label + ': ' + chart.data.datasets[0].data[i] + ' veículos',
                  fillStyle: style.backgroundColor,
                  strokeStyle: style.borderColor,
                  lineWidth: style.borderWidth,
                  fontColor: headingThemeColor,
                  hidden: !chart.getDataVisibility(i),
  
                  index: i
                };
              });
            }
            return [];
          }
        }
      },
      datalabels: {
        display: true,
        labels: {
          title: {
            font: {
              weight: "bold"
            }
          },
          value: {
            color: borderThemeColor,
          }
        }
      }
    }
  };

  useEffect(() => {
    const fetchMonitoringData = () => {
      try {
        // Fazer uma chamada para a API para buscar os novos valores
        const URL = defaults.api.baseURL + '/monitor';
        fetch(URL)
          .then(response => response.json())
          .then(apiData => {
            // Atualiza as informações de última comunicação 
            console.table(apiData.data.lastCommunications);
            setLastCommunications(apiData.data.lastCommunications);
            setQueues([
              {
                service: 'Obtenção de endereços por geolocalização',
                amount: apiData.data.pendingAddresses
              },
              {
                service: 'Envio de e-mails',
                amount: apiData.data.pendingEmails
              }
            ]);

            // Atualiza os valores do gráfico com base nos dados da API
            setData(prevData => ({
              ...prevData,
              datasets: [
                {
                  ...prevData.datasets[0],
                  data: apiData.data.statistics,
                },
              ],
            }));

          });
        ;
      } catch (error) {
        console.error('Erro ao buscar os dados da API:', error);
      }
    };

    const fetchPortStatusData = () => {
      try {
        // Fazer uma chamada para a API para buscar os novos valores
        const URL = defaults.api.baseURL + '/monitor/ports';
        fetch(URL)
          .then(response => response.json())
          .then(apiData => {
            // Atualiza as informações de última comunicação 
            console.table(apiData.data.ports);

            // Atualiza os valores da tabela de estado das portas com
            // base nos dados da API
            setPortData(prevData => ({
              ...prevData,
              ...apiData.data.ports,
            }));

            // Atualiza a data da última atualização com a data/hora
            // atual
            setLastPortUpdate(moment().format('DD/MM/YYYY HH:mm:ss'));
          });
        ;
      } catch (error) {
        console.error('Erro ao buscar os dados da API:', error);
      }
    };

    // Iniciar a primeira chamada à API imediatamente
    fetchMonitoringData();
    fetchPortStatusData();

    // Definir um intervalo para repetir a chamada à API de tempos em
    // tempos
    const monitorInterval = setInterval(
      fetchMonitoringData, 60*onSecond
    );
    const portInterval = setInterval(
      fetchPortStatusData, 60*onSecond
    );

    // Limpar o intervalo quando o componente for desmontado
    return () => {
      clearInterval(monitorInterval)
      clearInterval(portInterval)
    };
  }, []);

  useEffect(() => {
    console.log('Dados das portas', portData);
  }, [portData]);

  return (
    <main className="fullsite">
      <div className='columned'>
        <div className="table_area">
          <Table
            data={lastCommunications.length > 0 
              ? lastCommunications
              : [
                  {
                    "protocolname": "",
                    "variant": "",
                    "lastcommunication": ""
                  }
              ]
            }
            columns={{
              protocolname: {
                name: "Protocolo",
                align: "center",
                width: 160
              },
              variant: {
                name: "Variante",
                align: "center",
                width: 240
              },
              lastcommunication: {
                name: "Última comunicação",
                align: "center",
                width: 180,
                formatter: 'datetime'
              },
              amount: {
                name: "Qtde",
                align: "center",
                width: 80,
                totalizer: true
              }
            }}
            rowColor={(data) => {
              if (!data) {
                return '';
              }
              const limitDateTime = moment().subtract(10, 'minutes');
              const lastCommunication = moment(data.lastcommunication);
              
              if (limitDateTime.isAfter(lastCommunication)) {
                return 'critical';
              }

              return '';
            }}
            pageSize={0}
          />
        </div>
        <div className="graph">
          <Doughnut data={data} options={options} />
        </div>
        <div className="table_area">
          <h2>Estado das filas de processamento</h2>
          <Table
            data={queues}
            columns={{
              service: {
                name: "Serviço",
                align: "center",
                width: 160
              },
              amount: {
                name: "Qtde",
                align: "center",
                width: 80
              }
            }}
            rowColor={(data) => {
              if (!data) {
                return '';
              }
              const limitValue = 500;
              
              if (data.amount > limitValue) {
                return 'critical';
              }

              return '';
            }}
            pageSize={0}
          />
        </div>
        <div className="table_area">
          <h2>Estado das portas</h2>
          {lastPortUpdate && (<h5>Atualizado em {lastPortUpdate}</h5>)}
          <PortStatus>
          {Object.values(portData).map((protocol, index) => {
            return (
              <PortStatusItem
                key={index}
                label={protocol.protocolname}
                ports={protocol.ports} />
            );
          })}
          </PortStatus>
        </div>
      </div>
    </main>
  );
}

export default MonitorPage;
