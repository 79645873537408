import React from 'react';

function NoEvent({ noEventMessage }) {
  return (
    <div key="1" className="noevent">
      <span>{ noEventMessage }</span>
    </div>
  );
}

export default NoEvent;