import React, {
  useEffect,
  useRef
} from "react";
import {
  useForm,
  FormProvider
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

function Form ({
  schema,
  onSubmit,
  children,
  defaultValues,
  watchFields,
  changedFields,
  clearChangedFields,
  onFieldChange,
  ...props
}) {
  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema)
  });
  const handleSubmit = methods.handleSubmit;
  const lastWatchedFields = useRef(watchFields && watchFields.map((field) => {
    return methods.getValues(field);
  }));

  const watchedFields = watchFields
    ? methods.watch(watchFields)
    : {}
  ;

  useEffect(() => {
    if (watchFields) {
      watchFields.forEach((field, index) => {
        if (watchedFields[index] !== null) {
          if (watchedFields[index] !== lastWatchedFields.current[field]) {
            // O valor sofreu alteração

            // Atualiza o valor do campo assistido
            lastWatchedFields.current[field] = watchedFields[index];

            // Chama a função de callback, se houver
            if (onFieldChange) {
              onFieldChange(field, watchedFields[index]);
            }
          }
        }
      });
    }
  }, [watchedFields, onFieldChange]);

  useEffect(() => {
    if (changedFields) {
      Object.entries(changedFields).forEach(([field, value]) => {
        console.log(`Chave: ${field}, Valor: ${value}`);
        methods.setValue(field, value);
      });

      clearChangedFields();
    }
  }, [changedFields]);

  useEffect(() => {
    console.log('errors', methods.formState.errors);
  }, [methods.formState.errors]);
  useEffect(() => {
    console.log('isDirty', methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);
  
  return (
    <FormProvider {...methods}>
      <form className="form" onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}

export default Form;
