import React from 'react';
import MarkerClusterGroup from './MarkerClusterGroup';
import shortid from 'shortid';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import MapMarker from './MapMarker';

import '../../styles/MarkerTooltip.scss';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
  popupAnchor: [0, -40]
});

L.Marker.prototype.options.icon = DefaultIcon;

function MarkersLayer({ markers }) {
  //selectedDevice={selectedDevice}
  //setSelectedDevice={setSelectedDevice}
  //map={map}

  return (
    <MarkerClusterGroup>
      {markers.map((marker, index) => (
        <MapMarker
          key={shortid.generate()}
          id={marker.id}
          index={index}
          device={marker}
        />
      ))}
    </MarkerClusterGroup>
  );
}

export default MarkersLayer;
