import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import classnames from "classnames";

function NumericField({
  name,
  label,
  control,
  rules,
  onChange,
  min,
  max,
  size,
  defaultValue,
  ...rest
}) {
  const isRequired = rules && rules.required ? true : false;

  const {
    field,
    fieldState: { invalid, error },
    trigger
  } = useController({
    name,
    control,
    rules: {
      required: isRequired,
      validate: (fieldValue) => {
        // Se não for obrigatório, não valida
        if (!isRequired) {
          return true;
        }

        // Se for uma string, valida se o campo não está vazio
        if (typeof fieldValue === 'string') {
          if (fieldValue.trim() === '') {
            return "Este parâmetro é obrigatório";
          }
        } else if (fieldValue === undefined || fieldValue === null) {
          // Se for um número ou outro tipo, valida se não é undefined
          // ou null
          return "Este parâmetro é obrigatório";
        }

        // Converte o valor para número
        const numericValue = Number(fieldValue);

        // Verifica se a conversão resultou em NaN (valor não numérico)
        if (isNaN(numericValue)) {
          return "O valor deve ser um número";
        }

        // Valida se está dentro dos limites
        if (min !== undefined && max !== undefined && (numericValue < min || numericValue > max)) {
          return `O valor deve estar entre ${min} e ${max}`;
        }
        if (min !== undefined && numericValue < min) {
          return `O valor deve ser maior ou igual a ${min}`;
        }
        if (max !== undefined && numericValue > max) {
          return `O valor deve ser menor ou igual a ${max}`;
        }

        return true;
      }
    }
  });

  const [value, setValue] = useState(() => {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (field.value === undefined) {
      return '';
    }

    return field.value;
  });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    console.log('field.value de', field.name, 'mudou para', field.value);
    if (field.value !== undefined) {
      // Força para que o valor seja uma string numérica
      let newValue = `${field.value}`;
      newValue = newValue.replace(/[^\d]/g, '');
      if (size) {
        newValue = newValue.slice(0, Math.abs(size));
      }
      setValue(newValue);
    } else {
      setValue('');
    }
  }, [field.name, field.value, size]);

  const validateValue = (newValue) => {
    // Só valida se min ou max estiverem definidos
    if (min === undefined && max === undefined) {
      return true;
    }

    if (typeof newValue === 'string') {
      if (newValue.trim() === '') {
        return false;
      }
    } else if (newValue === undefined || newValue === null) {
      return false;
    }
    
    // Converte o valor para número
    newValue = Number(newValue);

    // Verifica se a conversão resultou em NaN (valor não numérico)
    if (isNaN(newValue)) {
      return false;
    }

    if (min !== undefined && newValue < min) {
      return false;
    }
    if (max !== undefined && newValue > max) {
      return false;
    }
    
    return true;
  };

  const handleOnChange = (event) => {
    const { value: inputValue, selectionStart } = event.target;
    let newValue = inputValue.replace(/[^\d]/g, '');
    if (size) {
      newValue = newValue.slice(0, Math.abs(size));
    }
  
    // Salva a posição do cursor antes da manipulação
    const cursorPosition = selectionStart;

    // Enviamos o valor para o hook form
    field.onChange(newValue);

    // Valida o valor sem alterá-lo
    const isValid = validateValue(Number(newValue));
    setHasError(!isValid);

    // Atualiza o valor interno do componente (UI state)
    setValue(newValue);

    if (onChange) {
      // Propagar o evento para o componente principal
      onChange(newValue);
    }

    // Restaura a posição do cursor depois da manipulação
    //event.target.value = newValue;
    event.target.setSelectionRange(cursorPosition, cursorPosition);
  };

  useEffect(() => {
    // Lida com erros de validação
    setHasError(invalid);
  }, [invalid]);

  console.log('Field', name, 'rendered');

  return (
    <>
      <input
        type="text"
        ref={field.ref}
        name={name}
        value={value}
        onChange={handleOnChange}
        size={size??undefined}
        pattern="[0-9]*"
        className={classnames({ error: hasError })}
        {...rest}
      />
      {hasError && (
        <span className="errorMsg">
          {error?.type === 'required' 
            ? 'O valor de ' + label.toLowerCase() + ' deve ser informado'
            : error?.message || `O valor deve estar entre ${min} e ${max}`
          }
        </span>
      )}
    </>
  );
}

export default NumericField;