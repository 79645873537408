import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

import Api from './Api';
import TokenService from "./TokenService";

/**
 * Registra um novo usuário no sistema.
 * 
 * @param string username 
 *   O nome do usuário
 * @param string email
 *   O e-mail do usuário 
 * @param string password
 *   A senha do usuário
 * 
 * @returns
 *   Os dados da requisição do registro do usuário
 */
const register = async (
  username,
  email,
  password
) => {
  return Api
    .post("/signup", {
      username,
      email,
      password,
    });
};

/**
 * Executa a autenticação do usuário.
 * 
 * @param string username
 *   O nome do usuário
 * @param string password
 *   A senha do usuário
 * @param array tenant
 *   Os dados do tenant
 * 
 * @returns
 *   Os dados da requisição de autenticação do usuário
 */
const login = async (
  username,
  password,
  tenant
) => {
  const installationUUID = await getCurrentBrowserFingerPrint();

  console.log('deviceIdent:', installationUUID);

  return Api
    .post("/auth/login",{
      'username': username,
      'password': password,
      'uuid': tenant.uuid,
      'deviceIdent': `${installationUUID}`
    })
    .then((response) => {
      if (response.status === 'Authenticated') {
        const { user, token, renew } = response.data;
        //console.log('AuthService.login:response:', response);

        // Armazena as informações do usuário autenticado
        TokenService.setUser({
          id: user.id,
          name: user.name,
          role: user.role,
          phoneNumber: user.phonenumber,
          email: user.email,
          groupID: user.groupid,
          customerID: user.entity.id,
          username: user.username,
          accessToken: token.credential,
          refreshToken: renew.credential,
          tenant: tenant.tenantname,
          modules: user.modules,
          belongsToAnAssociation: user.entity.cooperative,
        });

        return Promise.resolve(response);
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Executa a desautenticação do usuário.
 */
const logout = () => {
  TokenService.removeUser();
};

/**
 * Obtém as contas de usuários com base num identificador.
 * 
 * @returns
 *   Os dados de contas de usuários
 */
const accountFinder = async (
  identifier,
  tenant
) => {
  const uriData = `${tenant.uuid}/${identifier}`;
  return Api
    .get(`/auth/account/finder/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Found') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Solicita um código de confirmação da conta.
 * 
 * @returns
 *   Os dados de confirmação da conta
 */
const accountRequestValidationCode = async (
  hashedUserID,
  tenant
) => {
  const uriData = `${tenant.uuid}/${hashedUserID}`;
  return Api
    .get(`/auth/account/code/send/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Valida a conta usando um código de confirmação.
 * 
 * @returns
 *   Os dados de confirmação da conta
 */
const accountValidateUsingCode = async (
  hashedUserID,
  code,
  tenant
) => {
  const uriData = `${tenant.uuid}/${hashedUserID}/${code}`;
  return Api
    .put(`/auth/account/code/validate/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Gera uma nova senha para a conta do usuário.
 * 
 * @returns
 *   Os dados de confirmação da conta
 */
const accountRedefinePasswordUsingCode = async (
  hashedUserID,
  code,
  tenant,
  newPassword
) => {
  const uriData = `${tenant.uuid}/${hashedUserID}/${code}`;
  return Api
    .put(`/auth/account/code/redefine/${uriData}`, {
      newpassword: newPassword
    })
    .then((response) => {
      if (response.status === 'Updated') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Obtém os dados do usuário.
 */
const getCurrentUser = () => {
  return TokenService.getUser();
};

export {
  register,
  login,
  logout,
  accountFinder,
  accountRequestValidationCode,
  accountValidateUsingCode,
  accountRedefinePasswordUsingCode,
  getCurrentUser
};
