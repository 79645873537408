import { useState, useEffect } from 'react';

/**
 * Reproduz um áudio de notificação.
 * 
 * @param {string} audioFile
 *   O arquivo de áudio que será tocado quando a notificação for
 *   disparada
 * @returns 
 */
function useNotificationSound(audioFile) {
  const [audio] = useState(new Audio(audioFile));

  /**
   * Reproduz o áudio de notificação se houver permissão.
   */
  function playNotificationSound() {
    if (Notification.permission === 'granted') {
      audio.play();
    }
  }

  /**
   * Solicita permissão para notificações sonoras.
   */
  function requestNotificationPermission() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Permissão concedida para notificações sonoras.');
        } else {
          console.log('Permissão negada para notificações sonoras.');
        }
      });
    }
  }

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return { playNotificationSound };
}

export default useNotificationSound;