import {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';

const initialState = {
  drivers: []
};

function driverDataReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_DRIVERS':
      const drivers = action.payload;
      const driverIdsToRemove = state.drivers
        .map(driver => driver.id)
        .filter(driverID => !drivers.some(driver => driver.id === driverID))
      ;
      const driversToKeep = state.drivers
        .filter(driver => !driverIdsToRemove.includes(driver.id))
      ;
      const updatedDrivers = [];
      drivers.forEach((driver) => {
        const existingDriverIndex = driversToKeep.findIndex(
          (f) => f.id === driver.id
        );
        if (existingDriverIndex === -1) {
          // O motorista não existe, então a adicionamos
          updatedDrivers.push(driver);
        } else {
          // O motorista existe, então a atualizamos
          updatedDrivers.push({
            ...driversToKeep[existingDriverIndex],
            ...driver,
          });
        }
      });

      return {
        ...state,
        drivers: updatedDrivers
      };
    case 'ADD_DRIVER':
      const newDriver = action.payload;
      return {
        ...state,
        drivers: [...state.drivers, newDriver]
      };
    case 'UPDATE_DRIVER':
      const updatedDriver = action.payload;
      const existingDriverIndex = state.drivers.findIndex(
        (f) => f.id === updatedDriver.id
      );
      if (existingDriverIndex === -1) {
        throw new Error('Tentativa de atualizar motorista inexistente');
      }

      const changedDrivers = [...state.drivers];
      changedDrivers[existingDriverIndex] = updatedDriver;

      return {
        ...state,
        drivers: changedDrivers
      };
    case 'REMOVE_DRIVER':
      const driverToRemove = action.payload;
      const driverIndex = state.drivers.findIndex(
        (f) => f.id === driverToRemove.id
      );
      if (driverIndex === -1) {
        throw new Error('Tentativa de remover motorista inexistente');
      }

      const driversModified = [...state.drivers];
      driversModified.splice(driverIndex, 1);

      return {
        ...state,
        drivers: driversModified
      };
    default:
      throw new Error(`Tipo de ação não tratada: ${action.type}`);
  }
}

const DriverContext = createContext();

function DriverProvider(props) {
  const [driverState, dispatch] = useReducer(driverDataReducer, initialState);

  const addDriver = useCallback((fence) => {
    dispatch({ type: 'ADD_DRIVER', payload: fence });
  }, [dispatch]);
  const updateDriver = useCallback((fence) => {
    dispatch({ type: 'UPDATE_DRIVER', payload: fence });
  }, [dispatch]);
  const removeDriver = useCallback((fence) => {
    dispatch({ type: 'REMOVE_DRIVER', payload: fence });
  }, [dispatch]);
  const updateDrivers = useCallback((fences) => {
    dispatch({ type: 'UPDATE_DRIVERS', payload: fences });
  }, [dispatch]);

  const value = {
    drivers: driverState.drivers,
    addDriver,
    updateDriver,
    removeDriver,
    updateDrivers
  };

  return <DriverContext.Provider value={value} {...props} />;
}

function useDriverContext() {
  const context = useContext(DriverContext);
  if (!context) {
    throw new Error('useDriverContext deve ser utilizado dentro de um DriverProvider');
  }
  
  return context;
}

export { DriverProvider, useDriverContext };