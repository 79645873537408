import React from 'react';
import classNames from 'classnames';

import Overlay from '../Overlay';

import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';

import './style.css';

const SidePanel = ({ title, side = 'left', isOpen, onClose, children }) => {
  return (
    <>
      <div
        className={classNames(
          'side-panel',
          side,
          isOpen ? 'open' : ''
        )}
      >
        <div className="panel-header">
          <h2 className="panel-title">{title}</h2>
          <button className="panel-close-button" onClick={onClose}>
            <ClearIcon width='1rem' fill="var(--theme-modal-color)" />
          </button>
        </div>
        <div className="panel-content">
          {children}
        </div>
      </div>
      <Overlay isOpen={isOpen} onClick={onClose} />
    </>
  );
};

export default SidePanel;