import React from 'react';
import classNames from 'classnames';

import '../styles/PrimaryButton.scss'

function PrimaryButton({ children, onClick, size="normal", contains="text", color="primary", id=null, ...rest }) {
  const buttonClass = classNames('primary',
    color,
    {
      'icon': (contains==='icon'),
      'small': (size==='small'),
      'smaller': (size==='smaller')
    }
  );

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      id={id}
      {...rest}
    >
      {children}
    </button>
  );
}

export default PrimaryButton;