import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FeatureGroup,
  LayersControl
} from 'react-leaflet';
import {
  getPanelElement,
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";

import DeviceGrid from './DeviceGrid';
import Map from './Maps/Map';
import MarkersLayer from './Maps/MarkersLayer';
import RenderRoute from './Maps/RenderRoute';
import FencesLayer from './Maps/FencesLayer';
import LandmarksLayer from './Maps/LandmarksLayer';
import SplashScreen from './SplashScreen';
import { useDeviceContext } from '../features/DeviceContext';
import { useGeoContext } from '../features/GeoContext';
import { useAPIContext } from '../features/APIContext';
import CustomersBar from './CustomersBar';
import { getUser } from '../features/authorization/AuthContext';

import '../styles/Main.scss';
import '../styles/Status.scss';

function Main() {
  const {
    devicesState,
    setDevicePage,
    setDevicePageSize,
    setDeviceFilter,
    clearDeviceFilter,
    setSelectedDevice,
    isLoading,
    map,
    onMapReferenced,
    markers,
    setGridMode,
    addDevicesToGrid,
    deleteDeviceFromGrid,
    clearDevicesFromGrid,
    updateVehicleDenomination
  } = useDeviceContext();

  const {
    emblems,
    fences,
    landmarks
  } = useGeoContext();

  const {
    isStarting,
    onUpdate,
    waitingForUpdate,
    forceUpdateNow
  } = useAPIContext();

  const user = getUser();

  const featureGroupRef = useRef();

  const defaultVisible = user
    ? (
        (user.groupID < 6)
          ? (localStorage.getItem("UserListVisible") === 'true') || false
          : false
      )
    : false
  ;

  const [isListVisible, setListVisible] = useState(defaultVisible);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);

  const toggleListVisibility = useCallback(() => {
    setListVisible(
      (isListVisible) => {
        localStorage.setItem("UserListVisible", !isListVisible);
        return !isListVisible;
    });
  }, [isListVisible, setListVisible]);

  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;

    if (featureGroupRef) {
      let bounds = featureGroupRef.current?.getBounds();

      if (bounds && bounds.isValid()) {
        map.fitBounds(featureGroupRef.current.getBounds());
      }
    };
  }, [map, featureGroupRef]);

  useEffect(() => {
    if (user) {
      setGridMode( user.groupID < 6 ? 'empty' : 'filter' );
    } else {
      setGridMode('filter');
    }
  }, [setGridMode, user?.groupID]);

  const RenderedRoute = useMemo(() => {
    if (devicesState.selectedDevice) {
      const device = devicesState.currentDevices.find((device) => {
        return device.equipment.id === devicesState.selectedDevice;
      })

      if (device === undefined) {
        return null;
      }

      if (device.route === undefined || device.route.length === 1) {
        return null;
      };

      return (
        <RenderRoute
          route={device.route}
          showEnd={false}
          currentLocation={[device.latitude, device.longitude]}
        />
      )
    }

    return null;
  }, [devicesState.selectedDevice, devicesState.currentDevices]);

  const handleFilteredDeviceOptions = useCallback((searchText) => {
    if (!devicesState.devices) return [];

    if (searchText === '') {
      return devicesState.devices.map((device) => {
        const { plate, denomination } = device.vehicle || {};
        const serialNumber = device.equipment.serialNumber;
        const name = plate
          ? (
              denomination
                ? `${plate} [${denomination}]`
                : plate
            )
          : serialNumber
        ;

        return {
          value: device.equipment.id,
          name: name,
          serialNumber: serialNumber
        };
      });
    }

    const filteredDevices = devicesState.devices.filter((device) => {
      const serialNumber = device.equipment.serialNumber;
      const { plate, denomination } = device.vehicle || {};
  
      return (
        serialNumber.toLowerCase().includes(searchText) ||
        plate?.toLowerCase().includes(searchText.toLowerCase()) ||
        denomination?.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    return filteredDevices.map((device) => {
      const { plate, denomination } = device.vehicle || {};
      const serialNumber = device.equipment.serialNumber;
      const name = plate
        ? (
            denomination
              ? `${plate} [${denomination}]`
              : plate
          )
        : serialNumber
      ;

      return {
        value: device.equipment.id,
        name: name,
        serialNumber: serialNumber
      };
    });
  }, [devicesState.devices]);

  const handleResizeMap = (size) => {
    if (size === 100) {
      // O mapa está ocupando toda a tela
      setIsPanelCollapsed(true);
    } else {
      // O mapa não está ocupando toda a tela
      if (isPanelCollapsed) {
        // O painel está colapsado, então expande ele
        setIsPanelCollapsed(false);
      }
    }
    
    setTimeout(function(){
      if (map) {
        map.invalidateSize();
      }
    }, 50);
  };

  const handleToggleCollapsePanel = () => {
    setIsPanelCollapsed((isCollapsed) => {
      return !isCollapsed;
    });
  }

  return (
    <>
      <SplashScreen show={isStarting} />
      <CustomersBar
        devices={devicesState.devices}
        visible={isListVisible}
        setVisible={toggleListVisibility}
        onSelectedCustomer={addDevicesToGrid}
      />

      <div style={{position: 'static', width: '100%', height: 'calc(100% - 70px)', zIndex: 0}}>
        <PanelGroup
          direction="vertical"
        >
          <Panel onResize={handleResizeMap}>
            <Map
              onRef={onMapReferenced}
              center={{ position: [-23.3292135, -46.7273893], zoom: 11 }}
              isPanelCollapsed={isPanelCollapsed}
              togglePanelCollapsed={handleToggleCollapsePanel}
            >
              <FeatureGroup attribution="ignoreOnFit">
                <LayersControl.Overlay name="Pontos de referência" checked={true} options={{sortLayers: false}}>
                  <LandmarksLayer
                    emblems={emblems}
                    landmarks={landmarks}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay name="Cercas geográficas" checked={true} options={{sortLayers: false}}>
                  <FencesLayer
                    fences={fences}
                  />
                </LayersControl.Overlay>
              </FeatureGroup>
              <FeatureGroup ref={featureGroupRef}>
                {RenderedRoute}
                <MarkersLayer markers={devicesState.currentDevices} />
              </FeatureGroup>
            </Map>
          </Panel>
          <PanelResizeHandle
            id="resize-handle"
            style={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              height: '.2rem',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#aaaaaa',
            }}
          />
          <ResizablePanel
            id="grid-panel"
            maxSize={70}
            minSize={10}
            defaultSize={30}
            collapsible
            collapsed={isPanelCollapsed}
          >
            <DeviceGrid
              devices={devicesState.currentDevices}
              currentPage={devicesState.currentPage}
              setPage={setDevicePage}
              totalOfPages={devicesState.totalOfPages}
              pageSize={devicesState.pageSize}
              setPageSize={setDevicePageSize}
              filter={devicesState.filter}
              setDeviceFilter={setDeviceFilter}
              clearDeviceFilter={clearDeviceFilter}
              selectedDevice={devicesState.selectedDevice}
              setSelectedDevice={setSelectedDevice}
              map={map}
              markers={markers}
              isLoading={isLoading}
              lastCommunication={devicesState.lastCommunication}
              onCount={devicesState.onCount}
              offCount={devicesState.offCount}
              onUpdate={onUpdate}
              waitingForUpdate={waitingForUpdate}
              forceUpdateNow={forceUpdateNow}
              isListVisible={isListVisible}
              setListVisible={toggleListVisibility}
              handleFilteredDeviceOptions={handleFilteredDeviceOptions}
              addDevicesToGrid={addDevicesToGrid}
              deleteDeviceFromGrid={deleteDeviceFromGrid}
              clearDevicesFromGrid={clearDevicesFromGrid}
              updateVehicleDenomination={updateVehicleDenomination}
            />
          </ResizablePanel>
        </PanelGroup>
      </div>
    </>
  );
}

const GripHorizontal = () => (
  <svg
    viewBox="0 0 24 24"
    width="1rem"
    height="1rem"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="9" r="1"/>
    <circle cx="19" cy="9" r="1"/>
    <circle cx="5" cy="9" r="1"/>
    <circle cx="12" cy="15" r="1"/>
    <circle cx="19" cy="15" r="1"/>
    <circle cx="5" cy="15" r="1"/>
  </svg>
);

const ResizablePanel = ({
  collapsed, children, ...props
}) => {
  const panelRef = useRef();

  useEffect(() => {
    if (collapsed) {
      panelRef.current.collapse();
    } else {
      panelRef.current.expand();
    }
  }, [collapsed]);

  return (
    <Panel ref={panelRef} {...props}>
      {children}
    </Panel>
  );
};

export default Main;
