import { toast } from 'react-toastify';

import Tooltip from './Tooltip';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import useCopyToClipboard from '../../hooks/clipboard';

import 'react-toastify/dist/ReactToastify.css';

function PlateAndDenomination({
  vehicle,
  equipment,
  align='center',
  enableEditDenomination=false,
  onEditDenomination
}, ) {
  const { isClipboardApiSupported, copyToClipboard } = useCopyToClipboard();

  if (!vehicle && !equipment) {
    return null;
  }

  const limitedLabel = (value, size) => {
    if (value.length > size) {
      return '…' + value.slice(-size)
    }

    return value;
  };
  const firstLabel = (equipment)
    ? limitedLabel(equipment.serialNumber, 15)
    : vehicle?.plate
  ;
  const secondLabel = (equipment)
    ? `${equipment.brand.name} / ${equipment.model.name}`
    : vehicle?.denomination
  ;
  
  const handleCopyPlate = (event) => {
    event.stopPropagation();
    
    copyToClipboard(firstLabel)
      .then(() => {
        toast.info(`Copiado ${firstLabel} para a área de transferência`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
    ;
  };

  const handleEditDenomination = (event) => {
    event.stopPropagation();
    
    console.log(`Editando o apelido do veículo ${vehicle.id}`);
    onEditDenomination(vehicle);
  }

  return (
    <div className={`doubleline ${align}`}>
      <span className="line1">
        { isClipboardApiSupported() &&
          <Tooltip
            label="Clique para copiar para a área de transferência"
            side="right"
          >
            <CopyIcon
              className='copy-icon'
              color="var(--theme-gray-400)"
              onClick={handleCopyPlate}
            />
            &nbsp;
          </Tooltip>
        }
        {firstLabel}
      </span>
      <span className="line2 highlight">
        { enableEditDenomination && (
        <Tooltip
          label="Clique para editar o apelido"
          side="right"
        >
          <EditIcon
            className='copy-icon'
            color="var(--theme-gray-400)"
            onClick={handleEditDenomination}
          />
          &nbsp;
        </Tooltip>
        )}
        {secondLabel}
      </span>
    </div>
  );
}

export default PlateAndDenomination;
