const status = {
  'ignition': {
    'tip': [ 'Ignição desconhecida', 'Ignição ligada', 'Ignição desligada'],
    'class': [ 'on', 'off' ]
  },
  'block': {
    'tip': [ 'Bloqueio não disponível', 'Bloqueado', 'Desbloqueado'],
    'class': [ 'blocked', 'unblocked' ]
  },
  'siren': {
    'tip': [ 'Sirene não disponível', 'Sirene ligada', 'Sirene desligada'],
    'class': [ 'armed', 'disarmed' ]
  },
};

export function getTip(value, field) {
  return value === null
    ? status[field]['tip'][0]
    : value
      ? status[field]['tip'][1]
      : status[field]['tip'][2]
  ;
}

export function getClass(value, field) {
  return 'status ' + ((value)
    ? status[field]['class'][0]
    : status[field]['class'][1]
  );
}
