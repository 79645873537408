export function getDeviceInfo(device)
{
  const label = (device.vehicle)
    ? 'Placa'
    : 'Dispositivo'
  ;
  const identification = (device.vehicle)
    ? device.vehicle.plate
    : device.equipment.serialNumber
  ;
  const denomination = (device.vehicle)
    ? device.vehicle.denomination
    : ''
  ;
  const typeName = (device.vehicle)
    ? device.vehicle.type.name
    : 'Rastreador'
  ;
  const brandName = (device.vehicle)
    ? device.vehicle.brand.name
    : device.equipment.brand.name
  ;
  const modelName = (device.vehicle)
    ? device.vehicle.model.name
    : device.equipment.model.name
  ;
  const colorName = (device.vehicle)
    ? device.vehicle.color
    : ''
  ;

  return {
    label: label,
    identification: identification,
    denomination: denomination,
    typeName: typeName,
    brandName: brandName,
    modelName: modelName,
    colorName: colorName
  };
}
