import { createContext, useContext } from 'react';

const APIContext = createContext();

function useAPIContext() {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error('useAPIContext deve ser utilizado dentro de um APIProvider');
  }
  
  return context;
}

export { APIContext, useAPIContext };