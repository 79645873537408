import React from 'react';

import Container from '../../components/Container';

function PageNotFound() {
  return (
    <Container>
      <h1>Page not found</h1>
    </Container>
  );
}

export default PageNotFound;