function Button({ children, color, primary, small, onClick, ...rest }) {
  const classes = "form-button "
    + (small ? "small " : "")
    + (
        color
          ? `colors ${color} `
          : primary
              ? "form-button-primary"
              : "form-button-secondary"
      )
  ;

  return (
    <button
      className={classes}
      type="button"
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;