import React, { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import classnames from "classnames";

function TextField({
  name,
  label,
  control,
  rules,
  onChange,
  maxLength,
  pattern,
  defaultValue,
  ...rest
}) {
  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules
  });

  const [value, setValue] = useState(() => {
    if (defaultValue !== undefined) {
      return defaultValue;
    } else if (field.value === undefined) {
      return '';
    }

    return field.value;
  });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    console.log('field.value de', field.name, 'mudou para', field.value);
    if (field.value !== undefined && field.value !== null) {
      setValue(field.value);
    } else {
      setValue('');
    }
  }, [field.name, field.value]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  
  const handleOnChange = (event) => {
    const { value } = event.target;

    // Enviamos o valor para o hook form
    field.onChange(value);
    // Atualiza o valor interno do componente (UI state)
    setValue(value);

    if (onChange) {
      // Propagar o evento para o componente principal
      onChange(value);
    }
  };

  useEffect(() => {
    // Lida com erros de validação
    setHasError(invalid);
  }, [invalid]);

  console.log('Field', name, 'rendered');

  return (
    <>
      <input
        type="text"
        ref={field.ref}
        name={name}
        value={value}
        onChange={handleOnChange}
        size={maxLength??undefined}
        pattern={pattern??undefined}
        className={classnames({ error: hasError })}
        {...rest}
      />
      {hasError && (
        <span className="errorMsg">
          {error?.type === 'required' 
            ? 'O valor de ' + label.toLowerCase() + ' deve ser informado'
            : error?.message
          }
        </span>
      )}
    </>
  );
}

export default TextField;