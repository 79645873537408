import React from 'react';

import { getFormatedDateTime } from '../../hooks/dateUtils';
import IgnitionStatus from "../StatusIcons/IgnitionStatus";
import BlockStatus from "../StatusIcons/BlockStatus";
import SirenStatus from "../StatusIcons/SirenStatus";

import './styles.scss';

function DeviceDetails({ device }) {
  if (!device) {
    return null;
  }
  
  const Equipment = (equipment) => (
    <>
      <div className='devicedetail__container__item'>
        <div className='devicedetail__container__item__label'>
          Equipamento
        </div>
        <div className='devicedetail__container__item__value'>
          {equipment.brand.name} / {equipment.model.name}
        </div>
      </div>
      <div className='devicedetail__container__item'>
        <div className='devicedetail__container__item__label'>
          Número de série
        </div>
        <div className='devicedetail__container__item__value'>
          {equipment.serialNumber}
        </div>
      </div>
    </>
  );

  const LastCommunication = ({datetime}) => (
    <div className='devicedetail__container__item'>
      <div className='devicedetail__container__item__label'>
        Última comunicação
      </div>
      <div className='devicedetail__container__item__value emphasis'>
        {getFormatedDateTime(datetime)}
      </div>
    </div>
  );

  const Status = ({device}) => (
    <div className='devicedetail__container__item'>
      <div className='devicedetail__container__item__label'>
        &nbsp;
      </div>
      <div className='devicedetail__container__item_icons'>
        <IgnitionStatus size="24" status={device.ignition} />
        <BlockStatus size="24" status={device.block} />
        <SirenStatus size="24" status={device.siren} />
      </div>
    </div>
  );

  const Vehicle = (vehicle) => {
    const brandModelAndColor = [
      vehicle.brand.name,
      vehicle.model.name,
      ...vehicle.color.name??[]
    ];

    return (
      <>
        <div className='devicedetail__container__item'>
          <div className='devicedetail__container__item__label'>
            Placa
          </div>
          <div className='devicedetail__container__item__value'>
            {vehicle.plate}
          </div>
        </div>
        <div className='devicedetail__container__item'>
          <div className='devicedetail__container__item__label'>
            Marca / Modelo / Cor
          </div>
          <div className='devicedetail__container__item__value'>
            { brandModelAndColor.join(' / ') }
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='devicedetail__container'>
      <Equipment {...device.equipment} />
      {device.vehicle && <Vehicle {...device.vehicle} />}
      <Status device={device} />
      <LastCommunication datetime={device.systemdate} />
    </div>
  );
}

export default DeviceDetails;