function useCopyToClipboard() {
  function isClipboardApiSupported() {
    return !!navigator.clipboard;
  }

  function copyToClipboard(text) {
    if (!navigator.clipboard) {
      return new Promise((resolve, reject) => {
        // Se o navegador não suporta a função de clipboard, utilizamos o
        // velho método de copiar texto para a área de transferência
        // usando um elemento <textarea>
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        resolve();
      });
    } else {
      return navigator.clipboard.writeText(text);
    }
  }

  return { isClipboardApiSupported, copyToClipboard };
}

export default useCopyToClipboard;
