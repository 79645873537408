import React, {
  useMemo,
  useRef
} from 'react';
import {
  useFormContext,
  Controller,
  useFieldArray
} from 'react-hook-form';

import {
  Button,
  Group,
  Row,
} from '../form';
import SelectVehicle from '../SelectVehicle';
import VehicleItem from './VehicleItem';

function VehicleList({
  name,
  customerVehicles,
  noitemsLabel = 'Nenhum veículo adicionado.'
}) {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: name,
  });

  const [newVehicle, setNewVehicle] = React.useState(null);

  const viewBoxRef = useRef(null);

  const handleAddVehicle = () => {
    if (newVehicle) {
      append({
        vehicleid: newVehicle.id,
        equipmentid: newVehicle.equipmentid,
        plate: newVehicle.plate,
        stored: false
      });
      setNewVehicle(null);

      // Movemos o scroll para o final da lista depois de um tempo
      // para que o novo item seja visível
      setTimeout(() => {
        viewBoxRef.current.scrollTop = viewBoxRef.current.scrollHeight;
      }, 100);
    }
  }

  const handleAddAllVehicle = () => {
    vehicleList.forEach(vehicle => {
      append({
        vehicleid: vehicle.id,
        equipmentid: vehicle.equipmentid,
        plate: vehicle.plate,
        stored: false
      });
    });

    // Movemos o scroll para o final da lista depois de um tempo
    // para que o novo item seja visível
    setTimeout(() => {
      viewBoxRef.current.scrollTop = viewBoxRef.current.scrollHeight;
    }, 100);
  }

  const handleDelAllVehicle = () => {
    fields.forEach((_, index) => {
      remove(index);
    });
  }

  // Lida com a remoção de um veículo da lista, já que a lista de
  // veículos é ordenada no processo de exibição e precisamos localizar
  // o índice correto para remoção
  const handleRemoveVehicle = (vehicleid) => {
    const index = fields.findIndex(field => field.vehicleid === vehicleid);
    if (index !== -1) {
      remove(index);
    }
  };
  
  // Armazena uma lista local dos veículos que podemos selecionar, que
  // é a lista de veículos do cliente menos os veículos que já foram
  // adicionados
  const vehicleList = useMemo(() => {
    return customerVehicles.filter(vehicle => {
      return !fields.some(field => field.vehicleid === vehicle.id);
    });
  }, [fields]);

  // Gera uma lista ordenada dos veículos que já foram adicionados apenas
  // para exibição
  const sortedFields = useMemo(() => {
    return [...fields].sort((a, b) => a.plate.localeCompare(b.plate));
  }, [fields]);

  return (
    <div style={styles.wrapper}>
      {fields.length === 0 && <div style={styles.noitems}>{noitemsLabel}</div>}
      <div ref={viewBoxRef} style={styles.viewBox}>
      {sortedFields.map((vehicle, index) => (
        <VehicleItem
          key={vehicle.vehicleid}
          plate={vehicle.plate}
          stored={vehicle.stored}
          onDelete={() => handleRemoveVehicle(vehicle.vehicleid)}
        />
      ))}
      </div>

      <div style={styles.footer}>
        <SelectVehicle
          vehicles={vehicleList}
          selected={newVehicle}
          onSelect={setNewVehicle}
          onClear={() => setNewVehicle(null)}
          height={150}
        />
        <Button
          small
          color="green"
          onClick={handleAddVehicle}
        >
          Adicionar
        </Button>
        <Button
          small
          color="gray"
          onClick={handleAddAllVehicle}
          disabled={vehicleList.length === 0}
        >
          Adicionar todos
        </Button>
        <Button
          small
          color="red"
          onClick={handleDelAllVehicle}
          disabled={fields.length === 0}
        >
          Remover todos
        </Button>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingRight: '0',
    paddingLeft: '0',
  },
  viewBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    padding: '.2rem 0 .5rem 0',
    width: '100%',
    maxHeight: 'calc((66.063 * 3px) - 1px)',
    overflowY: 'auto',
    boxSizing: 'border-box',
    marginBottom: '.2rem',
  },
  noitems: {
    fontSize: '.9rem',
    fontStyle: 'italic',
    color: '#888',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
  },
  footer: {
    display: 'inline-grid',
    gridTemplateColumns: '2fr .8fr 1fr 1fr',
    alignItems: 'center',
    gap: '.5rem',
  }
};

export default VehicleList;
