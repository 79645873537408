import React from "react";
import { useController } from "react-hook-form";

import TimeInput from '../components/TimeInput';

function TimePickerField({ label, name, footerLabel, control, rules, small = false, onChange }) {
  const {
    field,
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules
  });

  const handleOnChange = (range) => {
    field.onChange(range);

    if (onChange) {
      onChange(range);
    }
  };

  return (
    <>
      <label htmlFor={field.name}>{ label }</label>
      <TimeInput
        small={small}
        name={field.name}
        value={field.value}
        onChange={handleOnChange}
      />
      {invalid && <span className="errorMsg">Informe um período válido</span>}
    </>
  );
}

export default TimePickerField;