import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  FeatureGroup
} from 'react-leaflet';
import L from "leaflet";
import Select from 'react-select';
import { toast } from 'react-toastify';

import Block from '../../components/Block';
import Panel from '../../components/Panel';
import LoadingOverlay from '../../components/LoadingOverlay';
import Map from '../../components/Maps/Map';
import VirtualizedList from '../../components/VirtualizedList';
import FencesLayer from '../../components/Maps/FencesLayer';
import FenceForm from '../../forms/FenceForm';
import { useDeviceContext } from '../../features/DeviceContext';
import { useGeoContext } from '../../features/GeoContext';
import { getCurrentUser } from '../../services/AuthService';
import * as GeoService from '../../services/GeoService';
import SearchInput from '../../components/SearchInput';

import '../../styles/Form.scss';
import 'react-toastify/dist/ReactToastify.css';

const FencePage = () => {
  const {
    devicesState
  } = useDeviceContext();

  const {
    colors,
    fences,
    addFence,
    updateFence,
    removeFence,
    updateFences,
    landmarks,
    addLandmark
  } = useGeoContext();

  const user = getCurrentUser();

  // As definições do mapa
  const mapRef = useRef();
  const mapCenter = {
    position: [-23.3292135, -46.7273893],
    zoom: 11
  };
  const featureGroupRef = useRef();
  
  const [searchValue, setSearchValue] = useState('');
  
  // Os veículos do usuário
  const [vehicles, setVehicles] = useState([]);

  // Os clientes
  const customers = useMemo(() => {
    if (user.groupID < 6) {
      // Filtra os dispositivos para obter apenas aqueles com cliente
      // definido
      const filteredDevices = devicesState.devices.filter(
        device => device.customer !== undefined && device.customer !== null
      );

      // Cria um conjunto de clientes únicos 
      const uniqueCustomers = new Set();
      filteredDevices.forEach(device => {
        uniqueCustomers.add(device.customer.id);
      });

      // Converte o conjunto de clientes de volta para um array
      const uniqueCustomersArray = Array.from(uniqueCustomers);

      // Ordena os clientes alfabeticamente com base no nome
      const sortedCustomers = uniqueCustomersArray
        .map(customerId => {
          const device = filteredDevices.find(d => d.customer.id === customerId);
          return {
            value: device.customer.id,
            label: device.customer.id === device.customer.payerID
              ? device.customer.name
              : device.customer.affiliated
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label))
      ;

      // Adicionamos no começo uma opção para as cercas do usuário
      sortedCustomers.unshift({
        value: null,
        label: 'Minhas cercas'
      });

      return sortedCustomers;
    }
  }, [devicesState.devices, user.groupID]);
  const [selectedCustomer, setSelectedCustomer] = useState((customers && customers[0]) || null);
  
  // O indicativo de carregamento
  const [isLoading, setIsLoading] = useState(true);
  
  const [editingFence, setEditingFence] = useState(null);
  const [editingFences, setEditingFences] = useState([]);
  const [addingFenceMode, setAddingFenceMode] = useState(false);
  const [visibleFences, setVisibleFences] = useState(() =>{
    // Retorna todas as cercas, exceto as temporárias
    return fences.filter((fence) => fence.temporary === false);
  });
  console.log('Visible fences', visibleFences);
  const [forceSelect, setForceSelect] = useState(null);

  const displayFences = useMemo(() => {
    // Exibimos todas as cercas, exceto as que estão em modo de edição e
    // que não sejam temporárias
    return fences.filter((fence) => fence.id !== editingFence?.id && !fence.temporary);
  }, [fences, editingFence]);

  const names = useMemo(() => {
    // Obtemos os nomes das cercas, tornando-os case insensitive, sem
    // espaços e sem acentos, mas retirando dos nomes àquele que está
    // em edição
    return fences.map(fence => {
      if (fence.id === editingFence?.id) {
        return null;
      }

      return fence.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      ;
    });
  }, [fences, editingFence]);
  
  const notifications = [
    {
      value: "DEFAULT",
      label: "Usar o comportamento padrão"
    },
    {
      value: "NONE",
      label: "Não notificar"
    },
    {
      value: "ENTER",
      label: "Notificar quando entrar"
    },
    {
      value: "EXIT",
      label: "Notificar quando sair"
    },
    {
      value: "BOTH",
      label: "Notificar sempre (tanto ao entrar quanto ao sair)"
    }
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      backgroundColor: "var(--theme-input-bg)",
      borderRadius: ".375rem",
      borderColor: "var(--theme-input-border-color)",
      boxShadow: state.isFocused ? "0 0 0 2px #007bff" : "none",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
      fontWeight: 400,
      lineHeight: 1.5,
      borderRadius: ".375rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-placeholder-color)",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: state.isFocused ? "var(--theme-dropdown-hover-bg)" : "transparent",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: ".5rem .3rem",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "var(--theme-input-color)",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "var(--theme-dropdown-color)",
      backgroundColor: "var(--theme-dropdown-bg)",
      borderRadius: ".25rem",
      border: "1px solid var(--theme-input-border-color)"
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: "var(--theme-heading-color)",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: ".5rem .3rem",
      textTansform: "none",
    })
  };

  useEffect(() => {
    return () => {
      console.log('FencePage is unmounting');

      if (user && user.groupID < 6) {
        // Carregamos os dados de cerca do usuário
        console.log('Obtendo cercas do usuário');

        GeoService.getFences(user.customerID)
          .then((response) => {
            console.debug(`Recuperadas ${response.length} cercas`);
            // Atualiza as cercas
            updateFences(response);
          })
          .catch((error) => {
            const { message } = error;
      
            console.error(message);
          })
        ;
      }
    };
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      // Obtemos as cercas do cliente selecionado
      console.log('Obtendo cercas do cliente', selectedCustomer.label);
      setIsLoading(true);
      GeoService.getFences(selectedCustomer.value)
        .then((response) => {
          console.debug(`Recuperadas ${response.length} cercas`);
          // Atualiza as cercas
          updateFences(response);

          // Depois de um tempo, fazemos o fit nas cercas
          setTimeout(() => {
            setIsLoading(false);
            const bounds = new L.LatLngBounds();
            mapRef.current.eachLayer(function (layer) {
              if (layer instanceof L.TileLayer) {
                console.log('Ignoring base layer', layer.options.id);
                return;
              }
              if (layer instanceof L.FeatureGroup) {
                if (layer._layers === undefined || Object.keys(layer._layers).length === 0) {
                  console.log('Ignoring layer with no content');
                  return;
                }
                // Se as options do layer contiverem uma atribuição
                // (attribution) e o seu valor for 'ignoreOfFit', então não
                // incluímos a camada no ajuste do mapa
                if (layer.options.attribution === 'ignoreOnFit') {
                  console.log('Ignoring layer with hidden content');
                  return;
                }
        
                bounds.extend(layer.getBounds());
              }
            });
                
            if (bounds.isValid()) {
              mapRef.current.fitBounds(bounds,
                {
                  animated: true,
                  duration: 0.5,
                  easeLinearity: 0.50,
                  paddingTopLeft: [40, 40],
                  paddingBottomRight: [40, 40],
                  maxZoom: 17
                }
              );
            }
          }, 1000);
        })
        .catch((error) => {
          const { message } = error;
    
          console.error(message);
        })
      ;
    }
  }, [selectedCustomer, updateFences]);

  useEffect(() => {
    const allFences = fences
      .slice()
      .filter(fence => fence.temporary === false)
    ;

    if (searchValue) {
      // Limpa a string de pesquisa, removendo acentos e caracteres
      // especiais
      const search = searchValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      
      const filteredFences = allFences.filter((fence) => {
        // Verifica se o ID da cerca é igual ao ID da que está em edição
        if (fence.id === editingFence?.id) {
          return true;
        }

        // Verifica se o nome da cerca contém o valor de pesquisa
        // (ignorando maiúsculas e minúsculas)
        const matchesSearchValue = fence.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
          )
        ;

        return matchesSearchValue;
      });

      if (editingFence) {
        if (editingFence.id === 0) {
          // Adiciona também a nova cerca em edição
          console.log('Adicionando cerca em edição', editingFence);
          filteredFences.push(editingFence);
        }
      }

      setVisibleFences(filteredFences);
    } else {
      if (editingFence) {
        if (editingFence.id === 0) {
          // Adiciona também a nova cerca em edição
          allFences.push(editingFence);
        }
      }

      setVisibleFences(allFences);
    }
  }, [searchValue, fences, editingFence]);

  useEffect(() => {
    const idOfCustomer = (user.groupID < 6)
      ? (
          selectedCustomer && selectedCustomer.value
            ? selectedCustomer.value
            : user.customerID
        )
      : user.customerID
    ;

    setVehicles(previous => {
      return devicesState.devices
        .filter(device => device.vehicle !== undefined && device.vehicle !== null)
        .filter(device => device.customer.id === idOfCustomer)
        .map(device => {
          return {
            id: device.vehicle.id,
            plate: device.vehicle.plate,
            denomination: device.vehicle.denomination,
            name: device.customer.name,
            searchName: device.customer.name
              ? device.customer.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            affiliated: device.customer.affiliated,
            searchAffiliated: device.customer.affiliated
              ? device.customer.affiliated
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            brand: device.vehicle.brand.name,
            model: device.vehicle.model.name,
            type: device.vehicle.type.name,
            article: device.vehicle.type.article,
          };
        })
      ;
    });
  }, [devicesState.devices, setVehicles, selectedCustomer, user.groupID, user.customerID]);

  const handleAddFence = () => {
    console.log('handleAddFence');
    setAddingFenceMode(prevAddingFenceMode => !prevAddingFenceMode);
  }

  const handleSelectFence = useCallback((fence) => {
    setEditingFence(fence);
    setEditingFences([fence]);

    // Determina os limites da cerca
    const center = L.latLng(fence.latitude, fence.longitude);
    const bounds = center.toBounds(fence.radius);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 18
        }
      );
    }
  }, []);

  const handleDeleteFence = useCallback(async (fence) => {
    console.log('Deleting fence', fence);

    if (fence.id === 0) {
      if (editingFence) {
        if (editingFence.id === fence.id) {
          setEditingFence(null);
          setEditingFences([]);
        }
      }

      return;
    }

    // Fazemos a requisição para remover a cerca
    const result = await GeoService.removeFence(fence.id);
    if (result) {
      // Removemos a cerca da lista
      removeFence(fence);

      if (editingFence) {
        if (editingFence.id === fence.id) {
          setEditingFence(null);
          setEditingFences([]);
        }
      }
    }
  }, [editingFence, removeFence]);

  const refreshFence = (field, newValue) => {
    if (!editingFence) {
      // Ignora, pois não há cerca em edição
      return;
    }

    if (editingFence[field] === newValue) {
      // Ignora, pois o valor não foi alterado
      return;
    }

    const fence = { ...editingFence, [field]: newValue };
    console.log('Refresh fence because field', field, 'changed to', newValue);
    setEditingFence(fence);
    setEditingFences([fence]);

    if (field === 'latitude' || field === 'longitude' || field === 'radius') {
      // Determina os limites da cerca
      const center = L.latLng(fence.latitude, fence.longitude);
      const bounds = center.toBounds(fence.radius + 200);

      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds,
          {
            animated: true,
            duration: 0.5,
            easeLinearity: 0.50,
            paddingTopLeft: [50, 50],
            paddingBottomRight: [50, 50],
            maxZoom: 18
          }
        );
      }
    }
  };

  const handleFenceUpdated = async (fenceData) => {
    console.log('handleFenceUpdated', fenceData);

    // Fazemos a requisição para adicionar/modificar a cerca
    const result = (fenceData.id === 0)
      ? await GeoService.addFence(fenceData)
      : await GeoService.updateFence(fenceData)
    ;
    if (result) {
      if (fenceData.id === 0) {
        // Adicionamos a cerca na lista
        fenceData.id = parseInt(result);
        addFence(fenceData);
        console.log('Fence added', fenceData);
      } else {
        // Atualizamos a cerca na lista
        updateFence(fenceData);
        console.log('Modified fence', fenceData);
      }

      console.log('Fences deediting');
      setEditingFence(null);
      setEditingFences([]);
    }
  };

  // Os métodos do lado do mapa
  const handleNewFence = (format, center, radius) => {
    console.log('handleNewFence', format, center, radius);
    const newFence = {
      id: 0,
      name: '',
      note: '',
      format: format,
      latitude: center[0],
      longitude: center[1],
      radius: radius,
      notification: "ENTER",
      showinmap: true,
      disabled: false,
      temporary: false,
      customerid: (user.groupID < 6)
        ? (
            selectedCustomer && selectedCustomer.value
              ? selectedCustomer.value
              : user.customerID
          )
        : user.customerID
    };
    console.log('New fence', newFence);
    setEditingFence(newFence);
    setEditingFences([newFence]);

    // Determina os limites da cerca
    const fenceCenter = L.latLng(newFence.latitude, newFence.longitude);
    const bounds = fenceCenter.toBounds(newFence.radius + 200);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 18
        }
      );
    }
  };

  const handleEditFence = (fenceID) => {
    const fence = fences.find(f => f.id === fenceID);
    setEditingFence(fence);
    setEditingFences([fence]);

    // Determina os limites da cerca
    const fenceCenter = L.latLng(fence.latitude, fence.longitude);
    const bounds = fenceCenter.toBounds(fence.radius);

    if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds,
        {
          animated: true,
          duration: 0.5,
          easeLinearity: 0.50,
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50],
          maxZoom: 18
        }
      );
    }

  };

  const handleUpdatedFence = (fence) => {
    setEditingFence(fence);
    setEditingFences([fence]);
  }
  
  useEffect(() => {
    if (mapRef.current) {
      const classList = mapRef.current._container.classList;
      if (addingFenceMode) {
        classList.add('leaflet-interactive');
        classList.add('leaflet-crosshair');
      } else {
        classList.remove('leaflet-interactive');
        classList.remove('leaflet-crosshair');
      }
    }
  }, [addingFenceMode]);

  useEffect(() => {
    console.log('Editing fence changed', editingFence);
    if (editingFence) {
      console.log('Set force select', editingFence.id);
      setForceSelect(editingFence.id);
    } else {
      console.log('Set force select to null');
      setForceSelect(null);
    }
  }, [editingFence]); 

  return (
    <Block horizontal={true}>
      <Panel style={styles.panel}>
        <h3 style={styles.title}>Cercas virtuais</h3>
        {user.groupID < 6 && (
        <div style={styles.customerBar}>
          <span style={styles.customerLabel}>Cliente:</span>
          <Select
            id="customer"
            value={selectedCustomer}
            onChange={(customer) => {
              setSelectedCustomer(customer);
            }}
            options={customers}
            placeholder="Selecione um cliente..."
            styles={customStyles}
          />
        </div>
        )}
        <div style={styles.toolbar}>
          <SearchInput
            value={searchValue}
            placeholder="Localize uma cerca..."
            onChange={setSearchValue}
            disabled={editingFence !== null}
          />
          <button
            style={{
              ...styles.button,
              backgroundColor: addingFenceMode
                ? '#665d1e'
                : 'var(--theme-primary)',
              opacity: editingFence !== null ? 0.5 : 1
            }}
            onClick={handleAddFence}
            disabled={editingFence !== null}
          >
            <svg
              width=".9rem"
              height=".9rem"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" fill="currentColor" />
            </svg>
          </button>
        </div>
        <div style={styles.wrapper}>
          <VirtualizedList
            label="cerca"
            items={visibleFences}
            onSelect={handleSelectFence}
            onDelete={handleDeleteFence}
            forceSelect={forceSelect}
            onUnselect={() => {
              console.log('Fence is unselected');
              setEditingFence(null);
              setEditingFences([]);
            }}
            renderItem={(fence, hideItem) => {
              return (
                <div style={{ margin: '0 .5em' }}>
                  <FenceForm
                    fence={editingFence}
                    notifications={notifications}
                    names={names}
                    customerVehicles={vehicles}
                    onFieldChange={refreshFence}
                    onSubmit={(fence) => {
                      console.log('Fence is submitting', fence);
                      handleFenceUpdated(fence);
                      hideItem();
                    }}
                    onCancel={() => {
                      console.log('Fence is canceling');
                      hideItem();
                      setEditingFence(null);
                      setEditingFences([]);
                    }}
                    onSaveAsLandmark={async () => {
                      console.log('Fence is saving as landmark');
                      // Obtém os dados da cerca
                      const {
                        name,
                        latitude,
                        longitude,
                        note,
                        customerid
                      } = editingFence;

                      if (name === '') {
                        // Emitimos uma mensagem de erro
                        toast.error(
                          'Você deve primeiro informar um nome para esta '
                          + 'cerca antes de salvá-la', {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        return false;
                      }

                      // Adiciona a cerca como ponto de referência
                      const newLandmark = {
                        id: 0,
                        name: name,
                        note: note,
                        latitude: latitude,
                        longitude: longitude,
                        emblemid: 30,
                        color: colors[0],
                        showname: true,
                        disabled: false,
                        customerid: customerid
                      };

                      // Analisa se não existe um ponto de referência com o
                      // mesmo nome
                      const landmarkExists = landmarks.find(
                        landmark => landmark.name === newLandmark.name
                      );
                      if (landmarkExists) {
                        // Emitimos uma mensagem de erro
                        toast.error(
                          'Já existe um ponto de referência com o nome '
                          + newLandmark.name, {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        return false;
                      }

                      // Analisa se não existe um ponto de referência com a
                      // mesma localização
                      const landmarkAtLocation = landmarks.find(
                        landmark => (
                          landmark.latitude === newLandmark.latitude
                          && landmark.longitude === newLandmark.longitude
                        )
                      );
                      if (landmarkAtLocation) {
                        // Emitimos uma mensagem de erro
                        toast.error(
                          'Já existe um ponto de referência na localização '
                          + newLandmark.latitude + ', ' + newLandmark.longitude, {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });

                        return false;
                      }

                      console.log('Adding landmark', name, latitude, longitude, note);
                      const result = await GeoService.addLandmark(newLandmark);
                      if (result) {
                        // Adicionamos o ponto de referência na lista
                        newLandmark.id = parseInt(result);
                        addLandmark(newLandmark);
                        console.log('Landmark added', newLandmark);

                        // Emitimos uma mensagem de alerta
                        toast.info(
                          'Armazenada a cerca ' + name
                          + ' como ponto de referência', {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      } else {
                        // Emitimos uma mensagem de erro
                        toast.error(
                          result.message, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        });
                      }

                      return true;
                    }}
                  />
                </div>
              );
            }}
            noContentMessage="Nenhuma cerca encontrada"
          />
        </div>
      </Panel>
      <LoadingOverlay
        isLoading={isLoading}
        message="Aguarde enquanto carregamos os dados..."
      />
      <div
        className="section map"
        style={{
          flex: 1,
          height: '100%',
          minHeight: '89vh',
          boxShadow: 'none',
          borderLeft: '1px solid var(--theme-border-color)',
        }}
      >
        <Map
          onRef={mapRef}
          center={mapCenter}
          onMapReady={(map) => {
            // Fazemos o fit das cercas depois de um tempo
            setTimeout(() => {
              setIsLoading(false);
              const bounds = new L.LatLngBounds();
              mapRef.current.eachLayer(function (layer) {
                if (layer instanceof L.TileLayer) {
                  console.log('Ignoring base layer', layer.options.id);
                  return;
                }
                if (layer instanceof L.FeatureGroup) {
                  if (layer._layers === undefined || Object.keys(layer._layers).length === 0) {
                    console.log('Ignoring layer with no content');
                    return;
                  }
                  // Se as options do layer contiverem uma atribuição
                  // (attribution) e o seu valor for 'ignoreOfFit', então não
                  // incluímos a camada no ajuste do mapa
                  if (layer.options.attribution === 'ignoreOnFit') {
                    console.log('Ignoring layer with hidden content');
                    return;
                  }
          
                  bounds.extend(layer.getBounds());
                }
              });
          
              if (bounds.isValid()) {
                mapRef.current.fitBounds(bounds, {
                  animated: true,
                  duration: 0.5,
                  easeLinearity: 0.50,
                  paddingTopLeft: [40, 40],
                  paddingBottomRight: [40, 40],
                  maxZoom: 17
                });
              }
            }, 1000);
          }}
        >
          <FeatureGroup
            ref={featureGroupRef}
          >
            <FencesLayer
              fences={editingFences}
              onNewFence={handleNewFence}
              onUpdatedFence={handleUpdatedFence}
              addingMode={addingFenceMode}
              setAddingMode={setAddingFenceMode}
              underEditing={editingFence !== null}
            />
            <FencesLayer
              fences={displayFences}
              onEditFence={handleEditFence}
              editable={true}
            />
          </FeatureGroup>
          { addingFenceMode && (
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px solid #b59124',
                backgroundColor: 'rgba(255, 204, 51, 0.7)',
                padding: '10px',
                width: '300px',
                fontWeight: '500',
                fontSize: '1rem',
                borderRadius: '.475rem',
                marginTop: '40px',
                marginLeft: '40px',
                textAlign: 'center',
                zIndex: 500
              }}>
                Para adicionar a nova cerca, clique no local
                onde está localizado seu centro
            </div>
          )}
        </Map>
      </div>
    </Block>
  );
};

const styles = {
  panel: {
    borderRadius: "0",
    flexDirection: "column",
    width: '500px',
    padding: '.5rem',
  },
  customerBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    marginBottom: '.5rem'
  },
  customerLabel: {
    fontSize: '.9rem',
    paddingBottom: '.1rem',
    color: 'var(--theme-text-color)',
  },
  title: {
    marginBottom: '.5rem',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '.5rem',
    gap: '.5rem',
  },
  button: {
    display: 'inline-flex',
    padding: '.58571429em .58571429em .58571429em',
    border: 'none',
    borderRadius: '.475rem',
    cursor: 'pointer',
    fontSize: '.9rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    gap: '.5rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rounded: '.475rem',
    flex: 1,
    border: '2px solid var(--theme-border-color)',
    backgroundColor: 'var(--theme-modal-bg)',
    borderRadius: '.5rem',
  }
};

export default FencePage;
