import { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import 'leaflet-arrowheads';

function PolylineDecorator({ positions }) {
  const map = useMap();
  const polylineRef = useRef(null);

  useEffect(() => {
    if (!map) return;

    if (!positions || positions.length === 0) {
      if (polylineRef.current) {
        polylineRef.current.remove();
      }
      return;
    }

    const polyline = L.polyline(positions, {
        color: '#0079d9',
        smoothFactor: 5
      })
      .arrowheads({ 
        frequency: '150px', 
        size: '12px',
        color: '#0026f7'
      })
      .addTo(map)
    ;

    polylineRef.current = polyline;

    return () => {
      if (polylineRef.current) {
        polylineRef.current.remove();
      }
    };
  }, [map, positions]);
  
  return null;
}

export default PolylineDecorator;