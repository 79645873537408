import React, {
  useState
} from 'react';

import {ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import {ReactComponent as EyeSlashIcon } from '../assets/icons/eye-slash.svg';

const PasswordInput = ({
  label,
  name,
  value,
  onChange,
  maxLength = 50,
  onBlur = () => {},
  wrong = "",
  wrongMessage = ""
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    onChange(e.target.value.replace(/\s/g, ""));
  };

  return (
    <div style={{ flex: 1 }} className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className={'labeled input right'}>
        <input
          className={"medium" + (wrong ? " error" : "")}
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={handlePasswordChange}
          maxLength={maxLength}
          onBlur={onBlur}
        />
        <span className="label medium" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
          {showPassword ? (
            <EyeSlashIcon width={'1em'} height={'1em'} fill='currentColor' />
          ) : (
            <EyeIcon width={'1em'} height={'1em'} fill='currentColor' />
          )}
        </span>
      </div>
      {wrong && <span className="error">{wrongMessage}</span>}
    </div>
  );
};

export default PasswordInput;
