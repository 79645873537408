import React from 'react';

function Pagination({ currentPage, totalPages, onPageChange }) {
  // Quantidade de botões de página exibidos além do atual
  const pageRange = 1;
  const pageNumbers = [];
  let startPage = Math.max(1, currentPage - pageRange);
  let endPage = Math.min(totalPages, currentPage + pageRange);

  if (currentPage - startPage < pageRange) {
    endPage = Math.min(endPage + pageRange - (currentPage - startPage), totalPages);
  }

  if (endPage - currentPage < pageRange) {
    startPage = Math.max(startPage - (pageRange - (endPage - currentPage)), 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const goToFirstPage = () => {
    if (!isFirstPage) {
      onPageChange(1);
    }
  };

  const goToLastPage = () => {
    if (!isLastPage) {
      onPageChange(totalPages);
    }
  };

  const goToPreviousPage = () => {
    if (!isFirstPage) {
      onPageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (!isLastPage) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button
        onClick={goToFirstPage}
        disabled={isFirstPage}
        title="Primeira página"
        aria-label="Primeira página"
      >
        <svg className="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="m13.086984 17.481396-5.7189095-5.731396 5.7189095-5.7313961-1.760624-1.7606249-7.4920215 7.492021 7.4920215 7.492021zm7.078677 0-5.718909-5.731396 5.718909-5.7313961-1.760625-1.7606249-7.492021 7.492021 7.492021 7.492021z" strokeWidth="1.24867"/></svg>
      </button>
      <button
        onClick={goToPreviousPage}
        disabled={isFirstPage}
        title="Página anterior"
        aria-label="Página anterior"
      >
        <svg className="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="m16.921323 17.481396-5.718909-5.731396 5.718909-5.7313961-1.760625-1.7606249-7.492021 7.492021 7.492021 7.492021z" strokeWidth="1.24867"/></svg>
      </button>
      {startPage > 1 && <span className="pagination-item disabled">...</span>}
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          className={pageNumber === currentPage ? 'active' : ''}
          onClick={() => onPageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      ))}
      {endPage < totalPages && <span className="pagination-item disabled">...</span>}
      <button
        onClick={goToNextPage}
        disabled={isLastPage}
        title="Próxima página"
        aria-label="Próxima página"
      >
        <svg className="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="m7.668677 17.481396 5.718909-5.731396-5.718909-5.7313961 1.760625-1.7606249 7.492021 7.492021-7.492021 7.492021z" strokeWidth="1.24867"/></svg>
      </button>
      <button
        onClick={goToLastPage}
        disabled={isLastPage}
        title="Última página"
        aria-label="Última página"
      >
        <svg className="icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="m10.913016 17.481396 5.718909-5.731396-5.718909-5.7313961 1.760624-1.7606249 7.492021 7.492021-7.492021 7.492021zm-7.0786775 0 5.718909-5.731396-5.718909-5.7313961 1.760625-1.7606249 7.4920215 7.492021-7.4920215 7.492021z" strokeWidth="1.24867"/></svg>
      </button>
    </div>
  );
}

export default Pagination;