import React, { useEffect, useState } from 'react';

import SearchBar from '../SearchBar';
import { ReactComponent as ClearIcon } from '../../assets/icons/clear.svg';

import './styles.css';

function CustomersBar({
  devices,
  visible,
  setVisible,
  onSelectedCustomer
}) {
  const [filter, setFilter] = useState('');
  const [customers, setCustomers] = useState([]);
  const [unassignedCount, setUnassignedCount] = useState(0);

  const handleFilterChange = (searchText) => {
    setFilter(searchText);
  };

  const handleFilterClear = () => {
    setFilter('');
  }

  const handleCustomerClick = (customerId, isPayer) => {
    if (onSelectedCustomer) {
      // Obtemos os ID's dos dispositivos do cliente selecionado
      const selectedDevices = devices.filter(
        (device) => (customerId === 0)
          ? !device.customer
          : (isPayer
              ? device.customer && device.customer.payerID === customerId
              : device.customer && device.customer.id === customerId
            )
      ).map((device) => device.equipment.id);

      onSelectedCustomer(selectedDevices);
    }
  };

  useEffect(() => {
    if (!devices) return;

    const listOfCustomers = devices.reduce(
      (acc, device) => {
        if (!device.vehicle || !device.customer) {
          acc.unassignedCount += 1;

          return acc;
        }
        
        const customerId = device.customer.id.toString();
        // Quando é um afiliado, o nome vêm em customer.affiliated e o
        // nome do cliente é o nome da associação à qual ele pertence
        const customerName = device.customer.id === device.customer.payerID
          ? device.customer.name
          : device.customer.affiliated
        ;

        if (!acc.content[customerId]) {
          acc.content[customerId] = {
            id: device.customer.id,
            name: customerName,
            isPayer: false,
            count: 1,
          };
        } else {
          acc.content[customerId].count += 1;
        }

        if (device.customer.id !== device.customer.payerID) {
          const payerId = device.customer.payerID.toString();
          const payerName = device.customer.name;
        
          if (!acc.content[payerId]) {
            acc.content[payerId] = {
              id: device.customer.payerID,
              name: payerName,
              isPayer: true,
              count: 1,
            };
          } else {
            acc.content[payerId].count += 1;
          }
        }
    
        return acc;
      },
      // Inicializa o objeto de retorno com um contador para equipamentos
      // não vinculados
      { content: {}, unassignedCount: 0 } 
    );
    
    // Obtém os clientes em ordem alfabética
    const uniqueResult = Object.values(listOfCustomers.content);
    const sortedResult = uniqueResult.sort((a, b) => a.name.localeCompare(b.name));

    setCustomers(sortedResult);
    setUnassignedCount(listOfCustomers.unassignedCount);
  }, [devices]);
  
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleToggleList = () => {
    setVisible(!visible);
  };

  const handleAddAllVehicles = () => {
    if (onSelectedCustomer) {
      // Obtemos os ID's dos dispositivos do cliente selecionado
      const selectedDevices = devices.filter(
        (device) => (device.vehicle)
      ).map((device) => device.equipment.id);

      onSelectedCustomer(selectedDevices);
    }
  };

  return (
    <>
    {visible === true && (
    <div className="customers-bar">
      <div className="customers-bar-header">
        <h2>Relação de clientes</h2>
        <button className="close-button" onClick={handleToggleList}>
          <ClearIcon width='1rem' fill="var(--theme-modal-color)" />
        </button>
      </div>
      <div className="customers-search">
        <SearchBar
          searchValue={filter}
          onChange={handleFilterChange}
          onClear={handleFilterClear}
          placeholder="Localize um cliente..."
          onDemand={true}
        />
      </div>
      <div className="customer-list">
        <div
          key={0}
          className="customer-item"
          onClick={() => handleCustomerClick(0, false)}
        >
          &lt; Não vinculados &gt;
          <div className="label">
            {unassignedCount}
          </div>
        </div>
        {filteredCustomers.map((customer) => (
          <div
            key={customer.id}
            className="customer-item"
            onClick={() => handleCustomerClick(customer.id, customer.isPayer)}
          >
            {customer.name}
            <div className="label">
              {customer.count}
            </div>
          </div>
        ))}
        {filteredCustomers.length === 0 && (
          <p className="no-results">Nenhum cliente encontrado.</p>
        )}
      </div>
      <div className="customers-bar-footer">
        <button
          className="customers-bar-footer-buttom"
          onClick={handleAddAllVehicles}
        >
          <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m47.347276 16.389442-3.712408-3.712409c-.881229-.881228-2.306193-.881228-3.178048 0l-22.452574 22.452574-10.443494-10.452868c-.8812283-.88123-2.3061934-.88123-3.178047 0l-3.72178377 3.721784c-.88122837.881227-.88122837 2.306193 0 3.187421l15.74961377 15.758989c.881228.881228 2.306194.881228 3.178047 0l27.749319-27.768069c.881229-.890604.881229-2.315569.0094-3.187422zm-30.402379 9.937256c.581236.59061 1.537462.59061 2.118698 0l19.499521-19.5182713c.581236-.5906105.581236-1.5374623 0-2.118698l-4.237396-4.24677081c-.581236-.59061052-1.537462-.59061052-2.118697 0l-14.202777 14.20277711-5.193623-5.2029978c-.581235-.5906105-1.537462-.5906105-2.118698 0l-4.2467706 4.2467718c-.5812357.59061-.5812357 1.537462 0 2.118697z" strokeWidth=".093748"/></svg>
          Adicionar tudo
        </button>
        <span className="customers-bar-footer-total">
          Total de clientes: <strong>{customers.length}</strong>
        </span>
      </div>
    </div>
    )}
    </>
  );
}

export default CustomersBar;