import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import classNames from 'classnames';

import PrimaryButton from './PrimaryButton'
import * as EventService from '../services/EventService';
import { useAuthDispatch, doLogout } from '../features/authorization/AuthContext';

import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import { ReactComponent as RemoveIcon } from "../assets/icons/remove.svg";

import '../styles/CommentBox.scss';
import '../styles/Forms.scss';

function CommentBox({ event, getEvent, updateEvent, alarm=false }) {
  // As manipulações do usuário autenticado
  const dispatchToAuth = useAuthDispatch();
  const navigate = useNavigate();
  const { tenantName } = useParams();

  // A manipulação de comentários
  const [itemsList, setItemsList] = useState(event.comments);
  const [commentValue, setCommentValue] = useState('');

  useEffect(() => {
    setItemsList(event.comments);
  }, [event.comments]);

  const colorButton = alarm
    ? 'danger'
    : 'primary'
  ;

  const updateComments = (updatedEvent) => {
    console.log(
      'Requisitando a atualização dos comentários do evento [',
      event.id, ']', event.trackerEvent.name
    );
    
    EventService.treatEvent(event.id, event.comments)
      .then( function (response) {
        const eventID = Object.keys(response.events[0])[0];

        // Adicionamos nosso comentário
        updateEvent(updatedEvent);
  
        // Limpa o campo de input
        setCommentValue("");

        setItemsList(updatedEvent.comments);
        
        console.log('Atualizado comentários do evento ID', eventID);
      })
      .catch((error) => {
        const { request, response } = error;

        if (response) {
          switch (response.status) {
            case 401:
              console.error('Sessão expirada');
              doLogout(dispatchToAuth);
              console.log(`Desautenticado. Redirecionando para /app/${tenantName}/login`);
              navigate(`/app/${tenantName}/login`);
            
              break;
            default:
              console.error(error);

              break;
          }
        } else if (request) {
          // Solicitação enviada, mas nenhuma resposta recebida
          console.error("Server time out");
        } else {
          // Algo aconteceu na configuração da solicitação que acionou
          // um erro
          console.error(
            "OPS! Algo deu errado ao configurar a marcação de evento como lido"
          );
        }
      })
    ;
  };

  const addCommentLine = (e) => {
    if (commentValue !== '') {
      let updatedEvent = getEvent(parseInt(e.currentTarget.id));

      const comments = [...itemsList, commentValue];
      updatedEvent.comments = comments;

      // Atualiza o comentário no servidor
      updateComments(updatedEvent);
    }
  };

  const enterCommentLine = (e) => {
    if (e.charCode === 13) {
      addCommentLine(e);
    }
  };
  
  const handleCommentValue = (e) => {
    setCommentValue(e.target.value);
  };
  
  const handleAddComment = (e) => {
    e.preventDefault();
    
    addCommentLine(e);
  };
  
  const handleRemoveComment = (e) => {
    e.preventDefault();

    let updatedEvent = getEvent(parseInt(e.currentTarget.id));
    let row = parseInt(e.currentTarget.getAttribute("row"));

    const comments = itemsList.filter((item, index) => index !== row);
    updatedEvent.comments = comments;

    // Atualiza o comentário no servidor
    updateComments(updatedEvent);
  };

  return (
    <div className="comment-box">
      <ul className="comment-list" >
        {itemsList.map((comment, index) => (
          <li key={index} className="comment-item">
            <SimpleIcon>
              <CheckIcon />
            </SimpleIcon>
            <Content type='normal'>
              {comment}
            </Content>
            <Icon
              id={event.id}
              row={index}
              type='remove'
              tooltip='Excluir comentário'
              side='left'
              onClick={handleRemoveComment}
            >
              <RemoveIcon />
            </Icon>
          </li>
        ))}
      </ul>
      <div className="wrap-input">
        <span className="label-input">Mensagem</span>
        <div className="inline-fields">
          <input
            id={`eventcomment_${event.id}`}
            name="eventcomment"
            type="text"
            className="field-input"
            value={commentValue}
            onChange={handleCommentValue}
            onKeyPress={enterCommentLine}
            placeholder="Informe o comentário..."
          />
          <PrimaryButton
            id={event.id}
            contains="icon"
            size="small"
            onClick={handleAddComment}
            color={colorButton}>
            <AddIcon />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

function SimpleIcon({ children }) {
  return (
    <span
      className={classNames('iconHolder', 'check')}
    >
      { children }
    </span>
  );
}

function Icon({ type, tooltip, side='right', onClick, children, id = null, row=null }) {
  const tooltipClass = classNames(side, '200');

  return (
    <span
      className={classNames('iconHolder', type)}
      data-tooltip={tooltipClass}
      aria-label={tooltip}
      onClick={onClick}
      id={id}
      row={row}
    >
      { children }
    </span>
  );
}

function Content({ type, children }) {
  return (
    <span className={classNames('content', type)}>
      { children }
    </span>
  );
}

export default CommentBox;