import React from "react";

import "./styles.scss";

const Totalizer = ({ children }) => {
  return (
    <div className="totalizer">
      {children}
    </div>
  );
}

const TotalizerItem = ({ label, value, color }) => {
  return (
    <div
      className="totalizer__item"
      style={{
        backgroundColor: color
      }}
    >
      <div className="totalizer__item__label">
        {label}
      </div>
      <div className="totalizer__item__value">
        {value}
      </div>
    </div>
  );
}

export { Totalizer, TotalizerItem };