export const defaults = {
  api: {
    // Desenvolvimento
    // URL na máquina local
    // baseURL: "https://erp.test/api/v1"
    // Produção
    // URL na VPS
    baseURL: "https://api.mrfleet.com.br/api/v1"
  }
};
