import Api from './Api';
//import zlib from 'react-zlib-js';
import { Buffer } from 'buffer';
import Pako from 'pako';

/**
 * Recupera as últimas posições dos veículos do usuário.
 * 
 * @param {int} pageNumber 
 *   O número da página sendo requisitada
 * @param {int} itemsPerPage
 *   A quantidade de itens por página
 * @param {string} filter
 *   O filtro de pesquisa
 * @param {array} devicesOnPage
 *   A lista de dispositivos na página
 * 
 * @returns
 *   Os dados da requisição do registro de última posição
 */
const getLastPositions = async (
  pageNumber,
  itemsPerPage,
  filter,
  devicesOnPage
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: itemsPerPage,
    filter: filter,
    compress: true,
    withRouteData: devicesOnPage ? devicesOnPage.join(',') : ''
  };

  return Api
    .get("/position/last", { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        // Obtemos a string base64 codificada
        const compressedBase64Data = response.data.data;

        // Passo 1: Decodificar a string base64
        const compressedData = Buffer.from(
          compressedBase64Data, 'base64'
        );

        // Passo 2: Descomprimir o conteúdo em formato GZIP
        const inflatedData = Pako.inflateRaw(
          compressedData, {to: 'string'}
        );

        // Decodifica o conteúdo JSON.
        const data = JSON.parse(inflatedData);

        response.data.data = data;
        
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      });
    })
  ;
};

/**
 * Recupera o histórico de posições do veículo do usuário.
 * 
 * @param {int} pageNumber
 *   O número da página sendo requisitada
 * @param {int} pageSize
 *   A quantidade de itens por página
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * @param {int} speedLimit
 *   O limite de velocidade
 * 
 * @returns
 *   Os dados da requisição do histórico de posições
 */
const getPositionsHistory = async (
  pageNumber,
  pageSize,
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2,
  speedLimit = 0
) => {
  const queryParms = {
    page: pageNumber,
    itemsPerPage: pageSize,
    ...((speedLimit > 0) ? { speedLimit: speedLimit } : {})
  };
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;

  return Api
    .get(`/position/chronology/${uriData}`, { params: queryParms })
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};


/**
 * Recupera o histórico de percurso do veículo do usuário.
 * 
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * 
 * @returns
 *   Os dados da requisição do histórico de percurso
 */
const getRouteHistory = async (
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2
) => {
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;

  return Api
    .get(`/position/route/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

/**
 * Recupera o histórico de velocidade do veículo do usuário.
 * 
 * @param {string} object
 *   O tipo de objeto sendo pesquisado (veículo ou rastreador)
 * @param {int} id 
 *   O ID do objeto (veículo ou rastreador)
 * @param {bool} mainTracker
 *   O indicativo se desejamos obter o histórico do rastreador principal
 * @param {string} typeOfPeriod
 *   O tipo de período
 * @param {string} parm1
 *   O primeiro parâmetro complementar. Data inicial ou quantidade de
 *   dias/horas
 * @param {string} parm2
 *   O segundo parâmetro complementar. Data final
 * 
 * @returns
 *   Os dados da requisição do histórico de posições
 */
const getSpeedHistory = async (
  object,
  id,
  mainTracker,
  typeOfPeriod,
  parm1,
  parm2
) => {
  const main = (mainTracker) ? 'true' : 'false';

  const uriData = `${object}/${id}/${main}/${typeOfPeriod}/`
    + parm1
    + ((parm2) ? '/' + parm2 : '')
  ;

  return Api
    .get(`/position/speed/${uriData}`, {})
    .then((response) => {
      if (response.status === 'Success') {
        return response.data;
      }

      return Promise.reject({
        message: response.message,
        error: response.error
      })
    })
  ;
};

export {
  getLastPositions,
  getPositionsHistory,
  getRouteHistory,
  getSpeedHistory
};
