import React from "react";

function Panel({ children, vertical, style={}, ...rest }) {
  return (
    <div
      style={{
        ...styles.panel,
        flexDirection: vertical ? "column" : "row",
        ...style
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

const styles = {
  panel: {
    display: "flex",
    backgroundColor: "var(--theme-card-bg)",
    borderRadius: "0.375rem",
    boxShadow: "0 7px 14px 0 rgba(3, 12, 51, 0.15), 0 3px 6px 0 rgba(0, 0, 0, 0.2)"
  }
}

export default Panel;