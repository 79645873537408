import React from 'react';
import classNames from 'classnames';

import '../styles/NotificationIndicatorNumber.scss';

function NotificationIndicatorNumber({ number, color }) {
  return ( 
    <span
      className={classNames('notification-indicator-number', color)}
    >
      { number }
    </span>
  );
}

export default NotificationIndicatorNumber;